<template>
  <div class="timeline-phrase" data-testid="timeline-phrase">
    <slot
      v-for="({ element, text, slot, props: markupProps }, idx) in builtMarkup"
      :name="`content_part_${slot}`"
      :element="element"
      :idx="idx"
      :text="text"
    >
      <component v-bind="markupProps ?? null" :is="element" :key="idx">
        <template v-if="text?.length" #default>
          {{ text }}
        </template>
      </component>
      <template v-if="idx !== builtMarkup.length - 1"> &nbsp; </template>
    </slot>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import useTimelineContent from '@/composables/useTimelineContent';

const props = defineProps({
  activity: {
    type: Object,
    default: null,
  },
  accountName: {
    type: String,
    default: '',
  },
  phrase: {
    type: Array,
    default: null,
  },
});

const { buildMarkupByActivity } = useTimelineContent();
const builtMarkup = computed(() =>
  props.phrase
    ? props.phrase
    : buildMarkupByActivity(props?.activity, props?.accountName),
);
</script>

<style scoped lang="pcss">
.timeline-phrase {
  @apply mb-8 inline-flex items-center flex-wrap;
}
</style>
