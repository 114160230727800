import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { crossbeamApi } from '@/api';
import appConfig from '@/config';
import { PCLOUD_INTEGRATIONS } from '@/constants/integrations';
import { captureException } from '@/errors';
import { useFeatureFlagStore } from '@/stores/FeatureFlagsStore';
import { initStore } from '@/stores/store-utils';
import type {
  CrossbeamForSalesIntegration,
  EnabledTray,
  PCloudIntegration,
  PCloudIntegrationType,
  PartnerStackIntegration,
  SlackIntegration,
  TrayInstance,
  TraySolution,
  TrayStatus,
} from '@/types/integrations';
import urls from '@/urls';

import { useFlashesStore } from './FlashesStore';

export const usePartnerCloudStore = defineStore('PartnerCloud', () => {
  const availableIntegrations = ref<PCloudIntegration[]>([]);
  const enabledTray = ref<EnabledTray[]>([]);
  const enabledSalesEdge = ref<CrossbeamForSalesIntegration[]>([]);
  const slackAppIntegration = ref<SlackIntegration>({ is_enabled: false });
  const partnerStackIntegration = ref<PartnerStackIntegration>({
    is_enabled: false,
  });
  const inProgressInstallation = ref<string | null>(null);
  const trayStatuses = ref<TrayStatus[]>([]);

  const featureFlagStore = useFeatureFlagStore();
  const { addSuccessFlash } = useFlashesStore();

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    try {
      const availableFetchedIntegrations: PCloudIntegration[] = [];
      const enabledTrayIntegrations: EnabledTray[] = [];
      const fetchedTraySolutions: Record<string, TraySolution> = {};
      const fetchedTrayInstances: Record<string, TrayInstance> = {};
      const enabledSalesEdgeIntegrations: CrossbeamForSalesIntegration[] = [];

      const [slackResponse, psResponse, trayResponse] = await Promise.all([
        crossbeamApi.GET('/v0.1/slack-app/slack-integration'),
        axios.get<PartnerStackIntegration>(urls.partnerStack.integration), // TODO: replace with crossbeamApi.GET when available
        crossbeamApi.GET('/v0.1/tray-integrations'),
      ]);

      if (slackResponse.data) slackAppIntegration.value = slackResponse.data;
      partnerStackIntegration.value = psResponse.data;

      trayResponse.data?.solutions.forEach((solution) => {
        const tag = solution.node.tags.find((tag) => tag.includes('xb'));
        if (tag) fetchedTraySolutions[tag] = { id: solution.node.id };
      });

      trayResponse.data?.instances.forEach((instance) => {
        const tag = instance.node.solution.tags.find((tag) =>
          tag.includes('xb'),
        );
        if (tag) {
          fetchedTrayInstances[tag] = {
            type: instance.node.solution.title,
            needsUpdate:
              instance.node.solutionVersionFlags
                .requiresUserInputToUpdateVersion,
            ...instance.node,
          };
        }
      });

      if (trayResponse) {
        trayStatuses.value = trayResponse.data?.statuses?.length
          ? trayResponse.data.statuses
          : [];
      }

      const isTrayIntegration = (
        integration: PCloudIntegration,
      ): integration is PCloudIntegration & { trayTag: string } => {
        return integration.isTray === true && 'trayTag' in integration;
      };

      PCLOUD_INTEGRATIONS.forEach((pCloudIntegration) => {
        if (
          pCloudIntegration.type === 'partner_stack' &&
          partnerStackIntegration.value?.is_enabled
        ) {
          return;
        }

        const trayTag = isTrayIntegration(pCloudIntegration)
          ? pCloudIntegration.trayTag
          : null;

        const mergedIntegration = {
          ...(trayTag ? (fetchedTraySolutions[trayTag] ?? {}) : {}),
          ...(trayTag ? (fetchedTrayInstances[trayTag] ?? {}) : {}),
          ...pCloudIntegration,
        };

        const isTrayEnabled = !!(
          mergedIntegration.isTray && 'enabled' in mergedIntegration
        );

        /* the `unavailableWithoutFeatureFlag` property let's us hide integrations
            that aren't ready for public consumption but are ready for us to start testing!
            If it doesn't have the property specified, this returns true
            */
        const isAvailable =
          !mergedIntegration.unavailableWithoutFeatureFlag ||
          featureFlagStore.hasFeatureFlag(mergedIntegration.ff);

        if (isTrayEnabled) {
          enabledTrayIntegrations.push(mergedIntegration as EnabledTray);
        } else if (isAvailable) {
          availableFetchedIntegrations.push(mergedIntegration);
        }
      });

      let integrations;
      try {
        const { data } = await axios.get(
          `${appConfig.salesEdgeBaseUrl}/extensions/status/third-party-integration-statuses`,
        );
        integrations = data.integrations;
      } catch (xhr) {
        integrations = [];
      }

      const salesEdgeInfo = PCLOUD_INTEGRATIONS.find(
        (integration) => integration.type === 'salesedge-hubspot',
      );

      for (const cur of integrations ?? []) {
        if (cur.is_active && salesEdgeInfo?.type === 'salesedge-hubspot') {
          enabledSalesEdgeIntegrations.push({
            installUser: cur.install_user, // { email: string, name: string, id: integer }
            installDate: cur.install_date, // unix timestamp with 5 decimal places
            isActive: cur.is_active, // boolean
            ...(salesEdgeInfo as PCloudIntegration & {
              type: 'salesedge-hubspot';
            }),
          });
        }
      }

      availableIntegrations.value = availableFetchedIntegrations;
      enabledTray.value = enabledTrayIntegrations;
      enabledSalesEdge.value = enabledSalesEdgeIntegrations;
    } catch (xhr) {
      const flashesStore = useFlashesStore();
      flashesStore.addErrorFlash({
        message: 'Failed to load some integrations',
        description: `If this error persists, please contact
              <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>.`,
      });
    }
  });

  refresh({ useCache: true });

  function getAvailableIntegrationByType(type: PCloudIntegrationType) {
    return availableIntegrations.value?.find(
      (integration) => type === integration.type,
    );
  }

  function getStatusesByInstanceId(id: string) {
    return trayStatuses.value.filter(
      (status) => status.solution_instance_id === id,
    );
  }

  async function deleteIntegration(info: EnabledTray) {
    const body = { instance_id: info.id, instance_name: info.name };
    const { error } = await crossbeamApi.DELETE(`/v0.1/tray-integrations`, {
      body,
    });

    if (error) {
      captureException(error);
      throw new Error(error);
    }

    await refresh();
    addSuccessFlash({ message: 'Successfully deleted integration' });
  }

  return {
    error,
    ready,
    readySync,
    running,
    refreshPartnerCloudStore: refresh,
    getAvailableIntegrationByType,
    getStatusesByInstanceId,
    enabledSalesEdge,
    enabledTray,
    slackAppIntegration,
    partnerStackIntegration,
    inProgressInstallation,
    availableIntegrations,
    trayStatuses,
    deleteIntegration,
  };
});
