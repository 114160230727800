<template>
  <BittsCard class="h-80 w-full px-16 py-24">
    <div>
      <div class="flex gap-16 items-start">
        <BittsBone
          type="heading"
          class="max-w-[200px] min-w-[40px]"
          :width="20"
        />
        <BittsTag
          size="x-small"
          color="upsell"
          variant="rounded"
          text="Custom"
        />
        <div class="flex gap-16 w-full mr-72">
          <div
            v-for="i in [1, 2, 3, 4]"
            :key="i"
            class="w-full flex flex-col gap-16"
          >
            <BittsBone
              :key="i"
              type="heading"
              :width="getWidth(40)"
              :height="7"
            />
            <BittsBone
              :key="i"
              type="heading"
              :width="getWidth(50)"
              :height="12"
            />
          </div>
        </div>
      </div>
    </div>
    <BittsBone />
  </BittsCard>
</template>

<script setup>
import { BittsBone, BittsCard, BittsTag } from '@crossbeam/bitts';

function getWidth(multiplier) {
  return Math.floor(Math.max(20, Math.random() * multiplier));
}
</script>
