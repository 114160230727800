import { EVENT_SITES } from './analytics';
import iteratively from './itly';

// note, we use a segment plugin with iteratively, so it will load segment.
// Since our prod segment key has a fullstory integration set up via the segment UI, it will also
// load fullstory for prod

export default function useIteratively({
  captureException,
  environment,
  useCopilotStore = null,
  uuid,
}) {
  const appTrackingObject = (trackingObject, currentUser, currentOrg) => {
    const userEmail = currentUser?.email || '';
    const userId = currentUser?.id?.toString() || '';
    const organizationId = currentOrg?.id?.toString() || '';

    if (!trackingObject.properties) trackingObject.properties = {};
    trackingObject.properties.email = userEmail;
    trackingObject.properties.user_id = userId;
    trackingObject.properties.organization_id = organizationId;
    trackingObject.properties.device_id = uuid;

    if (!trackingObject.properties.event_site)
      trackingObject.properties.event_site = EVENT_SITES.MISSING_EVENT_SITE;
    if (useCopilotStore) {
      const copilotStore = useCopilotStore();
      copilotStore?.source
        ? (trackingObject.properties.source = copilotStore.source)
        : (trackingObject.properties.source = '');
    }
    return trackingObject;
  };

  const isInitialized = iteratively.itly.itly.options;

  const loadIteratively = () => {
    if (!isInitialized) {
      iteratively.load({ environment });
    }
  };

  const setTrackingOptions = (currentUser, currentOrg) => {
    const originalTrackingFunc = iteratively.itly.track;
    /* Overwrite the track function to add more info
     * We're sending the organization ID, device ID, user ID, and email
     * properties automatically. If an event_site properties is not supplied,
     * we tell analytics with MISSING_EVENT_SITE.
     * We are intentionally swallowing iteratively errors so as to not crash the application */

    iteratively.itly.track = (trackingObject = {}) => {
      try {
        const updatedTrackingObject = appTrackingObject(
          trackingObject,
          currentUser,
          currentOrg,
        );
        originalTrackingFunc(updatedTrackingObject);
      } catch (err) {
        if (import.meta.env.DEV) console.log(err);
        else if (captureException) captureException(err);
      }
    };
  };

  const captureLoggedInUser = (currentUser, currentOrg) => {
    if (currentUser?.id) {
      iteratively.identify(currentUser.id.toString(), {
        email: currentUser.email,
      });
    }
    if (currentOrg?.id) {
      iteratively.group(currentOrg.id.toString(), {
        name: currentOrg.name,
      });
    }
  };

  return {
    iteratively,
    loadIteratively,
    setTrackingOptions,
    captureLoggedInUser,
  };
}
