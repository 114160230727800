<template>
  <BittsCard
    :class="{
      highlighted: !!highlight,
    }"
    class="c-billing-plan-card"
  >
    <div class="c-billing-plan-card__content">
      <div v-if="!!highlight" class="highlight-tag">
        <BittsTag color="early-adopter" variant="rounded">
          <template #pre>
            <FontAwesomeIcon
              v-if="tier === 'connector'"
              class="mr-4 text-12"
              :icon="['fak', 'upgrade']"
            />
          </template>
          <span>
            {{ highlight }}
          </span>
        </BittsTag>
      </div>
      <div class="section">
        <h4 class="text-subheader">
          {{ capitalize(props.tier) }}
        </h4>
      </div>
      <div class="section">
        <div :class="{ 'flex gap-6 items-end': isConnectorTier }">
          <h2 class="card-price">
            {{ corePrice }}
          </h2>
          <div class="text-normal" :class="{ 'mb-4': isConnectorTier }">
            {{ V4_BILLING_COPY.explorePlanCards[props.tier].subtitle }}
          </div>
        </div>
        <p v-if="isConnectorTier" class="text-success-text mb-2">
          {{ `or ${salesPrice} per seat/month for Sales seats` }}
        </p>
        <p
          v-if="props.seats"
          class="text-normal"
          :class="{ 'text-success-text': isConnectorTier }"
        >
          {{ props.seats }}
        </p>
      </div>
      <div class="section" :class="{ 'mb-2': isConnectorTier }">
        <slot name="action" />
      </div>
      <div
        v-for="section in featureSections"
        :key="section"
        class="section"
        :class="`section--${section}`"
      >
        <h6 class="section-title" :class="`section-title--${section}`">
          {{ sectionTitles[section] }}
        </h6>
        <ul>
          <li v-for="item in filteredFeatures(section)" :key="item.text">
            <span v-if="section === 'top'" class="text-neutral-400"> • </span>
            <FontAwesomeIcon
              v-else-if="['sales', 'partnerships'].includes(section)"
              class="text-neutral-400"
              :icon="
                isFreeTier && section === 'sales'
                  ? ['fak', 'ban']
                  : ['fak', 'add']
              "
            />
            <component
              :is="item.tooltip ? BittsTooltip : 'span'"
              v-if="section !== 'integrations'"
              placement="bottom"
            >
              <template #title>
                <p>
                  {{ item.tooltip }}
                </p>
              </template>
              <p class="text-neutral-text">
                <span
                  v-html="
                    item.tooltip
                      ? injectStrings(item.text, {
                          values: [item.injection],
                          underline: true,
                        })
                      : item.text
                  "
                />
              </p>
            </component>
            <BittsTag
              v-else
              color="partner"
              size="small"
              variant="rounded"
              class="c-billing-plan-card__integration-tag"
            >
              <template #pre>
                <BittsSvg
                  v-if="item.svg"
                  class="w-12 h-12 mr-8"
                  :svg="item.svg"
                />
                <FontAwesomeIcon
                  v-else-if="item.icon"
                  class="mr-4 h-12"
                  :icon="item.icon"
                />
              </template>
              <span>
                {{ item.text }}
              </span>
            </BittsTag>
          </li>
        </ul>
      </div>
    </div>
  </BittsCard>
</template>
<script setup>
import { BittsCard, BittsSvg, BittsTag, BittsTooltip } from '@crossbeam/bitts';

import { capitalize } from 'lodash';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import {
  FULL_ACCESS,
  SALES_ONLY,
  SEAT_COSTS,
  V4_BILLING_COPY,
} from '@/constants/billing';
import {
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { centsToDollars, injectStrings } from '@/utils';

const props = defineProps({
  tier: {
    type: String,
    required: true,
  },
  highlight: {
    type: String,
    default: null,
  },
  period: {
    type: String,
    default: '',
  },
});

const { currentOrg } = useAuth();
const isFreeTier = computed(() => props.tier === 'explorer');
const isConnectorTier = computed(() => props.tier === 'connector');
const isEnterpriseTier = computed(() => props.tier === 'supernode');

const sectionTitles = computed(() => {
  return {
    top: isFreeTier.value
      ? 'Includes:'
      : `Everything in ${isConnectorTier.value ? 'Explorer' : 'Connector'} plus:`,
    partnerships: 'For Partnerships',
    sales: 'For Sales',
    integrations: 'Available Integrations',
  };
});

const preferredCrm = computed(() => currentOrg.value.preferred_crm);
function filteredFeatures(section) {
  return V4_BILLING_COPY.explorePlanCards[props.tier].features[section].filter(
    (feature) => {
      if (section !== 'integrations') return true;
      if (feature.svg?.startsWith(SALESFORCE_DATA_SOURCE_TYPE))
        return preferredCrm.value === SALESFORCE_DATA_SOURCE_TYPE;
      if (feature.svg?.startsWith(HUBSPOT_DATA_SOURCE_TYPE))
        return (
          preferredCrm.value === HUBSPOT_DATA_SOURCE_TYPE ||
          preferredCrm.value === HS3_DATA_SOURCE_TYPE
        );
      return true;
    },
  );
}

const corePrice = computed(() => {
  if (isFreeTier.value) return 'Free';
  if (isEnterpriseTier.value) return 'Custom';
  return centsToDollars(
    SEAT_COSTS[FULL_ACCESS][props.period] / (props.period === 'year' ? 12 : 1),
  );
});

const salesPrice = computed(() => {
  if (isFreeTier.value || isEnterpriseTier.value) return null;
  return centsToDollars(
    SEAT_COSTS[SALES_ONLY][props.period] / (props.period === 'year' ? 12 : 1),
  );
});

const featureSections = computed(() =>
  Object.keys(V4_BILLING_COPY.explorePlanCards[props.tier].features),
);
</script>
<style lang="pcss">
.c-billing-plan-card.bitts-container {
  @apply h-full;
  .c-billing-plan-card__content {
    @apply p-24 w-full flex flex-col items-start gap-24;
    .highlight-tag {
      @apply w-full left-0 absolute top-[-12px] flex justify-center;
    }
    .section {
      @apply w-full;
    }
  }
}
</style>

<style lang="pcss" scoped>
.c-billing-plan-card {
  @apply relative;
  .text-normal {
    @apply text-neutral-text-weak text-base font-normal;
  }

  .text-subheader {
    @apply text-lg  text-neutral-text-strong font-bold;
  }

  .card-price {
    @apply text-xxl text-neutral-text-strong;
  }

  .section {
    .section-title {
      @apply font-bold mb-8;
      &.section-title--partnerships {
        @apply text-primary-accent;
      }
      &.section-title--sales {
        @apply text-green-700;
      }
    }

    li {
      @apply list-disc text-neutral-text-weak py-4 flex items-center gap-16 text-base;
    }

    &.section--top {
      @apply mb-8 pb-24 border-b min-h-[180px] border-neutral-border;
    }
    &.section--partnerships {
      @apply min-h-[124px];
    }
    &.section--sales {
      @apply min-h-[190px];
    }
    &.section--integrations {
      ul {
        @apply flex flex-wrap;
      }
    }
  }
}
</style>
<style lang="pcss">
.c-billing-plan-card.bitts-container {
  background: radial-gradient(
    91.08% 26.5% at 50% -12.42%,
    v-bind(radialGradient) 0%,
    theme(colors.white / 0) 100%
  );
  &.highlighted {
    border: solid 2px transparent;
    border-radius: 16px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-image: linear-gradient(white, white),
      linear-gradient(
        90.04deg,
        theme(colors.info.accent) 0%,
        theme(colors.info.text) 100%
      );
  }
}

.c-billing-plan-card__integration-tag.bitts-tag {
  .ant-tag {
    @apply cursor-pointer flex items-center;
  }
}
</style>
