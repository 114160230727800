export default function useNotifications() {
  const parseFeedIdFromFeedProcessedEvent = (notificationEvent) => {
    /* for csvs and google sheets, the event object id is json instead of just the feed id */
    const objectId = notificationEvent.object_id;
    if (typeof objectId === 'object' && objectId && 'feed_id' in objectId) {
      return objectId.feed_id;
    }
    return objectId;
  };

  return {
    parseFeedIdFromFeedProcessedEvent,
  };
}
