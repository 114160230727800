<template>
  <div
    v-for="(
      { mention_link, user_name, user_email, sentence }, idx
    ) in shownSentences"
    :key="activity.uuid + '__' + idx"
    class="gong-call-context"
  >
    <div class="mention-info">
      <BittsAvatar
        :user="{ email: user_email, ...buildName(user_name) }"
        size="x-small"
        :show-initials="true"
      />
      <span>{{ user_name }}</span>
      <span>•</span>
      <button
        class="text-secondary-text"
        target="_blank"
        data-testid="gong-mention-link"
        @click="onClickDeepLink(mention_link)"
        type="button"
      >
        {{ buildTimestamp(mention_link) }}
      </button>
    </div>
    <p
      data-testid="mention-sentence"
      v-html="buildHighlightedSentence(sentence)"
    />
  </div>
  <button
    v-if="mentions.length > initialNumShown"
    class="view-more-mentions"
    data-testid="view-all-mentions"
    @click="showAll = !showAll"
    type="button"
  >
    <FontAwesomeIcon
      :icon="['fak', 'chevron-down']"
      :class="{ 'rotate-180': showAll }"
      class="transition-all"
    />{{ showAll ? 'Collapse mentions' : 'View all mentions' }}
  </button>
</template>

<script setup>
import { BittsAvatar } from '@crossbeam/bitts';
import { ATTRIBUTION_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { first } from 'lodash';
import { Duration } from 'luxon';
import { computed, ref } from 'vue';

import useIteratively from '@/composables/useIteratively';
import { usePartnersStore } from '@/stores';

const props = defineProps({
  activity: {
    type: Object,
    default: null,
  },
});

const partnerStore = usePartnersStore();
const partner = computed(
  () =>
    partnerStore.getPartnerOrgByUuid(
      props.activity.partner_organization_uuid,
    ) ?? {},
);

const initialNumShown = 4;
const showAll = ref(false);

const mentions = computed(() => props.activity?.data?.mentions || []);
const shownSentences = computed(() => {
  return mentions.value.slice(0, showAll.value ? Infinity : initialNumShown);
});

const buildTimestamp = (link) => {
  const highlights = new URLSearchParams(link).get('highlights');
  const seconds = JSON.parse(decodeURIComponent(highlights))?.at(-1)?.from;
  if (!seconds) return '--:--';

  const duration = Duration.fromObject({ seconds });

  if (duration.seconds > 3600) return duration.toFormat('hh:mm:ss');
  return duration?.toFormat('mm:ss');
};

const buildName = (name) => {
  if (!name) return { first_name: '', last_name: '' };
  const nameParts = name.split(' ');

  return {
    first_name: first(nameParts),
    last_name: nameParts.length > 1 ? nameParts[1] : '',
  };
};

const partnerWithoutLLCOrInc = computed(() =>
  partner.value.name
    .replace(/(,?\s+(LLC|Inc)\.?)$|/gi, '')
    .replace(/\s+/gi, ''),
);

/* case insensitive and global to highlight all instances, also matching if white spaces are present.
  example... for DC Comics: dccomics, dc comics, and d c comics will match */
const partnerRegex = computed(
  () =>
    new RegExp(
      `${partnerWithoutLLCOrInc.value
        .split('')
        .map((char) => {
          if (char === ',') return '\\s*,?\\s*'; // replace comma with optional comma and white spaces
          if (char === '.') return '\\s*\\.?\\s*'; // replace dot with optional dot and white spaces
          return char;
        })
        .join('(\\.\\s*|\\s*)')}(\\s*,?\\s+(LLC|Inc)\\.?\\s*?)?`, // match LLC or Inc at the end, we're replacing with the partner name so we want that as optional
      'gi',
    ),
);

const buildHighlightedSentence = (sentence) => {
  // truncate sentence if it's too long
  const maxSentenceLength = 300;

  const truncatedString =
    sentence.length > maxSentenceLength
      ? `"${sentence.substring(0, maxSentenceLength)}..."`
      : `"${sentence}"`;

  const newSentence = truncatedString.replace(
    partnerRegex.value,
    `<strong class="highlight-me">${partner.value.name}</strong>`,
  );
  return newSentence;
};

const { iteratively } = useIteratively();

function onClickDeepLink(url) {
  iteratively.userInteractedWithTimeline({
    cta: ATTRIBUTION_CTAS.GONG_MENTIONS,
    cta_2: partner.value.name,
    event_site: EVENT_SITES.GONG_MENTIONS,
  });

  window.open(url, '_blank');
}
</script>

<style scoped lang="pcss">
.gong-call-context {
  @apply text-sm text-neutral-text mb-16;
}

.mention-info {
  @apply flex items-center gap-4 mb-4;
}

.view-more-mentions {
  @apply flex items-center gap-4 text-neutral-text-weak mb-16;
}

:deep() .highlight-me {
  @apply bg-info-background-weak px-4 rounded-lg font-bold text-info-text-strong;
}
</style>
