import { ref } from 'vue';

// eslint-disable-next-line import/order
import { ATTRIBUTION_PAGE_LIMIT } from '@/constants/attribution';

const rollupMetrics = ref<RollupMetricItem[]>([
  { type: 'total_sourced', value: 0 },
  { type: 'total_influenced', value: 0 },
  { type: 'total_unattributed', value: 0 },
  { type: 'time_to_close_with_partners', value: 0 },
]);

import {
  AttributionOpportunity,
  Metrics,
  Pagination,
  RollupMetricItem,
} from '@/types/attribution';

const eiAttributionMetrics = ref<Metrics>({
  total_sourced: null,
  total_influenced: null,
  total_unattributed: null,
  total_attributed_count: 0,
  total_unattributed_count: 0,
  total_attributed: null,
  deal_size_with_partners: null,
  deal_size_without_partners: null,
  time_to_close_with_partners: null,
  time_to_close_without_partners: null,
  win_rate_with_partners: null,
  win_rate_without_partners: null,
});

const opportunities = ref<AttributionOpportunity[]>([]);
const pagination = ref<Pagination>({
  last_page: 0,
  limit: ATTRIBUTION_PAGE_LIMIT,
  next_href: null,
  page: 1,
  total_count: 0,
});
const unattributedOpportunities = ref<AttributionOpportunity[]>([]);
const accountActivities = ref([]);
const isCached = ref(false);

function $reset() {
  opportunities.value = [];
  pagination.value = {
    last_page: 0,
    limit: ATTRIBUTION_PAGE_LIMIT,
    next_href: null,
    page: 1,
    total_count: 0,
  };
  unattributedOpportunities.value = [];
  accountActivities.value = [];
  isCached.value = false;
  rollupMetrics.value = [
    { type: 'total_sourced', value: 0 },
    { type: 'total_influenced', value: 0 },
    { type: 'total_unattributed', value: 0 },
    { type: 'time_to_close_with_partners', value: 0 },
  ];
  eiAttributionMetrics.value = {
    total_sourced: null,
    total_influenced: null,
    total_unattributed: null,
    total_attributed_count: 0,
    total_unattributed_count: 0,
    total_attributed: null,
    deal_size_with_partners: null,
    deal_size_without_partners: null,
    time_to_close_with_partners: null,
    time_to_close_without_partners: null,
    win_rate_with_partners: null,
    win_rate_without_partners: null,
  };
}

export function useAttributionState() {
  return {
    rollupMetrics,
    opportunities,
    pagination,
    unattributedOpportunities,
    accountActivities,
    eiAttributionMetrics,
    isCached,
    $reset,
  };
}
