<template>
  <a
    href="https://help.crossbeam.com/en/articles/5246994-flagging-incorrect-matches"
    target="_blank"
    @click.stop
  >
    <BittsTooltip popover-class="c-match-issue__popover">
      <BittsSvg svg="alertTriangle" class="c-match-issue-flag__icon" />
      <template #title>
        <div class="c-match-issue__popover-text">
          {{ issueMessage }} Click warning icon to learn more.
        </div>
      </template>
    </BittsTooltip>
  </a>
</template>

<script>
import { BittsSvg, BittsTooltip } from '@crossbeam/bitts';

import useAuth from '@/composables/useAuth';

export default {
  name: 'MatchIssueFlag',
  components: {
    BittsSvg,
    BittsTooltip,
  },
  props: {
    matchIssues: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { currentOrg, currentUser } = useAuth();

    return { currentOrg, currentUser };
  },
  computed: {
    issueMessage() {
      return (
        this.selfReportMessage ||
        this.orgReportMessage ||
        this.partnerReportMessage
      );
    },
    selfReportMessage() {
      return this.matchIssues.find(
        (mi) => mi.reporting_user.id === this.currentUser.id,
      )
        ? 'You reported this as an inaccurate match.'
        : null;
    },
    orgReportMessage() {
      const issue = this.matchIssues.find(
        (mi) => mi.reporting_organization.id === this.currentOrg.id,
      );
      if (issue) {
        const user = issue.reporting_user;
        return `${user.first_name} ${user.last_name} reported this as an inaccurate match.`;
      }
      return null;
    },
    partnerReportMessage() {
      const user = this.matchIssues[0].reporting_user;
      const org = this.matchIssues[0].reporting_organization;
      return (
        `${user.first_name} ${user.last_name} from ${org.name} ` +
        'reported this as an inaccurate match.'
      );
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-match-issue-flag__icon:deep(path) {
  @apply text-neutral-400;
  fill: currentColor;
}
</style>
