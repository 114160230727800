<template>
  <div />
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { GOOGLE_SHEETS_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { useFeedsStore } from '@/stores';

type Props = {
  feedId: number;
  isOnboarding?: boolean;
};

/* This component routes to the sheet connection view after Google Sheets is connected 
It can be expanded in the future to accomodate other data source types if our 
oAuth callback location remains the same */
const props = defineProps<Props>();
const router = useRouter();
const feedsStore = useFeedsStore();

useHead({ title: 'Crossbeam - Data Sources' });

onMounted(async () => {
  await feedsStore.refreshFeedsStore();
  const feed = feedsStore.getFeedById(props.feedId);
  switch (feed?.integration.type) {
    case GOOGLE_SHEETS_DATA_SOURCE_TYPE: {
      const name = props.isOnboarding
        ? 'onboarding-google-sheets'
        : 'google-sheets';
      await router.push({ name });
    }
  }
});
</script>
