<template>
  <BittsModalTwo
    save-text="Continue"
    variant="info"
    :icon="['fak', 'phone']"
    title="We'll be in touch soon"
    primary-button-text="Continue"
    :description="`As part of the cancellation process, a team member will reach out to you before your plan is cancelled. On ${subscriptionRenewalDate}, your account will be downgraded to our free Explorer account.`"
    :use-mask-to-close="true"
    @action="handleClick"
  />
</template>

<script setup lang="ts">
import { BittsModalTwo } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { useBillingStore } from '@/stores';

const router = useRouter();

const billingStore = useBillingStore();
const { subscriptionRenewalDate } = storeToRefs(billingStore);

async function handleClick() {
  await router.push({ name: 'billing' });
}
</script>
