<template>
  <BittsLoading :is-loading="!ready">
    <div class="seat-requests-table">
      <div class="seat-requests-table__header-section">
        <BittsSegment
          :initial-active-index="activeIndex"
          :tabs="SEAT_REQUEST_TABS"
          @change:segment="setActiveIndex"
        />
        <BittsInput
          v-model="search"
          type="search"
          data-testid="team-table-search"
          size="small"
          placeholder="Search for users"
          name="seat-requests-search"
          class="max-w-[240px]"
        />
      </div>
      <BittsTable
        class="seat-requests-table__table fade-in"
        :columns="columns"
        :rows="rows"
        :hide-pagination="rows.length <= PAGE_SIZE"
        :pagination-page-size="PAGE_SIZE"
        :enable-cell-text-selection="true"
        :suppress-row-click-selection="true"
        :compress-columns="true"
        :row-height="64"
      />
    </div>
  </BittsLoading>
</template>

<script setup>
import {
  BittsInput,
  BittsLoading,
  BittsSegment,
  BittsTable,
} from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import SeatRequestTableActionsCell from '@/components/team/seat-requests/SeatRequestTableActionsCell.vue';
import SeatRequestTableDateCell from '@/components/team/seat-requests/SeatRequestTableDateCell.vue';
import SeatRequestTableMessageCell from '@/components/team/seat-requests/SeatRequestTableMessageCell.vue';
import SeatRequestTableTypeCell from '@/components/team/seat-requests/SeatRequestTableTypeCell.vue';
import SeatRequestTableUserCell from '@/components/team/seat-requests/SeatRequestTableUserCell.vue';

import { useSeatRequestsStore } from '@/stores';
import { shallowObjectSearch, sortByKey } from '@/utils';

const seatRequestsStore = useSeatRequestsStore();
const { ready, fullAccessRequests, salesAccessRequests } =
  storeToRefs(seatRequestsStore);
watch(ready, () => {
  if (
    salesAccessRequests.value.length > 0 &&
    fullAccessRequests.value.length === 0
  )
    activeIndex.value = 2;
});

/* Search */
const search = ref('');
const fullAccessRequestsSearched = computed(() =>
  fullAccessRequests.value.filter((request) =>
    shallowObjectSearch(
      request,
      ['email', 'first_name', 'last_name'],
      search.value,
    ),
  ),
);
const salesAccessRequestsSearched = computed(() =>
  salesAccessRequests.value.filter((request) =>
    shallowObjectSearch(
      request,
      ['email', 'first_name', 'last_name'],
      search.value,
    ),
  ),
);

/* Tabs */
const activeIndex = ref(0);
function setActiveIndex(_, idx) {
  activeIndex.value = idx;
}
const SEAT_REQUEST_TABS = computed(() => [
  {
    title: 'All',
    count:
      fullAccessRequestsSearched.value.length +
      salesAccessRequestsSearched.value.length,
    disabled:
      fullAccessRequestsSearched.value.length === 0 &&
      salesAccessRequestsSearched.value.length === 0,
  },
  {
    title: 'Full Access',
    count: fullAccessRequestsSearched.value.length,
    disabled: fullAccessRequestsSearched.value.length === 0,
  },
  {
    title: 'Sales',
    count: salesAccessRequestsSearched.value.length,
    disabled: salesAccessRequestsSearched.value.length === 0,
  },
]);

/* Table */
const PAGE_SIZE = 10;
const rows = computed(() => {
  if (activeIndex.value === 0)
    return [
      ...fullAccessRequestsSearched.value,
      ...salesAccessRequestsSearched.value,
    ];
  if (activeIndex.value === 1) return fullAccessRequestsSearched.value;
  return salesAccessRequestsSearched.value;
});
const columns = computed(() => [
  {
    field: 'first_name',
    cellRenderer: SeatRequestTableUserCell,
    suppressMovable: true,
    sortable: true,
    width: 300,
    headerComponentParams: { displayName: 'Name', showArrow: true },
    comparator: (_, __, { data: a }, { data: b }) => {
      return a.first_name.localeCompare(b.first_name);
    },
  },
  {
    field: 'type',
    cellRenderer: SeatRequestTableTypeCell,
    suppressMovable: true,
    sortable: true,
    headerComponentParams: { displayName: 'Seat Type', showArrow: true },
    comparator: (_, __, { data: a }, { data: b }) => {
      return a.type.localeCompare(b.type);
    },
  },
  {
    field: 'message',
    cellRenderer: SeatRequestTableMessageCell,
    width: 300,
    suppressMovable: true,
    headerComponentParams: { displayName: 'Message' },
  },
  {
    field: 'created_at',
    cellRenderer: SeatRequestTableDateCell,
    suppressMovable: true,
    sort: 'asc',
    sortable: true,
    headerComponentParams: { displayName: 'Received' },
    comparator: (_, __, { data: a }, { data: b }) => {
      return sortByKey('created_at', 'desc', 'raw')(a, b);
    },
  },
  {
    field: '',
    cellRenderer: SeatRequestTableActionsCell,
    suppressMovable: true,
  },
]);
</script>
<style lang="pcss" scoped>
.seat-requests-table__header-section {
  @apply flex justify-between items-center mb-16;
}
</style>

<style lang="pcss">
.seat-requests-table__table {
  .ag-row .ag-cell:last-of-type {
    @apply justify-end;
  }
}
</style>
