<template>
  <div class="c-delete-partnership">
    <BittsModal
      class="c-delete-partnership__modal"
      :width="600"
      :visible="true"
      :use-mask-to-close="false"
      :use-keyboard-to-close="false"
      :show-close-button="false"
      :loading="loading"
    >
      <template #title>
        <div class="c-delete-partnership__modal_header">
          <div class="heart-box">
            <FontAwesomeIcon
              :icon="['fak', isOffline ? 'delete' : 'heart-break']"
              class="heart-icon"
            />
          </div>
          <h1>{{ modalTitle }}</h1>
        </div>
      </template>
      <template #content>
        <div class="c-delete-partnership__modal_content">
          <div class="warnings">
            <h3>Here's what will happen:</h3>
            <p v-for="(change, i) in changes" :key="`change__${i}`">
              <FontAwesomeIcon :icon="['fak', 'x']" class="icon" />
              {{ change }}
            </p>
          </div>
          <div class="confirmation">
            <h4>Type DELETE to confirm</h4>
            <BittsInput
              v-model="confirmationInput"
              placeholder="DELETE"
              name="confirmation-input"
              :status="
                confirmationInput.length && !isInputValid ? 'danger' : 'default'
              "
              danger-text="Please enter the text exactly as displayed to confirm"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <footer class="c-delete-partnership__modal_footer">
          <BittsButton
            text="Cancel"
            type="neutral"
            size="medium"
            class="w-full"
            :loading="loading"
            @click="handleClose"
          />
          <BittsButton
            text="Delete Partner"
            type="danger"
            size="medium"
            class="w-full"
            :loading="loading"
            :disabled="!isInputValid"
            @click="handleSubmit"
          />
        </footer>
      </template>
    </BittsModal>
  </div>
</template>

<script setup>
import { BittsButton, BittsInput, BittsModal } from '@crossbeam/bitts';

import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import usePartnerManagement from '@/composables/usePartnerManagement';
import { usePartnersStore } from '@/stores';

const router = useRouter();
const partnersStore = usePartnersStore();
const { deletePartnership } = usePartnerManagement();

const route = useRoute();
const partnerId = computed(() => route.params.partner_org_id);

const partner = computed(() =>
  partnersStore.getPartnerOrgById(partnerId.value),
);

const isOffline = computed(() => partner.value?.offline_partner);
const modalTitle = computed(() =>
  isOffline.value
    ? `Delete ${partner.value?.name}?`
    : `Delete ${partner.value?.name} as a partner?`,
);
const changes = computed(() =>
  isOffline.value
    ? [
        'This offline partner will be deleted entirely from Crossbeam',
        'All data uploaded for this partner will be deleted',
        'This partner will be removed from any saved reports',
        'This action will be permanent and irreversible',
      ]
    : [
        'You will no longer see this partner in Crossbeam',
        'All data sharing will immediately stop on both sides',
        'This partner will be removed from any saved reports',
        'Integrations will stop updating records from this partner',
        'Your partner will not be notified that you deleted them',
        'This action will be permanent and irreversible',
      ],
);

const confirmationInput = ref('');
const loading = ref(false);
const isInputValid = computed(
  () => confirmationInput.value.toLowerCase() === 'delete',
);

async function handleSubmit() {
  loading.value = true;
  await deletePartnership({ partner: partner.value });
  await router.push({ name: 'partners' });
}

async function handleClose() {
  await router.push({ path: route.query.cancelDestination });
}
</script>

<style lang="pcss" scoped>
.c-delete-partnership__modal_header {
  @apply flex flex-col gap-16 items-center w-full mb-24;

  .heart-box {
    @apply w-120 h-120 flex items-center;
    background: radial-gradient(
        136.96% 69.92% at 50.09% -12.42%,
        theme(colors.danger.accent / 0.3) 0%,
        theme(colors.white / 0) 100%
      ),
      linear-gradient(0deg, theme(colors.white), theme(colors.white));
    border-radius: 360px;
  }

  .heart-icon {
    @apply text-[64px] text-danger-accent m-auto;
  }
}
.c-delete-partnership__modal_content {
  @apply bg-neutral-background-weak px-40 py-24;

  .icon {
    @apply text-16 text-danger-accent inline-block;
  }

  .warnings {
    @apply flex flex-col gap-8 mb-24;
  }

  .confirmation {
    @apply flex flex-col gap-8;
  }
}
.c-delete-partnership__modal_footer {
  @apply flex gap-16 items-center justify-evenly px-40 py-24 w-full bg-neutral-background-weak rounded-b-3xl;
}

h1 {
  @apply text-neutral-text-strong text-xl font-bold text-center;
}

h3 {
  @apply text-neutral-text-strong text-base font-bold;
}

h4 {
  @apply text-neutral-text-strong text-sm font-bold;
}

p {
  @apply text-neutral-text text-base;
}
</style>

<style lang="pcss">
.c-delete-partnership__modal {
  .c-bitts-modal__content {
    @apply p-0 m-0;
  }
}
</style>
