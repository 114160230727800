<template>
  <BittsModal
    :loading="loading || !ready"
    class="c-edit-users-modal"
    save-text="Save changes"
    :visible="true"
    :width="600"
    :disabled="cannotSave"
    @saved="handleEditUsers"
    @closed="closeModal"
  >
    <template #content>
      <div class="content">
        <BittsUserStack size="medium" :users="users" />
        <h1 class="title"> Edit {{ users.length }} users </h1>
        <p data-testid="edit-users-description" class="description">
          Update product access and roles for your teammates
        </p>
        <OutOfBothSeatsCTA
          v-if="showOutOfBothSeatsCTA && !isFreeTier"
          :users="users"
        />
        <div v-else class="w-full">
          <SelectSeatType
            :picked-seat="pickedSeat"
            :auths="auths"
            :is-editing-user="true"
            @seat-selected="handleChangeSeat"
            @cta-clicked="handleCtaClicked"
            @seats-needed="(n) => (seatsNeeded = n)"
          />
          <div class="flex flex-col gap-16">
            <GettingValueCTA
              v-if="!isFreeTier && seatsNeeded > 0"
              :seat-type="unpickedSeat"
              :seats-needed="seatsNeeded"
            />
            <SelectCoreRole
              v-model="pickedCoreRole"
              :picked-seat="pickedSeat"
            />
            <SelectSalesRole
              v-if="!isFreeTier"
              v-model="pickedSalesRole"
              :picked-seat="pickedSeat"
            />
            <SeatAlerts
              v-if="pickedCoreRole || pickedSeat?.value === SALES"
              :user-ids="userIds"
              :picked-core-role="pickedCoreRole"
              @is-removing-last-admin="(bool) => (isRemovingLastAdmin = bool)"
            />
            <DowngradeCallout
              v-if="isSubscriptionCancelled && notRetained"
              :title="`Some users will be removed on ${subscriptionRenewalDate}`"
              subtitle="You've downgraded and chosen not to retain some of these users"
              button-text="Restore plan"
            />
          </div>
        </div>
      </div>
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsModal, BittsUserStack } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DowngradeCallout from '@/components/billing/DowngradeCallout.vue';
import GettingValueCTA from '@/components/team/GettingValueCTA.vue';
import OutOfBothSeatsCTA from '@/components/team/OutOfBothSeatsCTA.vue';
import SeatAlerts from '@/components/team/SeatAlerts.vue';
import SelectCoreRole from '@/components/team/SelectCoreRole.vue';
import SelectSalesRole from '@/components/team/SelectSalesRole.vue';
import SelectSeatType from '@/components/team/SelectSeatType.vue';

import useBilling from '@/composables/useBilling';
import useSeats from '@/composables/useSeats';
import { CORE } from '@/constants/team';
import {
  CORE_SEAT_OPTION,
  SALES,
  SALES_SEAT_OPTION,
} from '@/constants/team_v4';
import { captureException } from '@/errors';
import {
  allReady,
  useBillingStore,
  useFlashesStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';

const route = useRoute();
const router = useRouter();
const teamStore = useTeamStore();
const flashesStore = useFlashesStore();
const billingStore = useBillingStore();
const rolesStore = useRolesStore();
const rootStore = useRootStore();
const ready = allReady(billingStore, teamStore, rolesStore);

const { isFreeTier, isSubscriptionCancelled, subscriptionRenewalDate } =
  storeToRefs(billingStore);
const { seatsRemainingMap } = useSeats();
const { handleBillingInteraction } = useBilling();

useHead({ title: 'Team - Remove User' });

const userIds = computed(() => route.query.ids.map((id) => parseInt(id)));
const auths = computed(() =>
  userIds.value
    .map((id) => teamStore.getAuthorizationByUserId(id))
    .filter((val) => val),
);
const users = computed(() => auths.value.map(({ user }) => user));
const pickedSeat = ref({});
const showOutOfBothSeatsCTA = ref(false);
const unpickedSeat = computed(() =>
  pickedSeat.value?.value === CORE ? SALES : CORE,
);

function handleChangeSeat(newSeat) {
  pickedSeat.value = newSeat;
  if (newSeat.value === SALES) pickedCoreRole.value = null;
}

const pickedCoreRole = ref(null);
const pickedSalesRole = ref(undefined);

/* Handle case where users cannot be found, close the modal */
onMounted(async () => {
  await teamStore.readySync;
  if (users.value.length !== userIds.value.length) {
    flashesStore.addErrorFlash({
      message: 'At least one of those users does not exist',
    });
    await closeModal();
    return;
  }

  /* Get the initial seat type on mount, but if it's not possible then block the flow */
  pickedSeat.value = getInitialSeatType();
  if (!pickedSeat.value) showOutOfBothSeatsCTA.value = true;
});

async function closeModal() {
  await router.push({ name: 'team' });
}

const isRemovingLastAdmin = ref(false);
const cannotSave = computed(() => {
  return (
    showOutOfBothSeatsCTA.value ||
    isRemovingLastAdmin.value ||
    !pickedSeat.value?.value ||
    (pickedSeat.value?.value === CORE && !pickedCoreRole.value) ||
    (pickedSeat.value?.value === SALES && !pickedSalesRole.value)
  );
});

const loading = ref(false);
async function handleEditUsers() {
  loading.value = true;
  try {
    await teamStore.updateRoles({
      userIds: userIds.value,
      salesEdgeRoleId: pickedSalesRole.value,
      crossbeamRoleId: pickedCoreRole.value,
    });

    rootStore.loadUserProfile();
    flashesStore.addSuccessFlash({ message: 'Teammates updated' });
    billingStore.refreshBillingStore(false);
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Users could not be edited',
      description: 'If this error persists contact support@crossbeam.com',
    });
  } finally {
    closeModal();
  }
}

/* This determines whether to show the "getting value" CTA */
const seatsNeeded = ref(0);

function handleCtaClicked() {
  const seatType = pickedSeat.value?.value;
  const remainingSeats = users.value.length - seatsRemainingMap[seatType];
  const query = { [seatType]: remainingSeats };
  handleBillingInteraction(
    { seatType, event_site: EVENT_SITES.EDIT_USERS_SEAT_TYPE_CTA },
    query,
  );
}

/* Set default seat if possible. Either the seats are the same
type, so we set that, or the seats are different types but we have
sufficient space to convert them to the same. If neither situation
applies, show the blocking CTA */
function getInitialSeatType() {
  if (auths.value?.every((auth) => auth.role_id)) return CORE_SEAT_OPTION;
  if (auths.value?.every((auth) => !auth.role_id)) return SALES_SEAT_OPTION;
  const seatsToAddByType = auths.value?.reduce(
    (agg, auth) => {
      if (auth.role_id) agg[SALES] += 1;
      else agg[CORE] += 1;
      return agg;
    },
    { [CORE]: 0, [SALES]: 0 },
  );

  if (seatsRemainingMap.value[CORE] >= seatsToAddByType[CORE])
    return CORE_SEAT_OPTION;
  if (seatsRemainingMap.value[SALES] >= seatsToAddByType[SALES])
    return SALES_SEAT_OPTION;

  return null;
}

const notRetained = computed(() =>
  auths.value?.some((auth) => auth.losing_access),
);
</script>

<style lang="pcss" scoped>
.c-edit-users-modal {
  .content {
    @apply flex flex-col items-center mt-[-36px];
  }
  .title {
    @apply mt-20 text-neutral-text-strong text-xl font-bold mb-8;
  }
  .description {
    @apply text-neutral-text text-m mb-24;
  }
}
</style>
<style lang="pcss">
.c-edit-users-modal {
  .ant-modal-body {
    background: var(
      --radial-neutral,
      radial-gradient(
        130.96% 20.92% at 70.09% 2%,
        rgba(140, 159, 184, 0.18) 5%,
        rgba(140, 159, 184, 0) 100%
      ),
      #fff
    );
    @apply rounded-16;
  }

  .c-bitts-modal__buttons--wrapper {
    @apply flex w-full items-center justify-between;
  }
}
</style>
