<template>
  <component
    :is="divOrTooltip"
    :placement="divOrTooltip !== 'div' ? 'bottom' : null"
    class="timeline-population-content-wrapper"
    data-testid="population-content-tooltip"
    :class="{
      unavailable: !populationExists,
      'standard-population': isStandardPopulation,
    }"
  >
    <div class="timeline-population-content" data-testid="population-content">
      <FontAwesomeIcon
        v-if="!populationExists"
        :icon="['fak', 'hidden']"
        class="unavailable-icon"
      />
      {{ popName }}
    </div>
    <template #title>
      {{ tooltipContent }}
    </template>
  </component>
</template>

<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import { usePopulationsStore } from '@/stores';

const { populationId = null, populationName = null } = defineProps<{
  populationId: number | null;
  populationName: string | null;
}>();

const populationStore = usePopulationsStore();

const population = computed(() =>
  populationId ? populationStore.getPopulationById(populationId) : null,
);

const populationExists = computed(() => !!population.value);
const isStandardPopulation = computed(() => !!population.value?.standard_type);

const popName = computed(() =>
  populationExists.value ? populationName : 'Unavailable',
);

const popNameHasChanged = computed(() => {
  return popName.value !== population.value?.name;
});

const divOrTooltip = computed(() => {
  if (!populationExists.value || popNameHasChanged.value) return BittsTooltip;
  return 'div';
});

const tooltipContent = computed(() => {
  if (!populationExists.value) return 'This population no longer exists';
  if (popNameHasChanged.value)
    return `This population is now named "${population.value?.name}"`;
  return null;
});
</script>

<style scoped lang="pcss">
.timeline-population-content-wrapper {
  @apply text-sm text-neutral-text-strong rounded-4 border border-neutral-border inline-flex cursor-default px-4 py-2 bg-white;

  &.unavailable {
    @apply bg-neutral-background-disabled border-neutral-background-disabled;
  }

  &.standard-population {
    @apply before:content-[''] before:w-2 before:bg-info-accent before:rounded-full before:mr-4;
  }
}

.timeline-population-content {
  @apply inline-flex items-center font-bold text-neutral-text py-2;
}

.standard-population-border {
  @apply h-full rounded-full bg-info-accent w-2 mr-4;
}

.unavailable-icon {
  @apply text-neutral-400 mr-4;
}
</style>
