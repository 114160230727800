import { GetApiPayloadByUrl } from '@crossbeam/openapi';

import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useFeedsStore,
  useFlashesStore,
  useIntegrationProfileStore,
  usePartnerCloudStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import {
  IntegrationProfile,
  IntegrationProfileType,
  MinimalIntegrationProfile,
} from '@/types/integration_profiles';
import { STANDARD_POPULATIONS } from '@/types/populations';

export type SuccessfulSaveCallback = (() => Promise<string>) | undefined;

export default function useIntegrationProfile(
  profileType: IntegrationProfileType,
  successfulSave: SuccessfulSaveCallback = undefined,
) {
  const defaultStandardPopSettings = (
    [...STANDARD_POPULATIONS, 'custom'] as const
  ).map((pop) => ({
    standard_type: pop,
    is_included: ['customers', 'open_opportunities'].includes(pop),
  }));

  const { hasPermission } = useAuth() as {
    hasPermission: (permission: string) => boolean;
  };

  const integrationProfileStore = useIntegrationProfileStore();
  const { getFeedById } = useFeedsStore();
  const { addSuccessFlash, addErrorFlash } = useFlashesStore();
  const { getSourceById } = useSourcesStore();
  const partnerCloudStore = usePartnerCloudStore();
  const router = useRouter();

  const excludePartnershipsCreatedSince = ref<string | undefined>(undefined);
  const isSaving = ref(false);
  const integrationProfileSettings = ref<IntegrationProfile | null>(null);
  const existingIntegrationProfileSettings = ref<IntegrationProfile | null>(
    null,
  );

  const { populations } = storeToRefs(usePopulationsStore());
  const { isFreeTier, isEnterpriseTier } = storeToRefs(useBillingStore());

  const integrationDescription = computed(() => {
    return profileType === 'clari'
      ? 'Configure the populations syncing to Clari. You can only sync Salesforce based populations and Partner Opps or Partner Customers.'
      : `Amplify and activate your ecosystem insights by pushing overlapping partner data into the Crossbeam overlaps custom object in ${friendlyName.value}.`;
  });

  const enableDescription = computed(() => {
    // todo confirm copy
    return `Push overlap data to ${friendlyName.value}. This will impact your record export limit`;
  });

  const hasPermissionToManageIntegrations = computed(() =>
    hasPermission('manage:integrations'),
  );

  const isIntegrationAvailable = computed(() =>
    profileType === 'hubspot' ? !isFreeTier.value : isEnterpriseTier.value,
  );

  const friendlyName = computed(() => {
    if (profileType === 'crossbeam_copilot') return null;
    const names = {
      clari: 'Clari',
      salesforce: 'Salesforce',
      hubspot: 'HubSpot',
      microsoft_dynamics: 'MS Dynamics',
      snowflake: 'Snowflake',
    };
    return names[profileType];
  });

  onMounted(async () => {
    if (profileType && !integrationProfileSettings.value) {
      await initProfileSettings();
    }
  });

  const onSettingsUpdated = (payload: MinimalIntegrationProfile) => {
    if (!integrationProfileSettings.value) return;
    integrationProfileSettings.value = {
      ...integrationProfileSettings.value,
      ...payload,
    };
  };
  function onUpdateExcludePartnershipsCreatedSince(isChecked: boolean) {
    if (!integrationProfileSettings.value) return;
    integrationProfileSettings.value = {
      ...integrationProfileSettings.value,
      exclude_partnerships_created_since: isChecked
        ? null
        : (excludePartnershipsCreatedSince.value ?? null),
    };
  }

  const saveSettings = async () => {
    if (!integrationProfileSettings.value) {
      return;
    }
    const outPayload = {
      exclude_partnerships_created_since:
        integrationProfileSettings.value.exclude_partnerships_created_since,
      population_settings:
        integrationProfileSettings.value.population_settings.map((p) => ({
          population_id: p.population_id,
          is_partner_population: p.is_partner_population,
          is_included: p.is_included,
        })),
      standard_population_settings:
        integrationProfileSettings.value.standard_population_settings.map(
          (p) => ({
            standard_type: p.standard_type,
            is_included: p.is_included,
          }),
        ),
    };

    isSaving.value = true;
    const isExcludePartnershipsCreatedSinceChanged =
      !!integrationProfileSettings.value?.exclude_partnerships_created_since !==
      !!existingIntegrationProfileSettings.value
        ?.exclude_partnerships_created_since;

    try {
      await integrationProfileStore.putIntegrationProfileSettings(
        integrationProfileSettings.value?.profile_id,
        outPayload,
      );
      await integrationProfileStore.refreshIntegrationProfileStore();
      let successfulSaveMessage = '';
      if (successfulSave) {
        successfulSaveMessage = await successfulSave();
      }
      addSuccessFlash({
        message: `${friendlyName.value} settings saved`,
        description:
          successfulSaveMessage.length > 0
            ? successfulSaveMessage
            : isExcludePartnershipsCreatedSinceChanged
              ? integrationProfileSettings.value
                  ?.exclude_partnerships_created_since
                ? 'Partners will no longer be added automatically'
                : 'New partners will be automatically added'
              : undefined,
      });
    } catch (err) {
      captureException(err);
      addErrorFlash({
        message: 'Your integration settings could not be saved',
      });
    } finally {
      clearProfilesState();
      isSaving.value = false;
    }
  };

  const clearProfilesState = () => {
    partnerCloudStore.$patch({ inProgressInstallation: null });
    integrationProfileSettings.value = null;
    existingIntegrationProfileSettings.value = null;
  };

  const setIntegrationProfileDefaults = (
    integrationProfile: IntegrationProfile,
  ) => {
    const standardPopSettingsRaw = [
      ...integrationProfile.standard_population_settings,
      ...defaultStandardPopSettings.filter(
        (pop) =>
          !integrationProfile?.standard_population_settings.some(
            (p) => p.standard_type === pop.standard_type,
          ),
      ),
    ];

    integrationProfileSettings.value = {
      ...integrationProfile,
      standard_population_settings: standardPopSettingsRaw,
      population_settings: integrationProfile.population_settings,
    };
    existingIntegrationProfileSettings.value = integrationProfileSettings.value;
  };

  const initProfileSettings = async () => {
    await integrationProfileStore.readySync;
    const integrationProfile =
      integrationProfileStore.getIntegrationProfileByType(profileType);
    // on initial fetch, we set the date to now
    excludePartnershipsCreatedSince.value = DateTime.now().toISO();

    if (integrationProfile) {
      setIntegrationProfileDefaults(integrationProfile);
      return;
    }

    // If there isn't an appropriate integration profile, make a new one and post it.
    const popSettings = populations.value
      .filter((pop) => {
        const { feed_id: feedId } = getSourceById(pop.source_id) as unknown as {
          feed_id: number;
        };
        const feed = getFeedById(feedId);
        return feed?.integration.type === profileType;
      })
      .map((pop) => {
        return {
          population_id: pop.id,
          is_partner_population: false,
          is_included: true,
        };
      });

    const payload: GetApiPayloadByUrl<'/v0.1/integration-profiles', 'post'> = {
      integration_type: profileType,
      population_settings: popSettings,
      standard_population_settings: defaultStandardPopSettings,
    };

    isSaving.value = true;

    try {
      const { data, error } =
        await integrationProfileStore.postIntegrationProfileSettings(payload);
      if (error) throw error;

      integrationProfileSettings.value = data;
      existingIntegrationProfileSettings.value =
        integrationProfileSettings.value;
      await integrationProfileStore.refreshIntegrationProfileStore();
    } catch (err) {
      if (err instanceof Error) {
        addErrorFlash({ message: err.message });
      }
      // if we didn't successfully create a new integration profile, let's push the user to integrations page
      // We mainly want this for Snowflake. We can show a warning message there to the user that all overlaps are being pushed
      if (profileType === 'snowflake') {
        router.push({ name: 'integrations' });
      }
    } finally {
      isSaving.value = false;
    }
  };

  return {
    clearProfilesState,
    enableDescription,
    excludePartnershipsCreatedSince,
    friendlyName,
    hasPermissionToManageIntegrations,
    integrationDescription,
    integrationProfileSettings,
    isIntegrationAvailable,
    isSaving,
    onSettingsUpdated,
    onUpdateExcludePartnershipsCreatedSince,
    saveSettings,
    initProfileSettings,
  };
}
