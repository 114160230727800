<template>
  <div class="c-lookup-subtree">
    <div class="c-lookup-subtree__toggle">
      <button
        class="flex items-center justify-between gap-8"
        @click="toggleBranch"
        type="button"
      >
        <FontAwesomeIcon
          :icon="['fak', 'chevron-right']"
          class="text-info-text-strong"
          :style="{ height: '10px', width: '10px' }"
          :class="{
            'rotate-90 transition': isOpen,
            'rotate-[-90] transition': !isOpen,
          }"
        />
        <h2 class="inline-block">
          {{ title }}
        </h2>
      </button>
      <div class="c-lookup-subtree__tag">
        <FontAwesomeIcon
          :icon="['fak', 'link']"
          class="text-primary-accent"
          :style="{ height: '14px', width: '14px' }"
        />
        <span>Lookup Fields</span>
      </div>
    </div>
    <div v-if="isOpen" class="c-lookup-subtree__checkboxes">
      <div
        v-for="sourceField in filteredObjectFields"
        :key="sourceField.id"
        class="mb-4 w-full"
        data-testid="c-synctree-branch__leaf"
      >
        <SyncTreeLeaf
          :lookup="true"
          :source-field="sourceField"
          :source-field-map="sourceFieldMap"
          :lookup-field-ids="lookupFieldIds"
          :checked-reference-ids="checkedReferenceIds"
          @update="handleFieldChecked"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import SyncTreeLeaf from '@/components/data-sources/data-templates/SyncTreeLeaf.vue';

const props = defineProps({
  relationshipId: {
    type: String,
    required: true,
  },
  sourceFieldMap: {
    type: Object,
    required: true,
  },
  lookupFieldIds: {
    type: Array,
    required: true,
  },
  checkedReferenceIds: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    required: true,
  },
  search: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update']);

const isOpen = ref(false);

const filteredObjectFields = computed(() => {
  return props.lookupFieldIds.reduce((acc, id) => {
    const sourceField = props.sourceFieldMap[id];
    if (sourceField.relationship_id === props.relationshipId) {
      acc.push(sourceField);
    }
    return acc;
  }, []);
});

function handleFieldChecked({ sourceField, isChecked }) {
  emit('update', { sourceField, isChecked });
}

function toggleBranch() {
  isOpen.value = !isOpen.value;
}

if (
  filteredObjectFields.value.length > 0 ||
  props.title.includes(props.search)
) {
  isOpen.value = true;
}
</script>
<style lang="pcss" scoped>
.c-lookup-subtree {
  @apply w-full;
}

.c-lookup-subtree__toggle {
  @apply flex items-center justify-between;
}

.c-lookup-subtree__checkboxes {
  @apply ml-4 pl-16 border-l border-neutral-border;
}

.c-lookup-subtree__tag {
  @apply flex items-center gap-4 bg-neutral-background px-8 py-2 text-sm rounded-full;
}
</style>
