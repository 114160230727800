<template>
  <div class="c-synctree-leaf" :class="{ 'mb-4': !hasDetails }">
    <div class="c-synctree-leaf__content">
      <component :is="showTooltip ? BittsTooltip : 'div'">
        <BittsCheckbox
          :checked="sourceField.isChecked"
          :default-checked="sourceField.isChecked"
          :disabled="isFieldDisabled"
          @input="(isChecked) => $emit('update', { sourceField, isChecked })"
        >
          <template #label>
            <div class="flex items-center gap-4">
              <span>{{ sourceField.display_name }}</span>
              <LeftSyncTreeTags :source-field="sourceField" />
            </div>
          </template>
        </BittsCheckbox>
        <template #title>
          {{ tooltipText }}
        </template>
      </component>
      <RightSyncTreeTags
        :source-field="sourceField"
        :required-for-object="requiredForObject"
        :required-for-matching="requiredForMatching"
      />
    </div>
    <LeafInfo
      v-if="hasDetails"
      class="my-4"
      :class="{
        'ml-24': !lookup,
      }"
      :lookup-field-ids="lookupFieldIds"
      :source-field="sourceField"
      :source-field-map="sourceFieldMap"
    />
  </div>
</template>
<script setup>
import { BittsCheckbox, BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import LeafInfo from '@/components/data-sources/data-templates/LeafInfo.vue';
import LeftSyncTreeTags from '@/components/data-sources/data-templates/LeftSyncTreeTags.vue';
import RightSyncTreeTags from '@/components/data-sources/data-templates/RightSyncTreeTags.vue';

import { MDM_PROPERTIES } from '@/constants/mdm';

const props = defineProps({
  sourceField: {
    type: Object,
    required: true,
  },
  sourceFieldMap: {
    type: Object,
    required: true,
  },
  referenceFieldIds: {
    type: Array,
    default: () => [],
  },
  checkedLookupIds: {
    type: Array,
    default: () => [],
  },
  checkedReferenceIds: {
    type: Array,
    default: () => [],
  },
  requiredFields: {
    type: Array,
    default: () => [],
  },
  lookupFieldIds: {
    type: Array,
    default: () => [],
  },
  lookup: {
    type: Boolean,
    default: false,
  },
  isLastBaseTable: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['update']);

const hasDetails = computed(() => {
  return (
    props.sourceField.blockedPops.length > 0 ||
    props.sourceField.reportFilters.length > 0 ||
    props.sourceField.isLookupField ||
    props.sourceField.isReferenceField ||
    props.sourceField.isPotInfAmountField
  );
});

const isFieldDisabled = computed(() => {
  const hasBlockedPops =
    props.sourceField.blockedPops?.length > 0 && props.sourceField.isChecked;
  const isRequiredForObject =
    props.sourceField.isRequiredForObject && props.sourceField.isChecked;
  const { isPotInfAmountField } = props.sourceField;
  const isLockedReferenceField =
    props.referenceFieldIds.includes(props.sourceField.id) &&
    props.checkedLookupIds.some((id) => {
      const lookupField = props.sourceFieldMap[id];
      if (lookupField.copies_source_field_id === props.sourceField.id) {
        return lookupField.isChecked;
      }
    });
  const isLockedLookupField =
    props.lookup &&
    !props.checkedReferenceIds.includes(
      props.sourceField.copies_source_field_id,
    );

  /* Cast expresion to boolean */
  return !!(
    hasBlockedPops ||
    isRequiredForObject ||
    isLockedReferenceField ||
    isLockedLookupField ||
    isPotInfAmountField
  );
});

const showTooltip = computed(() => {
  return isFieldDisabled.value || props.sourceField.reportFilters.length > 0;
});

const requiredForObject = computed(() =>
  props.requiredFields.some((f) => f.id === props.sourceField.id),
);
const hasRequiredMdm = computed(() =>
  ['company_website', MDM_PROPERTIES.account.NAME, 'person_email'].includes(
    props.sourceField.mdm_property,
  ),
);
const requiredForMatching = computed(() => {
  if (!props.isLastBaseTable) return false;
  return requiredForObject.value && hasRequiredMdm.value;
});

const tooltipText = computed(() => {
  if (!showTooltip.value) return '';
  if (props.sourceField.isRequiredForMatching)
    return 'This field is required to match with your partners';
  if (props.sourceField.isReferenceField)
    return 'Unsync the attached lookup field to unsync this field';
  if (props.sourceField.isPotInfAmountField)
    return 'Being used to calculate Potential Revenue';
  if (props.sourceField.blockedPops?.length > 0 && props.sourceField.isChecked)
    return 'Remove this population filter to unsync this field';
  if (props.sourceField.reportFilters.length > 0)
    return 'Unsyncing this field will edit the contents of a report';
  if (requiredForObject.value)
    return 'This field is required to use this object';

  /* Only remaining possibility is a locked lookup */
  const referenceField =
    props.sourceFieldMap[props.sourceField.copies_source_field_id];
  return `Sync the ${referenceField.nickname} on the ${referenceField.object} object to sync this field`;
});
</script>
<style lang="pcss" scoped>
.c-synctree-leaf {
  @apply ml-8 w-full;

  .c-synctree-leaf__content {
    @apply flex justify-between;
  }
}
</style>
