<template>
  <BittsModal
    title="Delete Report?"
    :loading="loading"
    :content-text="`Are you sure you want to delete ${reportName}?`"
    save-text="Yes, Delete"
    cancel-text="No, Don't Delete"
    :visible="showDeleteReportModal"
    confirm-type="danger"
    @saved="onDelete(reportToDelete)"
    @closed="onCloseModal"
  />
</template>

<script>
import { BittsModal } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import { BASE_ROUTES } from '@/constants/routes';
import { useFlashesStore, useReportsStore } from '@/stores';

export default {
  name: 'DeleteReportModal',
  components: {
    BittsModal,
  },
  data() {
    return {
      name: null,
      loading: false,
      showDeleteReportModal: false,
    };
  },
  computed: {
    closeRoute() {
      if (!this.folderId) return { name: BASE_ROUTES.REPORTS };
      return {
        name: BASE_ROUTES.REPORT_FOLDER,
        params: {
          folder_id: this.folderId,
        },
      };
    },
    reportName() {
      return this.reportToDelete ? this.reportToDelete.name : '';
    },
    folderId() {
      return this.$route.params.folder_id;
    },
    reportToDelete() {
      const currentReportId = this.$route.query.id;
      return this.getByReportId(currentReportId);
    },
  },
  async created() {
    this.loading = true;
    await this.refreshReportsStore();
    this.loading = false;
  },
  mounted() {
    this.showDeleteReportModal = true;
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useReportsStore, [
      'getByReportId',
      'refreshReportsStore',
      'deleteReport',
    ]),
    async onDelete(report) {
      this.loading = true;
      try {
        await this.deleteReport(report);
        this.addSuccessFlash({ message: `"${report.name}" report deleted` });
        await this.refreshReportsStore();
      } catch (_err) {
        this.addErrorFlash({ message: 'Failed to delete report' });
      } finally {
        this.onCloseModal();
        this.loading = false;
      }
    },
    async onCloseModal() {
      this.showDeleteReportModal = false;
      await this.$router.push(this.closeRoute);
    },
  },
};
</script>
