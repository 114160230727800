<template>
  <div class="c-dormant-company">
    <h1> We've missed you, {{ currentOrg.name }} </h1>
    <p>
      We haven't seen anyone from your company in a while, so we made your
      account inactive. To keep using Crossbeam, please confirm you'd like to
      reactivate your account.
    </p>
    <div class="c-dormant-company__buttons">
      <router-link :to="{ name: 'logout' }" class="w-full md:w-auto">
        <BittsButton
          text="Back to Login"
          variant="outline"
          type="neutral"
          class="w-full md:w-auto mr-16 mt-16 md:mt-0"
        />
      </router-link>
      <BittsButton
        text="Contact Us"
        class="w-full md:w-auto order-first md:order-last"
        @click="contactSupport"
      />
    </div>
  </div>
</template>

<script setup>
import { BittsButton } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import usePolling from '@/composables/usePolling';
import { useRootStore } from '@/stores/RootStore';
import { intercomOrEmailMessage } from '@/utils';

useHead({ title: 'Dormant Organization - Crossbeam' });

const rootStore = useRootStore();
const route = useRoute();
const router = useRouter();
const next = computed(() => route.query.next);
const { currentOrg } = useAuth();

onMounted(async () => {
  if (!Object.keys(currentOrg.value).length) {
    await router.push({ name: 'main' });
  }
});

/* Reload the user profile until the company is no longer marked as dormant */
const poll = usePolling({
  interval: 10000,
  async poller() {
    await rootStore.loadUserProfile();
  },
  shouldComplete() {
    return !currentOrg.value.is_dormant;
  },
  async onComplete() {
    await router.push(next.value || { name: 'main' });
  },
});

poll();

function contactSupport() {
  intercomOrEmailMessage(
    'support@crossbeam.com',
    `${currentOrg.value.name} account reactivation`,
    `Hello, I'd like to talk to someone about reactivating ${currentOrg.value.name}.`,
  );
}
</script>
<style lang="pcss" scoped>
.c-dormant-company {
  @apply text-center flex flex-col items-center justify-center mx-24;
}

h1 {
  @apply text-brand-navy text-xxl md:text-xxxl mb-8 md:mb-16;
}

p {
  @apply text-base md:text-m leading-6 text-neutral-600 mb-40 md:mb-24 max-w-[800px];
}

.c-dormant-company__buttons {
  @apply flex flex-col md:flex-row w-full justify-center;
}
</style>
