// TODO: trace these icons, usd is fak
export const DATA_TYPES_LABELS = [
  {
    id: 'account',
    icon: {
      type: 'FontAwesomeIcon',
      props: {
        icon: 'info',
        class: 'c-view-options__card__row__icon',
      },
    },
    label: 'Account Information',
  },
  {
    id: 'leads',
    icon: {
      type: 'XbeamSvg',
      props: {
        svg: 'personIcon',
        class:
          'c-view-options__card__row__icon c-view-options__card__row__icon--xbeam-svg',
      },
    },
    label: 'Leads',
  },
  {
    id: 'lead',
    icon: {
      type: 'FontAwesomeIcon',
      props: {
        icon: 'info',
        class: 'c-view-options__card__row__icon',
      },
    },
    label: 'Person Information',
  },
  {
    id: 'account_owners',
    icon: {
      type: 'XbeamSvg',
      props: {
        svg: 'personIcon',
        class:
          'c-view-options__card__row__icon c-view-options__card__row__icon--xbeam-svg',
      },
    },
    label: 'Account Owners',
  },
  {
    id: 'contacts',
    icon: {
      type: 'XbeamSvg',
      props: {
        svg: 'companyIcon',
        class:
          'c-view-options__card__row__icon c-view-options__card__row__icon--xbeam-svg',
      },
    },
    label: 'Contact Information',
  },
  {
    id: 'accounts',
    icon: {
      type: 'XbeamSvg',
      props: {
        svg: 'companyIcon',
        class:
          'c-view-options__card__row__icon c-view-options__card__row__icon--xbeam-svg',
      },
    },
    label: 'Account Information',
  },
  {
    id: 'opportunities',
    icon: {
      type: 'FontAwesomeIcon',
      props: {
        icon: 'usd',
        class: 'c-view-options__card__row__icon',
      },
    },
    label: 'Opportunities',
  },
];
