export const POPULATIONS = 'populations';
export const SHARING_SETTING = 'sharing_setting';
export const OVERRIDES = 'overrides';
export const OFFLINE_PARTNERS = 'offline_partners';

export const HIDDEN = 'hidden';
export const OLAPS_ONLY = 'aggregates_only';
export const SHARING = 'full';
export const GREENFIELD_SHARING = 'greenfield_full';

export const STANDARD_TYPE = 'standard_type';
