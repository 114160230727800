<template>
  <div class="c-activity-timeline__sidebar">
    <h3 class="text-neutral-text-weak font-bold mb-8"> Activity Type </h3>
    <div class="c-timeline-sidebar__activity-types-container">
      <BittsBaseTree
        :node-data="treeFilterData"
        :checked-keys="selectedFilters"
        :initial-expanded-nodes="initialExpandedNodes"
        @update="onCheckedKeys"
      >
        <template #node-prefix="{ node }: { node: TreeNodeType }">
          <img
            v-if="['partnerstack', 'gong'].includes(node.id as any)"
            :src="IMG_URLS[node.id as string]"
            class="w-16 h-16"
          />
        </template>
      </BittsBaseTree>
    </div>
    <h3 v-if="visible" class="font-bold leading-4 mb-8 text-neutral-text-weak">
      Partner Data
    </h3>
    <div
      v-if="visible"
      class="c-activity-timeline__sidebar--partner-data fullstory-hide dd-privacy-mask"
    >
      <div
        v-for="partnerOrg in partnerOrgsInRecord"
        :key="partnerOrg.uuid"
        class="c-activity-timeline__org-selector hover:bg-neutral-50"
        @click.prevent="onOrgClick(partnerOrg.uuid)"
      >
        <BittsAvatar
          :org="partnerOrg"
          :show-initials="true"
          class="mr-8"
          size="medium"
        />
        <span class="text-neutral-900 leading-6 flex-1">{{
          partnerOrg.name
        }}</span>
        <BittsCheckbox
          :key="`org_select_${partnerOrg.uuid}`"
          :checked="orgFilters[partnerOrg.uuid]"
          @input="(val) => onOrgClick(partnerOrg.uuid)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsAvatar, BittsBaseTree, BittsCheckbox } from '@crossbeam/bitts';
import { TreeNodeType } from '@crossbeam/bitts/src/BittsTree/BittsBaseTree.vue';

import { computed } from 'vue';

import { ALL_EVENT_FILTERS } from '@/constants/timeline';

const {
  visible = false,
  orgFilters = {},
  selectedFilters = [],
  partnerOrgsInRecord = [],
  partnerStackIsActive = false,
  gongIsActive = false,
} = defineProps<{
  visible?: boolean;
  orgFilters?: Record<string, boolean>;
  selectedFilters?: string[];
  partnerOrgsInRecord?: { uuid: string; name: string }[];
  partnerStackIsActive?: boolean;
  gongIsActive?: boolean;
}>();

const emit = defineEmits<{
  (event: 'filter-checked', payload: { keys: string[] }): void;
  (event: 'org-filter-checked', payload: { id: string }): void;
}>();

const IMG_URLS: Record<string, string> = {
  partnerstack: new URL(
    '../../../assets/pngs/partner-cloud/Partnerstack.png',
    import.meta.url,
  ).href,
  gong: new URL('../../../assets/pngs/partner-cloud/Gong.png', import.meta.url)
    .href,
};

const initialExpandedNodes = {
  all_crossbeam_filters: true,
  all_integrations_filters: true,
};

const integrationsInstalled = computed(() => {
  return partnerStackIsActive || gongIsActive;
});

const treeFilterData = computed(() => {
  const children = [
    {
      checked: selectedFilters.includes('all_attribution_activity_filters'),
      label: 'Attribution',
      id: 'all_attribution_activity_filters',
      children: buildFilterChildren('attribution_activity'),
    },
    {
      checked: selectedFilters.includes('all_overlap_movement_filters'),
      label: 'Overlap Movement',
      id: 'all_overlap_movement_filters',
      children: buildFilterChildren('overlap_movement'),
    },
    {
      checked: selectedFilters.includes('all_population_movement_filters'),
      label: 'Population Movement',
      id: 'all_population_movement_filters',
      children: buildFilterChildren('population_movement'),
    },
    {
      checked: selectedFilters.includes('all_sales_edge_activity_filters'),
      label: 'Crossbeam for Sales',
      id: 'all_sales_edge_activity_filters',
      children: buildFilterChildren('sales_edge_activity'),
    },
  ];
  const treeData: TreeNodeType[] = [
    {
      checked:
        selectedFilters.includes('all_crossbeam_filters') ||
        !children.some((c) => !c.checked),
      label: 'Crossbeam',
      id: 'all_crossbeam_filters',
      children,
    },
  ];

  if (integrationsInstalled.value) {
    treeData.push({
      checked: selectedFilters.includes('all_integrations_filters'),
      label: 'Integrations',
      id: 'all_integrations_filters',
      children: buildFilterChildren('integrations'),
    });
  }

  return treeData;
});

function onCheckedKeys(tree: TreeNodeType[]) {
  function getCheckedIds(tree: TreeNodeType[]) {
    const result: string[] = [];

    function traverse(node: TreeNodeType) {
      if (node.checked) {
        result.push(node.id as string);
      }

      if (node.checked && node.children) {
        node.children.map((c) => ({ ...c, checked: true })).forEach(traverse);
      } else if (node.children) {
        node.children.forEach(traverse);
      }
    }

    tree.forEach(traverse);
    return result;
  }
  emit('filter-checked', { keys: getCheckedIds(tree) });
}

function onOrgClick(id: string) {
  emit('org-filter-checked', { id });
}

function buildFilterChildren(filterEventType: string): TreeNodeType[] {
  const filteredIntegrations = {
    ...(partnerStackIsActive && {
      partnerstack: {
        label: 'PartnerStack',
        id: 'partnerstack',
      },
    }),
    ...(gongIsActive && {
      gong: {
        label: 'Gong',
        id: 'gong',
      },
    }),
  };
  const EVENT_FILTERS = {
    integrations: filteredIntegrations,
    attribution_activity: {
      attribution_created: 'Attribution Created',
      attribution_deleted: 'Attribution Deleted',
      attribution_updated: 'Attribution Updated',
    },
    population_movement: {
      population_entry: 'Population Entry',
      population_exit: 'Population Exit',
    },
    overlap_movement: {
      overlap_entry: 'Overlap Entry',
      overlap_exit: 'Overlap Exit',
    },
    sales_edge_activity: {
      request_received: 'Incoming Message',
      request_sent: 'Outgoing Message',
    },
  };

  if (!(filterEventType in EVENT_FILTERS)) return [];

  return Object.entries(
    EVENT_FILTERS[filterEventType as keyof typeof EVENT_FILTERS],
  ).map(([id, val]) => {
    if (['gong', 'partnerstack'].includes(id)) {
      return {
        checked: selectedFilters.includes('all_integrations_filters'),
        id,
        label: val.label,
      };
    }
    return {
      checked:
        selectedFilters.includes(
          ALL_EVENT_FILTERS[filterEventType as keyof typeof ALL_EVENT_FILTERS],
        ) || selectedFilters.includes(id),
      id,
      label: val,
    };
  });
}
</script>

<style lang="pcss" scoped>
.c-activity-timeline__sidebar--partner-data {
  @apply bg-white rounded-8 border border-neutral-border mb-16 cursor-pointer;
}
.c-timeline-sidebar__activity-types-container {
  @apply bg-white p-16 rounded-8 border border-neutral-border mb-16;
}

.c-timeline-sidebar__activity-types-container:deep(.ant-tree-indent-unit) {
  @apply mr-5;
}
</style>
