<template>
  <div class="c-debooking-table-user-cell" :class="{ disabled: isRowDisabled }">
    <BittsCheckbox
      :disabled="isSelf"
      :checked="authOrInvite.isChecked || isSelf"
      class="mr-8 mt-2"
      data-testid="user-checkbox"
      @input="() => handlePickUser(id)"
    />
    <BittsAvatar v-bind="userAvatarProps" class="mr-8" size="small">
      <template v-if="!authOrInvite.user" #icon>
        <FontAwesomeIcon
          :icon="['fas', 'envelope']"
          class="text-neutral-accent"
          :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        />
      </template>
    </BittsAvatar>
    <div class="c-debooking-table-user-cell__user-info">
      <span
        v-if="!authOrInvite.user"
        data-testid="invite-email"
        class="text-neutral-text"
      >
        {{ authOrInvite.email }}
      </span>
      <strong
        v-else
        data-testid="user-name"
        class="c-debooking-table-user-cell__name"
      >
        <span v-if="user.first_name && user.last_name">
          {{ user.first_name }} {{ user.last_name }}
        </span>
        <!-- Users should have a name, but some are legacy -->
        <span v-else>{{ user.email }}</span>
      </strong>
      <BittsTag
        v-if="isSelf"
        data-testid="you-tag"
        variant="rounded"
        size="x-small"
        color="info"
      >
        You
      </BittsTag>
    </div>
  </div>
</template>

<script setup>
import { BittsAvatar, BittsCheckbox, BittsTag } from '@crossbeam/bitts';

import { computed, onBeforeMount, ref } from 'vue';

import useAuthOrInvite from '@/composables/useAuthOrInvite';
import { EXPLORER_SEATS } from '@/constants/billing';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const authOrInvite = ref(null);
const context = ref(null);
onBeforeMount(() => {
  authOrInvite.value = props.params.data;
  context.value = props.params.context;
});

const { id, isSelf } = useAuthOrInvite(authOrInvite);

const user = computed(
  () => authOrInvite.value.user ?? { email: authOrInvite.value.email },
);

const isRowDisabled = computed(() => {
  if (context.value.checkAll) return false;
  if (isSelf.value) return false;
  return (
    !authOrInvite.value.isChecked &&
    context.value.keptUsers?.size + context.value.keptInvites?.size >=
      EXPLORER_SEATS
  );
});

const userAvatarProps = computed(() =>
  authOrInvite.value.user
    ? {
        user: user.value,
        showInitials: true,
      }
    : {
        isIcon: true,
        forceCircle: true,
        iconSlotBgColor: 'bg-neutral-background',
      },
);

/* This calls a function which adds the ID to the list of checked users
and also sets the isChecked property on the authOrInvite property */
function handlePickUser(id) {
  authOrInvite.value.callback(id);
}
</script>

<style lang="pcss">
.c-debooking-table-user-cell {
  @apply flex items-center h-64;
}

.c-debooking-table-user-cell__name {
  @apply text-neutral-text-strong text-m cursor-text;
}

.c-debooking-table-user-cell__email {
  @apply text-neutral-text truncate cursor-pointer;
}

.c-debooking-table-user-cell__user-info {
  @apply flex items-center gap-4;
}
</style>
