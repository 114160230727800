<template>
  <div v-if="sourceFields.length > 0" class="c-review-fields-branch">
    <div class="c-review-fields-branch__object">
      <button @click="toggleBranch" type="button">
        <FontAwesomeIcon
          :icon="['fak', 'chevron-right']"
          class="text-info-text-strong mr-4"
          :class="{
            'rotate-90 transition': isOpen,
            'rotate-[-90] transition': !isOpen,
          }"
          :style="{ height: '10px', width: '10px' }"
        />
        <span>
          {{ startCase(object) }}
        </span>
      </button>
    </div>
    <div v-if="isOpen" class="c-review-fields-branch__fields">
      <div
        v-for="sourceField in sourceFields"
        :key="sourceField.id"
        class="c-review-fields-branch__field"
      >
        <span class="block text-neutral-text">
          {{ sourceField.nickname }}
        </span>
        <div class="c-review-fields-branch__tags">
          <BittsTag
            v-if="sourceField.isRequired"
            color="neutral"
            variant="rounded"
          >
            Required for object
          </BittsTag>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { BittsTag } from '@crossbeam/bitts';

import { startCase } from 'lodash';
import { computed, ref } from 'vue';

const props = defineProps({
  object: {
    type: String,
    required: true,
  },
  allCheckedFieldIds: {
    type: Array,
    required: true,
  },
  allSourceFields: {
    type: Array,
    required: true,
  },
  requiredColumns: {
    type: Object,
    required: true,
  },
});

const isOpen = ref(true);
function toggleBranch() {
  isOpen.value = !isOpen.value;
}

const objectFields = computed(() =>
  props.allSourceFields.filter(
    (sourceField) => sourceField.object === props.object,
  ),
);

const sourceFields = computed(() =>
  props.allCheckedFieldIds
    .filter((id) => objectFields.value.some((f) => f.id === id))
    .map((id) => {
      const sourceField = objectFields.value.find((f) => f.id === id);
      const isRequired = props.requiredColumns[props.object]?.includes(
        sourceField.column,
      );
      return { ...sourceField, isRequired };
    })
    .sort((a, b) => (a.nickname > b.nickname ? 1 : -1)),
);
</script>

<style lang="pcss">
.c-review-fields-branch {
}

.c-review-fields-branch__object {
  @apply text-neutral-text-strong text-base font-bold transform-none flex items-center gap-4 mb-4;
}

.c-review-fields-branch__fields {
  @apply flex flex-col gap-4 border-l border-neutral-border pb-4 ml-3 pl-12 mb-10;
}

.c-review-fields-branch__field {
  @apply flex justify-between items-center;
}

.c-review-fields-branch__tags {
  @apply flex justify-between items-center;
}
</style>
