import { EmptyObject, Nullable } from '@crossbeam/types';

import {
  CUSTOMERS_STANDARD_TYPE,
  OPEN_OPPS_STANDARD_TYPE,
  PROSPECTS_STANDARD_TYPE,
} from '@/constants/standard_populations';

export const STANDARD_POPULATIONS = [
  CUSTOMERS_STANDARD_TYPE,
  OPEN_OPPS_STANDARD_TYPE,
  PROSPECTS_STANDARD_TYPE,
] as const;

export type StandardPopulationType =
  | typeof PROSPECTS_STANDARD_TYPE
  | typeof OPEN_OPPS_STANDARD_TYPE
  | typeof CUSTOMERS_STANDARD_TYPE;

type PopVersion = {
  id: number;
  is_active: boolean;
  first_processed_at?: string;
};

type BasePopulation = {
  id: number;
  name: string;
  description: Nullable<string>;
  organization_id: number;
  population_type: 'people' | 'companies';
  standard_type: Nullable<StandardPopulationType>;
};

export type PopulationStats = {
  population_id: number;
  organization_id: number;
  total_size: number;
  updated_at: string;
};

export type Population = {
  meta: EmptyObject | PopulationStats;
  source_id: number;
  base_schema: string;
  base_table: string;
  current_version: PopVersion;
} & BasePopulation;

export type PartnerPopulation = BasePopulation;
