<template>
  <div class="record-export-summary">
    <div class="breakdown-title">
      <h3 class="font-bold">Records Exported</h3
      ><h3 :class="`text-${color}-text`">{{ limitUsedPercent }}</h3>
    </div>
    <ProgressBar :width="limitUsedPercent" class="h-8 my-8" :color />
    <div class="breakdown-description">
      <span>Your plan renews {{ formattedDate }}</span>
      <div>
        <strong class="font-bold text-neutral-text-strong">
          {{ recordsExported?.toLocaleString() ?? 0 }}
        </strong>
        / {{ exportLimit?.toLocaleString() ?? 0 }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed } from 'vue';

import useRecordExportLimits from '@/composables/useRecordExportLimits';

import ProgressBar from './ProgressBar.vue';

const {
  recordsExported,
  exportLimit,
  exportLimitStatus,
  limitUsedPercent,
  endDate,
} = useRecordExportLimits();

const color = computed(() => {
  switch (exportLimitStatus.value) {
    case 'limit-reached':
      return 'danger';
    case 'over-90':
      return 'warning';
    default:
      return 'secondary';
  }
});

const formattedDate = computed(() =>
  endDate.value
    ? DateTime.fromISO(endDate.value).toLocaleString(DateTime.DATE_MED)
    : '',
);
</script>

<style scoped lang="pcss">
.breakdown-title {
  @apply flex justify-between;
}

.breakdown-description {
  @apply flex justify-between text-neutral-text-weak text-base mb-24;
}
</style>
