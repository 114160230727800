<template>
  <div class="downgrade-details-view">
    <h3>Let us know how we can improve</h3>
    <p>Select all reasons that apply for your decision to downgrade</p>
    <div class="flex flex-col gap-8">
      <BittsCheckbox
        v-for="(reason, i) in possibleReasons"
        :key="`${i}__reason`"
        :label="reason"
        @input="() => handleChoice(reason)"
      />
      <BittsCheckbox
        :checked="givingComment"
        data-testid="other-checkbox"
        label="Other (please detail below)"
        @input="handleGivingComment"
      />
      <BittsTextArea
        v-model="comment"
        class="mt-16"
        data-testid="comment-input"
        placeholder="What else would you like to share with us? Any feedback helps"
        @update:model-value="handleChangeComment"
      />
    </div>
  </div>
</template>

<script setup>
import { BittsCheckbox, BittsTextArea } from '@crossbeam/bitts';

import { ref } from 'vue';

const possibleReasons = [
  'Technical issues',
  'Too expensive',
  'Switching to another product',
  'Team downsizing or economic impacts',
  'Not sure how to use the data & tools',
  'Missing the features I need',
  'Prefer not to say',
];

const pickedReasons = ref(new Set());

const model = defineModel({ type: Array, default: [] });

function setReasons() {
  if (
    (comment.value && !givingComment.value) ||
    (givingComment.value && !comment.value)
  ) {
    model.value = [];
    return;
  }

  const reasons = Array.from(pickedReasons.value);
  if (comment.value) reasons.push(comment.value);
  model.value = reasons;
}

/* Giving comment (requires actual comment) */
const comment = ref('');
const givingComment = ref(false);
function handleGivingComment() {
  givingComment.value = !givingComment.value;
  setReasons();
}

function handleChangeComment() {
  if (comment.value) givingComment.value = true;
  setReasons();
}

/* Giving non-comment feedback */
function handleChoice(reason) {
  pickedReasons.value.has(reason)
    ? pickedReasons.value.delete(reason)
    : pickedReasons.value.add(reason);
  setReasons();
}
</script>
<style lang="pcss" scoped>
h3 {
  @apply text-neutral-text-strong text-m font-bold mb-4 mt-40;
}

p {
  @apply text-neutral-text mb-24;
}
</style>
