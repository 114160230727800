<template>
  <BittsModal
    :width="600"
    :visible="visible"
    :show-close-button="!updatingCard"
    :mask-closable="!updatingCard"
    class="c-edit-payment-modal"
    title="Edit Payment Method"
    :loading-label="updatingCard ? 'Processing card change...' : ''"
    :show-buttons="false"
    :loading="loading"
    :disabled="loading && updatingCard"
    :cancel-disabled="updatingCard"
    @closed="handleCloseModal"
  >
    <template #subtitle>
      <span class="c-chargify-form__subtitle">
        This new card will be used for your next invoice and your current card
        ({{ cardType }} •••• {{ lastFour }}) will be deleted
      </span>
    </template>
    <template #content>
      <ChargifyForm
        submit-button-text="Save"
        :class="{ 'mb-120': isMobile }"
        :can-submit-to-chargify="true"
        :editing="true"
        :disabled="updatingCard || loading"
        @token="handleSubmitForm"
        @token-failure="handleTokenFailure"
        @cancel="handleCloseModal"
      />
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsModal } from '@crossbeam/bitts';
import { useScreenSize } from '@crossbeam/pointbreak';

import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ChargifyForm from '@/components/billing/ChargifyForm.vue';

import useBilling from '@/composables/useBilling';
import { useBillingStore, useFlashesStore } from '@/stores';

const props = defineProps({
  visible: {
    type: Boolean,
    default: true,
  },
  cancelDestination: {
    type: String,
    default: 'billing',
  },
  successDestination: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update-successful', 'cancel']);

const route = useRoute();
const { isMobile } = useScreenSize();

/* This page is also used as a modal on the self-serve pages, we do not want to override the page titles there */
if (route.name === 'edit-payment')
  useHead({ title: 'Edit Payment - Crossbeam' });

const router = useRouter();
const billingStore = useBillingStore();

const loading = ref(true);
const lastFour = computed(() => billingStore.creditCardLastFourDigits);
const cardType = computed(() => billingStore.creditCardType);
const isConnectorTier = computed(() => billingStore.isConnectorTier);

const flashesStore = useFlashesStore();

/* Used to control loading state of form + modal restrictions.
We don't want someone closing out of the modal while this API
call is running. */
const updatingCard = ref(false);
function setLoadingStates(bool) {
  loading.value = bool;
  updatingCard.value = bool;
}

const { editPaymentMethod } = useBilling();

onMounted(async () => {
  if (isConnectorTier.value) {
    await billingStore.refreshPortalAndPaymentInfo();
    loading.value = false;
    if (
      !billingStore.creditCardType ||
      !billingStore.creditCardLastFourDigits
    ) {
      flashesStore.addErrorFlash('Could not load credit card information');
      await router.push({ name: 'billing' });
    }
  }
});

async function handleCloseModal() {
  emit('cancel');
  await router.push({ name: props.cancelDestination });
}

function handleTokenFailure(err) {
  flashesStore.addErrorFlash({
    message: 'Failed to update payment',
    description: err.message,
  });
  setLoadingStates(false);
}

const subscriptionType = computed(() => billingStore.subscriptionType);
async function handleSubmitForm(token = '') {
  setLoadingStates(true);
  const success = await editPaymentMethod({
    token,
    period: subscriptionType.value,
  });
  setLoadingStates(false);
  if (success) {
    emit('update-successful');
    if (props.successDestination)
      await router.push({ name: props.successDestination });
  }
}
</script>
<style lang="pcss">
.c-edit-payment-modal {
  @apply p-0;
  .c-chargify-form__subtitle {
    @apply block max-w-[450px];
  }

  .c-loading__label {
    @apply text-m font-bold text-neutral-text-strong;
  }
}
</style>
