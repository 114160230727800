<template>
  <div class="c-accounts-card">
    <CardHeader :source="source" title="Accounts" />

    <div class="c-accounts-card__content">
      <TransitionGroup name="fade">
        <ExpandableView
          v-for="(item, index) in source.items"
          :key="`contacts_${index}`"
          class="c-accounts-card__item"
        >
          <template #header>
            <div
              :key="`contact_${index}_header`"
              class="c-accounts-card__item__header"
            >
              <BittsAvatar
                :org="{
                  domain: returnTrimmedDomain(
                    getMdmField(item, 'company_website'),
                  ),
                }"
                :show-initals="false"
                size="medium"
                shape="square"
              />
              <div class="c-accounts-card__item__header__label ml-8">
                <span class="c-accounts-card__item__header__label__title">
                  {{ getMdmField(item, MDM_PROPERTIES.account.NAME) }}
                </span>
                <span class="c-accounts-card__item__header__label__subtitle">
                  {{ getMdmField(item, 'company_website') }}
                </span>
              </div>
              <div class="c-accounts-card__item__header__orgs">
                <CardOrgIcons
                  :orgs="orgs(item)"
                  key-segment="contacts_details"
                  class="c-accounts-card__item__header__orgs__icons"
                />
              </div>
            </div>
          </template>
          <template #collapsed="{ toggle }">
            <div
              :key="`accounts_${index}_collapsed`"
              class="c-accounts-card__item__details"
            >
              <CardRecords
                :item="item"
                :show-from-icons="false"
                :is-our-data-selected="isOurDataSelected"
                :key-segment="`contact_${index}`"
              />
              <CardFooter
                v-if="hasOurs(item)"
                :toggle="toggle"
                :item="item"
                mode="collapsed"
              />
            </div>
          </template>
          <template #expanded="{ toggle }">
            <div
              :key="`accounts_${index}_details`"
              class="c-accounts-card__item__details"
            >
              <CardRecords
                :item="item"
                :show-from-icons="true"
                :key-segment="`contact_${index}`"
                :is-our-data-selected="isOurDataSelected"
                mode="details"
              />
              <CardFooter
                v-if="hasOurs(item)"
                :toggle="toggle"
                :item="item"
                mode="expanded"
              />
            </div>
          </template>
        </ExpandableView>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import { BittsAvatar } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import CardFooter from '@/components/records/Card/CardFooter.vue';
import CardHeader from '@/components/records/Card/CardHeader.vue';
import CardRecords from '@/components/records/Card/CardRecords.vue';
import ExpandableView from '@/components/records/ExpandableView.vue';

import useAuth from '@/composables/useAuth';
import { MDM_PROPERTIES } from '@/constants/mdm';
import { useDataSharesStore, usePartnersStore } from '@/stores';
import { getMdmField } from '@/utils';

import CardOrgIcons from './CardOrgIcons.vue';

export default {
  name: 'AccountsCard',
  components: {
    BittsAvatar,
    ExpandableView,
    CardRecords,
    CardHeader,
    CardFooter,
    CardOrgIcons,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    isOurDataSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return { currentOrg };
  },
  data() {
    return { MDM_PROPERTIES };
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(useDataSharesStore, [
      'getDataShareById',
      'getDataShareWithPopulationInfo',
    ]),
    getMdmField,
    orgs(item) {
      const appliedIncomingDataShares = item.data_share_ids.map((dataShareId) =>
        this.getDataShareById(dataShareId),
      );
      const partnerOrgIds = new Set([
        ...appliedIncomingDataShares.map(
          (dataShare) => dataShare.partner_org_id,
        ),
      ]);
      const orgs = [...partnerOrgIds].map((orgId) => {
        const orgReceivingDataShares = appliedIncomingDataShares
          .filter((dataShare) => dataShare.partner_org_id === orgId)
          .map((dataShare) => ({
            ...this.getDataShareWithPopulationInfo(dataShare.id),
            type: 'receiving',
          }));

        return {
          ...this.getPartnerOrgById(orgId),
          dataShares: orgReceivingDataShares,
        };
      });
      if (item.is_ours) {
        orgs.push(this.currentOrg);
      }
      return orgs;
    },
    returnTrimmedDomain(domain) {
      if (!domain) return '';
      return domain.replace(/^https?:\/\//, '');
    },
    getField(item, columnName) {
      const field = item.fields.find(
        (field) => field.name === columnName || field.column === columnName,
      );
      return field ? field.value : null;
    },
    hasOurs(item) {
      return item.fields.find((field) => field.is_ours);
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-accounts-card {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.c-accounts-card__content {
  margin: 12px 0px;
  background: var(--white);
  border: 1px solid var(--grey6);
  border-radius: 4px;
}

.c-accounts-card__item {
  border-bottom: 1px solid var(--grey6);
  &:last-of-type {
    border-bottom: 0px;
  }
}

.c-accounts-card__item__header {
  display: grid;
  grid-template-columns: [avatar] 40px [label] 1fr [orgs] 1fr;
  width: 100%;
  height: 40px;
  padding: 12px;
}

.c-accounts-card__item__header__label {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-accounts-card__item__header__avatar {
  height: 35px;
  width: 35px;
}

.c-accounts-card__item__header__orgs {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.c-accounts-card__item__header__orgs__icons {
  margin: 0px;
}

.c-accounts-card__item__header__label__title {
  @apply text-brand-navy;
  font-size: 14px;
}

.c-accounts-card__item__header__label__subtitle {
  @apply text-brand-blue;
  font-size: 10px;
}

.c-accounts-card__item__details {
  padding: 12px;
}
</style>
