<template>
  <BittsLoading :is-loading="true" />
</template>

<script>
import { BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { get } from 'lodash';
import { mapActions } from 'pinia';

import useAuth from '@/composables/useAuth';
import {
  DATA_TEMPLATES_CRMS,
  MD_DATA_SOURCE_TYPE,
  REQUIRED_FEED_STATUS,
} from '@/constants/data_sources';
import { NEW_ONBOARDING } from '@/constants/feature_flags';
import { useFeatureFlagStore, useFeedsStore, useFlashesStore } from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';

export default {
  components: { BittsLoading },
  setup() {
    useHead({
      title: 'OAuth - Crossbeam',
    });
    const { loadUserProfile } = useRootStore();
    const { currentOrg } = useAuth();
    return { loadUserProfile, currentOrg };
  },
  async created() {
    let nextPage = null;
    const params = this.$route.query;

    const featureFlagStore = useFeatureFlagStore();
    const hasNewOnboarding = featureFlagStore.hasFeatureFlag(NEW_ONBOARDING);
    const prefix =
      hasNewOnboarding && !this.currentOrg.setup_menu_hidden
        ? '/home/onboarding'
        : '/data-sources';

    let response;

    try {
      response = await axios.get(urls.oauth.callback, { params });
    } catch (err) {
      const errMsg = get(err, ['response', 'data', 'error']);
      const toastObj = {
        message: 'Data Source Connection Failed',
        description: errMsg,
      };
      if (errMsg) this.addErrorFlash(toastObj);
      this.$router.push({ path: prefix });
      return;
    }

    /* Check if we're on the first oAuth of Microsoft Dynamics,
        and if so, then just send the user to the pick-CRM component
        which will make another oAuth for feed creation */
    const isMdDiscovery =
      response.data.integration_type === MD_DATA_SOURCE_TYPE;
    if (isMdDiscovery) {
      const instances = response.data.instances.value;
      this.$router.push({
        path: `${prefix}/dynamics-pick-crm`,
        query: {
          instances: JSON.stringify(instances),
        },
      });
      return;
    }

    try {
      // Hubspot requires a redirect_uri to the /oauth/callback page,
      // so we track our starting point in oauth state on the backend
      nextPage = `${prefix}/feed/${response.data.feed.id}?successfulOauth=true`;
      if (response.data.feed.status === REQUIRED_FEED_STATUS) {
        const toDataTemplates = DATA_TEMPLATES_CRMS.includes(
          response.data.feed.integration?.type,
        );
        nextPage = toDataTemplates
          ? `${prefix}/feed/${response.data.feed.id}/setup`
          : `${prefix}/feed/${response.data.feed.id}/settings`;
        await this.refreshFeedsStore();
      }
      await this.loadUserProfile();
    } catch (err) {
      const errMsg = get(err, ['response', 'data', 'error']);
      const toastObj = {
        message: 'Data Source Connection Failed',
        description: errMsg,
      };
      if (errMsg) {
        this.addErrorFlash(toastObj);
      } else {
        throw err;
      }
    } finally {
      this.$router.push(nextPage || prefix);
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addErrorFlash']),
    ...mapActions(useFeedsStore, ['refreshFeedsStore']),
  },
};
</script>
