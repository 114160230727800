<template>
  <BittsLayout :is-page="true">
    <template #content>
      <PageTitle title="Organization Settings" />
      <OrganizationSettingsCard id="base_settings" class="mb-24" />
      <PotentialRevenueCard
        v-if="showPotentialRevenueCard"
        id="potential_revenue_settings"
        class="mt-24"
      />
      <AuditLogsCard
        v-if="hasScope('read:audit-log')"
        id="audit_logs_settings"
        class="mt-24"
      />
      <LoginOptionsCard
        v-if="hasScope('write:organization')"
        id="login_options_settings"
        class="mt-24"
      />
    </template>
  </BittsLayout>
</template>

<script>
import { BittsLayout } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { mapActions, mapState } from 'pinia';

import AuditLogsCard from '@/components/AuditLogsCard.vue';
import LoginOptionsCard from '@/components/LoginOptionsCard.vue';
import OrganizationSettingsCard from '@/components/OrganizationSettingsCard.vue';
import PageTitle from '@/components/PageTitle.vue';
import PotentialRevenueCard from '@/components/PotentialRevenueCard.vue';

import useAuth from '@/composables/useAuth';
import { SSO } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore, useFeedsStore } from '@/stores';

export default {
  name: 'OrganizationSettings',
  components: {
    BittsLayout,
    PageTitle,
    AuditLogsCard,
    LoginOptionsCard,
    OrganizationSettingsCard,
    PotentialRevenueCard,
  },
  setup() {
    useHead({ title: 'Settings - Crossbeam' });
    const { hasScope } = useAuth();
    return { hasScope };
  },
  computed: {
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    ...mapState(useBillingStore, ['isFreeTier']),
    ssoConfigEnabled() {
      return this.hasFeatureFlag(SSO) && this.hasScope('write:sso');
    },
    showPotentialRevenueCard() {
      return !this.isFreeTier;
    },
    stuff() {
      return this.getFeedById(1);
    },
  },
  methods: {
    ...mapActions(useFeedsStore, 'getFeedById'),
  },
};
</script>

<style lang="pcss">
.c-organization-settings {
  .ant-radio-wrapper.ant-radio-wrapper-disabled
    .bitts-radio-group-cards__label {
    @apply text-neutral-500;
  }

  .ant-radio-wrapper.ant-radio-wrapper-disabled
    .bitts-radio-group-cards__description {
    @apply text-neutral-400;
  }

  .bitts-radio-group-cards
    .ant-radio-checked.ant-radio-disabled
    .ant-radio-inner {
    @apply bg-neutral-background-disabled;
  }
  .c-settings__input
    .ant-input-group-wrapper.c-bitts-input
    .ant-input-group-addon {
    @apply border-none;
  }

  .ant-radio-disabled .ant-radio-inner {
    @apply bg-neutral-background-disabled;
  }

  .opacity-override {
    opacity: 1 !important;
  }
  /* the max-width for this component is set within the library itself for some reason */
  .vue-tags-input {
    max-width: none !important;
  }

  .vue-tags-input .ti-item.ti-selected-item {
    @apply bg-neutral-50 text-black;
  }

  .tooltip-title {
    @apply pb-8 flex gap-8 items-center;
  }
}
</style>
