<template>
  <div>
    <BittsTooltip placement="bottomLeft" class="mb-16 w-min">
      <h3 class="section-header"> Recent exports </h3>
      <template #title>
        <span>Total records exported per day for the last 14 days</span>
      </template>
    </BittsTooltip>
    <BittsTable
      v-if="hasRecentExports"
      hide-pagination
      :row-height="32"
      :columns
      :enforce-responsive-sizing="true"
      :rows="recentExports"
    />
    <BittsEmptyState
      v-else
      data-testid="recent-exports-empty"
      title="There are no report exports in the last 14 days"
      description="When you have activity it will show here"
      size="small"
      class="mb-24"
    >
      <template #image>
        <div
          class="mr-24 rounded-full bg-gradient-to-b from-neutral-background via-transparent"
        >
          <FontAwesomeIcon
            class="w-40 h-40 text-neutral-accent m-20"
            :icon="['fad', 'file-export']"
          />
        </div>
      </template>
    </BittsEmptyState>
  </div>
</template>

<script setup lang="ts">
import { BittsEmptyState, BittsTable, BittsTooltip } from '@crossbeam/bitts';

import type { ColDef } from '@ag-grid-community/core';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { computed } from 'vue';

import useRecordExportLimits, {
  FormattedExport,
} from '@/composables/useRecordExportLimits';

import RecordExportCell from './RecordExportCell.vue';

const { recentExports } = useRecordExportLimits();

const sortByDate = (a: FormattedExport, b: FormattedExport) =>
  DateTime.fromISO(a.dateExported).toMillis() -
  DateTime.fromISO(b.dateExported).toMillis();

const hasRecentExports = computed(() => {
  return !isEmpty(recentExports.value);
});

const columns: ColDef<FormattedExport>[] = [
  {
    field: 'dateExported',
    cellClass: ['text-neutral-text-weak', 'px-8'],
    headerClass: ['px-8'],
    headerComponentParams: { displayName: 'Date', showArrow: true },
    comparator: (_, __, { data: a }, { data: b }) => {
      return (a && b && sortByDate(a, b)) || 0;
    },
    valueFormatter: ({ value }) => DateTime.fromISO(value).toFormat('MMM dd'),
    suppressMovable: true,
    sortable: true,
    sort: 'desc',
    minWidth: 72,
    suppressSizeToFit: true,
  },
  {
    cellClass: ['font-bold', 'text-neutral-text-strong', 'px-8'],
    headerClass: ['px-8'],
    headerComponentParams: { displayName: 'Exported', showArrow: true },
    comparator: (_, __, { data: a }, { data: b }) => {
      return (a && b && a.totalExportedCount - b.totalExportedCount) || 0;
    },
    cellRenderer: RecordExportCell,
    suppressMovable: true,
    sortable: true,
    minWidth: 100,
    suppressSizeToFit: true,
  },
  {
    field: 'destinations',
    headerName: 'Destinations',
    cellClass: ['text-neutral-text-weak', 'px-8'],
    valueFormatter: ({ data }) =>
      Object.keys(data?.destinations ?? []).join(', '),
    headerClass: ['px-8'],
    suppressMovable: true,
    flex: 1,
  },
];
</script>

<style scoped lang="pcss">
.section-header {
  @apply text-neutral-text-strong font-bold text-base w-max
  border-b border-dashed border-neutral-text-placeholder;
}

:deep(.ant-tooltip) {
  @apply max-w-content;
}

:deep(.ag-root) {
  @apply px-8 pb-8;

  .ag-row {
    @apply rounded-lg;
  }
}
</style>
