<template>
  <p
    v-if="seatRequest"
    class="c-seat-request-table-date-cell"
    data-testid="seat-request-date"
  >
    {{ readable }}
  </p>
</template>

<script setup>
import { DateTime } from 'luxon';
import { computed } from 'vue';

import { timeSince } from '@/date_time_utils';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const seatRequest = computed(() => props.params.data);
const readable = computed(() =>
  timeSince(DateTime.fromISO(seatRequest.value?.created_at)),
);
</script>
<style lang="pcss">
.c-seat-request-table-date-cell {
  @apply text-sm text-neutral-text-weak;
}
</style>
