import { EVENT_SITES } from '@crossbeam/itly';

import {
  ATTRIBUTION_EVENTS_PARTNER_AVATAR_LOOKUP,
  ATTRIBUTION_EVENT_TYPES,
} from '@/constants/attribution';
import { usePartnersStore } from '@/stores';
import { TimelineEvent } from '@/types/timeline';

import useAuth from './useAuth';
import useIteratively from './useIteratively';

export default function useTimelineContentDeux() {
  const { iteratively } = useIteratively();
  const partnersStore = usePartnersStore();
  const { currentOrg } = useAuth();

  const ACTION_COPY_BY_SOURCE_AND_EVENT = {
    [ATTRIBUTION_EVENT_TYPES.REQUEST_SENT]: 'received a request about',
    [ATTRIBUTION_EVENT_TYPES.REQUEST_RECEIVED]: 'sent a request about',
    [ATTRIBUTION_EVENT_TYPES.POPULATION_EXIT]: 'is no longer',
    [ATTRIBUTION_EVENT_TYPES.POPULATION_ENTRY]: 'became',
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: 'is',
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: 'is no longer',
    [ATTRIBUTION_EVENT_TYPES.PARTNERSTACK_LEAD_CREATED]: 'sent',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_CREATED]: 'helped you',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_DELETED]: 'was removed from',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_UPDATED]: 'helped you',
  };
  const ATTRIBUTION_TYPE_TO_FRIENDLY_NAME: Record<string, string> = {
    sourced: 'source',
    influenced: 'with',
  };

  const createTextElement = (text: string, props: unknown = {}) => ({
    element: 'span',
    props,
    text,
  });

  const bold = (text: string) =>
    createTextElement(text, { class: 'text-neutral-text-strong font-bold' });

  const normal = (text: string) => createTextElement(text);

  const italic = (text: string) => createTextElement(text, { class: 'italic' });

  const normalWeak = (text: string) =>
    createTextElement(text, { class: 'text-neutral-text-weak' });

  const link = (text: string, href: string, onClick: () => void) => ({
    element: 'a',
    props: {
      href,
      target: '_blank',
      rel: 'noopener noreferrer',
      class: 'underline',
      'data-testid': 'activity-link',
      onClick,
    },
    text,
  });

  const buildSimplePhrase = (
    firstVal: string,
    action: string,
    secondVal: string,
  ) => [bold(firstVal), normal(action), normal(secondVal)];

  const buildPopMovementPhrase = (
    account: string,
    action: string,
    activity: TimelineEvent,
  ) => {
    if (!('population_name' in activity.data))
      throw new Error('No population name found');

    const { population_name: populationName } = activity.data;

    const phrase = [
      normalWeak(account),
      normalWeak(action),
      normalWeak(populationName),
    ];

    return phrase;
  };

  const buildOverlapPhrase = (
    account: string,
    action: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    if (!('partner_population_name' in activity.data))
      throw new Error('No partner population name found');

    const { partner_population_name: partnerPopulationName } = activity.data;

    const phrase = [
      normalWeak(account),
      normalWeak(action),
      partnerPopulationName
        ? normalWeak(partnerPopulationName)
        : italic('Unavailable'),
      normalWeak('of'),
      normalWeak(`${partnerName}'s`),
    ];

    return phrase;
  };

  const buildAttributionPhrase = (
    account: string,
    action: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    if (!('attribution_type' in activity.data)) return;
    const { attribution_type: attributionType } = activity.data;

    const friendlyAttributionType =
      ATTRIBUTION_TYPE_TO_FRIENDLY_NAME[attributionType] ?? attributionType;

    const phrase = [
      bold(partnerName),
      normal(action),
      normal(friendlyAttributionType),
      normal(account),
    ];

    return phrase;
  };

  const buildAttributionDeletedPhrase = (
    _account: string,
    action: string,
    _activity: TimelineEvent,
    partnerName: string,
  ) => {
    const phrase = [normal('Attribution'), normal(action), bold(partnerName)];

    return phrase;
  };

  const buildGongPhrase = (
    account: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    if (!('mentions' in activity.data)) return;

    const { deep_link_url, mentions } = activity.data;
    const numMentions = mentions?.length ?? 0;

    let mentionsCopy = 'was mentioned';

    if (numMentions > 0) {
      mentionsCopy += numMentions > 1 ? ` ${numMentions} times` : ' once';
    }

    const phrase = [
      bold(partnerName),
      deep_link_url
        ? link(mentionsCopy, deep_link_url, () => onClickDeepLink(activity))
        : normal(mentionsCopy),
      normal('on a call with'),
      normal(account),
    ];

    return phrase;
  };

  const buildPartnerStackPhrase = (
    account: string,
    activity: TimelineEvent,
  ) => {
    if (!('submitter_name' in activity.data)) return;

    const { submitter_name, partnerstack_partner } = activity.data;

    const phrase = [
      bold(submitter_name),
      normal('sent'),
      normal(account),
      normal('to'),
      bold(partnerstack_partner),
      normal('on PartnerStack'),
    ];

    return phrase;
  };

  const buildRequestPhrase = (
    account: string,
    action: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    let user_name: string | undefined, deep_link_url: string | undefined;
    if ('user_name' in activity.data) {
      user_name = activity.data.user_name;
      deep_link_url = activity.data.deep_link_url;
    }

    const phrase = [
      bold(user_name ?? partnerName),
      deep_link_url
        ? link(action, deep_link_url, () => onClickDeepLink(activity))
        : normal(action),
      normal(account),
    ];

    return phrase;
  };

  const phraseByActivityType = {
    [ATTRIBUTION_EVENT_TYPES.POPULATION_EXIT]: buildPopMovementPhrase,
    [ATTRIBUTION_EVENT_TYPES.POPULATION_ENTRY]: buildPopMovementPhrase,
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: buildOverlapPhrase,
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: buildOverlapPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_CREATED]: buildAttributionPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_DELETED]:
      buildAttributionDeletedPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_UPDATED]: buildAttributionPhrase,
  };

  const buildMarkupByActivity = (
    activity: TimelineEvent,
    accountName: string,
  ) => {
    const partner =
      (activity.partner_organization_uuid &&
        partnersStore.getPartnerOrgByUuid(
          activity.partner_organization_uuid,
        )) ||
      undefined;

    const partnerName = partner?.name ?? 'Deactivated Partner';
    const accountNameOrDeactivated = accountName ?? 'Deactivated Account';

    const { type: activityType, activity_source: activitySource } = activity;

    const phraseSubject =
      ('submitter_name' in activity.data && activity.data.submitter_name) ||
      partnerName;
    const phraseDirectObject =
      ('partnerstack_partner' in activity.data &&
        activity.data.partnerstack_partner) ||
      accountNameOrDeactivated;

    const action = ACTION_COPY_BY_SOURCE_AND_EVENT[activityType] ?? '';

    if (activitySource === 'gong') {
      return buildGongPhrase(accountName, activity, partnerName);
    }
    if (activitySource === 'partnerstack') {
      return buildPartnerStackPhrase(accountName, activity);
    }
    if (activitySource === 'salesedge') {
      return buildRequestPhrase(accountName, action, activity, partnerName);
    }

    const phrase =
      activitySource === 'crossbeam-core' && phraseByActivityType[activityType]
        ? phraseByActivityType[activityType](
            phraseDirectObject,
            action,
            activity,
            partnerName,
          )
        : buildSimplePhrase(phraseSubject, action, phraseDirectObject);

    return action ? phrase : [normal('All event information not found')];
  };

  const friendlySourceText = (activity: TimelineEvent) => {
    const source =
      {
        [ATTRIBUTION_EVENT_TYPES.PARTNER_MENTION]: 'Gong',
        [ATTRIBUTION_EVENT_TYPES.REQUEST_SENT]: 'Crossbeam for Sales',
        [ATTRIBUTION_EVENT_TYPES.REQUEST_RECEIVED]: 'Crossbeam for Sales',
        [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: 'Partner CRM',
        [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: 'Partner CRM',
        [ATTRIBUTION_EVENT_TYPES.PARTNERSTACK_LEAD_CREATED]: 'PartnerStack',
      }[activity.type] || 'Crossbeam';
    return `${source} Activity`;
  };

  const orgInActivity = (activity: TimelineEvent) =>
    activity.partner_organization_uuid &&
    ATTRIBUTION_EVENTS_PARTNER_AVATAR_LOOKUP[activity.type]
      ? partnersStore.getPartnerOrgByUuid(
          activity.partner_organization_uuid,
        ) || { name: null }
      : currentOrg.value;

  const onClickDeepLink = (activity: TimelineEvent) => {
    if (!('deep_link_url' in activity.data)) return;
    iteratively.userInteractedWithTimeline({
      cta: friendlySourceText(activity),
      cta_2: orgInActivity(activity).name,
      event_site: EVENT_SITES.ATTRIBUTION_TIMELINE_EVENT,
    } as never);
  };

  return {
    buildMarkupByActivity,
    bold,
    normal,
  };
}
