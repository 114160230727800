<template>
  <div class="flex flex-col">
    <slot />
    <span
      v-for="error in relevantErrors"
      :key="error.$uid"
      :class="errorClass"
      class="vuelidate-wrapper__error"
      data-testid="vuelidate-error-message"
    >
      {{ error.$message }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'VuelidateWrapper',
  props: {
    errorClass: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    property: {
      type: String,
      required: true,
    },
    useFirstError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    relevantErrors() {
      if (this.useFirstError) return this.getVuelidateErrorByProperty();
      return this.getVuelidateErrorsByProperty();
    },
  },
  methods: {
    getVuelidateErrorByProperty() {
      const err = this.errors.find((err) =>
        err.$propertyPath.includes(this.property),
      );
      return err ? [err] : [];
    },
    getVuelidateErrorsByProperty() {
      return this.errors.filter((err) =>
        err.$propertyPath.includes(this.property),
      );
    },
  },
};
</script>

<style scoped lang="pcss">
.vuelidate-wrapper__error {
  @apply text-danger-text text-sm mt-4;
}
</style>
