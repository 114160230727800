<template>
  <BittsLayout class="individual-list-page px-0" :is-page="true">
    <template #content>
      <BittsLoading :is-loading="loading">
        <div>
          <SharedListTopBar @open-modal="onOpenModal" />
          <div v-if="!rowData.length" class="m-24">
            <BittsEmptyState
              svg-name="emptyStateHome"
              class="c-individual-list-page__empty-state"
              title="Add accounts to start collaborating with your partners"
              description="Add accounts you want to share with your partner from a report view. Lists allow you to share accounts with your partner to collaborate inside Crossbeam"
              :button="{ text: 'Select Accounts' }"
              @button-clicked="onReportBtnClicked"
            />
          </div>
          <div v-else>
            <SharedListResultsBar
              :row-data="rowData"
              :rows-selected="rowsSelected"
              @open-modal="onOpenModal"
              @report-button-clicked="onReportBtnClicked"
            />
            <SharedListTable
              :column-defs="columnDefs"
              :row-data="rowData"
              :context="context"
              @open-modal="onOpenModal"
              @grid-ready="onGridReady"
              @update-rows="onRowsUpdated"
            />
          </div>
          <EditListModal
            v-if="editListModalVisible"
            :list-item="currentList"
            @saved="onListEdited"
            @closed="onCloseModals(EDIT_LIST)"
          />
          <DeleteRowsModal
            v-if="deleteRowsModalVisible"
            :list-id="currentList.id"
            :rows-selected="rowsSelected"
            :current-num-records="rowData.length"
            @saved="onRowsDeleted"
            @closed="onCloseModals(DELETE_ROWS)"
          />
          <PartnerStackModal
            v-if="partnerStackIsActive && partnerStackModalVisible"
            :selected-record="selectedReferralRecord"
            @saved="onPartnerStackModalClosed"
            @closed="onCloseModals(PARTNERSTACK)"
          />
          <DeleteColumnModal
            v-if="deleteColumnModalVisible"
            :col-info="columnToDelete"
            :list-id="currentList.id"
            @saved="onColumnDeleted"
            @closed="onCloseModals(DELETE_COLUMN)"
          />
          <SendToSalesModal
            v-if="sendToSalesModalVisible"
            :list="currentList"
            :is-single-send="triggeredBySingleSendBtn"
            :rows-selected="rowsSelected"
            @saved="onSendToSalesModalClosed"
            @closed="onCloseModals(CROSSBEAM_FOR_SALES)"
          />
        </div>
      </BittsLoading>
    </template>
  </BittsLayout>
</template>
<script setup lang="ts">
import { BittsEmptyState, BittsLayout, BittsLoading } from '@crossbeam/bitts';
import { Nullable } from '@crossbeam/types';

import {
  ColDef,
  ColGroupDef,
  GridApi,
  IRowNode,
} from '@ag-grid-community/core';
import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import PartnerStackModal from '@/components/integrations/PartnerStackModal.vue';
import SendToSalesModal from '@/components/integrations/SendToSalesModal.vue';
import DeleteColumnModal, {
  ColInfo,
} from '@/components/shared-list/DeleteColumnModal.vue';
import DeleteRowsModal from '@/components/shared-list/DeleteRowsModal.vue';
import EditListModal from '@/components/shared-list/EditListModal.vue';
import SharedListIntegrationCellRenderer from '@/components/shared-list/SharedListIntegrationCellRenderer.vue';
import SharedListNotesRenderer from '@/components/shared-list/SharedListNotesRenderer.vue';
import SharedListOpportunitiesEditorCell from '@/components/shared-list/SharedListOpportunitiesEditorCell.vue';
import SharedListOpportunitiesRendererCell from '@/components/shared-list/SharedListOpportunitiesRendererCell.vue';
import SharedListResultsBar from '@/components/shared-list/SharedListResultsBar.vue';
import SharedListTable from '@/components/shared-list/SharedListTable.vue';
import SharedListTopBar from '@/components/shared-list/SharedListTopBar.vue';
import SharedListUserCell from '@/components/shared-list/SharedListUserCell.vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useIntegrations from '@/composables/useIntegrations';
import useSharedList from '@/composables/useSharedList';
import { BASE_ROUTES } from '@/constants/routes';
import {
  CROSSBEAM_FOR_SALES,
  CUSTOM_TEXT,
  FILE_UPLOAD,
  GOOGLE_SHEETS,
  NAME,
  NOTE,
  OPPORTUNITY_AMOUNT,
  OPPORTUNITY_NAME,
  OPPORTUNITY_STAGE,
  OWNER_NAME,
  PARTNERSTACK,
  PRIVATE_COLUMNS,
} from '@/constants/shared_list';
import { captureException } from '@/errors';
import {
  useCB4SPartnersStore,
  useCollaborateStore,
  useFlashesStore,
} from '@/stores';
import {
  SharedListDataColumnValue,
  SharedListDataResponse,
  SharedListDataRow,
  SharedListGridParams,
} from '@/types/shared_lists';

useHead({
  title: 'Collaborate - Crossbeam',
});

const DELETE_ROWS = 'delete_rows';
const DELETE_COLUMN = 'delete_column';
const EDIT_LIST = 'edit_list';

const collaborateStore = useCollaborateStore();
const flashesStore = useFlashesStore();
const cb4sPartnersStore = useCB4SPartnersStore();

const { currentOrg } = useAuth();

const {
  currentList,
  partnerOrg,
  allUsersInList,
  hasPreventPartnerStackColumn,
  sendToSalesError,
} = useSharedList();

const deleteColumnModalVisible = ref(false);

const rowsSelected = ref<SharedListDataRow[]>([]);
const columnDefs = ref<ColDef[]>([]);
const selectedReferralRecord = ref({});

const loading = ref(true);
const router = useRouter();
const rowData = ref<SharedListDataResponse>([]);

const columnToDelete = ref<Nullable<ColInfo>>(null);
const triggeredBySingleSendBtn = ref(false);

const context = ref({});
const gridApi = ref<Nullable<GridApi>>(null);

function onGridReady(api: GridApi) {
  gridApi.value = api;
}

function onRowsUpdated(row: SharedListDataRow) {
  if (typeof row === 'object') {
    rowsSelected.value.push(row);
  } else {
    const idx = rowsSelected.value.findIndex(
      (selected) => selected.row_id === row,
    );
    rowsSelected.value.splice(idx, 1);
  }
}

onMounted(async () => {
  await collaborateStore.readySync;
  await cb4sPartnersStore.refreshCB4SPartnersStore();
  if (!currentList.value || !currentList.value['is_enabled?']) {
    flashesStore.addErrorFlash({ message: 'List is no longer available' });
    await router.push({ name: 'collaborate' });
    return;
  }
  context.value = {
    onShowDeleteColumnModal(colInfo: ColInfo) {
      columnToDelete.value = colInfo;
      onOpenModal(DELETE_COLUMN);
    },
    onShowModal(option: string, selectedRecord: SharedListDataRow) {
      triggeredBySingleSendBtn.value = true;
      if (option === PARTNERSTACK) {
        selectedReferralRecord.value = selectedRecord;
      }
      rowsSelected.value = [selectedRecord];
      onOpenModal(option);
    },
    async resetGrid() {
      await setupGrid();
    },
    async refreshStore() {
      await collaborateStore.refreshCollaborateStore();
    },
    rowsSelected: computed(() => rowsSelected.value),
  };
  setupGrid();
});

async function setupGrid() {
  loading.value = true;
  columnDefs.value = [];
  rowData.value = [];
  await collaborateStore.refreshCollaborateStore();
  setUpColumns();
  await getRowData(currentList.value.id);
  loading.value = false;
}

async function getRowData(listId: string) {
  try {
    const { data } = await crossbeamApi.GET('/v0.1/lists/{list_id}/data', {
      params: {
        path: { list_id: listId },
      },
    });
    rowData.value = data || [];
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
  }
}

function setUpColumns() {
  const firstColumn = {
    headerClass: 'ag-header-group-cell-own',
    headerGroupComponentParams: {
      isSuppressedColumn: true,
      isEmptyHeader: true,
      currentOrg: currentOrg.value,
      partnerOrg: partnerOrg.value,
      isOwnColumn: true,
    },
    children: [
      {
        headerName: 'Name',
        autoHeight: true,
        initialSort: 'asc',
        sortable: true,
        lockPosition: 'left',
        pinned: 'left',
        headerCheckboxSelection: true,
        cellRenderer: 'SharedListRecordCell',
        valueGetter: (params: SharedListGridParams) => `${params.data.name}`,
        comparator: (
          valueA: SharedListDataColumnValue,
          valueB: SharedListDataColumnValue,
        ) => {
          return sortColumn(valueA, valueB, NAME);
        },
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        cellRendererParams: {
          sendToSalesError: sendToSalesError.value,
        },
      },
    ],
  } as ColDef;
  columnDefs.value.push(firstColumn);

  const privateColumns: ColGroupDef = {
    marryChildren: true,
    children: [],
    headerGroupComponentParams: {
      isEmptyHeader: true,
      currentOrg: currentOrg.value,
      partnerOrg: partnerOrg.value,
      isPrivate: true,
    },
    headerClass: 'ag-header-group-private',
  };
  const individualPrivateCol: ColDef[] = [];

  currentList.value?.columns.forEach((col) => {
    const isOwnColumn =
      currentOrg.value.id === col.organization_id ||
      col.column_type === CUSTOM_TEXT;

    if (col.is_private) {
      const partnerStackCol =
        partnerStackIsActive && col.column_type === PARTNERSTACK;
      if (partnerStackCol && hasPreventPartnerStackColumn.value) return;
      const salesCol = col.column_type === CROSSBEAM_FOR_SALES;
      const colWithProperties = {
        colId: col.column_id,
        field: col.column_type,
        headerName: col.display_name,
        headerClass: getSubHeaderClass(col.column_type),
        cellRenderer: getCellRenderer(col.column_type),
        cellEditor: getCellEditor(col.column_type),
        editable: (params: SharedListGridParams) =>
          isEditColumn(col.column_type, params, isOwnColumn),
        cellClass: () => getCellClass(col.column_type),
        singleClickEdit: col.column_type === OPPORTUNITY_NAME,
        comparator: (
          valueA: SharedListDataColumnValue,
          valueB: SharedListDataColumnValue,
        ) => {
          return sortColumn(valueA, valueB, col.column_type);
        },
        flex: setColumnFlex(col.column_type),
        valueGetter: (params: SharedListGridParams) => {
          return params.data.data.find(
            (rd) => rd.column_id === params.column.colId,
          )?.value;
        },
        valueSetter: (params: SharedListGridParams) => {
          const val = params.data.data.find(
            (rd) => rd.column_id === params.column.colId,
          );
          if (!val) return;
          val.value = params.newValue;
          if (col.column_type === OPPORTUNITY_NAME) return true;
        },
      } as unknown as ColDef;
      partnerStackCol || salesCol
        ? individualPrivateCol.push({
            headerGroupComponentParams: {
              isEmptyHeader: true,
              currentOrg: currentOrg.value,
              partnerOrg: partnerOrg.value,
              isPrivate: true,
            },
            headerClass: 'ag-header-group-private',
            children: [colWithProperties],
          } as ColDef)
        : privateColumns.children.push(colWithProperties);
      return;
    }

    columnDefs.value.push({
      headerGroupComponentParams: {
        isEmptyHeader: !col.organization_id,
        currentOrg: currentOrg.value,
        partnerOrg: partnerOrg.value,
        isOwnColumn,
        isPrivate: false,
      },
      headerClass: getTopHeaderClass(col.column_type, isOwnColumn),
      children: [
        {
          headerComponentParams: {
            isDefaultColType: col.column_type !== CUSTOM_TEXT,
            isOwnColumn,
          },
          colId: col.column_id,
          field: col.column_type,
          headerName: col.display_name,
          headerClass: getSubHeaderClass(col.column_type),
          cellRenderer: getCellRenderer(col.column_type),
          cellEditor: getCellEditor(col.column_type),
          editable: (params: SharedListGridParams) =>
            isEditColumn(col.column_type, params, isOwnColumn),
          cellClass: () => getCellClass(col.column_type),
          flex: setColumnFlex(col.column_type),
          wrapText: col.column_type === NOTE,
          comparator: (
            valueA: SharedListDataColumnValue,
            valueB: SharedListDataColumnValue,
          ) => {
            return sortColumn(valueA, valueB, col.column_type);
          },
          valueGetter: (params: SharedListGridParams) => {
            return params.data.data.find(
              (rd) => rd.column_id === params.column.colId,
            )?.value;
          },
          valueSetter: (params: SharedListGridParams) => {
            const val = params.data.data.find(
              (rd) => rd.column_id === params.column.colId,
            );
            if (!val) return;
            val.value = params.newValue;
            return true;
          },
          cellRendererParams: {
            isOwnColumn,
            listId: currentList.value.id,
            allUsersInList: allUsersInList.value,
          },
        },
      ],
    } as ColDef);
  });
  columnDefs.value.splice(3, 0, ...individualPrivateCol, privateColumns);
  columnDefs.value.push({
    headerClass: 'ag-header-group-cell-own',
    headerGroupComponentParams: {
      isSuppressedColumn: true,
      isEmptyHeader: true,
      currentOrg: currentOrg.value,
      partnerOrg: partnerOrg.value,
      isOwnColumn: true,
    },
    children: [
      {
        lockPosition: 'right',
        headerName: null,
        maxWidth: 60,
      },
    ],
  } as ColDef);
}

function setColumnFlex(columnType: string) {
  return [OPPORTUNITY_NAME, OPPORTUNITY_STAGE, NOTE].includes(columnType)
    ? 2
    : 1;
}

function getCellRenderer(columnType: string) {
  switch (columnType) {
    case OWNER_NAME:
      return SharedListUserCell;
    case NOTE:
    case CUSTOM_TEXT:
      return SharedListNotesRenderer;
    case OPPORTUNITY_NAME:
    case OPPORTUNITY_STAGE:
    case OPPORTUNITY_AMOUNT:
      return SharedListOpportunitiesRendererCell;
    case PARTNERSTACK:
    case CROSSBEAM_FOR_SALES:
      return SharedListIntegrationCellRenderer;
    default:
      return null;
  }
}

function getCellEditor(columnType: string) {
  if (columnType === OPPORTUNITY_NAME) return SharedListOpportunitiesEditorCell;
  if (columnType === NOTE || columnType === CUSTOM_TEXT)
    return SharedListNotesRenderer;
  return null;
}

function getTopHeaderClass(columnType: string, isOwnColumn: boolean) {
  if (PRIVATE_COLUMNS.includes(columnType)) {
    return 'ag-header-group-private';
  }
  return isOwnColumn
    ? 'ag-header-group-cell-own'
    : 'ag-header-group-cell-partner';
}

function getSubHeaderClass(columnType: string) {
  if (PRIVATE_COLUMNS.includes(columnType)) {
    return 'ag-column-group-private';
  }
}

function getCellClass(columnType: string) {
  if (PRIVATE_COLUMNS.includes(columnType)) {
    return 'ag-cell-private';
  }
}
function isEditColumn(
  columnType: string,
  params: SharedListGridParams,
  isOwnColumn: boolean,
) {
  if (columnType === OPPORTUNITY_NAME) {
    const val = params.data.data.find(
      (rd) => rd.column_id === params.column.colId,
    );
    const value = val?.value;
    const isOverlapsRecord = !params.data?.is_greenfield;
    const isOwnGreenfieldRecord = !isOverlapsRecord && !!params.data.record_id;
    const unavailableTypes = [GOOGLE_SHEETS, FILE_UPLOAD];
    if (params.data?.integration_type === null) return false;
    return (
      value === null &&
      !unavailableTypes.includes(params.data?.integration_type) &&
      (isOwnGreenfieldRecord || isOverlapsRecord)
    );
  }
  return columnType === NOTE && isOwnColumn;
}

function sortColumn(
  valueA: SharedListDataColumnValue,
  valueB: SharedListDataColumnValue,
  column: string,
) {
  switch (column) {
    case CUSTOM_TEXT:
    case NOTE:
      if (
        typeof valueA === 'string' ||
        typeof valueB === 'string' ||
        (valueA === null && valueB === null) ||
        valueA?.updated_at === valueB?.updated_at ||
        !valueA?.updated_at ||
        !valueB?.updated_at
      )
        return 0;
      return valueA?.updated_at < valueB?.updated_at ||
        (valueA?.updated_at && valueB === null)
        ? 1
        : -1;
    case PARTNERSTACK:
    case CROSSBEAM_FOR_SALES:
      if (
        typeof valueA === 'string' ||
        typeof valueB === 'string' ||
        (valueA === null && valueB === null) ||
        valueA?.submitted_on === valueB?.submitted_on ||
        !valueA?.submitted_on ||
        !valueB?.submitted_on
      )
        return 0;
      return valueA?.submitted_on < valueB?.submitted_on ||
        (valueA?.submitted_on && valueB === null)
        ? 1
        : -1;
    case OPPORTUNITY_AMOUNT:
      if ((valueA === null && valueB === null) || valueA === valueB) return 0;
      return valueA > valueB || (valueA && valueB === null) ? 1 : -1;
    default: {
      if (typeof valueA !== 'string' || typeof valueB !== 'string') return 0;
      const lowerCasedA = valueA?.toLowerCase();
      const lowerCasedB = valueB?.toLowerCase();
      if (
        lowerCasedA === lowerCasedB ||
        (lowerCasedA === null && lowerCasedB === null)
      )
        return 0;
      return lowerCasedA > lowerCasedB || (lowerCasedA && lowerCasedB === null)
        ? 1
        : -1;
    }
  }
}

const deleteRowsModalVisible = ref(false);

async function onRowsDeleted() {
  loading.value = true;
  onCloseModals(DELETE_ROWS);
  await getRowData(currentList.value.id);
  loading.value = false;
}

async function onReportBtnClicked() {
  await router.push({ name: BASE_ROUTES.REPORTS });
}

// EditListModal
const editListModalVisible = ref(false);
async function onListEdited() {
  loading.value = true;
  onCloseModals(EDIT_LIST);
  await collaborateStore.refreshCollaborateStore();
  loading.value = false;
}

// PartnerStack Modal
const { partnerStackIsActive } = useIntegrations();
const partnerStackModalVisible = ref(false);

function onPartnerStackModalClosed(response: { rowId: string }) {
  if (response) {
    const row: IRowNode | undefined = gridApi.value?.getRowNode(response.rowId);
    const updatedRowNodes: IRowNode[] = [];
    gridApi.value?.forEachNode((rowNode) => {
      if (rowNode.data.record_id === row?.data.record_id) {
        updatedRowNodes.push(rowNode);
        rowNode.setDataValue('partnerstack', { ...response });
      }
    });
    gridApi.value?.redrawRows({ rowNodes: [...updatedRowNodes] });
  }
  onCloseModals(PARTNERSTACK);
}

type Item = { row_id: string; value: object };
function onSendToSalesModalClosed(response: Item[]) {
  if (response) {
    const updatedRowNodes: IRowNode[] = [];
    response.forEach((item: Item) => {
      const row: IRowNode | undefined = gridApi.value?.getRowNode(item.row_id);
      row?.setDataValue('crossbeam_for_sales', { ...item.value });
    });
    gridApi.value?.redrawRows({ rowNodes: [...updatedRowNodes] });
  }
  onCloseModals(CROSSBEAM_FOR_SALES);
}

async function onColumnDeleted() {
  onCloseModals(DELETE_COLUMN);
  await setupGrid();
}

const sendToSalesModalVisible = ref(false);

function onOpenModal(option: string, record = null) {
  switch (option) {
    case CROSSBEAM_FOR_SALES:
      sendToSalesModalVisible.value = true;
      break;
    case PARTNERSTACK:
      if (record) selectedReferralRecord.value = record;
      partnerStackModalVisible.value = true;
      break;
    case DELETE_ROWS:
      deleteRowsModalVisible.value = true;
      break;
    case EDIT_LIST:
      editListModalVisible.value = true;
      break;
    case DELETE_COLUMN:
      deleteColumnModalVisible.value = true;
      break;
    default:
      break;
  }
}

function onCloseModals(modal: string) {
  if (rowsSelected.value.length) {
    gridApi.value?.deselectAll();
    rowsSelected.value = [];
  }
  triggeredBySingleSendBtn.value = false;
  switch (modal) {
    case DELETE_ROWS:
      deleteRowsModalVisible.value = false;
      break;
    case CROSSBEAM_FOR_SALES:
      sendToSalesModalVisible.value = false;
      break;
    case PARTNERSTACK:
      selectedReferralRecord.value = {};
      partnerStackModalVisible.value = false;
      break;
    case EDIT_LIST:
      editListModalVisible.value = false;
      break;
    case DELETE_COLUMN:
      deleteColumnModalVisible.value = false;
      break;
    default:
      break;
  }
}
</script>
<style lang="pcss">
.c-individual-list-page__empty-state {
  height: calc(100vh - 259px) !important;
}
</style>
