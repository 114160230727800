import { DateTime } from 'luxon';

import { crossbeamApi } from '@/api';
import { ATTRIBUTION_FILTER_QUERY_PARAMS } from '@/constants/attribution';
import {
  AttributionPageQuery,
  ClosedWonOpportunitiesParams,
  SaveAttributionsPayload,
} from '@/types/attribution';

export const saveAttributions = async ({
  payload,
  masterUUID,
  opportunityID,
}: {
  payload: SaveAttributionsPayload;
  masterUUID: string;
  opportunityID: string;
}) => {
  const { data, error } = await crossbeamApi.PUT(
    '/v0.1/attributions/{master_uuid}/{opportunity_id}',
    {
      params: {
        path: {
          master_uuid: masterUUID,
          opportunity_id: opportunityID,
        },
      },
      body: payload,
    },
  );
  if (error) throw error;

  return data;
};

export const dismissRecommendedAttribution = async ({
  masterUUID,
  opportunityID,
}: {
  masterUUID: string;
  opportunityID: string;
}): Promise<void> => {
  const { error } = await crossbeamApi.PUT(
    '/v0.1/attribution/opportunities/{master_uuid}/{opportunity_id}/metadata',
    {
      params: {
        path: {
          master_uuid: masterUUID,
          opportunity_id: opportunityID,
        },
      },
      body: { is_dismissed: true },
    },
  );
  if (error) throw error;
};

export const fetchUnattributedOpportunities = async () => {
  const dateMonthAway = DateTime.local()
    .plus({ months: 1 })
    .toFormat('yyyyMMdd');
  const dateToday = DateTime.now().toFormat('yyyyMMdd');
  const { data, error } = await crossbeamApi.GET(
    '/v0.1/attribution/opportunities',
    {
      params: {
        query: {
          is_closed: false,
          is_won: false,
          dismissed: false,
          attribution_exists: false,
          order: 'asc',
          limit: 40,
          'close_date[gte]': dateToday,
          'close_date[lte]': dateMonthAway,
          page: 1,
        },
      },
    },
  );

  if (error) throw error;

  return data;
};

export const fetchClosedWonOpportunities = async (
  query: ClosedWonOpportunitiesParams,
) => {
  const { data, error } = await crossbeamApi.GET(
    '/v0.1/attribution/won-pipeline/opportunities',
    {
      params: {
        query,
      },
    },
  );

  if (error) throw error;

  return data;
};

export const deleteAttribution = async ({
  attributionID,
}: {
  attributionID: string;
}): Promise<void> => {
  const { error } = await crossbeamApi.DELETE('/v0.1/attributions/{id}', {
    params: {
      path: {
        id: attributionID,
      },
    },
  });
  if (error) throw error;
};

const {
  CLOSE_DATE_GTE,
  CLOSE_DATE_LTE,
  OPPORTUNITY_AMOUNT_GTE,
  OPPORTUNITY_AMOUNT_LTE,
  MASTER_UUID,
  SALES_STAGE,
  ORDER,
  SORT,
  PARTNER_UUIDS,
} = ATTRIBUTION_FILTER_QUERY_PARAMS;

const baseQuery = {
  page: 1,
  order: 'asc',
  limit: 20,
};
export const extractFiltersFromQuery = (
  query: AttributionPageQuery = baseQuery,
): AttributionPageQuery => {
  const startDateFilter = query[CLOSE_DATE_GTE];
  const endDateFilter = query[CLOSE_DATE_LTE];
  const startAmountFilter = query[OPPORTUNITY_AMOUNT_GTE];
  const endAmountFilter = query[OPPORTUNITY_AMOUNT_LTE];
  const params = { ...query };

  const partnerFilter = query[PARTNER_UUIDS];
  if (partnerFilter) {
    const partnerFilterArray = Array.isArray(partnerFilter)
      ? partnerFilter
      : [partnerFilter];
    params[PARTNER_UUIDS] = partnerFilterArray;
  }

  const salesStageFilter = query[SALES_STAGE];
  if (salesStageFilter) {
    const salesStageFilterArray = Array.isArray(salesStageFilter)
      ? salesStageFilter
      : [salesStageFilter];
    params[SALES_STAGE] = salesStageFilterArray;
  }

  if (startDateFilter || endDateFilter) {
    params[CLOSE_DATE_GTE] = startDateFilter;
    params[CLOSE_DATE_LTE] = endDateFilter;
  }

  if (startAmountFilter && endAmountFilter) {
    params[OPPORTUNITY_AMOUNT_GTE] = startAmountFilter;
    params[OPPORTUNITY_AMOUNT_LTE] = endAmountFilter;
  }

  if (query[MASTER_UUID]) params[MASTER_UUID] = query[MASTER_UUID];

  if (query[ORDER] && query[SORT]) {
    params[ORDER] = query[ORDER];
    params[SORT] = query[SORT] as 'record_name' | 'amount' | 'close_date';
  }
  return params;
};

interface Quarter {
  start: DateTime;
  end: DateTime;
}

/**
 * Builds query parameters for the current fiscal quarter based on the starting month of the fiscal year.
 *
 * @param {Object} quarterLookup
 * @returns {Object} - An object with the query parameters for the current fiscal quarter.
 * These params use our new Left Hand Side (LHS) brackets for the query operands.
 * @example
 * // For a fiscal year starting in July:
 * buildCurrentQuarterDateRangeQuery(lookup with fiscal year starting 7);
 * // Returns:
 * // {
 * //   close_date_gte: '20210701',
 * //   close_date_lte: '20210930',
 * // }
 */
export function buildCurrentQuarterDateRangeQuery(
  quarterLookup: Record<string, Quarter>,
) {
  const currentMonth = DateTime.local().month;
  const currentQuarter = Object.values(quarterLookup).find(({ start, end }) => {
    // handle wrapping around the year
    if (start.month > end.month) {
      return currentMonth >= start.month || currentMonth <= end.month;
    }
    return currentMonth >= start.month && currentMonth <= end.month;
  });

  if (!currentQuarter) return;

  return {
    [CLOSE_DATE_GTE]: currentQuarter.start.toFormat('yyyyMMdd'),
    [CLOSE_DATE_LTE]: currentQuarter.end.toFormat('yyyyMMdd'),
  };
}
