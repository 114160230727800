// Column Types
export const NOTE = 'note';
export const OWNER_NAME = 'owner_name';
export const OPPORTUNITY_NAME = 'opportunity_name';
export const OPPORTUNITY_STAGE = 'opportunity_stage';
export const OPPORTUNITY_AMOUNT = 'opportunity_amount';
export const NAME = 'name';
export const PARTNERSTACK = 'partnerstack';
export const CUSTOM_TEXT = 'custom_text';
export const CROSSBEAM_FOR_SALES = 'crossbeam_for_sales';

export const PRIVATE_COLUMNS = [
  OPPORTUNITY_NAME,
  OPPORTUNITY_STAGE,
  OPPORTUNITY_AMOUNT,
  PARTNERSTACK,
  CROSSBEAM_FOR_SALES,
];

export const GOOGLE_SHEETS = 'google_sheets';
export const FILE_UPLOAD = 'file_upload';

// Access Types
export const OWNER = 'owner';
export const SHARED = 'shared';
export const EDITOR = 'editor';
export const CREATOR = 'creator';

// List Access Status Codes
export const DISABLED_LISTS = 'DISABLED_LISTS';
export const FULL_ACCESS = 'FULL_ACCESS';
export const LIMITED_ACCESS = 'LIMITED_ACCESS';
export const NO_ACCESS = 'NO_ACCESS';

// List Notifications
export const LIST_SHARING_UPDATED = 'list_sharing_updated';
export const LIST_MEMBERS_UPDATED = 'list_members_updated';
export const LIST_NOTE_UPDATED = 'list_note_updated';
export const MAX_RECORDS_SHOWN = 5;

//
export const MAX_NUM_NOTE_CHARS = 1000;
export const MAX_NUM_CUSTOM_NOTE_CHARS = 500;

// Send to Sales error states
export const UPGRADE_OWN_CB4S_ROLE = 'upgrade-own-cb4s-role';
export const UPGRADE_OWN_CB4S_ROLE_ENABLE_PARTNER =
  'upgrade-own-cb4s-role-enable-partner';
export const CONTACT_ADMIN_UPGRADE_CB4S_ROLE = 'contact-admin-cb4s-role';
export const ENABLE_PARTNER_CB4S = 'enable-partner-cb4s';
export const CONTACT_ADMIN_ENABLE_PARTNER_CB4S =
  'contact-admin-enable-partner-cb4s';
