<template>
  <BittsLayout :is-page="true">
    <template #content>
      <BittsEmptyState
        description="Threads has been replaced with Shared Lists. All historical Threads activity can now be found in the activity timeline.
              We recommend using Shared Lists to collaborate with partners, where you can prioritize accounts, add notes, and send records to your Account Owners"
        :link="{
          text: 'Learn more about Shared Lists',
          url: 'https://help.crossbeam.com/en/articles/8345701-shared-lists',
        }"
        png-name="threads-empty-state.png"
        title="Use Shared Lists to message and collaborate in real-time with partners"
        class="sunset-threads"
      />
    </template>
  </BittsLayout>
</template>

<script setup>
import { BittsEmptyState, BittsLayout } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';

useHead({ title: 'Sunset Threads' });
</script>

<style lang="pcss">
.sunset-threads {
  @apply h-full;
  .c-bitts-empty-state-large {
    @apply w-full;
  }
}
</style>
