import { crossbeamApi } from '@/api';
import {
  ATTRIBUTION_METRIC_TYPES,
  ATTRIBUTION_SETTINGS_DEFAULTS,
  ATTRIBUTION_SETTINGS_WITH_PARTNER_METRICS,
} from '@/constants/attribution';
import urls from '@/urls';

import { useAttributionState } from './state';
import { extractFiltersFromQuery } from './utils';

const { rollupMetrics, eiAttributionMetrics } = useAttributionState();

async function fetchRollupMetrics(routeQuery, metricTypes) {
  const url = urls.attribution.metrics;
  const query = extractFiltersFromQuery(routeQuery);

  for (const metricType of metricTypes) {
    // we request the with and without metrics together despite these values being separate on the backend
    if (ATTRIBUTION_SETTINGS_WITH_PARTNER_METRICS[metricType]) {
      const withoutMetricType =
        ATTRIBUTION_SETTINGS_WITH_PARTNER_METRICS[metricType];
      query[withoutMetricType] = true;
    }
    query[metricType] = true;
  }

  const { data } = await crossbeamApi.GET(url, {
    params: {
      query,
    },
  });
  return data;
}

async function fetchEiAttributionMetrics(query) {
  const url = urls.attribution.metrics;
  const { data } = await crossbeamApi.GET(url, {
    params: {
      query,
    },
  });
  eiAttributionMetrics.value = data;
}

async function getUpdatedMetrics(routeQuery, incomingTypes) {
  /* Returns metric values based on the provided types and query params.
       DOES NOT honor metric settings coming from routeQuery.
     */
  const metricTypes = incomingTypes || ATTRIBUTION_SETTINGS_DEFAULTS;
  const unorderedMetrics = await fetchRollupMetrics(routeQuery, metricTypes);

  const orderMetrics = (settings) => {
    return settings.map((type) => {
      const value = unorderedMetrics[type];
      if (ATTRIBUTION_SETTINGS_WITH_PARTNER_METRICS[type]) {
        const secondaryMetricType =
          ATTRIBUTION_SETTINGS_WITH_PARTNER_METRICS[type];
        const withoutPartnersMetric = unorderedMetrics[secondaryMetricType];
        return { type, value, withoutPartnersMetric };
      }
      return { type, value };
    });
  };
  return orderMetrics(metricTypes);
}

async function refreshMetrics(routeQuery, types) {
  const { metricSettings, useMetricsFromQueryFlag } =
    metricSettingsFromQuery(routeQuery);
  const metricTypes = useMetricsFromQueryFlag ? metricSettings : types;
  rollupMetrics.value = await getUpdatedMetrics(routeQuery, metricTypes);
}

const metricSettingsFromQuery = (query) => {
  /**
   * This code converts the metric settings from the query params to a list of metric types in the appropriate order.
   * We are storing indexes for the metric type queries.
   * The user is allowed to create duplicates, or show multiples of the same type of metric.
   * So the query params are a string of indices which looks like this:
   * type_1=0123 or type_1=02&type_2=13
   */
  const metricSettings = ATTRIBUTION_SETTINGS_DEFAULTS;
  let useMetricsFromQueryFlag = false;

  Object.values(ATTRIBUTION_METRIC_TYPES).forEach((metricType) => {
    const currentMetric = metricType;
    if (currentMetric in query) {
      useMetricsFromQueryFlag = true;
      const metricIndexes = query[currentMetric].split('');
      for (const i of metricIndexes) {
        const metricIndex = Number(i);
        metricSettings[metricIndex] = currentMetric;
      }
    }
  });
  return { metricSettings, useMetricsFromQueryFlag };
};

export default {
  getUpdatedMetrics,
  refreshMetrics,
  fetchEiAttributionMetrics,
};
