import { computed, ref } from 'vue';

export default function useUiPagination(
  dataRef,
  opts = { perPage: 10, currentPage: 1 },
) {
  let { perPage, currentPage } = opts;

  if (opts) {
    if (!perPage) perPage = 10;
    if (!currentPage) currentPage = 1;
  }

  const activePage = ref(currentPage);
  const offset = computed(() => perPage * (activePage.value - 1));
  const dataOnPage = computed(() =>
    dataRef.value.slice(offset.value, offset.value + perPage),
  );
  const lastPage = computed(() => Math.ceil(dataRef.value.length / perPage));
  function setPage(val) {
    activePage.value = val;
  }

  return {
    activePage,
    setPage,
    lastPage,
    dataOnPage,
    offset,
  };
}
