import axios, { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { MessageObject } from '@/types/flashes';
import { uuid4 } from '@/utils';

enum FlashCategory {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
}

type Message = string | MessageObject;
type Flash = {
  accepted: boolean;
  category: FlashCategory;
  uuid: string;
  info: MessageObject;
};

const createFlash = (
  message: Message,
  category: FlashCategory = FlashCategory.SUCCESS,
): Flash => {
  const errorMessageObj = typeof message === 'string' ? { message } : message;
  return {
    accepted: false,
    info: errorMessageObj,
    category,
    uuid: uuid4(),
  };
};

const createErrorList = (err: AxiosError | Error) => {
  const defaultErr = [
    {
      message: 'An error has occurred. Please refresh the page and try again.',
      description:
        'If this error persists, please contact <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>.',
    },
  ];
  if (!axios.isAxiosError(err)) return defaultErr;
  return err.response?.data?.errors || defaultErr;
};

export const useFlashesStore = defineStore('Flashes', () => {
  const flashes = ref<Flash[]>([]);
  function acceptFlash(flash: Flash) {
    const f = flashes.value.find((f) => f.uuid === flash.uuid);
    if (f) f.accepted = true;
  }
  function dismissFlash(flash: Flash) {
    const idx = flashes.value.findIndex((f) => f.uuid === flash.uuid);
    if (idx > -1) {
      flashes.value.splice(idx, 1);
    }
  }
  function addSuccessFlash(message: MessageObject) {
    flashes.value.push(createFlash(message, FlashCategory.SUCCESS));
  }
  function addErrorFlash(message: Message) {
    flashes.value.push(createFlash(message, FlashCategory.ERROR));
  }
  function addInfoFlash(message: Message) {
    flashes.value.push(createFlash(message, FlashCategory.INFO));
  }
  function addWarningFlash(message: Message) {
    flashes.value.push(createFlash(message, FlashCategory.WARNING));
  }
  function addUnhandledError(err: AxiosError | Error) {
    if (!axios.isAxiosError(err)) {
      if (
        err.message === "Cannot read properties of null (reading 'blur')" ||
        err.message?.includes('Failed to fetch dynamically imported module') ||
        err.message?.includes('Importing a module script failed')
      )
        return;
    }
    const errs = createErrorList(err);
    for (const e of errs) {
      flashes.value.push(createFlash(e, FlashCategory.ERROR));
    }
  }

  return {
    flashes,
    dismissFlash,
    acceptFlash,
    addSuccessFlash,
    addErrorFlash,
    addInfoFlash,
    addWarningFlash,
    addUnhandledError,
  };
});
