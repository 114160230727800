<template>
  <div class="c-pop-card-records">
    <component
      :is="recordTitleWrapper"
      placement="topLeft"
      :class="{
        'c-pop-card-records__tooltip-trigger': showUniqueRecordInfo,
      }"
      class="c-pop-card-records__header"
    >
      Records
      <template #title>
        Total number of records from this Population. This number may be
        different from the total unique records across all Populations.
      </template>
    </component>
    <BittsTooltip v-if="!isUpdating" class="record-tooltip">
      <FontAwesomeIcon
        :icon="recordIcon"
        class="text-neutral-accent mr-4 text-12"
      />
      <span class="font-bold"> {{ recordNumberFormatted || '-' }}</span>
      <template #title>
        {{ `Data type is ${popType}` }}
      </template>
    </BittsTooltip>
    <span v-else class="c-pop-card-records__content-updating">
      Updating...
    </span>
  </div>
</template>

<script setup>
import { BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import useRecordExportLimits from '@/composables/useRecordExportLimits';
import { formatNumberWithCommas } from '@/utils';

const props = defineProps({
  population: {
    type: Object,
    required: true,
  },
  recordNumber: {
    type: Number,
    default: 0,
  },
});

const { exportLimit } = useRecordExportLimits();
const showUniqueRecordInfo = computed(() => !!exportLimit.value);
const recordTitleWrapper = computed(() =>
  showUniqueRecordInfo.value ? BittsTooltip : 'span',
);

const recordNumberFormatted = computed(() =>
  props.recordNumber || props.recordNumber === 0
    ? formatNumberWithCommas(props.recordNumber)
    : '',
);
const isUpdating = computed(
  () => !props.population?.current_version?.first_processed_at,
);
const popType = computed(() => props.population?.population_type);
const recordIcon = computed(() =>
  props.population?.population_type === 'companies'
    ? ['fak', 'company']
    : ['fak', 'person'],
);
</script>
<style lang="pcss" scoped>
.c-pop-card-records__header {
  @apply text-sm text-neutral-text;
}
.c-pop-card-records__content {
  @apply text-neutral-text;
}
.c-pop-card-records__content-updating {
  @apply text-neutral-text-placeholder italic;
}
.c-pop-card-records__tooltip-trigger {
  @apply border-b border-dashed border-neutral-text-weak;
}
</style>
<style lang="pcss">
.record-tooltip.bitts-tooltip {
  span {
    @apply inline-flex items-center;
  }
}
</style>
