import axios from 'axios';
import { computed, ref } from 'vue';

import {
  ACTIVE_FEED_STATUS,
  CONNECTION_CHECK_ERROR_STATUS,
  DELETING_FEED_STATUS,
  DELETION_FAILED_FEED_STATUS,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  PENDING_FEED_STATUS,
  RECORD_LIMIT_EXCEEDED_STATUS,
  REQUIRED_FEED_STATUS,
  SOURCE_FAILED_STATUS,
} from '@/constants/data_sources';
import { useFeedsStore, useSourcesStore } from '@/stores';
import urls from '@/urls';
import { returnTrimmedDomain } from '@/utils';

/* The simplified statuses that are emitted out of this
 * composable, mapped from the possible feed statuses */
const statuses = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  PAUSED: 'PAUSED',
  SETTING_UP: 'SETTING_UP',
  REQUIRE_SOURCES_SELECTION: 'NOT_SYNCED',
  ACTIVE: 'ACTIVE',
  DELETING: 'DELETING',
  DELETION_FAILED: 'DELETION_FAILED',
};

const reauthorizeFeed = (loading) => async (feed) => {
  loading.value = true;
  try {
    const resp = await axios.post(urls.oauth.authorize, {
      params: { integration_type: feed.integration.type, feed_id: feed.id },
    });
    window.open(resp.data.authorization_url, '_self');
  } finally {
    loading.value = false;
  }
};

const createFeedStatusUiObject = (
  text,
  bgColor,
  statusBgColor,
  textColor,
  type,
) => ({
  text,
  bgColor,
  statusBgColor,
  textColor,
  type,
});

export default function ({ feedId }) {
  const sourcesStore = useSourcesStore();
  const feedsStore = useFeedsStore();
  const feed = computed(() => feedsStore.getFeedById(feedId));
  const sources = computed(() => sourcesStore.getSourcesByFeedId(feedId));

  const feedStatus = computed(() => {
    switch (feed.value?.status) {
      case DELETING_FEED_STATUS:
        return createFeedStatusUiObject(
          'Deleting',
          'bg-danger-accent',
          'bg-danger-background-weak',
          'text-danger-text-strong',
          statuses.DELETING,
        );
      case DELETION_FAILED_FEED_STATUS:
        return createFeedStatusUiObject(
          'Deletion Failed',
          'bg-warning-accent',
          'bg-warning-background-weak',
          'text-warning-text-strong',
          statuses.DELETION_FAILED,
        );
    }

    if (feed.value?.is_paused) {
      return createFeedStatusUiObject(
        'Not Syncing',
        'bg-warning-accent',
        'bg-warning-background-weak',
        'text-warning-text-strong',
        statuses.PAUSED,
      );
    }

    /* Google Sheets cannot be in a pending state. */
    if (
      feed.value?.integration.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE &&
      ![
        REQUIRED_FEED_STATUS,
        CONNECTION_CHECK_ERROR_STATUS,
        RECORD_LIMIT_EXCEEDED_STATUS,
        SOURCE_FAILED_STATUS,
        DELETING_FEED_STATUS,
        DELETION_FAILED_FEED_STATUS,
      ].includes(feed.value?.status)
    ) {
      const missingRequired = sources.value.some(
        (source) => source.properties?.row_errors?.missing_required > 0,
      );

      if (missingRequired) {
        return createFeedStatusUiObject(
          'Warning',
          'bg-warning-accent',
          'bg-warning-background-weak',
          'text-warning-text-strong',
          statuses.WARNING,
        );
      }
      return createFeedStatusUiObject(
        'Connection Active',
        'bg-success-accent',
        'bg-success-background-weak',
        'text-success-text-strong',
        statuses.ACTIVE,
      );
    }

    /* If error or warning state, return it */
    switch (feed.value?.status) {
      case REQUIRED_FEED_STATUS:
        return createFeedStatusUiObject(
          'Not Synced',
          'bg-danger-accent',
          'bg-danger-background-weak',
          'text-danger-text-strong',
          statuses.REQUIRE_SOURCES_SELECTION,
        );
      case CONNECTION_CHECK_ERROR_STATUS:
      case RECORD_LIMIT_EXCEEDED_STATUS:
        return createFeedStatusUiObject(
          'Error',
          'bg-danger-accent',
          'bg-danger-background-weak',
          'text-danger-text-strong',
          statuses.ERROR,
        );
      case SOURCE_FAILED_STATUS:
        return createFeedStatusUiObject(
          'Warning',
          'bg-warning-accent',
          'bg-warning-background-weak',
          'text-warning-text-strong',
          statuses.WARNING,
        );
    }

    /* If initial sync hasn't finished yet, show setting up */
    if (
      !feed.value?.initial_sync_complete &&
      feed.value?.integration.type !== GOOGLE_SHEETS_DATA_SOURCE_TYPE
    ) {
      return createFeedStatusUiObject(
        'Setting up...',
        'bg-warning-accent',
        'bg-warning-background-weak',
        'text-warning-text-strong',
        statuses.SETTING_UP,
      );
    }

    /* Switch on last possible options: setting up or active */
    switch (feed.value?.status) {
      case PENDING_FEED_STATUS:
        return createFeedStatusUiObject(
          'Setting up...',
          'bg-warning-accent',
          'bg-warning-background-weak',
          'text-warning-text-strong',
          statuses.SETTING_UP,
        );
      case ACTIVE_FEED_STATUS:
        return createFeedStatusUiObject(
          'Active',
          'bg-success-accent',
          'bg-success-background-weak',
          'text-success-text-strong',
          statuses.ACTIVE,
        );
      default:
        return {};
    }
  });

  const crmLinkInfo = computed(() => {
    return {
      text:
        feed.value?.nickname ||
        returnTrimmedDomain(feed.value?.external_base_url),
      url: feed.value?.external_base_url,
    };
  });

  /* Other feed booleans */
  const feedDeletionFailed = computed(
    () => feedStatus.value.type === statuses.DELETION_FAILED,
  );
  const feedHasErrorMessage = computed(
    () => feedIsErrored.value && feed.value?.error_message,
  );
  const feedAddSourceDisabled = computed(
    () => feed.value?.status === RECORD_LIMIT_EXCEEDED_STATUS,
  );

  const feedIsSyncing = computed(() => feed.value?.is_syncing);
  const feedIsErrored = computed(
    () => feedStatus.value.type === statuses.ERROR,
  );
  const feedIsWarning = computed(
    () => feedStatus.value.type === statuses.WARNING,
  );
  const feedIsDeleting = computed(
    () => feedStatus.value.type === statuses.DELETING,
  );
  const feedIsActive = computed(
    () => feedStatus.value.type === statuses.ACTIVE,
  );
  const feedIsPaused = computed(
    () => feedStatus.value.type === statuses.PAUSED,
  );

  /* Reauthorize Feed */
  const loading = ref(false);
  const reauthorize = reauthorizeFeed(loading);

  return {
    feedStatus,
    feedIsSyncing,
    feedIsErrored,
    feedIsDeleting,
    feedIsWarning,
    feedIsPaused,
    feedDeletionFailed,
    feedIsActive,
    feedHasErrorMessage,
    feedAddSourceDisabled,
    reauthorize,
    feedIsReauthorizing: loading,
    statuses,
    crmLinkInfo,
  };
}
