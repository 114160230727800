<template>
  <BittsModal
    name="app_details"
    :loading="pageLoading"
    :visible="true"
    title="Create Integration"
    :show-divider="true"
    save-text="Create Integration"
    @closed="cancel"
    @saved="createApplication"
  >
    <template #content>
      <div>
        <BittsInput
          v-model="oAuthApplication.name"
          form-label="Integration Name"
          class="mb-24"
          placeholder="Integration Name"
          name="integration-name"
          :status="
            v$.oAuthApplication.name.$errors?.length ? 'danger' : 'default'
          "
          :danger-text="
            v$.oAuthApplication.name.$errors?.at(-1)?.$message || ''
          "
        />
        <BittsInput
          v-model="oAuthApplication.description"
          class="mb-24"
          form-label="Integration Description"
          placeholder="Integration Description"
          name="integration-description"
        />
        <div class="c-edit-application__modal-body mb-24">
          <span class="c-edit-application__label">Callback URL</span>
          <BittsSelectTags
            v-model="oAuthApplication.callbacks"
            :warning-message="
              showCallbackUrlsMessage ? callbackUrlsMessage : ''
            "
            note="Note: You can add separate callback URLs by separating with enter"
            placeholder="Callback URLs"
            class="c-edit-application__tags-input"
            @change="onChangeCallbackUrls"
            @search="onTypeCallbackUrl"
          />
        </div>
        <div class="c-edit-application__modal-body">
          <span class="c-edit-application__label">Allowed Origins</span>
          <BittsSelectTags
            v-model="oAuthApplication.allowed_origins"
            note="Note: You can add separate allowed origins by separating with enter"
            :warning-message="
              showAllowedOriginsMessage ? allowedOriginsMessage : ''
            "
            placeholder="Allowed Origins"
            class="c-edit-application__tags-input"
            @change="onChangeAllowedOrigins"
            @search="onTypeAllowedOrigin"
          />
        </div>
        <p class="c-edit-application__developer-terms">
          Please note: By clicking "Create Integration" below, you agree to the
          Crossbeam Developer Terms. Please review the
          <BittsLink
            text="Developer Terms here"
            url="https://www.crossbeam.com/legal/developers/"
          />
        </p>
      </div>
    </template>
  </BittsModal>
</template>

<script>
import {
  BittsInput,
  BittsLink,
  BittsModal,
  BittsSelectTags,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { mapActions } from 'pinia';
import { ref } from 'vue';

import { useFlashesStore, useOauthApplicationStore } from '@/stores';
import { isUrlValid } from '@/utils';

export default {
  name: 'OAuthApplicationCreate',
  components: {
    BittsInput,
    BittsLink,
    BittsModal,
    BittsSelectTags,
  },
  setup() {
    // without this, we use the metaInfo from integrations/create.vue even after the modal closes
    useHead({});

    const oAuthApplication = ref({
      name: null,
      description: '',
      callbacks: [],
      allowed_origins: [],
    });

    const rules = {
      oAuthApplication: {
        name: {
          required: helpers.withMessage(
            'Please name your application.',
            required,
          ),
        },
      },
    };

    const v$ = useVuelidate(rules, { oAuthApplication });

    return { oAuthApplication, v$ };
  },
  data() {
    return {
      pageLoading: false,
      showCallbackUrlsMessage: false,
      showAllowedOriginsMessage: false,
      inProgressCallBackUrl: '',
      inProgressAllowedOrigin: '',
      callbackUrlsMessage: 'Callback must start with https://',
      allowedOriginsMessage:
        'Origin must start with https:// or chrome-extensions://',
    };
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useOauthApplicationStore, [
      'refreshOAuthApplicationsStore',
      'createOAuthApplication',
    ]),
    modalClosed() {
      this.$router.push({ name: 'integrations' });
    },
    cancel() {
      this.$router.push({ name: 'integrations' });
    },
    isOriginValid(tag) {
      return this.isUrlValid(tag, ['chrome-extensions://']);
    },
    isCallbackValid(tag) {
      return this.isUrlValid(tag, []);
    },
    isUrlValid(tag, allowedProtocols) {
      return isUrlValid(tag, allowedProtocols);
    },
    onChangeCallbackUrls(val) {
      this.showCallbackUrlsMessage = !!val.filter(
        (v) => !this.isCallbackValid(v),
      ).length;
      this.oAuthApplication.callbacks = val.filter((v) =>
        this.isCallbackValid(v),
      );
      this.inProgressCallBackUrl = '';
    },
    onChangeAllowedOrigins(val) {
      this.showAllowedOriginsMessage = !!val.filter(
        (v) => !this.isOriginValid(v),
      ).length;
      this.oAuthApplication.allowed_origins = val.filter((v) =>
        this.isOriginValid(v),
      );
      this.inProgressAllowedOrigin = '';
    },
    onTypeCallbackUrl(val) {
      this.inProgressCallBackUrl = val;
    },
    onTypeAllowedOrigin(val) {
      this.inProgressAllowedOrigin = val;
    },
    async createApplication() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      if (this.inProgressCallBackUrl) {
        if (this.isCallbackValid(this.inProgressCallBackUrl)) {
          this.oAuthApplication.callbacks.push(this.inProgressCallBackUrl);
        } else {
          this.showCallbackUrlsMessage = true;
          return;
        }
      }
      if (this.inProgressAllowedOrigin) {
        if (this.isOriginValid(this.inProgressAllowedOrigin)) {
          this.oAuthApplication.allowed_origins.push(
            this.inProgressAllowedOrigin,
          );
        } else {
          this.showAllowedOriginsMessage = true;
          return;
        }
      }
      try {
        this.pageLoading = true;
        const payload = {
          name: this.oAuthApplication.name,
          description: this.oAuthApplication.description,
          callbacks: this.oAuthApplication.callbacks,
          allowed_origins: this.oAuthApplication.allowed_origins,
        };

        await this.createOAuthApplication(payload);
        this.addSuccessFlash({ message: 'The integration was created' });
        await this.refreshOAuthApplicationsStore();
      } catch (_err) {
        this.addErrorFlash({ message: 'The integration could not be created' });
      } finally {
        this.pageLoading = false;
        this.$router.push({ name: 'integrations' });
      }
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-edit-application__label {
  @apply text-neutral-text-strong font-bold text-sm mr-4;
}

.c-edit-application__tags-input {
  @apply mt-8 mb-24;
}
.c-edit-application__developer-terms {
  @apply mt-20 text-sm text-neutral-500;
}
</style>
