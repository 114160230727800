<template>
  <div class="c-upsell-tooltip">
    <BittsTooltip
      :cta-icon="['fak', 'upgrade']"
      :button-text="buttonText || 'Upgrade'"
      overlay-class="c-upsell-tooltip__overlay"
      :mount-to-body="true"
      :placement="placement"
      :include-cta="!hideUpsell"
      @cta-clicked="handleCtaClicked"
    >
      <slot />
      <template #title>
        <slot name="title" />
      </template>
    </BittsTooltip>
  </div>
</template>

<script setup lang="ts">
import { BittsTooltip, PlacementType } from '@crossbeam/bitts';

import useBilling from '@/composables/useBilling';
import { BillingInteraction, BillingQuery } from '@/types/billing';

const props = withDefaults(
  defineProps<{
    buttonText?: string | null;
    placement?: PlacementType;
    billingInteraction: BillingInteraction;
    billingQuery?: BillingQuery;
    hideUpsell?: boolean;
    routeTo?: { name: string } | null;
  }>(),
  {
    buttonText: null,
    placement: 'bottomLeft',
    billingQuery: null,
    hideUpsell: false,
    routeTo: null,
  },
);

const emit = defineEmits<(e: 'cta-clicked') => void>();

const { handleBillingInteraction } = useBilling();

function handleCtaClicked() {
  emit('cta-clicked');
  handleBillingInteraction(
    props.billingInteraction,
    props.billingQuery,
    false,
    props.routeTo,
  );
}
</script>

<style scoped lang="pcss">
.c-upsell-tooltip__overlay {
  .ant-tooltip-inner {
    @apply rounded-bts-md text-white p-8 flex flex-col items-stretch gap-8 bg-neutral-background-tooltip;
  }
  span {
    @apply gap-4 flex items-center;
  }
  .c-bitts-tooltip__button {
    @apply w-full;
  }
}
</style>
