<template>
  <BittsTooltip>
    <div
      v-if="totalOverlaps != null"
      data-testid="record-exports-estimation"
      class="rounded-lg my-16 py-8 px-12 text-base"
      :class="
        isDangerState ? 'bg-danger-background-weak' : 'bg-info-background-weak'
      "
    >
      <div class="flex items-center justify-between">
        <span class="text-neutral-text-weak"> Estimated record exports </span>
        <b :class="isDangerState ? 'text-danger-text' : 'text-neutral-text'">
          {{ totalOverlaps?.toLocaleString('en-US') }}
        </b>
      </div>
      <div v-if="isDangerState">
        <FontAwesomeIcon
          class="text-danger-accent text-12"
          :icon="['fak', 'exclamation-circle']"
        />
        <p class="text-neutral-text-weakest text-sm inline">
          Saving this will exceed your export limit and pause all record exports
        </p>
      </div>
    </div>
    <template #title>
      <div class="flex items-center">
        <p class="text-sm"> Unique records exported out of Crossbeam </p>
        <BittsButton
          :text="'Learn More'"
          variant="ghost"
          type="white"
          data-testid="exports-tooltip-button"
          class="inline mx-4"
          size="x-small"
          @click="openHelpTab"
        />
      </div>
    </template>
  </BittsTooltip>
</template>

<script setup lang="ts">
import { BittsButton, BittsTooltip } from '@crossbeam/bitts';

import { debounce } from 'lodash';
import { computed, ref, watch } from 'vue';

import useRecordExportLimits from '@/composables/useRecordExportLimits';
import useTrayStatus from '@/composables/useTrayStatus';
import { IntegrationProfilePopulationSetting } from '@/types/integration_profiles';

const { populations = undefined } = defineProps<{
  populations?: IntegrationProfilePopulationSetting[];
}>();

const isTestMode = import.meta.env.MODE === 'test';

const { getTotalOverlaps } = useTrayStatus();
const { exportLimit } = useRecordExportLimits();
const totalOverlaps = ref<number | null>();
const updateTotalOverlaps = debounce(
  async (populations: IntegrationProfilePopulationSetting[]) => {
    totalOverlaps.value = await getTotalOverlaps(
      populations.filter((pop) => pop.is_included),
    );
  },
  isTestMode ? 0 : 500,
  {
    leading: true,
    trailing: true,
  },
);

const isDangerState = computed(() => {
  return (
    totalOverlaps.value != null &&
    exportLimit.value != null &&
    totalOverlaps.value > exportLimit.value
  );
});

watch(
  () => populations,
  async (newVal) => {
    if (newVal) await updateTotalOverlaps(newVal);
  },
);

const openHelpTab = () => {
  window.open(
    'https://help.crossbeam.com/en/articles/8399864-record-export-limits',
    '_blank',
  );
};
</script>

<style scoped lang="pcss">
:deep(.ant-tooltip) {
  @apply max-w-content;
}
</style>
