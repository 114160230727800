<template>
  <BittsModalTwo
    :width="600"
    name="map-new-columns-modal"
    primary-button-text="Apply"
    @closed="close"
    @action="mapNewColumns"
    :disabled="noMappedFields"
    :loading="loading || !ready"
    :action-loading="loading"
  >
    <template #header>
      <BittsSvg
        :svg="isGoogleSheet ? 'googleSheetsIcon' : 'fileUploadIcon'"
        class="c-google-sheets-two__icon"
      />
      <h2 class="title mt-12"
        >{{ isConfirming ? 'Confirm your Selections' : 'Map Columns' }}
      </h2>
    </template>
    <div class="flex flex-col gap-8">
      <div class="flex">
        <div class="header w-[286px]">
          {{ isCsv ? 'CSV' : 'Google Sheet' }} Columns
        </div>
        <div class="header">Crossbeam Fields</div>
      </div>
      <FieldMapping label="Account owner email">
        <MapColumnSelectorTwo
          :all-columns="allColumns"
          :unmapped-columns="unmappedColumns"
          :field-mappings="fieldMappings"
          :type="ACCOUNT_OWNER_EMAIL_DISPLAY"
          :is-csv="isCsv"
          @update="updateMappings"
        />
      </FieldMapping>
      <FieldMapping label="Account owner name">
        <MapColumnSelectorTwo
          :all-columns="allColumns"
          :unmapped-columns="unmappedColumns"
          :field-mappings="fieldMappings"
          :type="ACCOUNT_OWNER_NAME_DISPLAY"
          :is-csv="isCsv"
          @update="updateMappings"
        />
      </FieldMapping>
      <FieldMapping label="Account owner phone">
        <MapColumnSelectorTwo
          :all-columns="allColumns"
          :unmapped-columns="unmappedColumns"
          :field-mappings="fieldMappings"
          :type="ACCOUNT_OWNER_PHONE_DISPLAY"
          :is-csv="isCsv"
          @update="updateMappings"
        />
      </FieldMapping>
      <div v-if="errorText" class="w-full pt-24 pb-24">
        <BittsAlert :description="errorText" color="error" message="Error" />
      </div>
    </div>
  </BittsModalTwo>
</template>

<script setup>
import { BittsAlert, BittsModalTwo, BittsSvg } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import FieldMapping from '@/components/data-sources/FieldMapping.vue';
import MapColumnSelectorTwo from '@/components/data-sources/MapColumnSelectorTwo.vue';

import { GOOGLE_SHEETS_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import {
  ACCOUNT_OWNER_EMAIL_DISPLAY,
  ACCOUNT_OWNER_NAME_DISPLAY,
  ACCOUNT_OWNER_PHONE_DISPLAY,
  AO_DISPLAY,
} from '@/constants/mdm';
import { captureException } from '@/errors';
import {
  allReady,
  useFeedsStore,
  useFileUploadsStore,
  useFlashesStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

const props = defineProps({
  sourceId: {
    type: Number,
    required: true,
  },
});

useHead({ title: 'Map New Columns - Crossbeam' });

const fileUploadsStore = useFileUploadsStore();
const flashesStore = useFlashesStore();
const ready = allReady(useFileUploadsStore(), useFeedsStore());
const sourcesStore = useSourcesStore();
const feedsStore = useFeedsStore();
const router = useRouter();

const feed = ref(null);
const errorText = ref('');
const source = ref(null);
const allColumns = ref([]);
const unmappedColumns = ref([]);
const fieldMappings = ref({
  [ACCOUNT_OWNER_NAME_DISPLAY]: [],
  [ACCOUNT_OWNER_EMAIL_DISPLAY]: [],
  [ACCOUNT_OWNER_PHONE_DISPLAY]: [],
});

const loading = ref(true);
const isConfirming = ref(false);

onMounted(async () => {
  await Promise.all([sourcesStore.readySync, feedsStore.readySync]);
  source.value = sourcesStore.getSourceById(props.sourceId);
  feed.value = feedsStore.getFeedById(source.value?.feed_id);
  allColumns.value = unusedSourceColumns(source.value);
  unmappedColumns.value = unusedSourceColumns(source.value);
  loading.value = false;
});

const isCsv = computed(() => !isGoogleSheet.value);
const isGoogleSheet = computed(() => {
  const feedId = sourcesStore.getSourceById(props.sourceId)?.feed_id;
  const feed = feedsStore.getFeedById(feedId);
  return feed?.integration.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE;
});

async function close() {
  await router.push({ name: 'data-sources' });
}

function getErrorText(err) {
  return err.response?.data?.errors?.length > 0
    ? err.response?.data?.errors[0]
    : 'We ran into an error processing your file. Please try again, or contact support.';
}

function reset() {
  unmappedColumns.value = unusedSourceColumns(source.value);
  fieldMappings.value = {
    [ACCOUNT_OWNER_NAME_DISPLAY]: [],
    [ACCOUNT_OWNER_EMAIL_DISPLAY]: [],
    [ACCOUNT_OWNER_PHONE_DISPLAY]: [],
  };
  loading.value = false;
}

async function mapNewColumns() {
  errorText.value = '';
  loading.value = true;
  try {
    const { payload, url } = createPayloadAndUrl();
    await axios.post(url, payload);
    flashesStore.addSuccessFlash({
      message: `Your ${AO_DISPLAY} information is being mapped. This may take a few minutes.`,
    });

    const promises = [
      feedsStore.refreshFeedsStore(),
      fileUploadsStore.refreshFileUploadsStore(),
      sourcesStore.refreshSourcesStore(),
    ];

    await Promise.all(promises);
    close();
  } catch (err) {
    captureException(err);
    errorText.value = getErrorText(err);
    reset();
  }
}
function unusedSourceColumns() {
  const columns =
    source.value?.fields.reduce((acc, field) => {
      if (
        field.is_visible &&
        field.mdm_property === null &&
        !['File Name', 'Upload Time', 'id', 'createdAt'].includes(field.column)
      ) {
        acc.push({ value: field.column, label: field.column });
      }
      return acc;
    }, []) ?? [];

  return columns;
}

const noMappedFields = computed(() =>
  Object.values(fieldMappings.value).every((m) => m.length === 0),
);

function createPayloadAndUrl() {
  if (isCsv.value) {
    const url = urls.sources.mapNewColumns(props.sourceId);
    const payload = {
      field_mappings: {},
    };

    for (const [key, x] of Object.entries(fieldMappings.value)) {
      for (const col of x) {
        payload.field_mappings[col.value] = key;
      }
    }
    return { payload, url };
  }

  /* For Google Sheets */
  const url = urls.sources.allV3;
  const mapping = {
    mdm_type: 'user',
    feed_id: feed.value.id,
    spreadsheet_id: source.value.properties.spreadsheet_id,
    sheet_id: source.value.properties.sheet_id,
    fields: [],
  };

  for (const [key, x] of Object.entries(fieldMappings.value)) {
    for (const col of x) {
      const type = key.split(' ').at(-1).toLowerCase();
      mapping.fields.push({
        mdm_property: `person_${type}`,
        column_name: col.value,
        is_primary_key: false,
      });
    }
  }

  return { payload: [mapping], url };
}

function updateMappings(v) {
  unmappedColumns.value = v.unmappedColumns;
  fieldMappings.value = v.fieldMappings;
}
</script>

<style lang="pcss" scoped>
.c-google-sheets-two__icon {
  @apply w-60 h-60 mb-6 mt-12;
}
.title {
  @apply text-neutral-text-strong font-bold text-xl mb-4 px-16 text-center w-full;
}
.header {
  @apply font-bold text-neutral-text-strong;
}
</style>
