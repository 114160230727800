// Integration Types
export const PARTNERSTACK = 'partnerstack';
export const GONG = 'gong';

export const ATTRIBUTION_ACTIVITY = 'attribution_activity';
export const POPULATION_MOVEMENT = 'population_movement';
export const OVERLAP_MOVEMENT = 'overlap_movement';
export const SALES_EDGE_ACTIVITY = 'sales_edge_activity';
export const INTEGRATIONS = 'integrations';
export const CROSSBEAM = 'crossbeam';

export const ALL_EVENT_FILTERS = {
  [ATTRIBUTION_ACTIVITY]: 'all_attribution_activity_filters',
  [POPULATION_MOVEMENT]: 'all_population_movement_filters',
  [OVERLAP_MOVEMENT]: 'all_overlap_movement_filters',
  [SALES_EDGE_ACTIVITY]: 'all_sales_edge_activity_filters',
  [INTEGRATIONS]: 'all_integrations_filters',
  [CROSSBEAM]: 'all_crossbeam_filters',
};
