<template>
  <SharingSettingsModal
    :population="population"
    :visible="true"
    @hide-sharing-modal="onClose"
  />
</template>

<script setup>
import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SharingSettingsModal from '@/components/partners/DataSharingSettings/SharingSettingsModal.vue';

import usePopulations from '@/composables/usePopulations';

useHead({ titleTemplate: 'Edit - %s - Sharing Settings - Crossbeam' });

const route = useRoute();
const router = useRouter();

const { allPopulations, customPopsWithStatsAndSharing } = usePopulations();

const populationId = computed(() => parseInt(route.params.population_id));
const population = computed(() => {
  const populations = [
    ...allPopulations.value,
    ...customPopsWithStatsAndSharing.value,
  ];
  return populations.find((pop) => pop.id === populationId.value);
});

const redirectToEditPage = computed(() => route.query.toEdit);
async function onClose() {
  if (!redirectToEditPage.value) {
    await router.push({ name: 'populations' });
  } else {
    const path = {
      name: 'edit_population',
      params: { population_id: populationId.value },
      query: {},
    };
    if (route.query.standardType)
      path.query.standardType = route.query.standardType;
    await router.push(path);
  }
}
</script>
