import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import usePolling from '@/composables/usePolling';
import {
  DELETE_FEED_INTERVAL,
  DELETING_FEED_STATUS,
} from '@/constants/data_sources';
import { captureException } from '@/errors';
import { useFeedsStore, useFlashesStore } from '@/stores';

export default function useFeedsDeleting() {
  const flashesStore = useFlashesStore();
  const feedsStore = useFeedsStore();

  const { feeds } = storeToRefs(feedsStore);
  const deletingFeeds = computed(() =>
    feeds.value.filter((f) => f.status === DELETING_FEED_STATUS),
  );
  const feedsAreDeleting = computed(() => deletingFeeds.value.length > 0);

  const isPolling = ref(false);

  let originalDeletingFeeds =
    deletingFeeds.value.length; /* Non reactive value */
  const poll = usePolling({
    interval: DELETE_FEED_INTERVAL,
    async poller() {
      await feedsStore.refreshFeedsStore();
    },
    shouldComplete() {
      if (deletingFeeds.value.length < originalDeletingFeeds)
        flashesStore.addSuccessFlash({
          message: 'Data source deleted successfully',
        });
      if (!feedsAreDeleting.value) {
        isPolling.value = false;
        return true;
      }
    },
    onError(err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'There was a problem deleting your data source',
      });
      isPolling.value = false;
    },
  });

  if (feedsAreDeleting.value) {
    isPolling.value = true;
    poll();
  }

  watch(feedsAreDeleting, (newValue, oldValue) => {
    if (newValue && !oldValue && !isPolling.value) {
      originalDeletingFeeds = deletingFeeds.value.length;
      isPolling.value = true;
      poll();
    }
  });
}
