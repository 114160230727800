<template>
  <div class="population-sharing-defaults">
    <div data-testid="num-fields-shared" class="font-bold text-neutral-text">
      {{ `${sourceFieldIds.length} Fields Shared` }}
    </div>
    <div
      v-for="sourceType in mdmTypesWithFields"
      :key="`source__${sourceType}`"
      class="population-sharing-defaults__source"
    >
      {{ mdmTypeToSourceInfo[sourceType].table }}
      <div class="flex flex-row flex-wrap">
        <BittsTag
          v-for="sourceField in sourcesWithFields[
            mdmTypeToSourceInfo[sourceType].id
          ]"
          :key="`source-field__${sourceField}`"
          color="neutral"
          variant="rounded"
          class="population-sharing-defaults__source-field"
        >
          {{ sourceField }}
        </BittsTag>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BittsTag } from '@crossbeam/bitts';

import { computed } from 'vue';

import { MDM_TYPES } from '@/constants/mdm';
import { useSourcesStore } from '@/stores';

const props = defineProps({
  sourceFieldIds: {
    type: Array,
    default: () => [],
  },
});

const allMdmTypes = [
  MDM_TYPES.ACCOUNT,
  MDM_TYPES.LEAD,
  MDM_TYPES.USER,
  MDM_TYPES.CONTACT,
  MDM_TYPES.DEAL,
];
const sourcesStore = useSourcesStore();

const fieldInfo = computed(() => {
  return props.sourceFieldIds.reduce(
    (fieldInfo, id) => {
      const sourceField = sourcesStore.getSourceFieldById(id);
      if (!sourceField) return fieldInfo;
      const sourceIdsInLookup = Object.values(
        fieldInfo.mdmTypeToSourceInfo,
      ).map((sourceInfo) => sourceInfo.id);
      if (!sourceIdsInLookup.includes(sourceField.source_id)) {
        const source = sourcesStore.getSourceById(sourceField.source_id);
        fieldInfo.mdmTypeToSourceInfo[source.mdm_type] = {
          id: sourceField.source_id,
          table: source.table,
        };
        fieldInfo.sourcesWithFields[sourceField.source_id] = [];
      }
      fieldInfo.sourcesWithFields[sourceField.source_id].push(
        sourceField.display_name,
      );
      return fieldInfo;
    },
    {
      mdmTypeToSourceInfo: {},
      sourcesWithFields: {},
    },
  );
});
const mdmTypeToSourceInfo = computed(() => fieldInfo.value.mdmTypeToSourceInfo);
const sourcesWithFields = computed(() => fieldInfo.value.sourcesWithFields);
const mdmTypesWithFields = computed(() =>
  allMdmTypes.filter(
    (type) =>
      !!sourcesWithFields.value[mdmTypeToSourceInfo.value[type]?.id]?.length,
  ),
);
</script>

<style lang="pcss" scoped>
.population-sharing-defaults {
  @apply border border-neutral-border border-solid
    rounded-bts-lg p-24 flex flex-col gap-16;
}
.population-sharing-defaults__source {
  @apply text-sm text-neutral-text-weak;
}
.population-sharing-defaults__source-field {
  @apply text-base text-neutral-text;
}
</style>
