<template>
  <div class="progress-bar">
    <div
      class="progress-bar-fill"
      data-testid="progress-bar-fill"
      :class="foregroundColor"
      :style="{
        width,
        maxWidth: '100%',
      }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const {
  width = '0%',
  height = 8,
  color = 'neutral',
} = defineProps<{
  width: string;
  height?: number;
  color?: 'danger' | 'warning' | 'neutral' | 'secondary';
}>();

const colorClasses = {
  danger: 'bg-danger-accent',
  warning: 'bg-warning-accent',
  neutral: 'bg-neutral-accent',
  secondary: 'bg-secondary-accent',
};

const foregroundColor = computed(() => colorClasses[color]);
const computedHeight = computed(() => `${height}px`);
</script>

<style scoped lang="pcss">
.progress-bar {
  @apply rounded-full relative w-full bg-neutral-background;
  height: v-bind(computedHeight);
}

.progress-bar-fill {
  @apply h-full rounded-full;
}

.breakdown-description {
  @apply flex justify-between text-neutral-text-weak text-base;
}
</style>
