<template>
  <div class="bucket">
    <div class="bucket-title">
      <div class="flex items-center">
        <slot name="row-avatar" />
        <span data-testid="friendly-name">{{ title }}</span>
        <RouterLink
          v-if="to"
          :to
          data-testid="panel-row-link"
          @click="emit('clicked:destination')"
        >
          <FontAwesomeIcon
            :icon="['fak', 'open-link']"
            class="text-neutral-accent text-12 ml-4 cursor-pointer"
          />
        </RouterLink>
      </div>
      <div class="text-neutral-text-weak" data-testid="bucket-count">
        <template v-if="useDistinctAsTotal">
          <span class="text-neutral-text-strong">
            <b class="font-bold">{{ addComma(numerator) }}</b> unique
          </span>
          |
        </template>
        {{ addComma(useDistinctAsTotal ? denominator : numerator) }} total
      </div>
    </div>
    <ProgressBar :width :color class="h-4 mb-8" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import ProgressBar from './ProgressBar.vue';

const {
  numerator = 0,
  denominator,
  color = 'neutral',
  title,
  useDistinctAsTotal = true,
  to = '',
} = defineProps<SummaryPanelRowProps>();

const emit = defineEmits<{
  'clicked:destination': [];
}>();

export type SummaryPanelRowProps = {
  numerator: number;
  denominator: number;
  color?: 'danger' | 'warning' | 'neutral' | 'secondary';
  title: string;
  useDistinctAsTotal?: boolean;
  to?: string;
};

const width = computed(() => {
  const percentage = (numerator / denominator) * 100;
  return `${Math.min(percentage, 100).toFixed(2)}%`;
});

const addComma = (num: number) => num.toLocaleString();
</script>

<style scoped lang="pcss">
.bucket-title {
  @apply flex justify-between text-sm py-4 mb-4;
}

.bucket {
  @apply mb-16;
}
</style>
