import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import {
  DEAL_CAPABLE_CRMS,
  HS3_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { MDM_PROPERTIES, MDM_TYPES } from '@/constants/mdm';
import {
  useFeedsStore,
  usePotentialRevenueStore,
  useSourcesStore,
} from '@/stores';

export default function useOpportunityData() {
  const sourcesStore = useSourcesStore();
  const { sources } = storeToRefs(sourcesStore);
  const potRevStore = usePotentialRevenueStore();
  const {
    potentialRevenueSettingsSource,
    potentialRevenueSettingsSourceField,
  } = storeToRefs(potRevStore);
  const feedsStore = useFeedsStore();

  const dealCapableSources = computed(() => {
    return sources.value.filter((source) => {
      return DEAL_CAPABLE_CRMS.some((crm) => source.schema.includes(crm));
    });
  });
  const dealSource = computed(() => {
    if (!dealCapableSources.value.length) return null;
    const hasPotentialRevenueSource =
      potentialRevenueSettingsSource.value &&
      !!Object.keys(potentialRevenueSettingsSource.value).length;
    return hasPotentialRevenueSource
      ? potentialRevenueSettingsSource.value
      : dealCapableSources.value.find(
          (source) => source.mdm_type === MDM_TYPES.DEAL,
        );
  });
  const dealFeedId = computed(() => {
    if (!dealCapableSources.value.length) return null;
    if (!dealSource.value) return dealCapableSources.value[0].feed_id;
    return dealSource.value.feed_id;
  });
  const dealFeed = computed(() => {
    if (!dealFeedId.value) return null;
    return feedsStore.getFeedById(dealFeedId.value);
  });
  const isSnowflake = computed(() => {
    if (!dealCapableSources.value.length) return false;
    let schema;
    if (!dealSource.value) schema = dealCapableSources.value[0].schema;
    else schema = dealSource.value.schema;
    return schema.includes(SNOWFLAKE_DATA_SOURCE_TYPE);
  });
  const hasOppDataSynced = computed(() => {
    return (
      dealSource.value &&
      dealSource.value.fields.some((field) => field.is_visible)
    );
  });

  const REQUIRED_OPPORTUNITY_COLUMNS_BY_CRM = {
    [SALESFORCE_DATA_SOURCE_TYPE]: [
      'Amount',
      'IsClosed',
      'IsWon',
      'CreatedDate',
      'CloseDate',
    ],
    [HS3_DATA_SOURCE_TYPE]: [
      'amount',
      'hs_is_closed_won',
      'hs_is_closed',
      'createdate',
      'closedate',
    ],
    [MD_DATA_SOURCE_TYPE]: [
      'actualclosedate',
      'budgetamount',
      'createdon',
      'statecode',
    ],
  };

  const missingFields = computed(() => {
    if (!dealFeed.value || isSnowflake.value) return []; // we cant do snowflake anyway
    const hasPotentialRevenueSourceField =
      potentialRevenueSettingsSourceField.value &&
      !!Object.keys(potentialRevenueSettingsSourceField.value).length;
    const crm = dealFeed.value.integration.type;
    return dealSource.value.fields.filter((field) => {
      if (
        field.is_visible ||
        !REQUIRED_OPPORTUNITY_COLUMNS_BY_CRM[crm].includes(field.column)
      )
        return false;
      if (
        field.mdm_property !== MDM_PROPERTIES.deal.AMOUNT ||
        !hasPotentialRevenueSourceField
      )
        return true;
      return potentialRevenueSettingsSourceField.value.column === field.column;
    });
  });

  const missingFieldsAlertInfo = computed(() => {
    const numFields = missingFields.value.length;
    if (!numFields) return {};
    let fieldInfo = '';
    if (numFields === 1) fieldInfo = missingFields.value[0].display_name;
    else if (numFields === 2)
      fieldInfo = `${missingFields.value[0].display_name} and ${missingFields.value[1].display_name}`;
    else {
      missingFields.value.forEach((field, i) => {
        if (i + 1 === numFields) fieldInfo += `and ${field.display_name}`;
        else fieldInfo += `${field.display_name}, `;
      });
    }
    return {
      title: `Sync ${fieldInfo} Data`,
      text: `Syncing ${fieldInfo} will generate more recommendations and help you find partners that can impact win rate, deal size, and time to close`,
    };
  });

  return {
    dealCapableSources,
    dealFeed,
    dealFeedId,
    hasOppDataSynced,
    missingFieldsAlertInfo,
    missingFields,
    isSnowflake,
  };
}
