<template>
  <div class="c-activity-timeline__sidebar">
    <h3 class="text-neutral-text-weak font-bold mb-8"> Activity Type </h3>
    <div class="c-timeline-sidebar__activity-types-container">
      <BittsTree
        :tree-data="treeFilterData"
        :checked-keys="selectedFilters"
        :multi-select="true"
        :initial-expanded-keys="initialExpandedKeys"
        @check="onCheckedKeys"
      />
    </div>
    <h3 v-if="visible" class="font-bold leading-4 mb-8 text-neutral-text-weak">
      Partner Data
    </h3>
    <div
      v-if="visible"
      class="c-activity-timeline__sidebar--partner-data fullstory-hide dd-privacy-mask"
    >
      <div
        v-for="partnerOrg in partnerOrgsInRecord"
        :key="partnerOrg.uuid"
        class="c-activity-timeline__org-selector hover:bg-neutral-50"
        @click.prevent="onOrgClick(partnerOrg.uuid)"
      >
        <BittsAvatar
          :org="partnerOrg"
          :show-initials="true"
          class="mr-8"
          size="medium"
        />
        <span class="text-neutral-900 leading-6 flex-1">{{
          partnerOrg.name
        }}</span>
        <BittsCheckbox
          :key="`org_select_${partnerOrg.uuid}`"
          :checked="orgFilters[partnerOrg.uuid]"
          @input="(val) => onOrgClick(partnerOrg.uuid)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BittsAvatar, BittsCheckbox, BittsTree } from '@crossbeam/bitts';

import {
  ALL_EVENT_FILTERS,
  ATTRIBUTION_ACTIVITY,
  CROSSBEAM,
  GONG,
  INTEGRATIONS,
  OVERLAP_MOVEMENT,
  PARTNERSTACK,
  POPULATION_MOVEMENT,
  SALES_EDGE_ACTIVITY,
} from '@/constants/timeline';

// used for mapping filter event to filter title
// leaving an example addition of event type to the file as POC
const EVENT_FILTERS = {
  integrations: {
    gong: {
      title: 'Gong',
      imgUrl: new URL(
        '../../../assets/pngs/partner-cloud/Gong.png',
        import.meta.url,
      ).href,
    },
    partnerstack: {
      title: 'PartnerStack',
      imgUrl: new URL(
        '../../../assets/pngs/partner-cloud/Partnerstack.png',
        import.meta.url,
      ).href,
    },
  },
  attribution_activity: {
    attribution_created: 'Attribution Created',
    attribution_deleted: 'Attribution Deleted',
    attribution_updated: 'Attribution Updated',
  },
  population_movement: {
    population_entry: 'Population Entry',
    population_exit: 'Population Exit',
  },
  overlap_movement: {
    overlap_entry: 'Overlap Entry',
    overlap_exit: 'Overlap Exit',
  },
  sales_edge_activity: {
    request_received: 'Incoming Message',
    request_sent: 'Outgoing Message',
  },
};

export default {
  name: 'TimelineSidebar',
  components: {
    BittsAvatar,
    BittsTree,
    BittsCheckbox,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    orgFilters: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
    partnerOrgsInRecord: {
      type: Array,
      default: () => [],
    },
    partnerStackIsActive: {
      type: Boolean,
      default: false,
    },
    gongIsActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['filter-checked', 'org-filter-checked'],
  data() {
    return {
      initialExpandedKeys: [
        ALL_EVENT_FILTERS[CROSSBEAM],
        ALL_EVENT_FILTERS[INTEGRATIONS],
      ],
    };
  },
  computed: {
    treeFilterData() {
      const treeData = [
        {
          title: 'Crossbeam',
          key: ALL_EVENT_FILTERS[CROSSBEAM],
          class: ALL_EVENT_FILTERS[CROSSBEAM],
          children: [
            {
              title: 'Attribution',
              key: ALL_EVENT_FILTERS[ATTRIBUTION_ACTIVITY],
              class: ATTRIBUTION_ACTIVITY,
              children: this.buildFilterChildren(ATTRIBUTION_ACTIVITY),
            },
            {
              title: 'Overlap Movement',
              key: ALL_EVENT_FILTERS[OVERLAP_MOVEMENT],
              class: OVERLAP_MOVEMENT,
              children: this.buildFilterChildren(OVERLAP_MOVEMENT),
            },
            {
              title: 'Population Movement',
              key: ALL_EVENT_FILTERS[POPULATION_MOVEMENT],
              class: POPULATION_MOVEMENT,
              children: this.buildFilterChildren(POPULATION_MOVEMENT),
            },
            {
              title: 'Crossbeam for Sales',
              key: ALL_EVENT_FILTERS[SALES_EDGE_ACTIVITY],
              class: SALES_EDGE_ACTIVITY,
              children: this.buildFilterChildren(SALES_EDGE_ACTIVITY),
            },
          ],
        },
      ];

      if (this.integrationsInstalled) {
        treeData.push({
          title: 'Integrations',
          key: ALL_EVENT_FILTERS[INTEGRATIONS],
          children: this.buildFilterChildren('integrations'),
        });
      }

      return treeData;
    },
    integrationsInstalled() {
      return this.partnerStackIsActive || this.gongIsActive;
    },
  },
  methods: {
    onCheckedKeys(keys) {
      this.$emit('filter-checked', {
        keys,
      });
    },
    onOrgClick(id) {
      this.$emit('org-filter-checked', { id });
    },
    buildFilterChildren(filterEventType) {
      if (!this.partnerStackIsActive)
        delete EVENT_FILTERS.integrations.partnerstack;
      if (!this.gongIsActive) delete EVENT_FILTERS.integrations.gong;
      return Object.entries(EVENT_FILTERS[filterEventType]).map(
        ([key, val]) => {
          if ([GONG, PARTNERSTACK].includes(key)) {
            return {
              key,
              title: val.title,
              scopedSlots: {
                title: 'title',
                imgUrl: val.imgUrl,
              },
            };
          }
          return {
            key,
            class: key,
            title: val,
          };
        },
      );
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-activity-timeline__sidebar--partner-data {
  @apply bg-white rounded-8 border border-neutral-border mb-16 cursor-pointer;
}
.c-timeline-sidebar__activity-types-container {
  @apply bg-white p-16 rounded-8 border border-neutral-border mb-16;
}

.c-timeline-sidebar__activity-types-container:deep(.ant-tree-indent-unit) {
  @apply mr-5;
}
</style>
