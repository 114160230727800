<template>
  <div class="flex justify-center items-center h-screen">
    <div class="flex flex-col items-center">
      <div class="w-240 mb-40">
        <BittsSvg svg="pam" />
      </div>
      <h1 class="text-xxxl font-bold text-neutral-text-strong mb-8">
        Sorry, we're offline making some improvements
      </h1>
      <p class="text-xl text-neutral-text mb-16">
        We'll be back soon with a better Crossbeam
      </p>
      <BittsButton
        text="Check Status"
        size="large"
        data-testid="statuspage-button"
        :href="rootStore.maintenanceModeEvent?.shortlink"
      />
    </div>
  </div>
</template>

<script setup>
import { BittsButton, BittsSvg } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { onMounted } from 'vue';

import appConfig from '@/config';
import { useRootStore } from '@/stores/RootStore';

const rootStore = useRootStore();

onMounted(() => {
  // This is removing the iframe statuspage widget when on maintenance mode page because it's redundant info
  const statusPageWidget = document.querySelector(
    `[src="https://${appConfig.statuspagePageId}.statuspage.io/embed/frame"]`,
  );
  statusPageWidget?.parentElement?.removeChild(statusPageWidget);
});

useHead({ title: 'Crossbeam - In Maintenance' });
</script>
