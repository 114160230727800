<template>
  <div
    @click="handleRoute"
    class="c-sidebar-link"
    :class="{ active: isActive }"
  >
    <FontAwesomeIcon
      class="c-sidebar-link__icon"
      :icon="item.icon"
      :style="{ height: '20px', width: '20px' }"
    />
    <span class="c-sidebar-link__label">{{ item.label }}</span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { DataSourceSettingsTabType } from '@/components/data-sources/SettingsModal/DataSourceSettingsModal.vue';
import { PartnershipSettingsTabType } from '@/components/partners/DataSharingSettings/SettingsModal/PartnershipSettingsModal.vue';

export type SidebarItem<T> = {
  label: string;
  icon: [string, string];
  tab: T;
};

const props = withDefaults(
  defineProps<{
    item: SidebarItem<DataSourceSettingsTabType | PartnershipSettingsTabType>;
    defaultTab?: string;
  }>(),
  {
    defaultTab: 'general',
  },
);

const router = useRouter();
const route = useRoute();

const path = computed(() => route.fullPath);

const isActive = computed(
  () =>
    route.query?.tab === props.item.tab ||
    (!route.query?.tab && props.item.tab === props.defaultTab),
);

async function handleRoute() {
  await router.push({ path: path.value, query: { tab: props.item.tab } });
}
</script>

<style lang="pcss" scoped>
.c-sidebar-link__label {
  @apply opacity-75 font-bold relative;
}

.c-sidebar-link {
  @apply flex items-center gap-10 py-10 w-full rounded-8 relative pl-10;

  .c-sidebar-link__icon {
    @apply opacity-50;
  }

  &:focus-visible {
    @apply outline-none outline-offset-2 outline-2 outline-neutral-border-focus;
  }

  &:hover,
  &.active {
    @apply cursor-pointer bg-secondary-background-weak;
    .c-sidebar-link__icon {
      @apply text-secondary-text;
    }
  }

  &.active .c-sidebar-link__icon {
    @apply opacity-100;
  }
}

.c-sidebar-link--sales-edge {
  path {
    @apply fill-blue-50;
  }
}
</style>
