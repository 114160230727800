<template>
  <div class="self-serve flex flex-col h-full flex-1">
    <router-view-wrapper />
  </div>
</template>

<script setup>
import { useHead } from '@unhead/vue';

useHead({ title: 'Billing - Self Serve' });
</script>
