import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { captureException } from '@/errors';
import { initStore, pollStore } from '@/stores/store-utils';
import urls from '@/urls';

export const useSourcesStore = defineStore('SourcesStore', () => {
  const popSourceIdToSourcesMap = ref({});
  const sources = ref([]);

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const { data } = await axios.get(urls.sources.all, {
      params: {
        include_deleted: true,
      },
    });
    sources.value = data.items;
  });

  refresh({ useCache: true });

  const dealSources = computed(() => {
    return sources.value?.filter(({ mdm_type: type }) => type === 'deal') ?? [];
  });

  const contactSources = computed(() => {
    return (
      sources.value?.filter(({ mdm_type: type }) => type === 'contact') ?? []
    );
  });

  function getSourceById(id) {
    return sources.value.find((source) => source.id === id);
  }

  function getSourcesByFeedId(feedId) {
    return sources.value.filter((source) => source.feed_id === feedId);
  }

  function getAllFilterableSources(items) {
    return items.map((source) => {
      const { id, fields, table } = source;
      source['filterable-sources'].unshift({
        id,
        fields,
        table,
      });
      return source;
    });
  }

  function getSourceAndFieldByFieldId(sourceFieldId) {
    const source = sources.value.find((source) =>
      source.fields.find((sourceField) => sourceField.id === sourceFieldId),
    );
    if (!source) {
      return;
    }
    return {
      source: { ...source },
      field: {
        ...source.fields.find(
          (sourceField) => sourceField.id === sourceFieldId,
        ),
      },
    };
  }

  function getSourceFieldById(sourceFieldId) {
    const source = sources.value.find((source) =>
      source.fields.find((sourceField) => sourceField.id === sourceFieldId),
    );
    if (!source) {
      return;
    }
    return {
      ...source.fields.find((sourceField) => sourceField.id === sourceFieldId),
    };
  }

  function getSourcesByMdmType(parentMdmType) {
    return (
      sources.value?.filter(({ mdm_type: type }) => type === parentMdmType) ??
      []
    );
  }

  function getRelatedSources(source) {
    const datasetName = source.dataset_name;
    return datasetName
      ? sources.value.filter((source) => source.dataset_name === datasetName)
      : [source];
  }

  function resetSourcesByPopulationSourceId() {
    popSourceIdToSourcesMap.value = {};
  }

  async function setSourcesByPopulationSourceId(population) {
    const { source_id: sourceId } = population;
    if (popSourceIdToSourcesMap.value[sourceId]) return;
    const url = urls.sources.filterableSources(sourceId);
    try {
      const response = await axios.get(url);
      popSourceIdToSourcesMap.value[sourceId] = response.data.items;
    } catch (xhr) {
      captureException(xhr);
    }
  }

  async function deleteSource(id) {
    try {
      const url = urls.sources.delete(id);
      await axios.delete(url);
    } catch (xhr) {
      captureException(xhr);
    }
  }

  const { isPolling, startPolling, stopPolling } = pollStore(
    readySync,
    refresh,
  );

  return {
    error,
    ready,
    readySync,
    running,
    sources,
    popSourceIdToSourcesMap,
    refreshSourcesStore: refresh,
    dealSources,
    contactSources,
    getSourceById,
    getSourcesByFeedId,
    getAllFilterableSources,
    getSourceAndFieldByFieldId,
    getSourceFieldById,
    getSourcesByMdmType,
    getRelatedSources,
    resetSourcesByPopulationSourceId,
    setSourcesByPopulationSourceId,
    deleteSource,
    isPolling,
    startPolling,
    stopPolling,
  };
});
