import auth0 from 'auth0-js';

import appConfig from '@/config';
import { ls } from '@/local_storage';
import urls from '@/urls';
import { isLoginPopup } from '@/utils';

export class AuthService {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      ...appConfig.auth0,
      redirectUri: isLoginPopup()
        ? `https://${appConfig.host}/oauth-redirect`
        : appConfig.apiBaseUrl + urls.session.callback,
      responseType: 'code',
      scope: 'openid profile email user_metadata',
    });
  }

  googleLogin() {
    const oauthState = crypto.randomUUID();
    ls.oauthState.set(oauthState);
    this.auth0.authorize({ connection: 'google-oauth2', state: oauthState });
  }

  ssoLogin(connection) {
    const oauthState = crypto.randomUUID();
    ls.oauthState.set(oauthState);
    this.auth0.authorize({ connection, state: oauthState });
  }

  basicLogin(email, password) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          realm: 'Username-Password-Authentication',
          username: email,
          password,
        },
        (err, result) => {
          if (err) {
            return reject(err);
          }
          return resolve(result);
        },
      );
    });
  }
}

export default { AuthService };
