import axios from 'axios';
import { defineStore } from 'pinia';

import { captureException } from '@/errors';
import { createLaunchDarklyClient } from '@/launch-darkly';
import urls from '@/urls';

export const useFeatureFlagStore = defineStore('FeatureFlags', {
  state: () => {
    return {
      initPromise: null,
      featureFlags: [],
      launchDarklyClient: null,
    };
  },
  getters: {
    hasFeatureFlag: (state) => (flag) => {
      const foundFlag = state.featureFlags.find((o) => o.name === flag);
      if (!foundFlag) return false;

      /* The flag could be served as false from LD */
      if (foundFlag.variation === false) return false;
      return true;
    },
    getFlagVariation: (state) => (flag) => {
      return state.featureFlags.find((o) => o.name === flag)?.variation;
    },
    getCoupons: (state) => (type) => {
      return state.featureFlags.filter((flag) =>
        flag.name.startsWith(`_xb_coupon_${type}`),
      );
    },
  },
  actions: {
    initFeatureFlagStore({ currentUser, currentOrg }) {
      if (!this.initPromise)
        this.initPromise = this.refreshFeatureFlagStore({
          currentUser,
          currentOrg,
        });
      return this.initPromise;
    },
    async refreshFeatureFlagStore({
      currentUser,
      currentOrg,
      withLaunchDarkly = true,
    }) {
      if (!this.launchDarklyClient && withLaunchDarkly) {
        try {
          this.launchDarklyClient = await createLaunchDarklyClient({
            currentUser,
            currentOrg,
            featureFlagStore: this,
          });
        } catch (err) {
          captureException(err);
        }
      }

      if (!currentUser.id || !currentOrg.id) return;

      try {
        const { data: response } = await axios.get(urls.users.featureFlags);
        if (response) {
          const flags = response.items.map((flag) => ({
            name: flag.name,
            variation: flag.variation || true,
          }));
          this.setFlags({ flags });
        }
      } catch (err) {
        this.initPromise = null;
        captureException(err);
      }
    },
    setFlags({ flags, override }) {
      this.featureFlags = override
        ? getUniqueObjectsFromArray(this.featureFlags, flags)
        : getUniqueObjectsFromArray(flags, this.featureFlags);
    },
    trackViaLaunchDarkly(key, numericMetric = null) {
      if (!this.launchDarklyClient) return;
      this.launchDarklyClient.track(key, {}, numericMetric);
    },
  },
});

/* Generates a unique list of flags based on their name property, with preference for the second array if there are conflicts */
function getUniqueObjectsFromArray(flags1, flags2) {
  const allFlags = [...flags1, ...flags2];
  const uniqueFlagMap = allFlags.reduce((map, flag) => {
    map[flag.name] = flag;
    return map;
  }, {});

  return Object.values(uniqueFlagMap);
}
