<template>
  <BittsModal
    class="book-meeting-modal"
    :save-text="isSubmitted ? 'Close' : 'Skip'"
    :visible="true"
    :show-header="false"
    :show-cancel-button="false"
    :width="600"
    @saved="closeDialog"
  >
    <template #content>
      <div class="book-meeting-content">
        <h2 class="mt-8 modal-title"> Interested in our paid plans? </h2>
        <p class="modal-paragraph">
          Schedule a call with our Sales team to learn more.
        </p>
        <div :id="BOOK_MEETING_FORM_ID" class="book-meeting-form"></div>
      </div>
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsModal } from '@crossbeam/bitts';

import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { TEMP_CHILIPIPER_ONBOARDING } from '@/constants/feature_flags';
import { useDataSourcesStore, useFeatureFlagStore } from '@/stores';

import { BOOK_MEETING_FORM_ID, displayOnboardingMeetingDialog } from './utils';

const { currentOrg, currentUser } = useAuth();
const dataSourcesStore = useDataSourcesStore();
const featureFlagStore = useFeatureFlagStore();

const hasChilipiperOnboarding = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_CHILIPIPER_ONBOARDING),
);
const isSubmitted = ref(false);

onMounted(async () => {
  await dataSourcesStore.readySync;
  if (hasChilipiperOnboarding.value) {
    displayOnboardingMeetingDialog({
      firstName: currentUser.value.first_name,
      lastName: currentUser.value.last_name,
      email: currentUser.value.email,
      company: currentOrg.value.name,
      onSuccess: setSubmitted,
      onClose: closeDialog,
    });
  } else {
    closeDialog();
  }
});

const router = useRouter();
async function closeDialog() {
  await router.push({ name: 'home' });
}
function setSubmitted() {
  isSubmitted.value = true;
}
</script>

<style lang="pcss" scoped>
.book-meeting-content {
  background: radial-gradient(
    136.96% 39.92% at 50.09% -12.42%,
    theme(colors.upsell.accent / 0.2) 0%,
    theme(colors.upsell.accent / 0) 100%
  );

  @apply flex items-center flex-col w-full m-auto	p-24 gap-8 rounded-3xl;

  .modal-title {
    @apply text-xl text-neutral-text-strong font-bold;
  }

  .modal-paragraph {
    @apply text-neutral-text text-m pb-12;
  }
  .book-meeting-form {
    @apply h-[425px] w-[520px] relative overflow-hidden;

    & :deep(.chilipiper-popup) {
      @apply bg-transparent rounded-[10px] overflow-auto h-full;
    }
    & :deep(.chilipiper-popup-window) {
      @apply rounded-[10px] absolute !h-[425px] !max-h-[425px] !w-[520px] !max-w-[520px] top-0;
    }
  }
}
</style>

<style lang="pcss">
.book-meeting-modal {
  .c-bitts-modal__content {
    @apply p-0 m-0;
  }
}
</style>
