<template>
  <div class="c-explorer-card">
    <h3 class="title"> Plan Summary </h3>
    <BittsCard class="mb-40 overflow-hidden">
      <div class="c-explorer-card-content">
        <CurrentPlanCardSummary
          tier="Explorer"
          :start-date="billingTierCreatedDate"
        />
      </div>
    </BittsCard>
  </div>
</template>

<script setup>
import { BittsCard } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';

import CurrentPlanCardSummary from '@/components/billing/CurrentPlanCardSummary.vue';

import { useBillingStore } from '@/stores';

const billingStore = useBillingStore();
const { billingTierCreatedDate } = storeToRefs(billingStore);
</script>
<style lang="pcss" scoped>
.c-explorer-card {
  .title {
    @apply mb-16 text-neutral-text-strong text-lg font-bold;
  }
}
</style>
