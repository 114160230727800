<template>
  <VuelidateWrapper
    data-testid="file-input-vuelidate-wrapper"
    property="files"
    :errors="v$.$silentErrors"
    @dragover.prevent="highlight = true"
    @dragleave.prevent="highlight = false"
    @mouseover="highlight = true"
    @mouseleave="highlight = false"
    @drop.prevent="onDrop"
  >
    <div
      :tabindex="0"
      class="upload-or-drag-file"
      :class="{ highlight: highlight }"
    >
      <slot>
        <div class="flex items-start">
          <FontAwesomeIcon
            :icon="['far', 'arrow-up-from-bracket']"
            class="w-16 h-16 mr-8"
          />
          <b class="select-none">
            Drop {{ readableFileType }}
            {{ pluralize(props.fileLimit, 'file') }} here or browse
          </b>
        </div>
      </slot>
      <input
        ref="file"
        type="file"
        data-testid="file-input"
        class="file-input"
        @change="onSelectFiles"
      />
    </div>
  </VuelidateWrapper>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { pluralize } from 'humanize-plus';
import { computed, ref } from 'vue';

import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

const props = withDefaults(defineProps<Props>(), {
  fileLimit: 1,
});

const emit = defineEmits(['files-added', 'error']);

const CsvFileType = 'text/csv';
const PdfFileType = 'application/pdf';

type Props = {
  fileType: typeof CsvFileType | typeof PdfFileType;
  fileSizeLimit: number; // in Megabytes
  fileLimit?: number;
};

const files = ref(new Set<File>([]));

const readableFileType = computed(() => {
  switch (props.fileType) {
    case CsvFileType:
      return 'CSV';
    case PdfFileType:
      return 'PDF';
    default:
      return '';
  }
});
const rules = computed(() => ({
  files: {
    invalidType: helpers.withMessage(
      `You may only upload ${props.fileLimit === 1 ? 'a' : ''} ${readableFileType.value} ${pluralize(props.fileLimit, 'file')}`,
      (f: Set<File>) => [...f].every((f) => f.type === props.fileType),
    ),
    fileSizeLimit: helpers.withMessage(
      `Your files exceed the ${props.fileSizeLimit}mb maximum`,
      (f: Set<File>) =>
        [...f].every((f) => f.size / 1000000 <= props.fileSizeLimit),
    ),
    fileLimit: helpers.withMessage(
      `You may only upload ${props.fileLimit} ${pluralize(props.fileLimit, 'file')}`,
      (f: Set<File>) => f.size <= props.fileLimit,
    ),
  },
}));

const v$ = useVuelidate(rules.value, { files });

function onDrop(ev: DragEvent) {
  highlight.value = false;
  files.value = new Set<File>();
  if (ev.dataTransfer?.files) processFiles(ev.dataTransfer.files);
}

function onSelectFiles(event: Event) {
  highlight.value = false;
  const target = event.target as HTMLInputElement;
  if (target.files?.length && target.files.length > 0) {
    processFiles(target.files);
  }
}

function processFiles(fileList: FileList) {
  files.value = new Set<File>();
  for (const file of fileList) {
    files.value.add(file);
  }

  if (v$.value.$invalid) {
    emit('error');
  } else {
    emit('files-added', [...files.value]);
  }
}

const highlight = ref(false);
</script>
<style lang="pcss" scoped>
.upload-or-drag-file {
  @apply border-2 border-neutral-border border-dashed w-full h-40 mt-12 rounded-8 flex items-center justify-center pb-40 pt-[44px] relative mb-6;

  &.highlight {
    @apply bg-secondary-background-weak border-secondary-border;
  }

  .file-input {
    @apply absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer;
  }
}
</style>
