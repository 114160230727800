import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { CORE, SALES } from '@/constants/team_v4';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores/FlashesStore';
import { initStore, pollStore } from '@/stores/store-utils';
import { SeatRequest, SeatRequestStatus } from '@/types/seat_requests';
import urls from '@/urls';

export const useSeatRequestsStore = defineStore('SeatRequests', () => {
  const flashesStore = useFlashesStore();
  const seatRequests = ref<SeatRequest[]>([]);

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const { data }: { data: SeatRequest[] } = await axios.get(
      urls.seatRequests.list,
    );
    if (data)
      seatRequests.value = data.filter(
        ({ status }) => status === SeatRequestStatus.pending,
      );
  });

  refresh({ useCache: true });

  const fullAccessRequests = computed(() =>
    seatRequests.value.filter(({ type }) => type === CORE),
  );
  const salesAccessRequests = computed(() =>
    seatRequests.value.filter(({ type }) => type === SALES),
  );

  function getSeatRequestByEmail(email: string) {
    return seatRequests.value.find((request) => request.email === email);
  }

  function getSeatRequestByUuid(uuid: string) {
    return seatRequests.value.find((request) => request.id === uuid);
  }

  async function rejectSeatRequest(uuid: string) {
    try {
      const request = seatRequests.value.find(
        ({ id }) => id === uuid,
      ) as SeatRequest;
      const friendlyName = request.first_name
        ? `${request.first_name} ${request.last_name}`
        : request.email;
      await axios.patch(urls.seatRequests.update(uuid), {
        status: SeatRequestStatus.rejected,
      });
      seatRequests.value = seatRequests.value.filter(({ id }) => id !== uuid);
      const message = `${request.type === CORE ? 'Full Access' : 'Sales'} Request Rejected`;
      const description = `${friendlyName} was not invited to ${request.type === CORE ? 'Crossbeam' : 'Crossbeam for Sales'}`;
      flashesStore.addSuccessFlash({ message, description });
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({ message: 'Could not reject request' });
    }
  }

  /* Poll store after it is initialized */
  const { startPolling, stopPolling, isPolling } = pollStore(
    readySync,
    refresh,
  );

  return {
    error,
    ready,
    readySync,
    refreshSeatRequestsStore: refresh,
    startPolling,
    stopPolling,
    isPolling,
    rejectSeatRequest,
    seatRequests,
    running,
    fullAccessRequests,
    salesAccessRequests,
    getSeatRequestByEmail,
    getSeatRequestByUuid,
  };
});
