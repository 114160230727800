import { provide, reactive, toRefs } from 'vue';

import { OVERLAP_DATA_STATE_KEY } from '@/constants/amm_grid';

export default function usePartnerOverlapState() {
  const overlapState = reactive({
    customPopulationsForReport: {},
    hasReportChanged: false,
    selectedAMMGridCell: null,
  });

  const overlapStateRefs = toRefs(overlapState);
  provide(OVERLAP_DATA_STATE_KEY, overlapStateRefs);
  return overlapStateRefs;
}
