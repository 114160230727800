<template>
  <div class="c-enterprise-card">
    <h3 class="title"> Plan Summary </h3>
    <BittsCard class="mb-40 overflow-hidden">
      <div class="c-enterprise-card-content">
        <CurrentPlanCardSummary
          tier="Supernode"
          :start-date="billingTierCreatedDate"
        >
          <template #top-right>
            <BittsButton
              size="small"
              text="Contact us"
              data-testid="connector-contact-us-button"
              variant="outline"
              type="neutral"
              @click="
                handleBillingInteraction({
                  cta: PLAN_AND_BILLING_CTAS.GENERIC_SUPERNODE_OUTREACH,
                  event_site: EVENT_SITES.SMALL_ENTERPRISE_CARD_CTA,
                  talkToSalesReason: 'Help Managing Supernode plan',
                })
              "
            />
          </template>
        </CurrentPlanCardSummary>
      </div>
    </BittsCard>
  </div>
</template>

<script setup>
import { BittsButton, BittsCard } from '@crossbeam/bitts';
import { EVENT_SITES, PLAN_AND_BILLING_CTAS } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';

import CurrentPlanCardSummary from '@/components/billing/CurrentPlanCardSummary.vue';

import useBilling from '@/composables/useBilling';
import { useBillingStore } from '@/stores';

const billingStore = useBillingStore();
const { billingTierCreatedDate } = storeToRefs(billingStore);
const { handleBillingInteraction } = useBilling();
</script>
<style lang="pcss" scoped>
.c-enterprise-card {
  .title {
    @apply mb-16 text-neutral-text-strong text-lg font-bold;
  }
}
</style>
