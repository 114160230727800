export const MAX_PARTNER_TAGS = 80;
export const FIND_PARTNERS_LINK =
  'https://www.partnerbase.com/app/search/?partnerTechStack=Crossbeam&sort=partner_count';

export const PARTNER_SCORES = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  NONE: 'none',
  UNKNOWN: 'unknown',
};

export const PARTNER_SCORE_WEIGHT_LOOKUP = {
  [PARTNER_SCORES.HIGH]: 4,
  [PARTNER_SCORES.MEDIUM]: 3,
  [PARTNER_SCORES.LOW]: 2,
  [PARTNER_SCORES.NONE]: 1,
  [PARTNER_SCORES.UNKNOWN]: 0,
};

export const PARTNER_GRADIENTS = {
  high: 'bg-gradient-to-r text-success-text from-success-background-weak to-success-background-weak/20',
  medium:
    'bg-gradient-to-r text-warning-text from-warning-background-weak to-warning-background-weak/20',
  low: 'bg-gradient-to-r text-accent-text from-accent-background-weak to-accent-background-weak/20',
  none: 'bg-gradient-to-r text-neutral-text from-neutral-background-weak to-neutral-background-weak/20',
};
