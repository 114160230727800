import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import useAuth from '@/composables/useAuth';
import { CB4SPartner } from '@/interfaces/cb4s_partners';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

export const useCB4SPartnersStore = defineStore('CB4SPartnersStore', () => {
  const partners = ref<CB4SPartner[]>([]);
  const auth = useAuth();
  const { currentOrg, currentAuth } = auth;

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    if (currentAuth?.value?.sales_edge_role) {
      const { data } = await axios.get(
        urls.salesEdge.get.partners(currentOrg.value.uuid),
      );
      if (data) {
        partners.value = data.results;
      }
    } else {
      partners.value = [];
    }
  });

  refresh({ useCache: true });

  function partnerEnabled(orgId: number): boolean {
    const orgIdString = orgId.toString();
    return !!partners.value?.find(
      (partner) =>
        partner?.crossbeam_partner_integration_settings?.partner_org_id ===
        orgIdString,
    );
  }

  return {
    error,
    ready,
    readySync,
    running,
    refreshCB4SPartnersStore: refresh,
    partnerEnabled,
    partners,
  };
});
