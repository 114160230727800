<template>
  <BittsLoading :is-loading="loading" class="view-all-notifications">
    <BittsLayout :is-page="true">
      <template #content>
        <PageTitle
          title="Activity"
          subtitle="Take action on partner requests and stay informed on the latest opportunity activity"
        />
        <div
          v-if="notifications.length"
          class="view-all-notifications__notifications"
        >
          <div
            class="flex justify-between pt-12 px-12 pb-6 border-b mb-2 border-neutral-border"
          >
            <span>Notifications</span>
            <BittsButton
              v-if="unreadNotifications.length > 0"
              text="Mark All as Read"
              type="primary"
              variant="ghost"
              size="x-small"
              @click="handleMarkAllRead"
            />
          </div>
          <component
            :is="notificationComponent(item)"
            v-for="(item, index) in notifications"
            :key="`notification_${index}`"
            class="notification-component"
            :item="item"
            @action-clicked="sendIterativelyEvent($event, item)"
          />
        </div>
        <BittsEmptyState
          v-else
          :font-awesome-icon="['fak', 'notifications']"
          font-awesome-icon-class="view-all-notifications__empty-state-icon"
          :font-awesome-icon-style="{ height: '40px', width: '40px' }"
          title="You have no notifications"
          class="view-all-notifications__empty-state"
        >
          <template #subtitle>
            <div class="view-all-notifications__empty-state-description">
              Check here for updates on partnership requests, data share
              requests, list activity, and more
            </div>
          </template>
        </BittsEmptyState>
      </template>
    </BittsLayout>
  </BittsLoading>
</template>

<script setup>
import {
  BittsButton,
  BittsEmptyState,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

import ConnectionCheckFailedNotification from '@/components/notifications/ConnectionCheckFailedNotification.vue';
import DataShareNotification from '@/components/notifications/DataShareNotification.vue';
import FeedProcessedNotification from '@/components/notifications/FeedProcessedNotification.vue';
import ListNotification from '@/components/notifications/ListNotification.vue';
import PopRevealNotification from '@/components/notifications/PopRevealNotification.vue';
import ProposalAcceptedNotification from '@/components/notifications/ProposalAcceptedNotification.vue';
import ProposalReceivedNotification from '@/components/notifications/ProposalReceivedNotification.vue';
import PushConnectionErrorNotification from '@/components/notifications/PushConnectionErrorNotification.vue';
import RecordExportLimitNotification from '@/components/notifications/RecordExportLimitNotification.vue';
import ReportPopulationRemovedNotification from '@/components/notifications/ReportPopulationRemovedNotification.vue';
import ReportPopulationStoppedSharingNotification from '@/components/notifications/ReportPopulationStoppedSharingNotification.vue';
import SeatRequestNotification from '@/components/notifications/SeatRequestNotification.vue';
import ShareRequestNotification from '@/components/notifications/ShareRequestNotification.vue';
import PageTitle from '@/components/PageTitle.vue';

import useIteratively from '@/composables/useIteratively';
import useRecordExportLimits from '@/composables/useRecordExportLimits';
import {
  useDataRequestsStore,
  useDataSharesStore,
  useFeedsStore,
  useFlashesStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
  useSeatRequestsStore,
} from '@/stores';

useHead({ title: 'Notifications' });

const { addSuccessFlash, addErrorFlash } = useFlashesStore();

const notificationsStore = useNotificationsStore();
const { allNotifications, unreadNotifications } =
  storeToRefs(notificationsStore);

const { exportLimitStatus } = useRecordExportLimits();

const notifications = computed(() => {
  return allNotifications.value.filter(
    (notif) =>
      !!exportLimitStatus.value || notif.event_type !== 'record_export_limit',
  );
});

const loading = ref(false);
const putPending = ref(false);

const dataRequestsStore = useDataRequestsStore();
const dataSharesStore = useDataSharesStore();
const feedsStore = useFeedsStore();
const partnersStore = usePartnersStore();
const populationsStore = usePopulationsStore();
const seatRequestsStore = useSeatRequestsStore();

onMounted(async () => {
  loading.value = true;
  const promises = [
    dataRequestsStore.refreshInboundShareRequests(),
    dataSharesStore.refreshDataSharesStore(),
    feedsStore.refreshFeedsStore(),
    notificationsStore.refreshNotificationsStore(),
    populationsStore.refreshPartnerPopulations(),
    partnersStore.refreshProposals(),
    seatRequestsStore.refreshSeatRequestsStore(),
  ];
  await Promise.all(promises);
  loading.value = false;
});

const NOTIFICATION_LOOKUP = {
  share_request: ShareRequestNotification,
  data_shared: DataShareNotification,
  feed_processed: FeedProcessedNotification,
  connection_check_failed: ConnectionCheckFailedNotification,
  report_population_removed: ReportPopulationRemovedNotification,
  report_population_stopped_sharing: ReportPopulationStoppedSharingNotification,
  population_visible: PopRevealNotification,
  proposal_received: ProposalReceivedNotification,
  proposal_accepted: ProposalAcceptedNotification,
  push_connection_error: PushConnectionErrorNotification,
  invite_request_access: SeatRequestNotification,
  record_export_limit: RecordExportLimitNotification,
};

function notificationComponent(item) {
  if (NOTIFICATION_LOOKUP[item.event_type])
    return NOTIFICATION_LOOKUP[item.event_type];
  if (item.event_type.startsWith('list')) return ListNotification;
}

async function handleMarkAllRead() {
  if (!putPending.value && unreadNotifications.value.length !== 0) {
    putPending.value = true;
    loading.value = true;
    try {
      await notificationsStore.markAllRead();
      addSuccessFlash({ message: 'Notifications marked as read' });
    } catch (_err) {
      addErrorFlash({
        message: 'Notifications not marked as read',
        description:
          'Please try again or contact support@crossbeam.com for help.',
      });
    } finally {
      loading.value = false;
      putPending.value = false;
    }
  }
}

const { iteratively } = useIteratively();

function sendIterativelyEvent(buttonText, item) {
  iteratively.userClickedNotification({
    event_site: EVENT_SITES.NOTIFICATION_CARD,
    notification_text: item.event_type,
    notification_unread: !item.is_read,
    cta: buttonText,
  });
}
</script>

<style lang="pcss" scoped>
.notification-component:not(:last-of-type) {
  @apply border-b border-neutral-border w-full m-0;
}
.view-all-notifications__empty-state-description {
  @apply text-neutral-text text-base;
}
.view-all-notifications__header {
  @apply flex justify-between items-center pb-16 font-bold text-neutral-text-strong text-xl;
}
.view-all-notifications__notifications {
  @apply bg-white border border-solid border-neutral-border rounded-bts-md mb-24;
}
</style>

<style lang="pcss">
.view-all-notifications__empty-state {
  .c-bitts-empty-state-large {
    @apply px-0 py-24;
  }
  &.c-bitts-empty-state-large-border {
    @apply h-auto bg-white;
  }
}
.view-all-notifications__empty-state-icon {
  @apply text-neutral-text-weak p-24 rounded-[44px];
  background: radial-gradient(
      136.96% 50.92% at 50.09% -12.42%,
      theme(colors.neutral.accent) 0%,
      theme(colors.white / 0) 100%
    ),
    linear-gradient(0deg, theme(colors.white), theme(colors.white));
}
</style>
