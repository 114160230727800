<template>
  <BittsSelect
    v-model="pickedSalesRole"
    class="c-select-sales-role"
    data-testid="sales-role-selector"
    form-label="Sales Role"
    placeholder="Select Sales Role"
    :options="salesRoleOptions"
  />
</template>

<script setup>
import { BittsSelect } from '@crossbeam/bitts';

import { computed, watch } from 'vue';

import {
  CO_SELLER,
  NO_ACCESS,
  PARTNER_MANAGER,
  VIEWER,
} from '@/constants/team'; // TODO: Migrate SE Roles
import { CORE, OLD_ROLES_TO_NEW_MAP } from '@/constants/team_v4';

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  pickedSeat: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const pickedSalesRole = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

/* When the seat type is changed to sales, clear the sales role
if it was previously no access. */
const isCoreSeatSelected = computed(() => props.pickedSeat?.value === CORE);
watch(isCoreSeatSelected, (isCore) => {
  if (!isCore && pickedSalesRole.value === NO_ACCESS)
    pickedSalesRole.value = undefined;
});

const salesRoleOptions = computed(() => {
  const options = [PARTNER_MANAGER, CO_SELLER, VIEWER].map((role) => ({
    value: role,
    label: OLD_ROLES_TO_NEW_MAP[role].label,
  }));
  if (props.pickedSeat?.value === CORE)
    options.push({ value: NO_ACCESS, label: 'No Access' });
  return options;
});
</script>
