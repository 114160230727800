<template>
  <RoleDetail />
</template>

<script setup>
import { useHead } from '@unhead/vue';

import RoleDetail from '@/components/RoleDetail.vue';

useHead({
  title: 'Create New Role - Crossbeam',
});
</script>
