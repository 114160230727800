<template>
  <div class="flex flex-col">
    <BittsCheckbox
      data-testid="select-all-checkbox"
      :checked="areAllFieldsSelected"
      :disabled="!canEditSharing"
      label="Select All"
      class="data-sharing-field-selector__select-all"
      @input="onSelectAllChecked"
    />
    <BittsTree
      :disable-expansion="!canManageSharing"
      show-line
      show-checkboxes
      multi-select
      :tree-data="shareableSourceFields"
      :checked-keys="selectedFields"
      class="data-sharing-field-selector"
      @check="onFieldSelection"
    />
  </div>
</template>

<script setup>
import { BittsCheckbox, BittsTree } from '@crossbeam/bitts';

import { computed, inject } from 'vue';

import useAuth from '@/composables/useAuth';
import { DATA_SHARING_STATE_KEY } from '@/constants/data_shares';
import { sortByKey } from '@/utils';

const props = defineProps({
  canEditSharing: {
    type: Boolean,
    default: true,
  },
  selectedFields: {
    type: Array,
    default: () => [],
  },
  showGreenfieldTag: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['fields-selected']);

const { hasScope } = useAuth();
const canManageSharing = computed(() => hasScope('write:sharing'));

const greenfieldSourceFields = inject('greenfieldSourceFields');

const { allSelectableFields, filterableSources, isGreenfieldChecked } = inject(
  DATA_SHARING_STATE_KEY,
);
function onFieldSelection(userSelectedKeys = []) {
  const selectedSourceFieldIds = userSelectedKeys.filter(
    (id) => typeof id === 'number',
  );
  if (props.showGreenfieldTag && isGreenfieldChecked.value) {
    greenfieldSourceFields.value.forEach((disabledId) => {
      if (!selectedSourceFieldIds.includes(disabledId)) {
        selectedSourceFieldIds.push(disabledId);
      }
    });
  }
  emit('fields-selected', selectedSourceFieldIds);
}
function onSelectAllChecked(checked) {
  const fields = checked ? [...allSelectableFields.value] : [];
  onFieldSelection(fields);
}
const areAllFieldsSelected = computed(
  () => allSelectableFields.value.length === props.selectedFields.length,
);

const shareableSourceFields = computed(() => {
  if (!filterableSources.value) return [];
  return filterableSources.value
    .map((source) => {
      const key = `${source.table}_${source.id}`;
      const children = source.fields
        .reduce(transformVisibleSourceFields, [])
        .toSorted(sortByKey('title'));
      return { checkable: true, title: source.table, key, children };
    })
    .toSorted(sortByKey('title'));
});
function transformVisibleSourceFields(fields, field) {
  if (!field.is_visible) return fields;
  const disabled =
    greenfieldSourceFields.value.includes(field.id) &&
    isGreenfieldChecked.value &&
    props.showGreenfieldTag &&
    props.selectedFields.includes(field.id);
  const scopedSlots = { title: 'title' };
  if (
    greenfieldSourceFields.value.includes(field.id) &&
    props.showGreenfieldTag
  ) {
    scopedSlots.suffix = 'reqd-greenfield-tag';
  }
  const transformedField = {
    title: field.display_name,
    key: field.id,
    checkable: true,
    scopedSlots,
    class: disabled ? 'data-sharing-field-selector__disabled-field' : '',
  };
  fields.push(transformedField);
  return fields;
}
</script>

<style lang="pcss" scoped>
.data-sharing-field-selector {
  @apply font-normal;
}
.data-sharing-field-selector__select-all {
  @apply ml-24 mb-4;
}
</style>

<style lang="pcss">
.data-sharing-field-selector {
  .ant-tree-treenode,
  .ant-tree-node-content-wrapper {
    @apply w-full items-start;
  }
  .ant-tree-title span {
    @apply w-full flex items-center;
  }

  .data-sharing-field-selector__disabled-field {
    @apply cursor-not-allowed;
    .ant-tree-checkbox,
    .ant-tree-node-content-wrapper {
      @apply pointer-events-none;
    }
    .ant-tree-checkbox-inner {
      @apply opacity-50;
    }
  }
}
.data-sharing-field-selector__select-all {
  .ant-checkbox + span {
    @apply ps-12;
  }
  &.bitts-checkbox .ant-checkbox-inner {
    @apply rounded-bts-sm border-neutral-border;
  }
  &.bitts-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    @apply border-info-accent;
  }
}
</style>
