<template>
  <div class="c-payment-cadence">
    <p class="text-neutral-text-weak mb-12"> 1. Select Payment Cadence </p>
    <BittsSegment
      class="payment-cadence-toggle"
      :class="isSmall ? 'flex-col is-mobile h-80' : 'h-48'"
      :tabs="PERIODS"
      :initial-active-index="initialActiveIndex"
      data-testid="payment-cadence-toggle"
      @change:segment="pickPeriod"
    >
      <template #post-title="{ id }">
        <span v-if="id === 'year'" class="text-success-text font-normal"
          >(Save 10%)</span
        >
        <span v-else class="text-neutral-text-weak font-normal"
          >(Requires annual commitment)</span
        >
      </template>
    </BittsSegment>
  </div>
</template>

<script setup>
import { BittsSegment } from '@crossbeam/bitts';
import { useScreenSize } from '@crossbeam/pointbreak';

import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  initialPeriod: {
    type: String,
    default: 'year',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['period-changed']);

const { isSmall } = useScreenSize();

const route = useRoute();

/* Billing Period */
const PERIODS = computed(() => [
  {
    id: 'year',
    title: '1 annual payment',
    disabled: props.disabled,
  },
  {
    id: 'month',
    title: '12 monthly payments',
    disabled: props.disabled,
  },
]);

const router = useRouter();

const initialActiveIndex = props.initialPeriod === 'year' ? 0 : 1;
const pickedPeriod = ref(props.initialPeriod);

function pickPeriod(value) {
  router.replace({ name: route.name, query: { period: value } });
  pickedPeriod.value = value;

  emit('period-changed', pickedPeriod.value);
}
</script>

<style lang="pcss">
.payment-cadence-toggle {
  @apply p-4 w-full;
  @apply rounded-16 opacity-100 !important;
}

.payment-cadence-toggle.is-mobile {
  .bitts-segment {
    @apply rounded-16;
  }
  .bitts-segment__tab {
    @apply flex-1 flex justify-center;
  }
  .bitts-segment__tab {
    @apply rounded-12;
  }
}

.payment-cadence-toggle:not(.is-mobile) {
  .bitts-segment {
    @apply rounded-16;
  }
  .bitts-segment__tab {
    @apply flex-1 flex justify-center;
    @apply rounded-16 opacity-100 !important;
  }
}
</style>
