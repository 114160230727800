/* eslint-disable */
export const enableFullstory = (currentUser, currentOrg, runsInIframe) => {
  window['_fs_debug'] = false;
  window['_fs_host'] = 'fullstory.com';
  window['_fs_org'] = 'GXJTG';
  window['_fs_namespace'] = 'FS';
  if (runsInIframe) {
      window['_fs_is_outer_script'] = runsInIframe;
  }
  (function(m,n,e,t,l,o,g,y){
      if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
      g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
      o=n.createElement(t);o.async=1;o.src='https://edge.fullstory.com/s/fs.js';
      y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
      g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
      g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
      g.consent=function(a){g("consent",!arguments.length||a)};
      g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
      g.clearUserCookie=function(){};
      g.setVars=function(n, p){g('setVars',[n,p])}
  })(window,document,window['_fs_namespace'],'script','user');
  if (FS && currentUser && currentUser.id) {
    FS.identify(currentUser.id, {
      displayName: currentUser.first_name +  ' ' + currentUser.last_name,
      email: currentUser.email,
      company_str: currentOrg.name,
      companyUrl_str: currentOrg.domain
    });
  }
}

export const domainsToBlock = [
  'getcrossbeam.com',
  'crossbeam.com',
  'quantummetric.com',
  'contentsquare.com'
]
