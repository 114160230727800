import axios from 'axios';

import {
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';

type fieldOptionsType = Record<string, string> | null;

type GetPicklistFieldOptionsArgs = {
  searchUrl: string;
  query: string;
  fieldId: number;
  fieldOptions: fieldOptionsType;
  dataSourceType: string;
  usePicklistOptions?: boolean;
};

export default async function useGetPicklistFieldOptions({
  searchUrl,
  query,
  fieldId,
  fieldOptions,
  dataSourceType,
  usePicklistOptions = false,
}: GetPicklistFieldOptionsArgs): Promise<string[]> {
  function getPicklistFieldOptions(
    fieldOptions: fieldOptionsType,
    dataSourceType: string,
  ) {
    if (
      [
        HUBSPOT_DATA_SOURCE_TYPE,
        SALESFORCE_DATA_SOURCE_TYPE,
        HS3_DATA_SOURCE_TYPE,
      ].includes(dataSourceType)
    ) {
      return Object.keys(fieldOptions || {});
    } else if (
      [PIPEDRIVE_DATA_SOURCE_TYPE, MD_DATA_SOURCE_TYPE].includes(dataSourceType)
    ) {
      return Object.values(fieldOptions || {});
    }
    return [];
  }

  const response = await axios.post(searchUrl, {
    query,
    field: fieldId,
  });
  return usePicklistOptions
    ? [
        ...new Set([
          ...response.data,
          ...getPicklistFieldOptions(fieldOptions, dataSourceType),
        ]),
      ]
    : response.data;
}
