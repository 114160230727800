<template>
  <BittsLoading :is-loading="loading">
    <div class="c-access-requested">
      <div>
        <h1> You've requested access to {{ organizationName }} </h1>
        <p class="description">
          Admins have been notified and will approve or deny your request.
          <span v-if="admins.length"
            >The current admins on this account are:</span
          >
        </p>
        <div class="admins-list">
          <BittsTag
            v-for="(admin, i) in admins"
            :key="`${admin}__${i}`"
            variant="rounded"
          >
            <div class="flex items-end gap-4">
              <BittsAvatar
                :show-border="false"
                class="mb-1"
                :show-initials="true"
                :user="{
                  first_name: admin.first_name,
                  last_name: admin.last_name,
                }"
                color="neutral"
                size="x-small"
              />
              <span class="admins-list__admin-name">
                {{ admin.first_name }} {{ admin.last_name }}
              </span>
            </div>
          </BittsTag>
        </div>
        <BittsCard :no-padding="false" class="p-24">
          <div class="card-content">
            <div class="flex gap-12">
              <BittsAvatar
                v-if="clearbitCompany"
                size="medium"
                shape="square"
                :org="clearbitCompany"
                class="c-view-invite__card__avatar"
              />
              <div class="text-start">
                <div class="organization-name">
                  {{ organizationName }}
                </div>
                <div class="organization-domain">
                  {{ domain }}
                </div>
              </div>
            </div>
            <BittsTag variant="rounded" color="neutral">
              <template #pre>
                <FontAwesomeIcon
                  :icon="['fak', 'lock']"
                  class="text-neutral-accent"
                  :style="{ height: '12px', width: '12px' }"
                />
              </template>
              Pending Access
            </BittsTag>
          </div>
          <p data-testid="message-to-admin" class="message-to-admin">
            <i class="text-neutral-text-weak"
              >Message to admin: {{ message }}</i
            >
          </p>
        </BittsCard>
        <p class="not-recognizing">
          Not recognizing these admins?
          <button
            class="support-button"
            @click="handleContactSupport"
            type="button"
          >
            Contact our support team
          </button>
        </p>
        <BittsButton
          class="logout-button"
          variant="outline"
          type="neutral"
          text="Log out"
          :right-icon="['fak', 'logout']"
          @click="handleLogout"
        />
      </div>
    </div>
  </BittsLoading>
</template>

<script setup>
import {
  BittsAvatar,
  BittsButton,
  BittsCard,
  BittsLoading,
  BittsTag,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import useClearbit from '@/composables/useClearbit';
import usePolling from '@/composables/usePolling';
import { captureException } from '@/errors';
import { useRootStore } from '@/stores/RootStore';
import { SeatRequestStatus } from '@/types/seat_requests';
import urls from '@/urls';
import { intercomOrEmailMessage, sortByKey } from '@/utils';

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});
const clearbitCompany = ref(null);
useHead({ title: 'Invite Request - Crossbeam' });

const router = useRouter();
const rootStore = useRootStore();
const { seatRequests } = storeToRefs(rootStore);

const { searchIcons } = useClearbit(false);

const admins = ref([]);
const loading = ref(true);
const organizationName = computed(() => request.value?.org_name);
const domain = computed(() => request.value?.org_domain);
const message = computed(() => request.value?.message);
const request = computed(() =>
  seatRequests.value.find(({ id }) => id === props.uuid),
);

onMounted(async () => {
  try {
    if (!request.value)
      throw new Error(`Request with ID ${props.uuid} could not be found`);
  } catch (err) {
    captureException(err);
    router.push({ name: 'error' });
    return;
  }

  try {
    const { data } = await axios.get(urls.users.suggestedOrgsByEmail);
    admins.value = data.admins
      .filter((admin) => admin.first_name && admin.last_name)
      .sort(sortByKey('last_name'));
  } catch (err) {
    captureException(err); // Do not error if admins cannot be found
  }

  try {
    const companyResults = await searchIcons(organizationName.value); // Get clearbit
    clearbitCompany.value = companyResults.find(
      (c) => c.name === organizationName.value,
    );
  } catch (err) {
    captureException(err); // Do not fail clearbit errors out
  }

  loading.value = false;
});

/* Once this page loads, we refresh the user's `me` state. If at any point an admin
approves the request, the status will no longer be pending and polling will end. When that
happens, we hand back off to the routing guards. If the invite was approved, there will
be a pending invite and the user will will land on the accept page */
const poll = usePolling({
  interval: 5000,
  async poller() {
    await rootStore.loadUserProfile();
  },
  shouldComplete() {
    return (
      request.value?.status !== SeatRequestStatus.pending || !request.value
    );
  },
  async onComplete() {
    await router.push({ name: 'company_setup' });
  },
});

poll();

function handleContactSupport() {
  intercomOrEmailMessage(
    'support@crossbeam.com',
    'Help with invite request',
    `Hello, I've sent an invite request to ${organizationName.value} and am having trouble getting access`,
  );
}

async function handleLogout() {
  await router.push({ name: 'logout' });
}
</script>

<style lang="pcss" scoped>
.c-access-requested {
  @apply mx-16 sm:mx-auto text-center flex flex-col items-center justify-center max-w-[600px];

  h1 {
    @apply text-xl text-neutral-text-strong font-bold mb-16;
  }

  p.description {
    @apply text-m mb-8 text-neutral-600;
  }

  .admins-list {
    @apply flex flex-wrap gap-8 mb-24 justify-center;

    .admins-list__admin-name {
      @apply text-neutral-text;
    }
  }

  .card-content {
    @apply flex items-center justify-between;

    .organization-name {
      @apply font-bold text-neutral-text-strong text-m;
    }

    .organization-domain {
      @apply text-neutral-text;
    }
  }

  .message-to-admin {
    @apply text-neutral-text text-left mt-24;
  }

  .support-button {
    @apply text-secondary-text;
    &:hover {
      @apply opacity-80;
    }
  }

  .not-recognizing {
    @apply text-neutral-text-weak text-m mt-24 sm:mt-40;
  }

  .logout-button {
    @apply sm:hidden mx-auto mt-40;
  }
}
</style>
<style lang="pcss">
.c-app-bare.c-app-bare__invite-requests {
  .c-app-bare__content {
    @apply justify-start mt-0 sm:mt-120;
  }
}
</style>
