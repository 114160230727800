<template>
  <BittsLayout :is-page="true" layout-classes="py-0">
    <template #content>
      <PopulationDetail
        ref="populationDetail"
        @has-user-run-population="hasUserRunPopulation = true"
        @population-change="onPopulationChange"
      />
      <BittsModal
        save-text="Save Changes"
        cancel-text="Discard Changes"
        title="Save Population Changes"
        :visible="showUnsavedChangesModal"
        :content-text="unsavedChangesModalText"
        :use-cancel-button-emitter="true"
        @saved="onSaveChangesViaModal"
        @closed="onCloseChangesViaModal"
        @cancelled="onDiscardChangesViaModal"
      />
    </template>
  </BittsLayout>
</template>

<script>
import { BittsLayout, BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';

import PopulationDetail from '@/components/populations/PopulationDetail.vue';

export default {
  name: 'CreatePopulation',
  components: {
    PopulationDetail,
    BittsModal,
    BittsLayout,
  },
  beforeRouteLeave(to, _from, next) {
    if (this.hasUserRunPopulation && this.hasPopulationChanged) {
      this.nextRoute = to;
      this.showUnsavedChangesModal = true;
      next(false);
    } else {
      next();
    }
  },
  setup() {
    useHead({ title: 'Create Population - Crossbeam' });
  },
  data() {
    return {
      hasPopulationChanged: false,
      hasUserRunPopulation: false,
      showUnsavedChangesModal: false,
    };
  },
  computed: {
    unsavedChangesModalText() {
      return 'Your population has unsaved changes. Would you like to save them before leaving?';
    },
  },
  methods: {
    onPopulationChange(hasChanges) {
      this.hasPopulationChanged = hasChanges;
    },
    async onDiscardChangesViaModal() {
      this.showUnsavedChangesModal = false;
      this.hasPopulationChanged = false;
      await this.$router.push({
        ...this.nextRoute,
      });
    },
    onSaveChangesViaModal() {
      this.showUnsavedChangesModal = false;
      this.$refs.populationDetail.onSavePopulation();
    },
    onCloseChangesViaModal() {
      this.showUnsavedChangesModal = false;
    },
  },
};
</script>
