import { ref } from 'vue';

import { MDM_PROPERTIES, MDM_TYPES } from '@/constants/mdm';

export default function useGreenfieldSharing() {
  const REQD_ACCOUNT_FIELDS = [
    MDM_PROPERTIES.account.NAME,
    MDM_PROPERTIES.account.WEBSITE,
  ];
  const REQD_LEAD_FIELDS = [
    MDM_PROPERTIES.lead.NAME,
    MDM_PROPERTIES.lead.EMAIL,
  ];

  const LEADS = 'people';

  const reqdGreenfieldFieldIds = ref([]);
  const missingReqdGreenfieldFields = ref([]);

  function checkRequiredFields(selectedIds) {
    const newSelectedIds = [...selectedIds];
    reqdGreenfieldFieldIds.value.forEach((fieldId) => {
      if (!newSelectedIds.includes(fieldId)) newSelectedIds.push(fieldId);
    });
    return newSelectedIds;
  }

  function setRequiredGreenfieldFieldIds(popType, sourcesAndFields = []) {
    let reqdFields = REQD_ACCOUNT_FIELDS;
    let mdmType = MDM_TYPES.ACCOUNT;
    const isLeadsPop = popType === LEADS;
    if (isLeadsPop) {
      reqdFields = REQD_LEAD_FIELDS;
      mdmType = MDM_TYPES.LEAD;
    }
    sourcesAndFields.forEach((source) => {
      if (source.mdm_type !== mdmType) return;
      source.fields.forEach((field) => {
        if (!reqdFields.includes(field.mdm_property)) return;
        reqdGreenfieldFieldIds.value.push(field.id);
        if (!field.is_visible && !field.is_filterable)
          missingReqdGreenfieldFields.value.push(field);
      });
    });
  }

  return {
    checkRequiredFields,
    missingReqdGreenfieldFields,
    reqdGreenfieldFieldIds,
    setRequiredGreenfieldFieldIds,
  };
}
