<template>
  <div class="c-right-synctree-tags">
    <BittsTag
      v-if="requiredForObject && !requiredForMatching"
      color="neutral"
      variant="rounded"
      size="x-small"
      data-testid="c-right-synctree-tags__object-tag"
    >
      Required for object
    </BittsTag>
    <BittsTag
      v-if="requiredForMatching"
      color="neutral"
      variant="rounded"
      size="x-small"
      data-testid="c-right-synctree-tags__object-tag"
    >
      Required for matching
    </BittsTag>
    <div class="c-synctree-tags--templates">
      <BittsTooltip>
        <BittsTag
          v-if="matchingTemplates.length > 0"
          color="bg-info-background-weak"
          size="x-small"
          variant="rounded"
          data-testid="c-right-synctree-tags__template-tag"
        >
          <FontAwesomeIcon
            :icon="['far', 'shapes']"
            :style="{ height: '12px', width: '12px' }"
            class="text-info-accent"
          />
          <span>
            {{ templateText }}
          </span>
        </BittsTag>
        <template #title>
          <div v-for="(template, i) in tooltipNodes" :key="i">
            {{ template }}
          </div>
        </template>
      </BittsTooltip>
    </div>
  </div>
</template>

<script setup>
import { BittsTag, BittsTooltip } from '@crossbeam/bitts';

import { computed, inject } from 'vue';

const props = defineProps({
  sourceField: {
    type: Object,
    required: true,
  },
  requiredForObject: {
    type: Boolean,
    default: false,
  },
  requiredForMatching: {
    type: Boolean,
    default: false,
  },
});

const templateToFieldMap = inject('templateToFieldMap');
const templates = inject('templates');

const matchingTemplates = computed(() => {
  return Object.entries(templateToFieldMap.value).reduce(
    (acc, [template, ids]) => {
      if (ids.includes(props.sourceField.id)) acc.push(template);
      return acc;
    },
    [],
  );
});

const templateText = computed(() => {
  let base = ` ${matchingTemplates.value.length} Preset`;
  if (matchingTemplates.value.length > 1) base += 's';
  return base;
});

const tooltipNodes = computed(() => {
  return matchingTemplates.value
    .map((type) => {
      const template = templates.value.find((t) => t.type === type);
      return `• ${template.friendly_name}`;
    })
    .sort();
});
</script>

<style lang="pcss" scoped>
.c-right-synctree-tags {
  @apply flex items-center justify-end flex-1;
}
</style>
