<template>
  <BittsModalTwo
    :width="600"
    :use-mask-to-close="true"
    class="c-google-sheets-two"
    @closed="cancel"
  >
    <template #header>
      <BittsSvg svg="googleSheetsIcon" class="c-google-sheets-two__icon" />
      <h2 class="title">Add Google Sheet</h2>
    </template>
    <div class="c-google-sheets-two__form">
      <BittsLoading :is-loading="loading">
        <div v-if="activeTab === ADD_SHEET_TAB">
          <div class="c-google-sheets-two__alerts">
            <BittsAlert
              v-if="submissionErrors && submissionErrors.length"
              data-testid="submission-error"
              class="py-8 w-full mb-12"
              color="error"
              message="We are having trouble with this sheet"
            >
              <template #body>
                <div
                  v-if="submissionErrors.length === 1"
                  class="gs-connection-error-item"
                >
                  {{ submissionErrors[0] }}
                </div>
                <ul v-else class="list-disc ml-30">
                  <li
                    v-for="(error, index) in submissionErrors"
                    :key="index"
                    class="gs-connection-error-item"
                  >
                    {{ error }}
                  </li>
                </ul>
              </template>
            </BittsAlert>
            <BittsAlert
              v-else-if="sheetNames.length"
              :message="connectSuccessMessage"
              :raw-html="true"
              class="py-8"
              color="success"
            />
            <BittsAlert
              v-if="showRecordLimitError"
              message="Can't add this sheet. It will put you over your Google Sheets row limit"
              :description="recordLimitErrorMessage"
              color="error"
            />
            <BittsAlert
              v-else-if="showRecordLimitWarning"
              :message="recordLimitWarningMessage"
              :description="recordLimitWarningDescription"
              color="warning"
            />
          </div>
          <BittsInput
            v-model.trim="sheetsURL"
            form-label="Google Sheet URL"
            placeholder="docs.google.com/spreadsheets/12345"
            data-testid="sheet-url-input"
            name="Google Sheet URL"
            class="mb-8 mt-24"
            :status="
              sheetsURL && v$.sheetsURL.$silentErrors.length
                ? 'danger'
                : 'default'
            "
            :danger-text="v$.sheetsURL.$silentErrors?.at(-1)?.$message || ''"
            @blur="getSpreadSheetDetails"
            @enter-pressed="getSpreadSheetDetails"
          >
            <template #secondaryText>
              <a
                class="download-button"
                href="https://s3.amazonaws.com/assets.crossbeam.com/Crossbeam-Template.csv"
              >
                <span>Download Template</span>
                <FontAwesomeIcon
                  class=""
                  :icon="['far', 'arrow-down-to-bracket']"
                  :style="{ height: '14px', width: '14px' }"
                />
              </a>
            </template>
            <template #suffix>
              <FontAwesomeIcon
                v-if="sheetNamesLoading"
                :icon="['fak', 'loading']"
                :style="{
                  height: '18px',
                  width: '18px',
                  color: 'currentColor',
                }"
                class="text-neutral-border mr-8 animate-spin"
              />
            </template>
          </BittsInput>
          <VuelidateWrapper property="selectedSheetName" :errors="v$.$errors">
            <BittsSelect
              v-model="selectedSheetName"
              data-testid="sheet-selector"
              :options="sheetNames"
              :disabled="!sheetNames.length"
              :loading="sheetNamesLoading"
              placeholder="Select Sheet"
              form-label="Select a sheet"
              class="mt-24"
              @update:model-value="setSelectedSheetName"
              :error="
                selectedSheetName &&
                v$.selectedSheetName.$silentErrors.length > 0
              "
            />
          </VuelidateWrapper>
          <BittsRadioGroupCards
            class="mt-24"
            orientation="horizontal"
            form-label="What type of data is this?"
            :options="sheetTypes"
            :disabled="!sheetNames.length"
            :initial-value="selectedUploadType"
            @change="selectedUploadType = $event"
          />
        </div>
        <div class="flex flex-col gap-8" v-else>
          <div class="flex">
            <div class="header w-[270px]">Google Sheet Columns</div>
            <div class="header">Crossbeam Fields</div>
          </div>
          <FieldMapping v-if="showAccountFields" label="Company Name">
            <VuelidateWrapper property="selectedNameField" :errors="v$.$errors">
              <BittsSelect
                v-model="selectedNameField"
                :use-disabled-options="true"
                data-testid="name-selector"
                :options="availableColumns"
                placeholder="Select a field (Required)"
                :allow-clear="true"
              />
            </VuelidateWrapper>
          </FieldMapping>
          <FieldMapping v-if="showAccountFields" label="Company Website">
            <BittsSelect
              v-model="selectedWebsiteField"
              :use-disabled-options="true"
              data-testid="website-selector"
              :options="availableColumns"
              placeholder="Select a field"
              :allow-clear="true"
            />
          </FieldMapping>
          <FieldMapping v-if="showLeadFields" label="Email">
            <VuelidateWrapper
              property="selectedEmailField"
              :errors="v$.$errors"
            >
              <BittsSelect
                v-model="selectedEmailField"
                :use-disabled-options="true"
                :options="availableColumns"
                placeholder="Select a field (Required)"
                :allow-clear="true"
              />
            </VuelidateWrapper>
          </FieldMapping>
          <FieldMapping label="Account Owner Email">
            <VuelidateWrapper
              property="selectedAEEmailField"
              :errors="v$.$errors"
            >
              <BittsSelect
                v-model="selectedAEEmailField"
                :use-disabled-options="true"
                data-testid="ae-email-selector"
                :options="availableColumns"
                placeholder="Select a field"
                :allow-clear="true"
              />
            </VuelidateWrapper>
          </FieldMapping>
          <FieldMapping label="Account Owner Name">
            <BittsSelect
              v-model="selectedAENameField"
              :use-disabled-options="true"
              :options="availableColumns"
              placeholder="Select a field"
              :allow-clear="true"
            />
          </FieldMapping>
          <FieldMapping label="Account Owner Phone">
            <BittsSelect
              v-model="selectedAEPhoneField"
              :use-disabled-options="true"
              :options="availableColumns"
              placeholder="Select a field"
              :allow-clear="true"
            />
          </FieldMapping>
        </div>
      </BittsLoading>
    </div>
    <template #footer>
      <BittsButton
        v-if="activeTab === MAP_COLUMNS_TAB"
        text="Back"
        type="neutral"
        variant="outline"
        :left-icon="['fak', 'arrow-left']"
        size="large"
        :disabled="loading"
        @click="back"
      />
      <div class="ml-auto flex gap-8">
        <BittsButton
          text="Cancel"
          type="neutral"
          variant="outline"
          size="large"
          :disabled="loading"
          @click="cancel"
        />
        <BittsButton
          v-if="activeTab === ADD_SHEET_TAB"
          :disabled="
            nextButtonDisabled ||
            showRecordLimitError ||
            loading ||
            submissionErrors?.length
          "
          :loading="gettingSheetDetails || loading"
          data-testid="next-button"
          text="Next"
          size="large"
          @click="next"
        />
        <BittsButton
          v-else
          :disabled="showRecordLimitError || loading"
          :loading="loading"
          text="Add Google Sheet"
          data-testid="add-google-sheet-button"
          size="large"
          @click="addGoogleSheet"
        />
      </div>
    </template>
  </BittsModalTwo>
</template>

<script setup>
import {
  BittsAlert,
  BittsButton,
  BittsInput,
  BittsLoading,
  BittsModalTwo,
  BittsRadioGroupCards,
  BittsSelect,
  BittsSvg,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import axios from 'axios';
import Humanize from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import FieldMapping from '@/components/data-sources/FieldMapping.vue';
import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

import useIteratively from '@/composables/useIteratively';
import {
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  RECORD_LIMIT_THRESHOLD,
} from '@/constants/data_sources';
import { MDM_PROPERTIES } from '@/constants/mdm';
import { useFeedsStore, useFlashesStore, useSourcesStore } from '@/stores';
import urls from '@/urls';

const props = defineProps({
  cancelDestination: {
    type: String,
    default: 'data-sources',
  },
});

const emit = defineEmits(['saved']);

const ACCOUNT = 'account';
const LEAD = 'lead';
const ADD_SHEET_TAB = 'add_sheet_tab';
const MAP_COLUMNS_TAB = 'map_columns_tab';

const sourcesStore = useSourcesStore();
const feedsStore = useFeedsStore();
const flashesStore = useFlashesStore();
const { iteratively } = useIteratively();
const router = useRouter();

const { sources } = storeToRefs(sourcesStore);

useHead({ title: 'Google Sheets - Crossbeam' });

onMounted(async () => {
  await Promise.all([feedsStore.readySync, sourcesStore.readySync]);
  feed.value = feedsStore.getFeedByDataSourceType('google_sheets');
});

const activeTab = ref(ADD_SHEET_TAB);
const loading = ref(false);
const feed = ref({});
const submissionErrors = ref(null);
const sheetNamesLoading = ref(false);

/* Field mappings */
const selectedNameField = ref(null);
const selectedWebsiteField = ref(null);
const selectedEmailField = ref(null);
const selectedAENameField = ref(null);
const selectedAEEmailField = ref(null);
const selectedAEPhoneField = ref(null);

/* Tab #1: Sheet Selection and Data Type */
const sheets = ref([]);
const columns = ref([]);
const sheetsURL = ref('');
const sheetName = ref(null);
const spreadsheetName = ref('');
const spreadsheetId = ref('');
const selectedSheetName = ref(null);
const selectedUploadType = ref(ACCOUNT);
function isGoogleSheetUrl(sheetsUrl) {
  return sheetsUrl.includes('docs.google.com');
}

const selectedSheetId = computed(
  () =>
    sheets?.value.find((sheet) => sheet.sheet_name === selectedSheetName.value)
      ?.sheet_id,
);

function isNewSheet() {
  if (!selectedSheetId.value) return true;
  const hasConnected = sources.value.some((source) => {
    if (source.properties?.sheet_id === selectedSheetId.value) {
      return true;
    }
  });
  return !hasConnected;
}

const sheetNames = computed(() =>
  sheets.value.map((sheet) => ({
    label: sheet.sheet_name,
    value: sheet.sheet_name,
  })),
);

const sheetTypes = computed(() => [
  {
    value: ACCOUNT,
    label: 'Companies',
  },
  {
    value: LEAD,
    label: 'People',
  },
]);

const connectSuccessMessage = computed(
  () => `You successfully connected to ${spreadsheetName.value}`,
);

/* Tab #2: Column selection */
const showAccountFields = computed(() => selectedUploadType.value === ACCOUNT);
const showLeadFields = computed(() => selectedUploadType.value === LEAD);

const selectedColumns = computed(() => [
  selectedNameField.value,
  selectedWebsiteField.value,
  selectedEmailField.value,
  selectedAEEmailField.value,
  selectedAENameField.value,
  selectedAEPhoneField.value,
]);

const availableColumns = computed(() =>
  columns.value
    .map((col) => ({
      label: col,
      value: col,
      disabled: selectedColumns.value.includes(col),
    }))
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)),
);

/* Validation */
const rules = computed(() => ({
  sheetsURL: {
    required: helpers.withMessage('A google sheet URL is required', required),
    isGoogleSheetUrl: helpers.withMessage(
      'URL must contain docs.google.com',
      isGoogleSheetUrl,
    ),
  },
  selectedNameField: {
    required: helpers.withMessage(
      'Please choose a column for company name',
      requiredIf(() => {
        return selectedUploadType.value === ACCOUNT;
      }),
    ),
  },
  selectedSheetName: {
    required: helpers.withMessage(
      'Please choose a sheet name',
      requiredIf(() => {
        return (
          selectedUploadType.value === ACCOUNT && sheetNames.value.length > 0
        );
      }),
    ),
    isGoogleSheetUrl: helpers.withMessage(
      'You have already connected this sheet',
      isNewSheet,
    ),
  },
  selectedEmailField: {
    required: helpers.withMessage(
      'Please choose a column for email',
      requiredIf(() => {
        return selectedUploadType.value === LEAD;
      }),
    ),
  },
}));

const v$ = useVuelidate(rules.value, {
  sheetsURL,
  selectedNameField,
  selectedSheetName,
  selectedEmailField,
  selectedAEEmailField,
});

/* Record limit restrictions */
const newRowsCount = ref(0);
const showRecordLimitError = computed(() => {
  const nextTotal = feed.value.active_record_count + newRowsCount.value;
  return (
    !loading.value &&
    feed.value.integration?.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE &&
    feed.value.max_record_count &&
    (feed.value.active_record_count >= feed.value.max_record_count ||
      nextTotal > feed.value.max_record_count)
  );
});

const recordLimitErrorMessage = computed(() => {
  const nextTotal = feed.value.active_record_count + newRowsCount.value;
  const amountOverDisplay = Humanize.intComma(
    nextTotal - feed.value.max_record_count,
  );
  const limitDisplay = Humanize.intComma(feed.value.max_record_count);
  return `This sheet will put you over your ${limitDisplay}
        row limit across all Google Sheets by ${amountOverDisplay} rows.`;
});

const showRecordLimitWarning = computed(() => {
  const nextTotal = feed.value.active_record_count + newRowsCount.value;
  return (
    !loading.value &&
    feed.value.integration?.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE &&
    feed.value.max_record_count &&
    nextTotal >= feed.value.max_record_count - RECORD_LIMIT_THRESHOLD
  );
});
const recordLimitWarningMessage = computed(() => {
  const limit = Humanize.intComma(feed.value.max_record_count);
  return `You are about to reach your ${limit} row limit`;
});
const recordLimitWarningDescription = computed(() => {
  const nextTotal = feed.value.active_record_count + newRowsCount.value;
  const available = Humanize.intComma(feed.value.max_record_count - nextTotal);
  return `After adding this sheet, you will have ${available} rows available.
              Delete rows in other Google sheets to reduce rows used`;
});

function clearSelectedFields() {
  selectedNameField.value = null;
  selectedWebsiteField.value = null;
  selectedEmailField.value = null;
}

const lastUrl = ref('');
async function getSpreadSheetDetails() {
  if (!sheetsURL.value || lastUrl.value === sheetsURL.value) return;
  lastUrl.value = sheetsURL.value;

  sheetNamesLoading.value = true;
  try {
    const response = await axios.get(
      urls.sources.googleSheetsNames(feed.value.id),
      { params: { sheet_url: sheetsURL.value } },
    );

    spreadsheetId.value = response.data.spreadsheet_id;
    spreadsheetName.value = response.data.spreadsheet_name;
    sheets.value = response.data.sheets;
    submissionErrors.value = null;
    columns.value = ['url', 'name', 'id', 'created'];
  } catch (err) {
    submissionErrors.value = err.response.data.errors;
    sheets.value = [];
  } finally {
    /* Auto-select the sheet based on the URL pasted, if possible. */
    const getGid = (url) => {
      try {
        return new URL(url).searchParams.get('gid') || null;
      } catch (err) {
        return null;
      }
    };
    const gid = getGid(sheetsURL.value);
    if (!gid) selectedSheetName.value = null;
    else {
      const sheet = sheets.value.find((s) => s.sheet_id === Number(gid));
      if (sheet) setSelectedSheetName(sheet.sheet_name);
    }

    clearSelectedFields();
    sheetNamesLoading.value = false;
  }
}

function getFields() {
  const accountLookup = {
    [MDM_PROPERTIES.account.NAME]: selectedNameField.value,
  };
  if (selectedWebsiteField.value) {
    accountLookup.company_website = selectedWebsiteField.value.trim();
  }
  const peopleLookup = { person_email: selectedEmailField.value };
  const columnsToSend =
    selectedUploadType.value === ACCOUNT ? accountLookup : peopleLookup;
  const fields = Object.entries(columnsToSend).reduce((acc, [key, val]) => {
    const columnObj = {
      mdm_property: key,
      column_name: val,
      is_primary_key: key === 'company_key' || key === 'person_key',
    };
    acc.push(columnObj);
    return acc;
  }, []);
  return fields;
}

/* Account executive mapping logic, will add to payload */
function buildAEField(field, mdm) {
  return {
    mdm_property: mdm,
    column_name: field,
    is_primary_key: false,
  };
}
function getAEFields() {
  const selectedAEFields = [];
  if (selectedAEEmailField.value) {
    selectedAEFields.push(
      buildAEField(selectedAEEmailField.value, 'person_email'),
    );
  }
  if (selectedAENameField.value) {
    selectedAEFields.push(
      buildAEField(selectedAENameField.value, 'person_name'),
    );
  }
  if (selectedAEPhoneField.value) {
    selectedAEFields.push(
      buildAEField(selectedAEPhoneField.value, 'person_phone'),
    );
  }

  return {
    mdm_type: 'user',
    feed_id: feed.value.id,
    spreadsheet_id: spreadsheetId.value,
    sheet_id: selectedSheetId.value,
    fields: selectedAEFields,
  };
}

async function addGoogleSheet() {
  v$.value.$touch();
  if (v$.value.$invalid) {
    activeTab.value = findTabWithError(v$.value);
    return;
  }

  loading.value = true;
  try {
    const payload = makePayloadAndUrl();
    const { data: response } = await axios.post(urls.sources.allV3, payload);

    iteratively.userConnectedDataSource({
      event_site: EVENT_SITES.GOOGLE_SHEETS_MODAL,
      data_source_type: 'google_sheets',
      feed_id: response.feed_id.toString(),
      custom_presets: [],
      initial_sync_selection: '',
    });

    await sourcesStore.refreshSourcesStore();

    emit('saved');
    const flashObject = {
      message: 'We have connected your Google Sheet.',
      description: 'This sheet is currently processing',
    };
    flashesStore.addSuccessFlash(flashObject);

    await feedsStore.refreshFeed(response.feed_id);
  } catch (err) {
    flashesStore.addErrorFlash({
      message: getErrorText(err, 'Failed to connect Google Sheet'),
    });
  } finally {
    loading.value = false;
    router.push({ name: 'data-sources' });
  }
}

const gettingSheetDetails = ref(false);
async function getSheetDetails() {
  v$.value.sheetsURL.$touch();
  if (!v$.value.sheetsURL.$invalid) {
    gettingSheetDetails.value = true;
    try {
      const resp = await axios.get(
        urls.sources.googleSheetsColumnsV2(feed.value.id),
        {
          params: {
            spreadsheet_id: spreadsheetId.value,
            sheet_id: selectedSheetId.value,
          },
        },
      );
      columns.value = resp.data.columns;
      sheetName.value = resp.data.sheet_name;
      spreadsheetName.value = resp.data.spreadsheet_name;
      newRowsCount.value = resp.data.row_count;
      submissionErrors.value = null;
    } catch (err) {
      submissionErrors.value = err.response.data.errors;
      clearSelectedFields();
      sheetNames.value = [];
    } finally {
      gettingSheetDetails.value = false;
    }
  }
}

async function setSelectedSheetName(sheet) {
  if (typeof sheet !== 'string') return;
  selectedSheetName.value = sheet;
  await getSheetDetails();
  v$.value.$touch();
}

function makePayloadAndUrl() {
  const baseData = {
    mdm_type: selectedUploadType.value,
    feed_id: feed.value.id,
    spreadsheet_id: spreadsheetId.value,
    sheet_id: selectedSheetId.value,
    fields: getFields(),
  };

  const payload = [baseData];

  /* If the user is mapping AE data, add it to the payload */
  if (
    selectedAEEmailField.value ||
    selectedAENameField.value ||
    selectedAEPhoneField.value
  ) {
    payload.push(getAEFields());
  }

  return payload;
}

/* Footer actions */
function next() {
  v$.value.$reset();
  activeTab.value = MAP_COLUMNS_TAB;
}
const nextButtonDisabled = computed(() => {
  if (activeTab.value === MAP_COLUMNS_TAB) {
    if (showAccountFields.value) {
      return !selectedNameField.value || !selectedWebsiteField.value;
    }
    return !selectedEmailField.value;
  }
  return (
    !sheetsURL.value ||
    !selectedSheetName.value ||
    v$.value.selectedSheetName.$silentErrors.length > 0
  );
});

function back() {
  activeTab.value = ADD_SHEET_TAB;
}

async function cancel() {
  await router.push({ name: props.cancelDestination });
}

/* Utils */
function getErrorText(err, defaultText) {
  if (
    err.response &&
    err.response.data &&
    err.response.data.errors &&
    err.response.data.errors.length > 0
  ) {
    return err.response.data.errors[0];
  }
  return defaultText;
}

function findTabWithError(vuelidate) {
  // figures out which tab is causing the vuelidate error
  if (vuelidate.sheetsURL.$error) {
    return ADD_SHEET_TAB;
  }
  return MAP_COLUMNS_TAB;
}
</script>
<style lang="pcss" scoped>
.c-google-sheets-two__icon {
  @apply w-60 h-60 mb-6 mt-12;
}
.title {
  @apply text-neutral-text-strong font-bold text-xl mb-4 px-16 text-center w-full;
}
.header {
  @apply text-neutral-text-strong font-bold;
}
.c-google-sheets-two__form {
  @apply mt-24;
}
.c-google-sheets-two__alerts {
  @apply flex flex-col gap-16;
}

.download-button {
  @apply text-secondary-accent flex gap-6 text-sm font-bold px-8 py-4 rounded-4 items-center mb-[-2px];
  &:hover {
    @apply bg-neutral-50;
  }
}
</style>
<style lang="pcss">
.c-google-sheets-two__form {
  .ant-select-selection-item > div {
    @apply opacity-100 text-neutral-text !important;
  }
}
</style>
