<template>
  <BittsLayout :is-page="true">
    <template #content>
      <PageTitle title="My Team" />
      <TeamCounter>
        <EnterpriseSeatCallout v-if="isEnterpriseTier" />
      </TeamCounter>
      <TeamTable />
      <router-view-wrapper />
      <!-- Invite Modal -->
    </template>
  </BittsLayout>
</template>

<script setup>
import { BittsLayout } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

import EnterpriseSeatCallout from '@/components/billing/EnterpriseSeatCallout.vue';
import PageTitle from '@/components/PageTitle.vue';
import TeamCounter from '@/components/team/TeamCounter.vue';
import TeamTable from '@/components/team/TeamTable.vue';

import { useBillingStore, useFlashesStore } from '@/stores';

const billingStore = useBillingStore();
const { isEnterpriseTier } = storeToRefs(billingStore);

useHead({ title: 'Team - Crossbeam' });
const route = useRoute();
const flashesStore = useFlashesStore();
if (route.query.downgrade_complete) {
  flashesStore.addSuccessFlash({
    message: 'Team successfully updated',
    description: 'Thanks for making these selections',
  });
}
</script>
