<template>
  <div class="c-invite-partner-search">
    <BittsSelect
      id="invite-partner-search"
      v-model="selectedCompany"
      :form-label="isOffline ? 'Partner Name' : 'Find a Partner'"
      placeholder="Search for a company"
      :options="options"
      option-type="company"
      dropdown-class-name="partner-search-dropdown bitts-select__dropdown"
      :dropdown-button="!!inviteButtonText"
      :allow-clear="true"
      :dynamic-options="true"
      @update:model-value="companySelected"
      @search-changed="onSearchChanged"
      @clear="clear"
    >
      <template #suffix="{ option }">
        <div class="c-invite-partner-search__domain">
          ({{ option.domain }})
        </div>
        <BittsTag
          v-if="option.public_invite_code"
          class="c-invite-partner-search__tag"
          variant="rounded"
          color="success"
          text="on Crossbeam"
          size="x-small"
        />
      </template>
      <template #dropdownButton>
        <div class="flex justify-center px-4 mt-4 w-full">
          <BittsButton
            :text="inviteButtonText"
            size="x-small"
            class="w-full"
            type="neutral"
            :left-icon="['fak', 'add']"
            @click.prevent="onDropdownButtonClick"
          />
        </div>
      </template>
    </BittsSelect>
  </div>
</template>

<script>
import { BittsButton, BittsSelect, BittsTag } from '@crossbeam/bitts';

import { debounce } from 'lodash';

import useClearbit from '@/composables/useClearbit';

export default {
  name: 'InvitePartnerSearch',
  components: {
    BittsButton,
    BittsSelect,
    BittsTag,
  },
  props: {
    isOffline: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clear', 'input'],
  setup() {
    const { orgsWithIcons, searchIcons } = useClearbit();
    return { orgsWithIcons, searchIcons };
  },
  data() {
    return {
      company: null,
      options: [],
      searchDebounced: null,
      queryInProgress: '',
      selectedCompany: undefined,
    };
  },
  computed: {
    inviteButtonText() {
      return this.queryInProgress.length
        ? this.customOptionLabel(this.queryInProgress)
        : null;
    },
  },
  created() {
    if (this.$route.query.name) {
      this.company = {
        name: this.$route.query.name,
        domain: this.$route.query.domain,
        clearbit_domain: this.$route.query.domain,
        public_invite_code: this.$route.query.invite,
      };
      this.companySelected(this.company);
    }
    this.searchDebounced = debounce(async (query) => {
      this.options = await this.searchIcons(query);
    }, 300);
  },
  methods: {
    onSearchChanged(query) {
      this.queryInProgress = query;
      this.searchDebounced(query);
    },
    companySelected(val) {
      if (!val) return;

      const company =
        typeof val === 'string'
          ? this.options.find((org) => org.domain === val || org.uuid === val)
          : val;

      this.queryInProgress = '';
      this.selectedCompany = val?.name || val;
      this.$emit('input', { company: company || {} });
    },
    clear() {
      this.onSearchChanged(null); /* Clears the query results */
      const emptyCompany = {
        name: null,
        domain: null,
        public_invite_code: null,
      };
      this.companySelected(emptyCompany);
      this.selectedCompany = undefined;
      this.$emit('clear');
    },
    customOptionLabel(newCompanyName) {
      if (this.isOffline) return `Use ${newCompanyName}`;
      return `Invite "${newCompanyName}" to Crossbeam`;
    },
    secondaryTextForSelect(option) {
      if (option.domain) return `(${option.domain})`;
      return '';
    },
    onDropdownButtonClick() {
      const company = {
        name: this.queryInProgress,
        value: this.queryInProgress,
        label: this.queryInProgress,
        domain: this.queryInProgress,
        logo: null,
      };
      this.companySelected(company);
    },
  },
};
</script>

<style lang="pcss">
.partner-search-dropdown {
  .bitts-select__option-label {
    @apply w-auto;
  }

  .c-invite-partner-search__domain {
    @apply flex-1 ml-4 text-neutral-text-weak;
  }
}
.c-invite-partner-search {
  .ant-select-selection-item {
    .c-invite-partner-search__domain,
    .c-invite-partner-search__tag {
      @apply hidden;
    }
  }
}
</style>
