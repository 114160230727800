<template>
  <BittsDrawer
    :visible="showDrawer"
    title="Crossbeam Copilot Settings"
    :primary-button-text="saveButtonText"
    :is-saving="savingSettings"
    @action:primary="saveSettings"
    @closed="onDrawerClosed"
  >
    <template #headerAvatar>
      <BittsAvatar
        :org="{ domain: 'crossbeam.com' }"
        shape="square"
        size="small"
      />
    </template>

    <template #content>
      <div v-if="hasManageIntegrations" class="copilot_drawer">
        <BittsLoading :is-loading="loading">
          <div class="px-16">
            <div class="flex items-center gap-8">
              <FontAwesomeIcon
                :icon="['fak', 'mapping']"
                class="text-secondary-accent text-16"
              />
              <p class="copilot_drawer__section__header font-bold">
                Customize Data in Crossbeam Copilot
              </p>
              <BillingCTA
                v-if="isFreeTier"
                size="x-small"
                data-testid="crossbeam-copilot-settings-cta"
                :billing-interaction="billingCta"
                :route-to="{ name: 'integrations' }"
              />
            </div>
            <div class="copilot_drawer__section__description">
              <p>
                Select how you want to push your data and your partner data into
                Crossbeam Copilot. These settings will apply anywhere you have
                Crossbeam Copilot set up.
              </p>
              <p>
                Note: This does not count towards your record export limit
              </p>
              <BittsLink
                text="Learn more about this"
                url="https://help.crossbeam.com/en/articles/9167877-crossbeam-copilot-overview"
                :open-in-new-tab="true"
                :show-arrow="true"
                class="mt-2"
              />
            </div>
            <PopulationSelectionTree
              :integration-profile-settings="newPopulationSettings"
              :class="isFreeTier && 'disabled'"
              setting-type="crossbeam_copilot"
              :is-saving="savingSettings"
              :expanded-keys="['my-data', 'partner-data']"
              :disabled="isFreeTier"
              @save-changes="onSettingsUpdated"
            />
          </div>
        </BittsLoading>
      </div>
    </template>
  </BittsDrawer>
</template>
<script setup lang="ts">
import {
  BittsAvatar,
  BittsDrawer,
  BittsLink,
  BittsLoading,
} from '@crossbeam/bitts';
import { EVENT_SITES, INTEGRATIONS_CTAS } from '@crossbeam/itly';
import { GetApiPayloadByUrl } from '@crossbeam/openapi';

import { isEqual } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import BillingCTA from '@/components/billing/BillingCTA.vue';
import PopulationSelectionTree from '@/components/salesforce-widget/PopulationSelectionTree.vue';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import {
  useBillingStore,
  useFlashesStore,
  useIntegrationProfileStore,
  usePopulationsStore,
} from '@/stores';
import {
  IntegrationProfile,
  MinimalIntegrationProfile,
} from '@/types/integration_profiles';
import { STANDARD_POPULATIONS } from '@/types/populations';

const integrationProfileStore = useIntegrationProfileStore();
const flashesStore = useFlashesStore();
const router = useRouter();
const route = useRoute();
const billingStore = useBillingStore();
const populationsStore = usePopulationsStore();
const showDrawer = ref(true);
const loading = ref(true);
const savingSettings = ref(false);

const { populations, partnerPopulations } = storeToRefs(populationsStore);
const { iteratively } = useIteratively();

// The mutating value that changes before saving
const newPopulationSettings = ref<MinimalIntegrationProfile | undefined>(
  undefined,
);

const { isFreeTier } = storeToRefs(billingStore);

const integration = computed(() => route.params.integration);
const billingCta = computed(() => {
  const integrationType =
    typeof integration.value === 'string'
      ? integration.value.toUpperCase()
      : '';
  return {
    cta: INTEGRATIONS_CTAS[
      `COPILOT_FOR_${integrationType}` as keyof typeof INTEGRATIONS_CTAS
    ],
    event_site:
      EVENT_SITES[
        `CROSSBEAM_COPILOT_SETTINGS_${integrationType}` as keyof typeof EVENT_SITES
      ],
  };
});

async function fetchSettings() {
  loading.value = true;
  await Promise.all([integrationProfileStore.readySync]);

  if (existingIntegrationProfile.value) {
    newPopulationSettings.value = {
      standard_population_settings:
        existingIntegrationProfile.value.standard_population_settings,
      population_settings: existingIntegrationProfile.value.population_settings,
    };
    loading.value = false;
  } else {
    initProfileSettings();
  }
}

const { hasPermission } = useAuth();
const hasManageIntegrations = hasPermission('manage:integrations');

const existingIntegrationProfile = computed<IntegrationProfile | undefined>(
  () =>
    integrationProfileStore.getIntegrationProfileByType('crossbeam_copilot'),
);

const saveButtonText = computed(() => {
  if (isFreeTier.value) return 'Close';
  return savingSettings.value ? 'Saving Changes' : 'Save';
});

function onSettingsUpdated(payload: MinimalIntegrationProfile) {
  newPopulationSettings.value = payload;
}

async function saveSettings() {
  if (isFreeTier.value) {
    onDrawerClosed();
    return;
  }

  const initialSettings = newPopulationSettings.value;
  savingSettings.value = true;
  await nextTick();

  const outPayload = {
    population_settings:
      newPopulationSettings.value?.population_settings.map((p) => ({
        population_id: p.population_id,
        is_partner_population: p.is_partner_population,
        is_included: p.is_included,
      })) || [],
    standard_population_settings:
      newPopulationSettings.value?.standard_population_settings.map((p) => ({
        standard_type: p.standard_type,
        is_included: p.is_included,
      })) || [],
  };

  if (isEqual(initialSettings, outPayload)) {
    onDrawerClosed();
    return;
  }

  if (!existingIntegrationProfile.value) {
    onDrawerClosed();
    return;
  }

  try {
    await integrationProfileStore.putIntegrationProfileSettings(
      existingIntegrationProfile.value.profile_id,
      outPayload,
    );
    flashesStore.addSuccessFlash({
      message: 'Successfully saved Crossbeam Copilot settings',
    });
    integrationProfileStore.refreshIntegrationProfileStore();
    // @ts-expect-error - missing values are inserted via setTrackingOptions() in app.js
    iteratively.userSavesCopilotIntegrationSetting({
      integration_name: integration.value as string,
      event_site: EVENT_SITES.CROSSBEAM_COPILOT_SETTINGS_DRAWER,
    });
    onDrawerClosed();
  } catch (_err) {
    onDrawerClosed();
    flashesStore.addErrorFlash({
      message: 'Your Crossbeam Copilot settings could not be saved',
    });
  } finally {
    savingSettings.value = false;
  }
}

async function onDrawerClosed() {
  showDrawer.value = false;
  await router.push({
    name: 'integrations',
    query: {
      ...route.query,
    },
  });
}

async function initProfileSettings() {
  loading.value = true;
  // If there isn't an appropriate integration profile, make a new one and post it.
  const popSettings = populations.value
    .map((pop) => {
      return {
        population_id: pop.id,
        is_partner_population: false,
        is_included: true,
      };
    })
    .concat(
      partnerPopulations.value.map((pop) => {
        return {
          population_id: pop.id,
          is_partner_population: true,
          is_included: true,
        };
      }),
    );
  const standardPopSettings = STANDARD_POPULATIONS.map((pop) => ({
    standard_type: pop,
    is_included: true,
  }));

  const payload: GetApiPayloadByUrl<'/v0.1/integration-profiles', 'post'> = {
    integration_type: 'crossbeam_copilot',
    population_settings: popSettings,
    standard_population_settings: standardPopSettings,
  };

  try {
    await integrationProfileStore.postIntegrationProfileSettings(payload);
    await integrationProfileStore.refreshIntegrationProfileStore();
    newPopulationSettings.value = {
      standard_population_settings: standardPopSettings,
      population_settings: popSettings,
    };
  } catch (err) {
    flashesStore.addErrorFlash({ message: (err as Error).message });
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  await fetchSettings();
});
</script>

<style lang="pcss">
.copilot_drawer .c-bitts-empty-state-large-border {
  @apply w-auto;
}

.copilot_drawer {
  @apply py-16;
}

.copilot_drawer__section__header {
  @apply text-base text-neutral-800 leading-6;
}

.copilot_drawer__section__description {
  @apply text-sm text-neutral-600 gap-6 flex flex-col;
}

.copilot_drawer__empty-state {
  @apply m-24 p-24 text-center border;
  border-color: theme('colors.neutral.300');
  border-radius: 16px;
}

.copilot_drawer__empty-state--description {
  @apply mb-16 text-neutral-500;
}

.copilot_drawer .c-bitts-empty-state-title {
  @apply text-neutral-900;
}

.copilot_drawer .c-bitts-empty-state-large-border {
  @apply w-auto;
}

.c-copilot-settings-drawer
  .ant-drawer-wrapper-body
  .ant-drawer-body
  div
  div
  header
  div
  .bitts-drawer__header-title {
  @apply font-bold;
}
</style>
