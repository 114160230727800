<template>
  <BittsModalTwo
    name="report-bad-match-modal"
    :loading="!ready"
    title="Report Issue with Matching"
    primary-button-text="Send Report"
    description="This information will be used to improve our matching algorithm. False matches you've reported will disappear over time. Until then, we will flag them in the UI for you and your partner."
    :action-loading="submitting"
    @action="submit"
  >
    <template #default>
      <div class="flex items-center">
        <BittsAvatar
          :fallback-image-type="recordType"
          :alt-text-from-parent="recordName"
          :image-source="imageSource"
          size="medium"
          shape="square"
        />
        <div class="ml-16">
          <div class="c-report-bad-match-modal__org-name">
            {{ recordName }}
          </div>
          <div v-if="recordDomain" class="c-report-bad-match-modal__org-domain">
            {{ recordDomain }}
          </div>
        </div>
      </div>
      <VuelidateWrapper
        class="mt-16"
        property="affectedPartners"
        :errors="v$.$errors"
      >
        <BittsMultiselect
          v-model="affectedPartners"
          :dropdown-match-select-width="false"
          form-label="Which partner(s) should not be listed as a match?"
          mode="multiple"
          :options="partners"
          option-type="company"
          placeholder="Select Partners"
        />
      </VuelidateWrapper>
      <VuelidateWrapper
        class="my-24"
        property="selectedIssues"
        :errors="v$.$errors"
      >
        <BittsCheckboxGroup form-label="What best describes the problem?">
          <BittsCheckbox
            v-for="issueType in matchIssueTypes"
            :key="issueType.id"
            v-model="issueType.checked"
            :label="issueType.description"
            class="c-report-bad-match-modal__issue-text"
            @input="(checked) => handleCheck(issueType, checked)"
          />
        </BittsCheckboxGroup>
      </VuelidateWrapper>
      <BittsTextArea
        v-model="additionalComments"
        name="additional-comments"
        placeholder="Anything else you think would be helpful to us!"
        :form-label="{
          title: 'Additional Comments',
          secondaryText: 'Optional',
        }"
      />
    </template>
  </BittsModalTwo>
</template>

<script>
import {
  BittsAvatar,
  BittsCheckbox,
  BittsCheckboxGroup,
  BittsModalTwo,
  BittsMultiselect,
  BittsTextArea,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import { mapActions, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

import { MDM_PROPERTIES } from '@/constants/mdm';
import { useFlashesStore, useMatchIssuesStore } from '@/stores';
import urls from '@/urls';
import { REGEX_URL, getMdmField, trim } from '@/utils';

export default {
  name: 'ReportMatchIssueModal',
  components: {
    BittsModalTwo,
    BittsAvatar,
    BittsCheckbox,
    BittsCheckboxGroup,
    BittsTextArea,
    BittsMultiselect,
    VuelidateWrapper,
  },
  props: {
    partnerPopulations: {
      type: Array,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
    sourceId: {
      type: [Number, String],
      required: true,
    },
    sourcePrimaryKey: {
      type: String,
      required: true,
    },
  },
  emits: ['closed'],
  setup() {
    const affectedPartners = ref([]);
    const matchIssuesStore = useMatchIssuesStore();
    const { matchIssueTypes, ready } = storeToRefs(matchIssuesStore);

    const selectedIssues = computed(() =>
      matchIssueTypes.value.filter((t) => t.checked),
    );

    const rules = {
      affectedPartners: {
        required: helpers.withMessage(
          'Please select at least one partner',
          required,
        ),
      },
      selectedIssues: {
        required: helpers.withMessage(
          'Please select at least one reason',
          required,
        ),
      },
    };

    const v$ = useVuelidate(rules, { affectedPartners, selectedIssues });

    return {
      v$,
      affectedPartners,
      matchIssueTypes,
      ready,
      selectedIssues,
    };
  },
  data() {
    return {
      additionalComments: '',
      bittsTest: false,
      submitting: false,
    };
  },
  computed: {
    imageSource() {
      return this.recordType === 'company'
        ? `https://logo.clearbit.com/${this.recordDomain}`
        : '';
    },
    partners() {
      const partnerIds = new Set(this.partnerPopulations.map((p) => p.org.id));
      return [...partnerIds].map((id) => {
        const partner = this.partnerPopulations.find(
          (p) => id === p.org.id,
        ).org;
        return { ...partner, value: id, label: partner.name };
      });
    },
    recordType() {
      return this.source.mdm_type === 'account' ? 'company' : 'person';
    },
    recordName() {
      return this.recordType === 'company'
        ? getMdmField(this.source.elements.account, MDM_PROPERTIES.account.NAME)
        : getMdmField(this.source.elements.lead, 'person_email');
    },
    recordDomain() {
      if (this.recordType === 'company') {
        const rawRecordDomain = trim(
          getMdmField(this.source.elements.account, 'company_website'),
        );
        return REGEX_URL.test(rawRecordDomain)
          ? rawRecordDomain.trim().replace(/https?:\/\//, '')
          : rawRecordDomain;
      }
      return null;
    },
    recordEmail() {
      if (this.recordType === 'person') {
        return getMdmField(this.source.elements.lead, 'person_email');
      }
      return null;
    },
  },
  methods: {
    ...mapActions(useMatchIssuesStore, [
      'fetchMatchIssuesBySourceIdAndRecordId',
    ]),
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    handleCheck(type, isChecked) {
      this.matchIssueTypes = this.matchIssueTypes.map((issueType) => {
        if (issueType === type) return { ...issueType, checked: isChecked };
        return { ...issueType };
      });
    },
    async submit() {
      this.v$.$touch();
      if (!this.v$.$error) {
        this.submitting = true;

        try {
          const payload = {
            master_id: this.sourcePrimaryKey,
            source_id: this.sourceId,
            partner_org_ids: this.affectedPartners,
            issue_type_ids: this.selectedIssues.map((i) => i.id),
            comments: this.additionalComments,
          };
          await axios.post(urls.matchingIssues.post, payload);
          this.fetchMatchIssuesBySourceIdAndRecordId({
            sourceId: this.sourceId,
            recordId: this.sourcePrimaryKey,
          });
          this.addSuccessFlash({ message: 'Matching Feedback Submitted' });
        } catch (_$ex) {
          this.addErrorFlash({
            message: 'Matching Feedback Not Submitted',
            description: 'Please try again',
          });
        } finally {
          this.submitting = false;
          this.v$.$reset();
          this.$emit('closed');
        }
      }
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-report-bad-match-modal__org-name {
  @apply text-base;
}

.c-report-bad-match-modal__org-domain {
  @apply text-sm text-neutral-500 leading-6;
}

.c-report-bad-match-modal__issue-text:deep(.bitts-checkbox-label) {
  @apply text-neutral-800;
}
</style>
