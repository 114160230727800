import axios, { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { FAVORITE_TYPES } from '@/constants/user_favorites';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores/FlashesStore';
import { initStore } from '@/stores/store-utils';
import { UserFavorite } from '@/types/user_favorites';
import urls from '@/urls';

export const useUserFavoritesStore = defineStore('UserFavorites', () => {
  const favorites = ref<UserFavorite[]>([]);
  const flashesStore = useFlashesStore();

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const { data } = await axios.get(urls.users.favorites.get);
    if (data) favorites.value = data;
  });

  function getFavoriteByResourceUuid(resourceUuid: string) {
    return favorites.value.find(
      ({ resource_uuid: uuid }) => uuid === resourceUuid,
    );
  }

  async function addFavorite({
    favoriteType,
    resourceUuid,
  }: {
    favoriteType: string;
    resourceUuid: string;
  }) {
    try {
      const { data } = await axios.post(urls.users.favorites.post, {
        favorite_type: favoriteType,
        resource_uuid: resourceUuid,
      });
      favorites.value = [...favorites.value, data];
    } catch (err) {
      captureException(err);
      flashesStore.addUnhandledError(err as Error | AxiosError);
    }
  }

  async function removeFavorite({ id }: { id: string }) {
    try {
      await axios.delete(urls.users.favorites.delete(id));
      favorites.value = favorites.value.filter(
        ({ id: favoriteId }) => favoriteId !== id,
      );
    } catch (err) {
      captureException(err);
      flashesStore.addUnhandledError(err as Error | AxiosError);
    }
  }

  const partnerFavorites = computed(() =>
    favorites.value.filter(
      ({ favorite_type: favoriteType }) =>
        favoriteType === FAVORITE_TYPES.PARTNER,
    ),
  );

  refresh({ useCache: true });

  return {
    favorites,
    error,
    ready,
    readySync,
    running,
    partnerFavorites,
    addFavorite,
    removeFavorite,
    refreshUserFavoritesStore: refresh,
    getFavoriteByResourceUuid,
  };
});
