import axios from 'axios';
import { uniqBy } from 'lodash';
import { ref } from 'vue';

import useAuth from '@/composables/useAuth';
import urls from '@/urls';

export default function useClearbit(isLoggedIn = true) {
  const searching = ref(false);
  const orgsWithIcons = ref([]);
  const { currentOrg } = useAuth();

  async function getClearbitCompanies(query) {
    if (!query) {
      searching.value = false;
      return [];
    }

    try {
      const { data } = await axios.get(urls.clearbitAutoComplete(query));
      return Array.isArray(data)
        ? data.map((org) => ({ ...org, label: org.name, value: org.domain }))
        : [];
    } catch (_err) {
      return [];
    }
  }

  async function getCrossbeamCompanies(query) {
    const { data } = await axios.get(urls.partnerSuggestions, {
      params: {
        query,
        is_partner_invite_context: true,
      },
    });

    return data.items.map((org) => ({
      ...org,
      label: org.name,
      value: org.uuid,
    }));
  }

  async function searchIcons(query) {
    searching.value = true;
    const clearbitCompanies = await getClearbitCompanies(query);
    const crossbeamCompanies = isLoggedIn
      ? await getCrossbeamCompanies(query)
      : [];

    orgsWithIcons.value = uniqBy(
      [...crossbeamCompanies, ...clearbitCompanies],
      (company) => company.domain,
    )
      .filter((org) => {
        if (!isLoggedIn) return true;
        return org.domain !== currentOrg.value?.domain;
      })
      .map((org) => ({
        ...org,
        value: org.uuid || org.domain,
        label: `${org.name}`,
      }));
    searching.value = false;
    return orgsWithIcons.value;
  }

  return {
    orgsWithIcons,
    searchIcons,
    searching,
  };
}
