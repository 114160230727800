import appConfig from '@/config';

const injectScripts = () => {
  if (appConfig.enableGoogleTags) {
    const gTagsScriptElement = document.createElement('script');
    gTagsScriptElement.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-5MS7DBB');
    `;
    document.head.append(gTagsScriptElement);

    const gTagsIframe = document.createElement('noscript');
    gTagsIframe.innerHTML =
      '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MS7DBB" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
    document.body.prepend(gTagsIframe);
  }

  if (appConfig.statusPageEmbedUrl) {
    const statusPageScriptElement = document.createElement('script');
    statusPageScriptElement.src =
      'https://ngfln6n04fyr.statuspage.io/embed/script.js';
    document.body.append(statusPageScriptElement);
  }
};

export default injectScripts;
