<template>
  <div class="c-pop-to-pop__override-container">
    <SharingSetting
      :data-share="{
        ...setting.dataShare,
        visibility: SHARING,
        sourceFields: setting.sourceFields,
      }"
      :is-own="direction === OUTGOING"
    />
    &nbsp;
    <span>
      <span class="text-neutral-500 pl-2">
        <span>{{ sharingSettingText }} </span>&hairsp;
      </span>
      <span :class="hasPopulationRefresh ? 'font-bold' : 'text-neutral-500'">
        {{ populationName }}
      </span>
    </span>
  </div>
</template>

<script>
import SharingSetting from '@/components/populations/SharingSetting.vue';

import { SHARING } from '@/constants/data_shares';

const INCOMING = 'incoming';
const OUTGOING = 'outgoing';

export default {
  name: 'PopulationToPopulationSharingSetting',
  components: { SharingSetting },
  props: {
    hasPopulationRefresh: {
      type: Boolean,
      default: false,
    },
    setting: {
      type: Object,
      required: true,
    },
    partnerName: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: OUTGOING,
      validator(value) {
        return [INCOMING, OUTGOING].includes(value);
      },
    },
  },
  data() {
    return { OUTGOING, SHARING };
  },
  computed: {
    isIncomingSetting() {
      return this.direction === INCOMING;
    },
    ownershipText() {
      if (this.isIncomingSetting) {
        return this.hasPopulationRefresh ? 'your' : 'my';
      }
      return this.partnerName.concat(
        this.partnerName.endsWith('s') ? "'" : "'s",
      );
    },
    sharingSettingText() {
      if (this.hasPopulationRefresh)
        return `when overlapping with ${this.ownershipText}`;
      return 'when';
    },
    populationName() {
      if (this.isIncomingSetting) return this.setting.population.name;
      return this.setting.partnerPopulation.name;
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-pop-to-pop__override-container {
  @apply flex items-center text-brand-navy py-4 text-sm;
}
</style>
