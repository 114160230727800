<template>
  <div :class="titleBarClasses" class="c-amm-org-title-bar">
    <div class="c-amm-org-title-bar__inner">
      <template v-if="direction === 'horizontal'">
        <BittsAvatar
          v-if="org"
          :org="org"
          :show-initials="true"
          size="x-small"
          class="c-amm-org-title-bar__org-avatar"
        />
        <FontAwesomeIcon
          v-else
          :icon="['fak', 'partner']"
          :style="{ height: '16x', width: '16px' }"
          class="text-neutral-accent"
        />
        <div class="c-amm-org-title-bar__org-name flex-1">
          {{ org?.name || 'Partner' }}
        </div>
        <div class="horizontal-pagination">
          <BittsButton
            size="x-small"
            type="neutral"
            variant="outline"
            :center-icon="['fak', 'chevron-left']"
            :disabled="columnDecrementDisabled"
            @click="onDecrementColumnClick"
          />
          <BittsButton
            size="x-small"
            type="neutral"
            variant="outline"
            :disabled="columnIncrementDisabled"
            :center-icon="['fak', 'chevron-right']"
            class="ml-8"
            @click="onIncrementColumnClick"
          />
        </div>
      </template>
      <template v-else>
        <div class="c-amm-org-title-bar__org-name mb-8 ml-0 pr-12">
          <div
            v-if="numOwnPops >= INCLUDE_NAME_MIN"
            :class="verticalNameWrapperClass"
          >
            {{ org.name }}
          </div>
          <span
            v-if="numOwnPops > INCLUDE_YOU_MIN"
            class="font-normal mt-4 text-neutral-text-weak"
          >
            (You)
          </span>
        </div>
        <BittsAvatar
          v-if="numOwnPops >= INCLUDE_AVATAR_MIN"
          :org="org"
          :show-initials="true"
          size="x-small"
          :class="{
            'c-amm-org-title-bar__org-avatar--two-pops':
              numOwnPops === INCLUDE_AVATAR_MIN,
          }"
          class="c-amm-org-title-bar__org-avatar"
        />
        <div
          :class="{
            'vertical-pagination-short': numOwnPops <= INCLUDE_YOU_MIN,
          }"
          class="vertical-pagination"
        >
          <BittsButton
            size="x-small"
            type="neutral"
            variant="outline"
            :center-icon="['fas', 'chevron-up']"
            :disabled="rowDecrementDisabled"
            @click="onDecrementRowClick"
          />
          <BittsButton
            size="x-small"
            type="neutral"
            variant="outline"
            :center-icon="['fak', 'chevron-down']"
            :disabled="rowIncrementDisabled"
            class="mt-8"
            @click="onIncrementRowClick"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { defineComponent } from 'vue';

const INCLUDE_NAME_MIN = 3;
const INCLUDE_AVATAR_MIN = 2;
const INCLUDE_YOU_MIN = 1;

export default defineComponent({
  name: 'AMMOrgTitleBar',
  components: {
    BittsAvatar,
    BittsButton,
  },
  props: {
    org: {
      type: Object,
      default: null,
    },
    direction: {
      type: String,
      default: 'horizontal',
      validator(value) {
        return ['horizontal', 'vertical'].includes(value);
      },
    },
    columnDecrementDisabled: {
      type: Boolean,
      default: false,
    },
    columnIncrementDisabled: {
      type: Boolean,
      default: false,
    },
    rowDecrementDisabled: {
      type: Boolean,
      default: false,
    },
    rowIncrementDisabled: {
      type: Boolean,
      default: false,
    },
    numOwnPops: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'increment-column',
    'decrement-column',
    'increment-row',
    'decrement-row',
  ],
  data() {
    return {
      INCLUDE_NAME_MIN,
      INCLUDE_AVATAR_MIN,
      INCLUDE_YOU_MIN,
    };
  },
  computed: {
    titleBarClasses() {
      return this.direction;
    },
    verticalNameWrapperClass() {
      return {
        'c-amm-org-title-bar__org-name-wrapper': true,
        'max-h-[188px]': !this.rowIncrementDisabled,
        'max-h-[132px]': this.numOwnPops === 4,
        'max-h-[68px]': this.numOwnPops === INCLUDE_NAME_MIN,
      };
    },
  },
  methods: {
    onIncrementColumnClick() {
      this.$emit('increment-column');
    },
    onDecrementColumnClick() {
      this.$emit('decrement-column');
    },
    onIncrementRowClick() {
      this.$emit('increment-row');
    },
    onDecrementRowClick() {
      this.$emit('decrement-row');
    },
  },
});
</script>

<style lang="pcss">
.c-amm-org-title-bar {
  @apply flex w-full border-neutral-border bg-neutral-background-weak;

  .c-amm-org-title-bar__org-name {
    @apply ml-8 text-sm font-bold;
  }

  &.horizontal {
    height: 40px;
    @apply rounded-tr-md border-b py-8 px-16;

    .c-amm-org-title-bar__inner {
      @apply flex items-center w-full;
    }
  }

  &.vertical {
    width: 40px;
    height: auto;
    position: relative;
    @apply border-r rounded-bl-bts-lg;

    .c-amm-org-title-bar__inner {
      width: 100%;
      height: 100%;
      padding: 8px 0;
    }

    .c-amm-org-title-bar__org-avatar {
      @apply w-16 pt-24 relative;
      transform: rotate(270deg);
      bottom: 12px;
    }

    .c-amm-org-title-bar__org-avatar--two-pops {
      bottom: 16px;
    }

    .c-amm-org-title-bar__org-name {
      display: flex;
      justify-content: center;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      text-align: right;
      white-space: nowrap;
    }
    .c-amm-org-title-bar__org-name-wrapper {
      @apply max-h-[204px] whitespace-nowrap text-ellipsis overflow-hidden;
    }
  }

  .horizontal-pagination {
    @apply flex;
  }

  .vertical-pagination {
    @apply flex flex-col px-4 absolute items-center w-full;
    bottom: 16px;
    &.vertical-pagination-short {
      bottom: 8px;
    }
  }

  .paginator-btn {
    @apply text-sm border border-solid border-neutral-300 rounded-bts-sm p-4
      text-neutral-400 flex flex-row justify-center items-center mx-3 font-bold outline-none;
    height: 24px;
  }
}
</style>
