// grid state consts
export const TOTAL_OVERLAPS = 'Total Overlaps';
export const POTENTIAL_REVENUE = 'Potential Revenue';

export const AMM_VIEW_OPTIONS = [
  { label: 'Total Overlaps', value: 'Total Overlaps' },
  { label: 'Potential Revenue', value: 'Potential Revenue' },
];

// alert/empty states
export const NOT_CONFIGURED = 'not-configured';

export const OWN_NO_CRM = 'own-no-crm';
export const OWN_NO_OPPS_DATA = 'own-no-opps-data';
export const OWN_NOT_SHARING = 'own-not-sharing';
export const MISSING_REQUIRED_FIELDS = 'missing-required-fields';
export const NO_PARTNERS_CUSTOMERS_POPULATION =
  'no-partners-customers-population';
export const OWN_NO_CUSTOMERS_POPULATION = 'own-no-customers-population';

export const PARTNER_NOT_SHARING = 'partner-not-sharing';
export const PARTNER_OVERLAPS_ONLY = 'partner-overlaps-only';

export const POSSIBLE_ALERT_STATES = [
  OWN_NOT_SHARING,
  OWN_NO_CRM,
  PARTNER_NOT_SHARING,
  PARTNER_OVERLAPS_ONLY,
  OWN_NO_OPPS_DATA,
  NOT_CONFIGURED,
  NO_PARTNERS_CUSTOMERS_POPULATION,
  MISSING_REQUIRED_FIELDS,
  OWN_NO_CUSTOMERS_POPULATION,
];

export const EMPTY_ALERT_STATES = [OWN_NOT_SHARING, PARTNER_NOT_SHARING];

// potential revenue alert states
export const OK = 'OK';
export const NOT_YET_CALCULATED = 'NOT_YET_CALCULATED';
export const INSUFFICIENT_BILLING_TIER = 'INSUFFICIENT_BILLING_TIER';
export const INELIGIBLE_HIDDEN_POPULATION = 'INELIGIBLE_HIDDEN_POPULATION';
export const NO_DEAL_CAPABLE_FEED = 'NO_DEAL_CAPABLE_FEED';
export const NO_DEAL_SOURCE = 'NO_DEAL_SOURCE';
export const NO_DEAL_CLOSED_FIELD = 'NO_DEAL_CLOSED_FIELD';
export const NO_DEAL_AMOUNT_FIELD = 'NO_DEAL_AMOUNT_FIELD';
export const PARTNER_POPULATION_NOT_SHARED = 'PARTNER_POPULATION_NOT_SHARED';

export const NO_ALERT_STATE_CODES = [
  OK,
  NOT_YET_CALCULATED,
  INSUFFICIENT_BILLING_TIER,
];
export const NO_ALERT_STATE_CODES_REPORT_BUILDER = [
  ...NO_ALERT_STATE_CODES,
  PARTNER_POPULATION_NOT_SHARED,
];

export const ERROR_TO_ALERT_STATE_LOOKUP = {
  INELIGIBLE_HIDDEN_POPULATION: OWN_NOT_SHARING,
  NO_DEAL_AMOUNT_FIELD: OWN_NO_OPPS_DATA,
  NO_DEAL_SOURCE: OWN_NO_OPPS_DATA,
  NO_DEAL_CLOSED_FIELD: OWN_NO_OPPS_DATA,
  NO_DEAL_CAPABLE_FEED: OWN_NO_CRM,
  PARTNER_POPULATION_NOT_SHARED: PARTNER_OVERLAPS_ONLY,
};

export const OVERLAP_DATA_STATE_KEY = 'overlaps-state';

export const AMM_ALERT_DATA = {
  [NOT_CONFIGURED]: {
    message: () =>
      'Configure populations on the settings page to gain access to these revenue metrics',
    description:
      'Select at least 1 population in order for us to generate these numbers',
    buttonText: 'Configure Data',
    route: () => {
      return { name: 'organizationSettings' };
    },
  },
  [OWN_NOT_SHARING]: {
    message: (name) => `None of your Populations are shared with ${name}`,
    description:
      'You need to have at least 1 Population set to sharing data to generate these numbers',
    buttonText: 'View Sharing Settings',
    route: ({ partnerOrgId }) => {
      return {
        name: 'partner_details',
        params: { partner_org_id: partnerOrgId },
        query: { tab: 'sharing' },
      };
    },
    tab: 'sharing',
  },
  [PARTNER_NOT_SHARING]: {
    message: (name) => {
      return `${name} isn’t sharing any Populations with you`;
    },
    description:
      'They need to have at least 1 Population set to sharing data to generate these numbers',
    type: 'warning',
    icon: 'fa-triangle-exclamation',
    buttonText: 'View Shared With You',
    route: ({ partnerOrgId }) => {
      return {
        name: 'partner_details',
        params: { partner_org_id: partnerOrgId },
        query: { tab: 'shared_with_you' },
      };
    },
    tab: 'shared_with_you',
  },
  [OWN_NO_CRM]: {
    message: () =>
      'Add a CRM data source to gain access to performance metrics',
    description:
      'We need access to opportunity data to be able to generate these numbers',
    itlyDataSync: true,
    buttonText: 'Connect CRM',
    route: () => {
      return { name: 'data-sources' };
    },
  },
  [PARTNER_OVERLAPS_ONLY]: {
    message: (name) =>
      `${name} isn’t sharing data from any Populations with you`,
    description:
      'They need to have at least 1 Population set to sharing data to generate these numbers',
    buttonText: 'View Shared With You',
    route: ({ partnerOrgId }) => {
      return {
        name: 'partner_details',
        params: { partner_org_id: partnerOrgId },
        query: { tab: 'shared_with_you' },
      };
    },
    tab: 'shared_with_you',
  },
  [OWN_NO_OPPS_DATA]: {
    message: () =>
      'Add the Co-Sell preset to gain access to performance metrics',
    description:
      'We need access these specific fields to generate these numbers',
    itlyDataSync: true,
    buttonText: 'Update Data Sync',
    route: ({ feedId, name }) => ({
      name,
      params: { id: feedId },
      query: { customizing: true },
    }),
  },
  [MISSING_REQUIRED_FIELDS]: {
    message: () =>
      'Sync opportunity data to gain access to performance metrics',
    buttonText: 'Finish Setup',
    route: ({ feedId, name }) => ({
      name,
      params: { id: feedId },
      query: { customizing: true },
    }),
  },
  [NO_PARTNERS_CUSTOMERS_POPULATION]: {
    message: (name) =>
      `You need ${name}’s Customers Population to see these performance metrics`,
    description:
      'Reach out to them directly to have them create or share their Customers Population to generate these numbers.',
  },
  [OWN_NO_CUSTOMERS_POPULATION]: {
    message: () =>
      'Finish setting up your Standard Populations to see your performance metrics',
    description: 'We need your Customers Population to generate these numbers',
    buttonText: 'Finish setup',
    route: () => ({ name: 'populations', hash: '#subtitle' }),
  },
};

export const LIVE_PARTNER_DETAIL_TABS = [
  {
    name: 'Overview',
    icon: ['fak', 'partner'],
    value: 'overlaps',
  },
  {
    name: 'Shared Lists',
    icon: ['far', 'table-list'],
    value: 'shared_lists',
  },
  {
    name: 'Shared with You',
    icon: ['fak', 'population'],
    value: 'shared_with_you',
  },
  {
    name: 'Sharing Settings',
    icon: ['fak', 'population'],
    value: 'sharing',
  },
];

export const OFFLINE_PARTNER_DETAIL_TABS = [
  {
    name: 'Overview',
    icon: ['fak', 'team'],
    value: 'overlaps',
  },
  {
    name: 'Partner Data',
    icon: ['fak', 'data-source'],
    value: 'data',
  },
  {
    name: 'Sharing Settings',
    icon: ['fak', 'population'],
    value: 'sharing',
  },
];
