<template>
  <BittsModal
    :loading="loading"
    name="dynamics-pick-crm"
    :show-buttons="false"
    :visible="true"
    @closed="modalClosed"
  >
    <template #content>
      <div class="flex w-full justify-center mb-18 mt-[-36px]">
        <BittsSvg :svg="MD_DATA_SOURCE_TYPE + 'Icon'" />
      </div>
      <h2 class="c-microsoft-dynamics-pick-instance__title">
        Choose your instance
      </h2>
      <div class="c-microsoft-dynamics-pick-instance">
        Select which instance of Microsoft Dynamics you’d like us to have access
        to. We will customize this sync in the next steps.
      </div>
      <BittsRadioGroupCards
        :options="options"
        :initial-value="pickedInstance"
        @change="pickInstance"
      />
      <BittsButton
        :disabled="pickedInstance === null"
        data-testid="select-dynamics-instance-button"
        class="mt-24 w-full"
        text="Connect"
        @click="submit"
      />
    </template>
  </BittsModal>
</template>

<script>
import {
  BittsButton,
  BittsModal,
  BittsRadioGroupCards,
  BittsSvg,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions } from 'pinia';

import { MD_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'DynamicsPickCrm',
  components: {
    BittsModal,
    BittsRadioGroupCards,
    BittsButton,
    BittsSvg,
  },
  props: {
    instances: {
      required: true,
      type: Array,
    },
  },
  setup() {
    useHead({
      title: 'Microsoft Dynamics - Crossbeam',
    });
  },
  data() {
    return {
      pickedInstance: null,
      loading: true,
      MD_DATA_SOURCE_TYPE,
    };
  },
  computed: {
    options() {
      return this.instances.map((instance) => ({
        value: instance.ApiUrl,
        label: instance.FriendlyName,
        description: instance.ApiUrl,
      }));
    },
  },
  created() {
    /* Automatically pick the first instance if there's only one */
    this.pickedInstance = this.options[0].value;
    if (this.instances.length === 1) {
      this.submit();
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addErrorFlash']),
    pickInstance(apiUrl) {
      this.pickedInstance = this.options.find(
        (option) => option.value === apiUrl,
      ).value;
    },
    async modalClosed() {
      await this.$router.push({ name: 'data-sources' });
    },
    async submit() {
      try {
        const params = {
          integration_type: MD_DATA_SOURCE_TYPE,
          instance: this.instances.find(
            (i) => i.ApiUrl === this.pickedInstance,
          ),
          settings_usecase: 'custom',
        };

        const resp = await axios.post(urls.oauth.authorize, { params });
        window.open(resp.data.authorization_url, '_self');
      } catch (err) {
        captureException(err);
        this.addErrorFlash({
          message: 'Could not connect to instance.',
        });
        this.modalClosed();
      }
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-microsoft-dynamics-pick-instance {
  @apply text-neutral-600 mb-16 text-center;
}
</style>

<style lang="pcss">
.c-microsoft-dynamics-pick-instance
  .bitts-radio-group-cards
  .ant-radio-wrapper {
  @apply mb-0;
}

.c-microsoft-dynamics-pick-instance__title {
  @apply text-neutral-text-strong text-lg font-bold text-center mb-16;
}
</style>
