<template>
  <BittsCallout
    v-if="overSeatLimit"
    :title="V4_BILLING_COPY.seatSummary.outOfSeats.title"
    :subtitle="V4_BILLING_COPY.seatSummary.outOfSeats.description"
    data-testid="enterprise-over-seats-callout"
    size="small"
    type="neutral"
    class="mb-16"
    action-text="Talk to sales"
    :action-icon="['fak', 'upgrade']"
    action-button-type="upsell"
    :icon="['fak', 'contract']"
    @bitts-callout-action="
      handleBillingInteraction({
        cta: PLAN_AND_BILLING_CTAS.OVER_SEAT_LIMIT,
        event_site: EVENT_SITES.ENTERPRISE_OVER_SEAT_CALLOUT,
        talkToSalesReason: 'Supernode account over seat limit',
      })
    "
  />
  <BittsCallout
    v-else-if="atSeatLimit"
    :title="V4_BILLING_COPY.seatSummary.atSeatLimit.title"
    :subtitle="V4_BILLING_COPY.seatSummary.atSeatLimit.description"
    data-testid="enterprise-max-seats-callout"
    action-text="Talk to sales"
    :action-icon="['fak', 'upgrade']"
    action-button-type="upsell"
    size="small"
    type="neutral"
    class="mb-16"
    :icon="['fak', 'contract']"
    @bitts-callout-action="
      handleBillingInteraction({
        cta: PLAN_AND_BILLING_CTAS.AT_SEAT_LIMIT,
        event_site: EVENT_SITES.ENTERPRISE_AT_SEAT_CALLOUT,
        talkToSalesReason: 'Supernode account at seat limit',
      })
    "
  />
</template>

<script setup>
import { BittsCallout } from '@crossbeam/bitts';
import { EVENT_SITES, PLAN_AND_BILLING_CTAS } from '@crossbeam/itly';

import useBilling from '@/composables/useBilling';
import useSeats from '@/composables/useSeats';
import { V4_BILLING_COPY } from '@/constants/billing';

const { handleBillingInteraction } = useBilling();

const { overSeatLimit, atSeatLimit } = useSeats();
</script>
