import { ls } from './local_storage';

/**
 * Used to set minimalView in local storage which is used to strip out functionality
 * mainly for iframe'ing the app in third party sites
 */
export default function initMinimalView() {
  const query = new URLSearchParams(window.location.search);
  const isInIframe = window.top !== window.self;
  const isMinimal = query.has('minimal');

  ls.minimalView.set(isMinimal && isInIframe);
}
