<template>
  <OAuthApplicationCreate />
</template>
<script setup>
import { useHead } from '@unhead/vue';

import OAuthApplicationCreate from '@/components/OAuthApplicationCreate.vue';

useHead({ title: 'Integrations - Create New Integration - Crossbeam' });
</script>
