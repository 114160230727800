import { computed, ref, watch } from 'vue';

import usePolling from '@/composables/usePolling';
import { SOURCE_STATUS_MAP } from '@/constants/data_sources';

/* useSourcesSyncing polls a list of sources when they go into a deleting or pending state */
export default function useSourcesSyncing(sources, refreshFn) {
  const pollErrored = ref(false);
  const needsSourceRefresh = computed(() =>
    sources.value.some((s) =>
      [...SOURCE_STATUS_MAP.process, ...SOURCE_STATUS_MAP.deleting].includes(
        s.status,
      ),
    ),
  );
  const dataPollFn = usePolling({
    async poller() {
      await refreshFn();
    },
    onError() {
      pollErrored.value = true;
    },
    shouldComplete() {
      return !needsSourceRefresh.value || pollErrored.value;
    },
  });

  if (needsSourceRefresh.value) dataPollFn();
  watch(needsSourceRefresh, (newValue, oldValue) => {
    if (!oldValue && newValue) dataPollFn();
  });
}
