<template>
  <ExpandableCard class="c-account-owners-card">
    <template #header>
      <CardHeader :source="source" title="Account Owners" />
    </template>
    <template #collapsed>
      <div key="collapsed" class="c-card__content">
        <div class="c-account-owners-records">
          <TransitionGroup name="fade">
            <div
              v-for="(item, index) in source.items"
              :key="`account_owner_${index}`"
              class="c-account-owners-records__row"
            >
              <div class="c-account-owners-records__row__person">
                <div class="c-account-owners-records__row__person__header">
                  <BittsAvatarPip
                    :org="getOrg(item)"
                    :user="getUser(index)"
                    :is-user-main-avatar="true"
                    :show-initials="true"
                    :include-hover-text="true"
                    size="medium"
                    shape="square"
                  />
                  <div class="c-account-owners-records__row__person__label">
                    <span
                      class="c-account-owners-records__row__person__label__name"
                    >
                      {{ getUserName(index) }}
                    </span>
                  </div>
                </div>
                <div class="c-account-owners-records__row__person__details">
                  <div
                    class="c-account-owners-records__row__person__details__label"
                  >
                    Email
                  </div>
                  <div
                    class="c-account-owners-records__row__person__details__value"
                  >
                    <a
                      :href="`mailto:${itemInfoLookup[index][EMAIL]}`"
                      class="c-account-owners-records__row__person__details__value__link"
                      >{{ itemInfoLookup[index][EMAIL] }}</a
                    >
                  </div>
                  <div
                    v-if="itemInfoLookup[index][PHONE]"
                    class="c-account-owners-records__row__person__details__label"
                  >
                    Phone
                  </div>
                  <div
                    v-if="itemInfoLookup[index][PHONE]"
                    class="c-account-owners-records__row__person__details__value"
                  >
                    {{ itemInfoLookup[index][PHONE] }}
                  </div>
                </div>
              </div>
            </div>
          </TransitionGroup>
        </div>
      </div>
    </template>
  </ExpandableCard>
</template>

<script>
import { BittsAvatarPip } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import useAuth from '@/composables/useAuth';
import { useDataSharesStore, usePartnersStore } from '@/stores';

import ExpandableCard from '../ExpandableCard.vue';

import CardHeader from './CardHeader.vue';

const FIRST_NAME = 'person_first_name';
const LAST_NAME = 'person_last_name';
const NAME = 'person_name';
const PHONE = 'person_phone';
const EMAIL = 'person_email';

export default {
  name: 'AccountOwnersCard',
  components: {
    ExpandableCard,
    CardHeader,
    BittsAvatarPip,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return { currentOrg };
  },
  data() {
    return {
      EMAIL,
      PHONE,
    };
  },
  computed: {
    itemInfoLookup() {
      const itemLookup = {};
      this.source.items.forEach((item, i) => {
        itemLookup[i] = {};
        item.fields.forEach((field) => {
          if (field.mdm_property) {
            itemLookup[i][field.mdm_property] = field.value;
          }
        });
      });
      return itemLookup;
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(useDataSharesStore, ['getDataShareById']),
    getOrg(item) {
      if (item.is_ours) {
        return this.currentOrg;
      }
      const share = this.getDataShareById(
        // There should only be one of these.
        item.data_share_ids[0],
      );
      return this.getPartnerOrgById(share.partner_org_id);
    },
    getUser(index) {
      return {
        email: this.itemInfoLookup[index][EMAIL],
        name: this.itemInfoLookup[index][NAME],
        first_name: this.itemInfoLookup[index][FIRST_NAME],
        last_name: this.itemInfoLookup[index][LAST_NAME],
      };
    },
    getUserName(index) {
      const name = this.itemInfoLookup[index][NAME];
      const fname = this.itemInfoLookup[index][FIRST_NAME];
      const lname = this.itemInfoLookup[index][LAST_NAME];
      const displayName = fname && lname ? `${fname} ${lname}` : name;
      return displayName || '';
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-account-owners-card {
  margin-top: 32px;
}

.c-card__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.c-card__content__grid-and-icons {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
}

.c-card__content__icons {
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
}

.c-account-owners-records__row {
  border-bottom: 1px solid var(--grey6);
  &:last-of-type {
    border-bottom: 0px;
  }
}

.c-account-owners-records__row__person {
  margin: 12px;
}

.c-account-owners-records__row__person__label {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
}

.c-account-owners-records__row__person__header {
  display: flex;
  width: 100%;
}

.c-account-owners-records__row__person__details {
  display: grid;
  grid-template-columns: [label] 50px [value] 1fr;
  width: 100%;
}

.c-account-owners-records__row__person__details__label {
  margin: 6px 0px;
  font-size: 12px;
  color: var(--grey1);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.c-account-owners-records__row__person__details__value {
  margin: 6px 0px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.c-account-owners-records__row__person__details__value__link {
  @apply text-brand-blue no-underline hover:opacity-70;
}

.c-account-owners-records__row__person__label__name {
  @apply text-brand-navy;
  font-size: 14px;
}
</style>
