<template>
  <BittsAlert
    class="mt-12"
    :message="errorTitle"
    :description="errorMessage"
    color="error"
  />
</template>

<script>
import { BittsAlert } from '@crossbeam/bitts';

import Humanize from 'humanize-plus';
import { DateTime } from 'luxon';

import {
  CONNECTION_CHECK_ERROR_STATUS,
  RECORD_LIMIT_EXCEEDED_STATUS,
  SOURCE_FAILED_STATUS,
} from '@/constants/data_sources';

export default {
  name: 'FeedListItemError',
  components: {
    BittsAlert,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dataSourceUser() {
      return this.feed.external_user_email || this.feed.connected_by;
    },
    errorTitle() {
      const limit = Humanize.intComma(this.feed.max_record_count);
      switch (this.feed.status) {
        case RECORD_LIMIT_EXCEEDED_STATUS:
          return `Your ${this.feed.integration.friendly_name} data source has stopped syncing because you are over the ${limit} row limit`;
        case SOURCE_FAILED_STATUS:
          return `There was an issue syncing your ${this.feed.integration.friendly_name}`;
        default:
          return `We're having trouble with your ${this.feed.integration.friendly_name} account.`;
      }
    },
    errorMessage() {
      const dt = this.feed.record_limit_exceeded_at ?? this.feed.updated_at;
      const formattedDt = DateTime.fromISO(dt).toFormat(
        "d MMMM, yyyy 'at' h:mm a",
      );
      const status = this.feed.status;
      if (status === RECORD_LIMIT_EXCEEDED_STATUS)
        return `${this.feed.error_message || ''} Last sync failed on ${formattedDt}`;
      if (status === SOURCE_FAILED_STATUS) return null;
      if (
        status === CONNECTION_CHECK_ERROR_STATUS &&
        this.feed.error_message === 'snowflake_no_fields_discovered'
      ) {
        return 'We weren\'t able to find any fields to sync, please use our setup guide, "Connect Snowflake Data" to review or contact support@crossbeam.com for help.';
      }

      if (this.feed.error_message) {
        const error = this.dataSourceUser
          ? `${this.feed.integration.friendly_name} setup with ${this.dataSourceUser}`
          : '';
        return `Here's the error: ${error}. ${this.feed.error_message}`;
      }
      return null;
    },
  },
};
</script>
