<template>
  <div
    :class="classesForHeader"
    class="amm-column-header-cell flex items-center"
  >
    <div
      v-if="column.standard_type"
      class="amm-column-header-cell__standard-population-bar"
    />
    <div
      :class="{ 'amm-column-header-cell__cutoff-right': isCutOffRight }"
      class="flex flex-col justify-left items-start pl-10 py-8"
    >
      <BittsTooltip v-if="!isEmpty" placement="right">
        <span class="amm-column-header-cell__name">
          {{ truncateColumnName(column.name) }}
        </span>
        <template #title>
          {{ column.name }}
        </template>
      </BittsTooltip>
      <div v-else class="amm-column-header-cell__empty" />
    </div>
  </div>
</template>

<script>
import { BittsTooltip } from '@crossbeam/bitts';

import Humanize from 'humanize-plus';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AMMColumnHeaderCell',
  components: {
    BittsTooltip,
  },
  props: {
    column: {
      type: Object,
      default() {
        return {};
      },
    },
    columnIncrementDisabled: {
      type: Boolean,
      default: false,
    },
    columnIndex: {
      type: Number,
      default: null,
    },
    numColumns: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    areColumnsSmallerThanFullWidth() {
      return this.numColumns < 3;
    },
    classesForHeader() {
      return {
        'border-neutral-border': true,
        'amm-column-header-cell__cutoff-right': this.isCutOffRight,
        'w-full': !this.isCutOffRight,
        'border-r border-neutral-border border-solid':
          this.areColumnsSmallerThanFullWidth && this.isInLastColumn,
      };
    },
    isCutOffRight() {
      return (
        (!this.columnIncrementDisabled && this.isInLastColumn) || this.isEmpty
      );
    },
    isEmpty() {
      return !this.column.name;
    },
    isInLastColumn() {
      return this.columnIndex === this.numColumns - 1;
    },
    threeOrLessColumns() {
      return this.numColumns <= 3;
    },
  },
  methods: {
    truncateColumnName(columnName) {
      if (!this.threeOrLessColumns) {
        return Humanize.truncate(columnName, 18);
      }
      return Humanize.truncate(columnName, 28);
    },
  },
});
</script>

<style lang="pcss" scoped>
.amm-column-header-cell {
  @apply w-full bg-neutral-50 border-b border-r border-solid border-neutral-200;
}
.amm-column-header-cell__empty {
  @apply rounded-l-bts-md bg-neutral-background-disabled w-3/4 h-8 self-center ml-auto;
}
.amm-column-header-cell__name {
  @apply font-normal leading-6 overflow-hidden
  text-neutral-text text-sm;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.amm-column-header-cell__cutoff-right {
  @apply w-[140px];
}
.amm-column-header-cell__standard-population-bar {
  @apply self-stretch w-4 rounded-bts-xl bg-info-accent m-4 mr-0;
  flex-shrink: 0;
}
</style>
