<template>
  <section
    class="pricing-card-sidebar-v4-copy"
    :class="[isSmall ? 'px-24' : 'px-40']"
  >
    <BittsLoading
      class="c-pricing-overview sticky top-24"
      :is-loading="loadingCostPreview || !allStoresReady"
      :class="[loadingCostPreview || !allStoresReady ? 'pt-72' : '']"
    >
      <BittsCard v-if="!isEmpty && !isPreviewErrored" class="p-24">
        <div>
          <h3 class="card-title"> Billing Summary </h3>
        </div>
        <CostSummary
          :reclaimed-core-seats="reclaimedCoreSeats"
          :reclaimed-sales-seats="reclaimedSalesSeats"
          :core-seat-count="newCoreSeats"
          :cost-per-core-seat="costPerCoreSeat"
          :sales-seat-count="newSalesSeats"
          :cost-per-sales-seat="costPerSalesSeat"
          :sales-seats-total-cost="salesSeatsTotalCost"
          :core-seats-total-cost="coreSeatsTotalCost"
          :prorated-fee="proratedFee"
          :period="period"
          :summary-total="amountDueToday"
          summary-header="Amount Due Today"
          class="my-24"
        />
        <div
          class="preview-alert"
          data-testid="preview-alert"
          :class="{ [period]: true }"
        >
          <span
            v-html="
              injectStrings(V4_BILLING_COPY.seatPurchase.alert, {
                values: injections,
                bold: true,
              })
            "
          />
        </div>
      </BittsCard>
      <BittsCard
        v-else-if="isPreviewErrored && !loadingCostPreview"
        data-testid="seat-cost-preview-error-state"
        class="p-24"
      >
        <template #default>
          <BittsAlert
            color="error"
            :message="V4_BILLING_COPY.connectorUpgrade.preview.error.title"
            :description="
              V4_BILLING_COPY.connectorUpgrade.preview.error.description
            "
          />
        </template>
      </BittsCard>
      <BittsEmptyState
        v-else
        data-testid="seat-cost-preview-empty-state"
        :font-awesome-icon="['fak', 'seat']"
        :title="V4_BILLING_COPY.connectorUpgrade.preview.emptyState.title"
        :description="
          V4_BILLING_COPY.connectorUpgrade.preview.emptyState.description
        "
      />
    </BittsLoading>
  </section>
</template>
<script setup>
import {
  BittsAlert,
  BittsCard,
  BittsEmptyState,
  BittsLoading,
} from '@crossbeam/bitts';
import { useScreenSize } from '@crossbeam/pointbreak';

import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';

import CostSummary from '@/components/billing/CostSummary.vue';

import useBilling from '@/composables/useBilling';
import {
  BILLING_PLAN_ANNUAL,
  BILLING_PLAN_MONTHLY,
  FULL_ACCESS,
  SALES_ONLY,
  SEAT_COSTS,
  V4_BILLING_COPY,
} from '@/constants/billing';
import { captureException } from '@/errors';
import { useBillingStore, useFlashesStore } from '@/stores';
import { centsToDollars, injectStrings } from '@/utils';

const props = defineProps({
  newCoreSeats: {
    type: Number,
    required: true,
  },
  reclaimedCoreSeats: {
    type: Number,
    default: 0,
  },
  reclaimedSalesSeats: {
    type: Number,
    default: 0,
  },
  newSalesSeats: {
    type: Number,
    required: true,
  },
  period: {
    type: String,
    default: 'year',
  },
});
const emit = defineEmits(['preview', 'preview-failed', 'amount-due']);
const flashesStore = useFlashesStore();
const { allStoresReady, processCostDataV4, fetchSeatDataV4 } = useBilling();

const billingStore = useBillingStore();
const { subscriptionNextBillingDate, grandTotal } = storeToRefs(billingStore);
const { isSmall } = useScreenSize();

const isPreviewErrored = ref(false);
const loadingCostPreview = ref(true);
const amountDueToday = ref(null);
const isEmpty = ref(true);
const nextInvoice = ref(null);
const proratedFee = ref(null);
const injections = computed(() => [
  centsToDollars(nextInvoice.value),
  subscriptionNextBillingDate.value,
]);

const isMonthly = computed(() => props.period === 'month');
const chosenPeriod = computed(() =>
  isMonthly.value ? BILLING_PLAN_MONTHLY : BILLING_PLAN_ANNUAL,
);
const newCoreSeats = computed(() => props.newCoreSeats);
const newSalesSeats = computed(() => props.newSalesSeats);
const reclaimedCoreSeats = computed(() => props.reclaimedCoreSeats);
const reclaimedSalesSeats = computed(() => props.reclaimedSalesSeats);
const costPerCoreSeat = ref(0);
const coreSeatsTotalCost = ref(0);
const costPerSalesSeat = ref(0);
const salesSeatsTotalCost = ref(0);

onMounted(() => {
  previewCost();
});
watch(
  [
    chosenPeriod,
    newCoreSeats,
    newSalesSeats,
    reclaimedCoreSeats,
    reclaimedSalesSeats,
  ],
  () => {
    previewCost();
  },
);

const costPerReclaimedCoreSeat = computed(() =>
  isMonthly.value
    ? SEAT_COSTS[FULL_ACCESS].month
    : SEAT_COSTS[FULL_ACCESS].year,
);
const costPerReclaimedSalesSeat = computed(() =>
  isMonthly.value ? SEAT_COSTS[SALES_ONLY].month : SEAT_COSTS[SALES_ONLY].year,
);

async function previewCost() {
  try {
    emit('preview');
    isPreviewErrored.value = false;
    loadingCostPreview.value = true;
    if (
      !newCoreSeats.value &&
      !newSalesSeats.value &&
      !reclaimedCoreSeats.value &&
      !reclaimedSalesSeats.value
    ) {
      isEmpty.value = true;
      loadingCostPreview.value = false;
      return;
    }

    const totalReclaimedCost =
      costPerReclaimedCoreSeat.value * reclaimedCoreSeats.value +
      costPerReclaimedSalesSeat.value * reclaimedSalesSeats.value;

    /* Only reclaimed seats are present, do not call preview API */
    if (!newCoreSeats.value && !newSalesSeats.value) {
      isEmpty.value = false;
      loadingCostPreview.value = false;
      nextInvoice.value = grandTotal.value + totalReclaimedCost;
      proratedFee.value = 0;
      amountDueToday.value = 0;
      emit('amount-due', amountDueToday.value);
      return;
    }

    isEmpty.value = false;

    const costData = await fetchSeatDataV4({
      newCoreSeats: newCoreSeats.value + reclaimedCoreSeats.value,
      newSalesSeats: newSalesSeats.value + reclaimedSalesSeats.value,
      period: chosenPeriod.value,
    });

    const processedData = processCostDataV4({
      costData,
      newCoreSeats: newCoreSeats.value,
      newSalesSeats: newSalesSeats.value,
      period: chosenPeriod.value,
    });

    nextInvoice.value = processedData.nextSubscriptionCost;
    proratedFee.value = processedData.proratedFee;
    amountDueToday.value = processedData.amountDueToday - totalReclaimedCost;
    costPerCoreSeat.value = processedData.costPerCoreSeat;
    coreSeatsTotalCost.value = processedData.coreSeatsTotalCost;
    costPerSalesSeat.value = processedData.costPerSalesSeat;
    salesSeatsTotalCost.value = processedData.salesSeatsTotalCost;
    loadingCostPreview.value = false;

    emit('amount-due', amountDueToday.value);
  } catch (err) {
    isPreviewErrored.value = true;
    loadingCostPreview.value = false;
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Could not preview cost',
      description: err.message,
    });
    emit('preview-failed');
  }
}
</script>

<style lang="pcss" scoped>
.card-title {
  @apply font-bold text-lg text-neutral-text-strong;
}

.preview-alert {
  @apply rounded-8 px-16 py-8 text-center bg-neutral-background-weak text-neutral-text;
}

.pricing-card-sidebar-v4-copy {
  @apply pb-[136px] lg:pb-32 h-full pt-32;
  background: radial-gradient(
      57.15% 41.02% at 23.19% 83.74%,
      theme(colors.upsell.accent / 0.8) 0%,
      theme(colors.upsell.accent / 0) 100%
    ),
    radial-gradient(
      71.71% 50.59% at 87.36% -3.52%,
      theme(colors.info.border / 0.8) 0%,
      theme(colors.info.border / 0) 100%
    ),
    linear-gradient(
      180deg,
      theme(colors.brand-navy) 0%,
      theme(colors.primary.text-button) 100%
    );
}

.feature-card {
  @apply p-24 rounded-16 mt-24;
  background: linear-gradient(
    100.88deg,
    theme(colors.white / 0.2) 0%,
    theme(colors.white / 0) 100%
  );
}
</style>
