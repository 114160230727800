<template>
  <BillingCTA
    data-testid="getting-value-cta"
    :message="V4_BILLING_COPY.gettingValueCta.message"
    :description="V4_BILLING_COPY.gettingValueCta.description"
    button-text="Add seats"
    size="small"
    :billing-interaction="{
      cta: TEAM_CTAS.AT_SEAT_LIMIT,
      seatType: seatType,
      event_site: EVENT_SITES.GETTING_VALUE_CTA,
      talkToSalesReason: mustTalkToSales && 'More seats',
    }"
    :billing-query="{
      [seatType]: seatsNeeded,
      seat_type: seatType,
    }"
  />
</template>

<script setup>
import { EVENT_SITES, TEAM_CTAS } from '@crossbeam/itly';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import useSeats from '@/composables/useSeats';
import { V4_BILLING_COPY } from '@/constants/billing';

defineProps({
  seatType: {
    type: String,
    default: null,
  },
  seatsNeeded: {
    type: Number,
    default: 1,
  },
});
const { mustTalkToSales } = useSeats();
</script>
