<template>
  <Notification :item="item">
    <template #logo>
      <BittsAvatar :org="partnerOrg" :show-initials="true" size="medium" />
    </template>
    <template #message> Partnership Accepted </template>
    <template #description>
      {{ userName }} at {{ organizationName }} accepted your partnership
      request.
    </template>
    <template #action>
      <BittsButton
        text="View Partner"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="viewPartner"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'team']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import { useNotificationsStore, usePartnersStore } from '@/stores';

export default {
  name: 'ProposalAcceptedNotification',
  components: {
    BittsAvatar,
    BittsButton,
    Notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  computed: {
    userName() {
      return this.item.data.receiving_user_name;
    },
    organizationName() {
      return this.item.data.receiving_org_name;
    },
    partnerOrg() {
      return this.getPartnerOrgById(this.item.data.receiving_org_id);
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(useNotificationsStore, ['markRead']),
    async viewPartner() {
      this.$emit('action-clicked', 'View Partner');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({
        name: 'partner_details',
        params: { partner_org_id: this.partnerOrg.id },
      });
    },
  },
};
</script>
