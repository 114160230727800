import { EmptyObject } from '@crossbeam/types';

import { sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import useAuth from '@/composables/useAuth';
import {
  PREVENT_DEFAULT_PARTNERSTACK_COL,
  TEMP_EXEC_UP_SHARED_LIST_ROWS,
} from '@/constants/feature_flags';
import {
  CONTACT_ADMIN_ENABLE_PARTNER_CB4S,
  CONTACT_ADMIN_UPGRADE_CB4S_ROLE,
  ENABLE_PARTNER_CB4S,
  UPGRADE_OWN_CB4S_ROLE,
  UPGRADE_OWN_CB4S_ROLE_ENABLE_PARTNER,
} from '@/constants/shared_list';
import { CO_SELLER, PARTNER_MANAGER } from '@/constants/team';
import {
  useCB4SPartnersStore,
  useCollaborateStore,
  useFeatureFlagStore,
} from '@/stores';
import {
  SharedList,
  SharedListUser,
  SharedListUserOrgAndLabel,
} from '@/types/shared_lists';

export default function useSharedList() {
  const route = useRoute();
  const listId = computed(() => route.params.id as string);

  const collaborateStore = useCollaborateStore();
  const cb4sPartnersStore = useCB4SPartnersStore();
  const { lists } = storeToRefs(collaborateStore);

  const featureFlags = useFeatureFlagStore();

  const { currentOrg, currentUser, currentAuth, isAdmin } = useAuth();

  const hasPreventPartnerStackColumn = computed(() =>
    featureFlags.hasFeatureFlag(PREVENT_DEFAULT_PARTNERSTACK_COL),
  );

  const currentList = computed(
    () => lists.value?.find((list) => list.id === listId.value) as SharedList,
  );

  const sendToSalesError = computed(() => {
    const cb4sRole = currentAuth?.value?.sales_edge_role;
    const canManageCB4S = cb4sRole === PARTNER_MANAGER;
    const canSendToSales = canManageCB4S || cb4sRole === CO_SELLER;
    const cb4sPartnerEnabled =
      partnerOrg.value &&
      cb4sPartnersStore.partnerEnabled(partnerOrg.value.organization_id);
    const partnerOrgName = partnerOrg.value?.name;
    if (!canSendToSales) {
      if (isAdmin.value) {
        return {
          type: UPGRADE_OWN_CB4S_ROLE,
          shortDescription:
            'Update your sales role to send records to Crossbeam for Sales',
        };
      }
      return {
        type: CONTACT_ADMIN_UPGRADE_CB4S_ROLE,
        shortDescription:
          'To send records, contact your admin to change your sales role',
      };
    }

    if (!cb4sPartnerEnabled) {
      if (canManageCB4S) {
        return {
          type: ENABLE_PARTNER_CB4S,
          shortDescription: `Enable ${partnerOrgName} in Crossbeam for Sales to send records`,
        };
      }
      if (isAdmin.value) {
        return {
          type: UPGRADE_OWN_CB4S_ROLE_ENABLE_PARTNER,
          shortDescription: `Update your sales role to Manager to enable ${partnerOrgName} in Crossbeam for Sales`,
        };
      }
      return {
        type: CONTACT_ADMIN_ENABLE_PARTNER_CB4S,
        shortDescription: `Contact your admin to enable ${partnerOrgName} in Crossbeam for Sales to send records`,
      };
    }

    return null;
  });

  const partnerOrg = computed(() =>
    orgsList.value?.find((org) => org.organization_id !== currentOrg.value.id),
  );
  const currentOrgInfoFromList = computed(() =>
    orgsList.value?.find((org) => org.organization_id === currentOrg.value.id),
  );

  function listUserName(user: SharedListUser | EmptyObject = {}) {
    if (user.first_name || user.last_name) {
      return [user.first_name, user.last_name]
        .reduce((fullName: string[], name) => {
          if (name) fullName.push(name);
          return fullName;
        }, [])
        .join(' ');
    }
    return 'Admin';
  }

  const teamMembersInCurrentList = computed(() => {
    const usersInList = collaborateStore
      .getListUsersInOrg(listId.value as string, currentOrg.value.id)
      .map((user) => ({
        ...user,
        org: currentOrgInfoFromList.value,
        label: listUserName(user),
      }));
    const sortedUsers = sortBy(usersInList, ['label']);
    const currentUserIndex = sortedUsers.findIndex(
      (user) => user.user_id === currentUser.value.id,
    );
    if (currentUserIndex !== -1) {
      const user = sortedUsers.splice(currentUserIndex, 1);
      sortedUsers.unshift(...user);
    }
    return sortedUsers;
  });

  const partnerTeamMembersInCurrentList = computed(() =>
    sortBy(
      collaborateStore
        .getListUsersInOrg(
          listId.value as string,
          partnerOrg.value?.organization_id,
        )
        .map((user) => ({
          ...user,
          org: partnerOrg.value,
          label: `${user.first_name} ${user?.last_name}`,
        })),
      ['label'],
    ),
  );

  const allUsersInList = computed<SharedListUserOrgAndLabel[]>(() => {
    return [
      ...teamMembersInCurrentList.value,
      ...partnerTeamMembersInCurrentList.value,
    ];
  });

  const maxListRowsLimit = computed(() => {
    if (featureFlags.hasFeatureFlag(TEMP_EXEC_UP_SHARED_LIST_ROWS)) return 150;
    return 50;
  });

  const orgsList = computed(() => currentList.value?.permissions);
  return {
    currentList,
    orgsList,
    partnerTeamMembersInCurrentList,
    teamMembersInCurrentList,
    partnerOrg,
    allUsersInList,
    hasPreventPartnerStackColumn,
    maxListRowsLimit,
    sendToSalesError,
  };
}
