<template>
  <div class="c-current-plan-card-summary">
    <div class="card-title" data-testid="card-title">
      <p class="flex items-center">
        <span class="title">You're on {{ tier }}</span>
        <span
          v-if="isConnectorTier && isSubscriptionCancelled"
          class="cancellation-period"
          >until {{ subscriptionRenewalDate }}</span
        >
      </p>
      <slot name="top-right" />
    </div>
    <p data-testid="card-subtitle" class="text-neutral-text-weak mb-4">
      <span v-if="isSubscriptionCancelled"
        >After that, you will be downgraded to the free Explorer plan</span
      >
      <span v-else>
        Thanks for being on this plan since
        <span class="text-neutral-text font-bold">{{ startDate }}</span>
      </span>
    </p>
    <p
      v-if="hasPartnerSponsorshipFlag && isPartnerSponsor"
      class="flex flex-col gap-4 text-neutral-text mt-16"
    >
      <span class="flex items-center gap-8 font-bold text-neutral-text-strong">
        <FontAwesomeIcon
          :icon="['fak', 'medal']"
          :style="{ height: '16px', width: '16px', color: 'currentColor' }"
          class="text-beta-accent"
        />
        Partner Sponsorship
      </span>
      You are currently sponsoring your Explorer partners
    </p>
    <slot />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { TEMP_EXEC_PARTNER_SPONSOR } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore } from '@/stores';

defineProps({
  tier: {
    type: String,
    required: true,
  },
  startDate: {
    type: String,
    required: true,
  },
});
const billingStore = useBillingStore();
const {
  isConnectorTier,
  isPartnerSponsor,
  isSubscriptionCancelled,
  subscriptionRenewalDate,
} = storeToRefs(billingStore);

const ffStore = useFeatureFlagStore();
const hasPartnerSponsorshipFlag = computed(() =>
  ffStore.hasFeatureFlag(TEMP_EXEC_PARTNER_SPONSOR),
);
</script>
<style lang="pcss" scoped>
.c-current-plan-card-summary {
  @apply p-16 sm:p-24 flex flex-col rounded-16;
  /* TODO: Refactor the colors out into our tailwind config */
  background: linear-gradient(
      104.9deg,
      theme(colors.secondary.background-weak / 0.8) 0%,
      rgba(226, 247, 253, 0) 100%,
      theme(colors.secondary.accent / 0) 100%
    ),
    linear-gradient(0deg, theme(colors.white), theme(colors.white));
  .title {
    @apply text-lg font-bold text-info-text-strong;
  }
  .card-title {
    @apply mb-8 flex flex-row justify-between;
  }

  .cancellation-period {
    @apply text-secondary-text text-lg ml-4;
  }
}
</style>
