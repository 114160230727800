<template>
  <div class="c-datasource-board">
    <div class="c-datasource-board-header">
      <h3 class="c-datasource-board__tab-title"> Add a Data Source </h3>
      <BittsInput
        v-model="search"
        placeholder="Search for data source"
        name="data-source-search"
        type="search"
        class="max-w-[300px]"
        size="small"
      />
    </div>
    <div>
      <BittsLoading :is-loading="!ready">
        <DataSourceGrid
          v-bind="$attrs"
          :data-sources="filteredDataSources"
          class="text-center mb-32"
          @input="emit('input', $event)"
        />
      </BittsLoading>
    </div>
  </div>
</template>

<script setup>
import { BittsInput, BittsLoading } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import DataSourceGrid from '@/components/data-sources/DataSourceGrid.vue';

import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { useDataSourcesStore } from '@/stores';

const emit = defineEmits(['input']);

const dataSourcesStore = useDataSourcesStore();
const { supportedDataSources, ready } = storeToRefs(dataSourcesStore);

const ORDER = [
  HS3_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  FILE_UPLOAD_DATA_SOURCE_TYPE,
];

const search = ref('');

const filteredDataSources = computed(() => {
  const sources = [...supportedDataSources.value].sort((a, b) =>
    ORDER.indexOf(a.type) < ORDER.indexOf(b.type) ? -1 : 1,
  );
  return sources.filter((ds) =>
    ds.friendly_name.toLowerCase().includes(search.value.toLowerCase()),
  );
});
</script>
<style scoped lang="pcss">
.c-datasource-board-header {
  @apply flex justify-between items-center mb-16;
}
.c-datasource-board__tab-title {
  @apply text-lg font-bold text-neutral-text-strong;
}
</style>
