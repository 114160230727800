<template>
  <BittsCard :no-padding="false" class="c-sales-access-requests-card">
    <div class="c-sales-access-requests-card__heading">
      <h3>Sales Requests</h3>
      <UpsellTooltip
        v-if="hasRequests && seatsNeeded && !hasUnlimitedSeats"
        data-testid="sales-upsell-tooltip"
        :hide-upsell="!mustTalkToSales || !canManageBilling"
        :billing-interaction="{
          event_site: EVENT_SITES.SEAT_REQUESTS_SALES_ACCESS_CARD_TOOLTIP,
          cta: TEAM_CTAS.MORE_SEATS,
          talkToSalesReason: 'Accept Sales seat requests',
        }"
        button-text="Talk to Sales"
      >
        <BittsButton
          data-testid="sales-access-seat-request-cta"
          :left-icon="['fak', 'upgrade']"
          size="small"
          :disabled="mustTalkToSales || !canManageBilling"
          :text="upsellButtonText"
          :variant="isFreeTier ? 'fill' : 'outline'"
          :type="isFreeTier ? 'upsell' : 'primary'"
          @click="
            handleBillingInteraction(
              {
                cta: TEAM_CTAS.ACCEPT_INVITE_REQUESTS,
                event_site: EVENT_SITES.SEAT_REQUESTS_SALES_ACCESS_CARD_BUTTON,
                seatType: SALES,
                number_of_seats: isFreeTier
                  ? coreSeatCount + fullAccessRequests.length
                  : 0,
              },
              {
                cta: TEAM_CTAS.ACCEPT_INVITE_REQUESTS,
                [SALES]: seatsNeeded,
                [CORE]: isFreeTier
                  ? coreSeatCount + fullAccessRequests.length
                  : 0,
              },
            )
          "
        />
        <template #title>
          <span data-testid="upsell-tooltip-text">
            {{ upsellTooltipText }}
          </span>
        </template>
      </UpsellTooltip>
      <BittsButton
        v-else-if="hasRequests && canManageTeam"
        data-testid="sales-access-seat-request-button"
        size="small"
        variant="outline"
        type="neutral"
        text="Approve all"
        @click="handleAcceptRequests"
      />
    </div>
    <div
      :class="{
        'text-neutral-text-strong': hasRequests,
        'text-neutral-text-placeholder': !hasRequests,
      }"
      class="c-sales-access-requests-card__count"
    >
      {{ salesAccessRequests.length }}
    </div>
  </BittsCard>
</template>

<script setup>
import { BittsButton, BittsCard } from '@crossbeam/bitts';
import { EVENT_SITES, TEAM_CTAS } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import UpsellTooltip from '@/components/billing/UpsellTooltip.vue';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import useSeats from '@/composables/useSeats';
import { CORE, SALES } from '@/constants/team';
import { useBillingStore, useSeatRequestsStore } from '@/stores';

const route = useRoute();
const router = useRouter();
const {
  salesSeatsRemaining,
  salesSeatCount,
  salesSeatLimit,
  mustTalkToSales,
  coreSeatCount,
  hasUnlimitedSeats,
} = useSeats();
const { handleBillingInteraction } = useBilling();
const { hasScope } = useAuth();
const canManageTeam = computed(() => hasScope('write:members'));
const canManageBilling = computed(() => hasScope('write:billing'));

const billingStore = useBillingStore();
const seatRequestsStore = useSeatRequestsStore();
const { salesAccessRequests, fullAccessRequests } =
  storeToRefs(seatRequestsStore);
const { isFreeTier } = storeToRefs(billingStore);

const hasRequests = computed(() => salesAccessRequests.value.length > 0);
const seatsNeeded = computed(() => {
  if (hasUnlimitedSeats.value) return 0;
  return Math.max(
    salesAccessRequests.value.length - salesSeatsRemaining.value,
    0,
  );
});

/* The copy and tooltip behavior is slightly different between billing tiers 🙄 */
const upsellTooltipText = computed(() => {
  if (!canManageBilling.value && !mustTalkToSales.value)
    return 'You do have billing access, please contact an account administrator';
  if (isFreeTier.value)
    return 'Upgrade to Connector to unlock access to Sales seats';
  const allOrCountText =
    salesSeatsRemaining.value === 0
      ? 'all'
      : `${salesSeatCount.value}/${salesSeatLimit.value}`;
  if (mustTalkToSales.value)
    return `You are using ${allOrCountText} available Sales seats in your current contract`;
  return `You are using ${allOrCountText} of your available Sales seats`; // Connector w/ subscription
});

const upsellButtonText = computed(() => {
  if (isFreeTier.value) return 'Upgrade';
  if (mustTalkToSales.value) return 'Approve all';
  return 'Add seats'; // Connector w/ subscription
});

async function handleAcceptRequests() {
  const emails = salesAccessRequests.value.map((r) => r.email).join(',');
  await router.push({
    name: 'approve_seat_request',
    query: {
      cancelDestination: route.name,
      seat_type: SALES,
      emails,
    },
  });
}
</script>
<style lang="pcss">
.c-sales-access-requests-card {
  @apply flex-1;

  .c-sales-access-requests-card__heading {
    @apply flex justify-between items-center;

    h3 {
      @apply text-neutral-text-weak text-base;
    }
  }

  .c-sales-access-requests-card__count {
    @apply text-xl font-bold;
  }
}
</style>
