<template>
  <SharingSettingsModal
    :population="population"
    :visible="true"
    @hide-sharing-modal="onClose"
  />
</template>

<script setup>
import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SharingSettingsModal from '@/components/partners/DataSharingSettings/SharingSettingsModal.vue';

import usePopulations from '@/composables/usePopulations';

const route = useRoute();
const router = useRouter();

const { allPopulations, customPopsWithStatsAndSharing } = usePopulations();

const populationId = computed(() => parseInt(route.params.population_id));
const population = computed(() => {
  const populations = [
    ...allPopulations.value,
    ...customPopsWithStatsAndSharing.value,
  ];
  return populations.find((population) => population.id === populationId.value);
});

useHead({
  title: computed(() => population.value?.name ?? ''),
  titleTemplate: 'Edit - %s - Sharing Settings - Crossbeam',
});

async function onClose() {
  await router.push({ name: 'populations' });
}
</script>
