import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import { allReady, useRolesStore, useTeamStore } from '@/stores';

/* This composable expects a reactive (computed or ref) user
 * or an invitation, and returns out derived state, such as whether it can
 * be deleted, it's role changed, and so forth */
export default function useAuthOrInvite(authOrInvite) {
  const rolesStore = useRolesStore();
  const teamStore = useTeamStore();
  const { authorizations } = storeToRefs(teamStore);
  const { currentUser } = useAuth();

  const ready = allReady(teamStore, rolesStore);
  const isAdmin = computed(
    () => rolesStore.getRoleById(authOrInvite.value?.role_id)?.name === 'Admin',
  );
  const admins = computed(() =>
    authorizations.value
      .filter((auth) => auth.user)
      .filter((auth) => rolesStore.getRoleById(auth.role_id)?.name === 'Admin'),
  );
  const isLastAdmin = computed(
    () => admins.value.length === 1 && isAdmin.value,
  );

  const id = computed(
    () => authOrInvite.value?.user?.id || authOrInvite.value?.id,
  );
  const isSelf = computed(
    () => id.value === currentUser.value.id && !!authOrInvite.value?.user,
  );

  const email = computed(
    () => authOrInvite.value?.user?.email || authOrInvite.value?.email,
  );
  const isInvite = computed(() => !authOrInvite.value?.user);
  const isUser = computed(() => !isInvite.value);
  const cannotEditRole = computed(() => !isUser.value || isLastAdmin.value);
  const cannotDelete = computed(() => isSelf.value);
  const role = computed(() =>
    rolesStore.getRoleById(authOrInvite.value?.role_id),
  );
  const salesRole = computed(() => authOrInvite.value?.sales_edge_role);

  const readableName = computed(() => {
    if (isUser.value) {
      return authOrInvite.value?.user.first_name &&
        authOrInvite.value?.user.last_name
        ? `${authOrInvite.value?.user.first_name} ${authOrInvite.value?.user.last_name}`
        : email.value;
    }
    return email.value;
  });

  return {
    id,
    email,
    ready,
    isUser,
    isInvite,
    isLastAdmin,
    isAdmin,
    role,
    salesRole,
    cannotEditRole,
    cannotDelete,
    isSelf,
    readableName,
  };
}
