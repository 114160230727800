export const FAILED = 'failed';
export const GRANDFATHERED = 'grandfathered';
export const PENDING = 'pending';
export const VERIFIED = 'verified';

const VALID = [GRANDFATHERED, VERIFIED];
const NEEDS_INTERVENTION = [GRANDFATHERED, PENDING];

export const isVerified = (org) => VALID.includes(org.verification_status);
export const needsIntervention = (org) =>
  NEEDS_INTERVENTION.includes(org.verification_status);
