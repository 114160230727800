<template>
  <Notification v-if="feed" :item="item">
    <template #logo>
      <BittsSvg v-if="feed" :svg="feed.integration.type + 'Icon'" />
      <BittsAvatar
        v-else
        :is-icon="true"
        icon-slot-bg-color="bg-beta-background-medium"
        size="medium"
      >
        <template #icon>
          <FontAwesomeIcon
            :icon="['fak', 'company']"
            :style="{ height: '20px', width: '20px', color: 'white' }"
          />
        </template>
      </BittsAvatar>
    </template>
    <template #message> Connection Error </template>
    <template #description>
      <span>
        Your {{ feed.integration.friendly_name }} connection encountered an
        error
      </span>
    </template>
    <template #action>
      <BittsButton
        text="View Error"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="viewError"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'data-source']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-danger-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script>
import { BittsAvatar, BittsButton, BittsSvg } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import { useFeedsStore, useNotificationsStore } from '@/stores';

export default {
  name: 'ConnectionCheckFailedNotification',
  components: {
    BittsAvatar,
    BittsButton,
    Notification,
    BittsSvg,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  computed: {
    feed() {
      return this.getFeedById(this.item.data.feed_id);
    },
  },
  methods: {
    ...mapActions(useFeedsStore, ['getFeedById']),
    ...mapActions(useNotificationsStore, ['markRead']),
    async viewError() {
      this.$emit('action-clicked', 'View Error');
      await this.markRead({ id: this.item.id, isRead: true });
      if (this.feed) {
        await this.$router.push({
          name: 'data-sources-settings',
          params: { id: this.feed.id },
        });
      } else {
        // This is for when the feed is deleted
        // or we can't fetch the feed for some reason
        await this.$router.push({
          name: 'data-sources',
        });
      }
    },
  },
};
</script>
