<template>
  <div class="c-user-info">
    <BittsModal
      class="c-user-info__modal"
      :width="600"
      :visible="true"
      :use-mask-to-close="false"
      :use-keyboard-to-close="false"
      :saving="isModalSaving"
      :disabled="saveDisabled"
      :cancel-disabled="cancelDisabled"
      :show-close-button="false"
      :cancel-tool-tip="cancelToolTip"
      :show-header="false"
      :loading="!ready || loading"
      :save-tool-tip="saveToolTip"
      @closed="closeModal"
      @saved="handleSubmit"
    >
      <template #content>
        <div class="c-user-info__modal_content">
          <div class="modal-avatar">
            <BittsAvatar
              v-if="!showBackupUserImage"
              :user="currentUser"
              data-testid="user-avatar"
              :show-initials="true"
              @loading-failed="showBackupUserImage = true"
            />
            <div v-else class="backup-image">
              {{ computedInitials }}
            </div>
            <a v-if="userPassLogin" href="//en.gravatar.com" target="_blank">
              <BittsTooltip placement="right">
                <BittsButton
                  size="medium"
                  :center-icon="['fak', 'edit']"
                  type="neutral"
                  class="absolute bottom-[-16px] ml-[285px]"
                />
                <template #title>
                  Gravatar changes will take a few minutes to update
                </template>
              </BittsTooltip>
            </a>
          </div>
          <h2
            :class="[userPassLogin ? 'mt-20' : 'mt-8']"
            class="modal-title"
            data-testid="user-info-title"
          >
            Tell us more about you{{
              currentUser.first_name ? `, ${currentUser.first_name}` : '!'
            }}
          </h2>
          <p class="modal-paragraph">
            Improve your Crossbeam experience by telling us more
          </p>
          <div class="flex items-start gap-16 mb-8">
            <BittsInput
              v-if="userPassLogin"
              v-model="firstName"
              data-testid="user-info-first-name"
              form-label="First Name"
              placeholder="Fox"
              name="first_name"
            />
            <BittsInput
              v-if="userPassLogin"
              v-model="lastName"
              data-testid="user-info-last-name"
              form-label="Last Name"
              placeholder="Mulder"
              name="last_name"
            />
          </div>
          <div
            v-if="hasChilipiperOnboarding"
            class="flex items-start gap-16 mb-8"
          >
            <BittsSelect
              v-model="pickedDepartment"
              :allow-clear="true"
              class="flex-1"
              form-label="Department"
              data-testid="user-info-department"
              placeholder="Select Department"
              :options="DEPARTMENTS"
            />
            <BittsSelect
              v-model="pickedCompanyPosition"
              :allow-clear="true"
              class="flex-1"
              form-label="Role"
              data-testid="user-info-role"
              placeholder="Select Role"
              :options="COMPANY_POSITIONS"
            />
          </div>
          <div
            v-if="hasChilipiperOnboarding && isFromProfile"
            class="mb-8 pr-4 w-2/4"
          >
            <BittsInput
              v-model="pronouns"
              data-testid="user-info-pronouns"
              :form-label="{ title: 'Pronouns', secondaryText: 'Optional' }"
              secondary-label="Optional"
              placeholder="they/them"
              name="pronouns"
              :status="pronouns.length > 50 ? 'danger' : 'default'"
              danger-text="Pronouns must be less than 50 characters"
            />
          </div>
          <div
            v-if="!hasChilipiperOnboarding"
            class="flex items-start gap-16 mb-8"
          >
            <BittsInput
              v-model="pronouns"
              class="flex-1"
              data-testid="user-info-pronouns"
              :form-label="{ title: 'Pronouns', secondaryText: 'Optional' }"
              secondary-label="Optional"
              placeholder="they/them"
              name="pronouns"
              :status="pronouns.length > 50 ? 'danger' : 'default'"
              danger-text="Pronouns must be less than 50 characters"
            />
            <BittsSelect
              v-model="pickedDepartment"
              :allow-clear="true"
              class="flex-1"
              form-label="Department"
              data-testid="user-info-department"
              placeholder="Select Department"
              :options="DEPARTMENTS"
            />
          </div>
          <BittsInput
            v-if="isFromProfile"
            v-model="jobTitle"
            class="mb-8"
            data-testid="user-info-job-title"
            :form-label="{
              title: 'What is your job title?',
              secondaryText: 'Optional',
            }"
            secondary-label="Optional"
            placeholder="Job Title"
            name="job_title"
            :status="jobTitle.length > 50 ? 'danger' : 'default'"
            danger-text="Job title must be less than 50 characters"
          />
          <div v-if="!currentOrg.preferred_crm && canUpdateCrm">
            <BittsSelect
              v-model="preferredCrm"
              form-label="What CRM do you use?"
              :options="crmDropdownContent"
              :has-opt-groups="true"
              :searchable="false"
              option-type="svg"
              placeholder="Select CRM"
            />
            <BittsAlert
              v-if="showCrmCallout"
              size="small"
              :color="crmCalloutType"
              class="mt-16"
              :message="crmCalloutTitle"
              :description="crmCalloutDescription"
            />
          </div>
        </div>
      </template>
    </BittsModal>
  </div>
</template>

<script setup>
import {
  BittsAlert,
  BittsAvatar,
  BittsButton,
  BittsInput,
  BittsModal,
  BittsSelect,
  BittsTooltip,
} from '@crossbeam/bitts';

import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import usePreferredCrm from '@/composables/usePreferredCrm';
import { TEMP_CHILIPIPER_ONBOARDING } from '@/constants/feature_flags';
import { COMPANY_POSITIONS, DEPARTMENTS } from '@/constants/me';
import { captureException } from '@/errors';
import {
  useDataSourcesStore,
  useFeatureFlagStore,
  useFlashesStore,
  useTeamStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';

const flashesStore = useFlashesStore();
const teamStore = useTeamStore();
const dataSourcesStore = useDataSourcesStore();
const featureFlagStore = useFeatureFlagStore();
const rootStore = useRootStore();
const { currentOrg, currentUser, hasScope } = useAuth();

const hasChilipiperOnboarding = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_CHILIPIPER_ONBOARDING),
);

const { ready } = storeToRefs(teamStore);

const loading = ref(true);
onMounted(async () => {
  await dataSourcesStore.readySync;
  loading.value = false;
});

const isModalSaving = ref(false);

/* Initials state */
const showBackupUserImage = ref(false);
const trimAndCapitalize = (v) => (v && v.at(0).toUpperCase()) || '';
const computedInitials = computed(
  () =>
    `${trimAndCapitalize(firstName.value)}${trimAndCapitalize(lastName.value)}`,
);

/* For google and SSO logins we build this form differently */
const userPassLogin = computed(() => {
  const userAuth = teamStore.authorizations.find(
    (auth) => auth?.user.id === currentUser.value.id,
  );
  return userAuth?.login_method === 'auth0';
});

const firstName = ref(currentUser.value.first_name);
const lastName = ref(currentUser.value.last_name);
const pronouns = ref(currentUser.value.pronouns ?? '');
const jobTitle = ref(currentUser.value.job_title ?? '');

const pickedDepartment = ref(
  DEPARTMENTS.find((d) => d.value === currentUser.value.department)?.value,
);
const pickedCompanyPosition = ref(
  COMPANY_POSITIONS.find((d) => d.value === currentUser.value.company_position)
    ?.value ?? undefined,
);

const isValidUserInfo = computed(() => {
  if (hasChilipiperOnboarding.value) {
    if (userPassLogin.value) {
      return (
        !!firstName.value &&
        !!lastName.value &&
        !!pickedDepartment.value &&
        !!pickedCompanyPosition.value
      );
    }
    return !!pickedDepartment.value && !!pickedCompanyPosition.value;
  }

  // The following is to remove when we get rid of hasChilipiperOnboarding
  if (userPassLogin.value) {
    return !!firstName.value && !!lastName.value && !!pickedDepartment.value;
  }
  return !!pickedDepartment.value;
});

const hasValidName = computed(
  () => !!currentUser.value.first_name && !!currentUser.value.last_name,
);

const CHAR_LIMIT = 50;
const isValidPronouns = computed(() => pronouns.value.length <= CHAR_LIMIT);
const isValidJobTitle = computed(() => jobTitle.value.length <= CHAR_LIMIT);

const cancelDisabled = computed(
  () => !hasValidName.value || !isValidUserInfo.value || needsCrm.value,
);
const saveDisabled = computed(
  () =>
    !isValidUserInfo.value ||
    !isValidPronouns.value ||
    !isValidJobTitle.value ||
    needsCrm.value,
);

/* Preferred CRM Selection */
const {
  crmDropdownContent,
  preferredCrm,
  showCrmCallout,
  crmCalloutTitle,
  crmCalloutDescription,
  crmCalloutType,
} = usePreferredCrm();

const needsCrm = computed(() => !preferredCrm.value && canUpdateCrm.value);
const canUpdateCrm = computed(() => hasScope('write:organization'));

/* Tooltips */
const tooltipText = 'Please fill in all required fields';
const cancelToolTip = computed(() => {
  if (!hasValidName.value || needsCrm.value)
    return 'Please save your info first';
  else if (!isValidUserInfo.value) return tooltipText;
  return '';
});

const saveToolTip = computed(() => {
  if (!isValidUserInfo.value || needsCrm.value) return tooltipText;
  else if (!isValidPronouns.value || !isValidJobTitle.value)
    return 'Inputs must be less than 50 characters';
  return '';
});

/* Handlers */
async function handleSubmit() {
  try {
    isModalSaving.value = true;
    const payload = {
      first_name: firstName.value,
      last_name: lastName.value,
      pronouns: pronouns.value,
      department: pickedDepartment.value,
      job_title: jobTitle.value,
    };
    if (hasChilipiperOnboarding.value) {
      payload.company_position = pickedCompanyPosition.value;
    }

    if (canUpdateCrm.value)
      await axios.patch(urls.org.patch, { preferred_crm: preferredCrm.value });

    await rootStore.loadUserProfile({ initialLogin: false });
    if (hasChilipiperOnboarding.value) {
      await rootStore.updateUserInfo(payload);
    } else {
      await rootStore.oldUpdateUserInfo(payload);
    }
    flashesStore.addSuccessFlash('New user information saved successfully');
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash(
      'We were not able to save your user information, please try again',
    );
  } finally {
    isModalSaving.value = false;
    closeModal();
  }
}

/* If coming from onboarding, direct them to book a meeting */
const router = useRouter();
const route = useRoute();
const isFromProfile = route.name === 'user_info_modal';
async function closeModal() {
  const name = isFromProfile
    ? 'user_settings'
    : 'onboarding_book_meeting_modal';
  await router.push({ name });
}
</script>

<style lang="pcss" scoped>
.c-user-info__modal_content {
  background: radial-gradient(
    136.96% 39.92% at 50.09% -12.42%,
    theme(colors.upsell.accent / 0.2) 0%,
    theme(colors.upsell.accent / 0) 100%
  );

  @apply flex flex-col gap-8 p-24 rounded-3xl;

  .modal-avatar {
    @apply relative;

    .backup-image {
      @apply w-80 h-80 bg-upsell-background-medium rounded-full m-auto flex items-center justify-center text-white text-xxl;
    }
  }

  .modal-title {
    @apply text-xl text-neutral-text-strong font-bold text-center;
  }

  .modal-paragraph {
    @apply text-neutral-text text-m text-center mb-24;
  }
}
</style>

<style lang="pcss">
.c-user-info__modal {
  .c-bitts-modal__content {
    @apply p-0 m-0;
  }

  .c-bitts-input-label {
    @apply font-bold text-neutral-text-strong;
  }
}
</style>
