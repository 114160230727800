<template>
  <div class="c-expandable-view">
    <slot name="header" />
    <transition name="fade" mode="out-in">
      <slot v-if="!isExpanded" :toggle="toggle" name="collapsed" />
      <slot v-else :toggle="toggle" name="expanded" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ExpandableView',
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
