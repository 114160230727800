<template>
  <div>
    <BittsSegment
      :tabs="sharedListTabs"
      :initial-active-index="activeTab"
      class="shared-list-tabs"
      @change:segment="(tab) => (activeTab = tab)"
    />
    <BittsLayout
      :key="sharedListTabs[activeTab]?.id"
      variant="equal"
      :min-col-width="360"
      :gap="16"
      class="mt-16"
    >
      <template v-for="list in listsByTab[activeTab]" :key="list.id" #[list.id]>
        <ListTile
          :list="list"
          :class="{ 'max-w-[484px]': listsByTab[activeTab].length === 1 }"
        />
      </template>
    </BittsLayout>
  </div>
</template>
<script setup lang="ts">
import { BittsLayout, BittsSegment } from '@crossbeam/bitts';

import { sortBy } from 'lodash';
import { computed, ref } from 'vue';

import ListTile from '@/components/shared-list/ListTile.vue';

import useAuth from '@/composables/useAuth';
import { OWNER } from '@/constants/shared_list';
import { useCollaborateStore } from '@/stores';
import { SharedList } from '@/types/shared_lists';

const { lists = [] } = defineProps<{
  lists?: SharedList[];
}>();

const ALL = 0;
const YOUR_LISTS = 1;
const SHARED_WITH_YOU = 2;

const activeTab = ref<typeof ALL | typeof YOUR_LISTS | typeof SHARED_WITH_YOU>(
  ALL,
);

const collaborateStore = useCollaborateStore();
const { currentUser, currentOrg } = useAuth();

const listSortedByRecent = computed(() =>
  sortBy(lists, ['updated_at']).reverse(),
);

const yourLists = computed(() => {
  const ownLists = lists.filter((list) => {
    const owner = collaborateStore.getOwnerByListId(list.id);
    const partnerOwner = collaborateStore.getPartnerOwnerByListId(list.id);
    const isOwnerInCurrentOrg =
      owner?.user_id === currentUser.value.id &&
      owner?.organization_id === currentOrg.value.id;
    const isPartnerOwnerInCurrentOrg =
      partnerOwner?.user_id === currentUser.value.id &&
      partnerOwner?.organization_id === currentOrg.value.id;
    return isOwnerInCurrentOrg || isPartnerOwnerInCurrentOrg;
  });
  return sortBy(ownLists, ['updated_at']).reverse();
});

const sharedWithYou = computed(() => {
  const sharedLists: SharedList[] = [];
  lists.forEach((list) => {
    const usersInList = collaborateStore.getListUsersInOrg(
      list.id,
      currentOrg.value.id,
    );
    usersInList.forEach((user) => {
      const currentUserHasEditorAccess =
        user.user_id === currentUser.value.id && user.user_access !== OWNER;
      if (
        currentUserHasEditorAccess &&
        user.organization_id === currentOrg.value.id
      ) {
        sharedLists.push(list);
      }
    });
  });
  return sortBy(sharedLists, ['updated_at']).reverse();
});

const listsByTab = computed(() => {
  return {
    [ALL]: listSortedByRecent.value,
    [YOUR_LISTS]: yourLists.value,
    [SHARED_WITH_YOU]: sharedWithYou.value,
  };
});

const sharedListTabs = computed(() => {
  return [
    {
      title: 'All',
      id: ALL,
      count: listsByTab.value[ALL].length,
      disabled: !listsByTab.value[ALL].length,
    },
    {
      title: 'Your Lists',
      id: YOUR_LISTS,
      count: listsByTab.value[YOUR_LISTS].length,
      disabled: !listsByTab.value[YOUR_LISTS].length,
    },
    {
      title: 'Shared with You',
      id: SHARED_WITH_YOU,
      count: listsByTab.value[SHARED_WITH_YOU].length,
      disabled: !listsByTab.value[SHARED_WITH_YOU].length,
    },
  ];
});
</script>
<style scoped lang="pcss">
.shared-list-tabs {
  @apply text-m self-start;
}
</style>
<style lang="pcss">
.shared-list-tabs {
  &.bitts-segment .bitts-segment__tab {
    @apply font-normal;
  }
}
</style>
