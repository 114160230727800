<template>
  <BittsLoading :is-loading="loading">
    <Roadwork
      v-if="!loading && isMobile && !viewDesktopOnMobile"
      class="min-h-screen bg-neutral-100 w-full"
      @confirmed="onConfirmViewDesktop"
    />
    <BittsLayout :is-page="true">
      <template #content>
        <div v-if="pageError" class="background-transparent">
          <div class="text-neutral-text-strong text-lg text-center mb-8">
            <FontAwesomeIcon :icon="['fak', 'warning']" />
            We encountered an error.
          </div>
          <div class="text-sm text-neutral-text-weak text-center pb-24">
            <p>We hit a snag loading this record. Please try again.</p>
            <p>
              If this problem persists, please contact
              <a target="_blank" href="mailto:support@crossbeam.com"
                >support@crossbeam.com</a
              >
              or send us a chat using using the bubble at the bottom right of
              your screen.
            </p>
          </div>
        </div>
        <section v-else>
          <IndividualRecordCardsHeader
            :source-data="sourceData"
            :source-id="sourceId"
            :source-primary-key="sourcePrimaryKey"
            @list-modal-visible="isListModalVisible = true"
          />
          <IndividualRecordCards
            v-if="isRecordInformation"
            :source="sourceData"
            :source-id="sourceId"
            :source-primary-key="sourcePrimaryKey"
          />
          <router-view-wrapper
            :source-data="sourceData"
            :source-id="sourceId"
            :source-primary-key="sourcePrimaryKey"
            :account-name="recordName"
          />
        </section>
        <ListModal
          analytics-location="IRP"
          :selected-records-to-add="currentRecordForList"
          :available-partner-orgs-for-list="partnerOrgIds"
          :visible="isListModalVisible"
          @closed="isListModalVisible = false"
        />
      </template>
    </BittsLayout>
  </BittsLoading>
</template>

<script>
import { BittsLayout, BittsLoading } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';
import { useScreenSize } from '@crossbeam/pointbreak';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions } from 'pinia';
import { computed, ref } from 'vue';

import IndividualRecordCards from '@/components/records/IndividualRecordCards.vue';
import IndividualRecordCardsHeader from '@/components/records/IndividualRecordCardsHeader.vue';
import Roadwork from '@/components/Roadwork.vue';
import ListModal from '@/components/shared-list/ListModal.vue';

import { MDM_PROPERTIES } from '@/constants/mdm';
import { captureException } from '@/errors';
import {
  useDataSharesStore,
  usePartnersStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';
import { getMdmField } from '@/utils';

export default {
  name: 'IndividualRecordPage',
  components: {
    IndividualRecordCardsHeader,
    IndividualRecordCards,
    Roadwork,
    BittsLayout,
    BittsLoading,
    ListModal,
  },
  props: {
    sourceId: {
      type: Number,
      required: true,
    },
    sourcePrimaryKey: {
      type: String,
      required: true,
    },
  },

  setup() {
    const sourceData = ref({});
    const { isMobile } = useScreenSize();

    const recordName = computed(() => {
      if (sourceData.value?.mdm_type === 'account') {
        return getMdmField(
          sourceData.value?.elements?.account,
          MDM_PROPERTIES.account.NAME,
        );
      } else if (sourceData.value?.mdm_type === 'lead') {
        return getMdmField(sourceData.value?.elements?.lead, 'person_email');
      }
      return '';
    });

    useHead({
      titleTemplate: computed(() => `${recordName.value} - %s - Crossbeam`),
    });

    return {
      isMobile,
      recordName,
      sourceData,
    };
  },

  data() {
    return {
      loading: true,
      primaryData: {},
      matchingPartnerOrgIds: [],
      orgDataCache: {},
      pageError: null,
      viewDesktopOnMobile: false,
      isListModalVisible: false,
    };
  },
  computed: {
    isFromSalesforceApp() {
      return this.$route.query.ref === 'sf_app';
    },
    salesforceAppUserEmail() {
      return this.$route.query.ref_account;
    },
    activePartnerOrgId() {
      return parseInt(this.$route.query.expanded) || -1;
    },
    source() {
      return this.getSourceById(this.sourceId);
    },
    isRecordInformation() {
      return this.$route.name === 'individual_record';
    },
    currentRecordForList() {
      return [{ source_id: this.sourceId, master_id: this.sourcePrimaryKey }];
    },
    partnerOrgIds() {
      const populationIds = new Set(
        this.sourceData.elements?.account?.data_share_ids
          .map(this.getDataShareById)
          .filter((val) => val)
          .map((share) => share?.partner_population_id),
      );
      const items = Array.from(populationIds).map((popId) => {
        const population = this.getPartnerPopulationById(popId);
        return this.getPartnerOrgById(population?.organization_id)?.id;
      });
      return items;
    },
  },
  watch: {
    async sourcePrimaryKey() {
      if (this.sourceId && this.sourcePrimaryKey) await this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(usePopulationsStore, ['getPartnerPopulationById']),
    ...mapActions(useDataSharesStore, ['getDataShareById']),
    ...mapActions(useSourcesStore, ['getSourceById']),
    async initialize() {
      this.loading = true;
      this.pageError = null;
      if (this.isFromSalesforceApp && this.salesforceAppUserEmail) {
        this.$iteratively.userClickedSfApp({
          user_email: this.salesforceAppUserEmail,
          event_site: EVENT_SITES.IRP,
        });
      }

      try {
        const dataSharesStore = useDataSharesStore();
        const sourcesStore = useSourcesStore();
        const partnersStore = usePartnersStore();
        const allResponses = await Promise.all([
          axios.get(urls.records.v0_2(this.sourceId, this.sourcePrimaryKey)),
          dataSharesStore.readySync,
          partnersStore.readySync,
          sourcesStore.readySync,
          this.loadDataSet(),
        ]);
        const dataResponse = allResponses[0];
        this.sourceData = dataResponse.data;
      } catch (err) {
        this.pageError = err;
        captureException(err);
      } finally {
        this.loading = false;
      }
    },
    async loadDataSet() {
      const partnerOrgId = this.activePartnerOrgId;
      if (partnerOrgId === -1) {
        return;
      }
      const url = urls.records.sharedDetails(
        this.sourceId,
        this.sourcePrimaryKey,
        partnerOrgId,
      );
      const response = await axios.get(url);
      this.orgDataCache[partnerOrgId] = response.data.items;
    },
    onConfirmViewDesktop() {
      this.viewDesktopOnMobile = true;
    },
  },
};
</script>
