<template>
  <BittsModalTwo
    :use-mask-to-close="true"
    @closed="handleCloseModal"
    :class="{ [pointbreak]: true }"
    class="c-onboarding-choose-data-modal"
    :width="modalWidth"
    title="Connect a Data Source"
    data-testid="onboarding-choose-data-modal"
    description="Let's get you set up to start generating Ecosystem-Led revenue"
  >
    <BittsContainer class="feature-card border-secondary-border">
      <div class="flex">
        <BittsSvg
          v-if="isChosenCrmSupported"
          class="w-72 h-72"
          :svg="chosenCrm ? chosenCrm + 'Icon' : 'salesforceIcon'"
        />
        <FontAwesomeIcon
          v-else
          :icon="['fak', 'data-source']"
          class="text-[72px] text-secondary-background"
        />
        <BittsTag variant="rounded" class="ml-auto" color="secondary">
          Recommended
        </BittsTag>
      </div>
      <h2>
        {{ chosenCrmFriendlyName || 'Connect a CRM' }}
      </h2>
      <div class="feature--included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'check']" />
        <p>Free</p>
      </div>
      <div class="feature--included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'check']" />
        <p>Data refresh</p>
      </div>
      <div class="feature--included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'check']" />
        <p>Opportunity Data</p>
      </div>
      <div
        class="flex flex-col items-center"
        :class="[chosenCrm === SALESFORCE_DATA_SOURCE_TYPE ? 'mt-24' : 'mt-48']"
      >
        <BittsCheckbox
          :checked="isSandbox"
          @input="isSandbox = !isSandbox"
          v-if="chosenCrm === SALESFORCE_DATA_SOURCE_TYPE"
          label="I'm connecting to a sandbox"
          class="mb-12"
        />
        <BittsButton
          data-testid="add-crm"
          v-if="isChosenCrmSupported"
          :left-icon="['fak', 'add']"
          :text="`Connect ${chosenCrmFriendlyName}`"
          :class="[chosenCrm === SALESFORCE_DATA_SOURCE_TYPE ? 'mt-0' : 'mt-8']"
          class="action-button mt-0"
          :disabled="viewOnly"
          @click="handleConnectCrm(chosenCrm)"
        />
        <BittsDropdown
          class="w-full mt-8"
          parent-class="w-[280px]"
          v-else
          :options="crmOptions"
          @selection="handleChooseCrm"
        >
          <template #prompt>
            <BittsButton
              class="w-full"
              :disabled="viewOnly"
              text="Select a CRM"
              :right-icon="['fak', 'chevron-down']"
            />
          </template>
        </BittsDropdown>
      </div>
    </BittsContainer>
    <BittsContainer class="feature-card">
      <BittsSvg class="w-72 h-72" svg="googleSheetsIcon" />
      <h2>Google Sheets</h2>
      <div class="feature--included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'check']" />
        <p>Free</p>
      </div>
      <div class="feature--included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'check']" />
        <p>Data refresh</p>
      </div>
      <div class="feature--not-included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'x']" />
        <p>Opportunity Data</p>
      </div>
      <BittsButton
        :disabled="viewOnly"
        @click="handleConnectCrm(GOOGLE_SHEETS_DATA_SOURCE_TYPE)"
        variant="outline"
        :left-icon="['fak', 'add']"
        text="Connect a Google Sheet"
        data-testid="add-google-sheets"
        class="action-button"
      />
    </BittsContainer>
    <BittsContainer class="feature-card">
      <BittsSvg class="w-72 h-72" svg="csv" />
      <h2>CSV File</h2>
      <div class="feature--included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'check']" />
        <p>Free</p>
      </div>
      <div class="feature--not-included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'x']" />
        <p>Data refresh</p>
      </div>
      <div class="feature--not-included">
        <FontAwesomeIcon class="icon" :icon="['fak', 'x']" />
        <p>Opportunity Data</p>
      </div>
      <BittsButton
        :disabled="viewOnly"
        variant="outline"
        :left-icon="['fak', 'add']"
        text="Upload a CSV File"
        data-testid="add-csv"
        class="action-button"
        @click="handleAddCsv"
      />
    </BittsContainer>
    <template #footer>
      <div class="w-full flex flex-col items-center">
        <p class="text-neutral-text-weak">
          Crossbeam is SOC II and ISO 27001 certified. It also complies with
          Data Regulations, including GDPR.
        </p>
      </div>
    </template>
  </BittsModalTwo>
</template>

<script setup>
import {
  BittsButton,
  BittsCheckbox,
  BittsContainer,
  BittsDropdown,
  BittsModalTwo,
  BittsSvg,
  BittsTag,
} from '@crossbeam/bitts';
import { usePointbreak } from '@crossbeam/pointbreak';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import {
  DATA_TEMPLATES_CRMS,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { useDataSourcesStore } from '@/stores';
import urls from '@/urls';

const router = useRouter();

const dataSourcesStore = useDataSourcesStore();
const { supportedDataSources } = storeToRefs(dataSourcesStore);
const { currentOrg, hasPermission } = useAuth();
const viewOnly = computed(() => !hasPermission('manage:data-sources'));

/* The organizations preferred CRM, set up when they log in */
const preferredCrm = computed(() => {
  if (HUBSPOT_DATA_SOURCE_TYPE === currentOrg.value.preferred_crm) {
    return HS3_DATA_SOURCE_TYPE;
  }
  return currentOrg.value.preferred_crm;
});

/* Choosing a CRM, when your preferred CRM is not available */
const chosenCrm = ref(preferredCrm.value);
const isChosenCrmSupported = computed(() =>
  supportedDataSources.value.find((ds) => ds.type === chosenCrm.value),
);
const crmOptions = computed(() =>
  supportedDataSources.value
    .filter(({ type }) =>
      [...DATA_TEMPLATES_CRMS, SNOWFLAKE_DATA_SOURCE_TYPE].includes(type),
    )
    .map(({ type, friendly_name: name }) => ({
      display: name,
      value: type,
      svg: `${type}Icon`,
    }))
    .sort((a, b) =>
      a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1,
    ),
);

const nameToFriendlyName = {
  [SALESFORCE_DATA_SOURCE_TYPE]: 'Salesforce',
  [HS3_DATA_SOURCE_TYPE]: 'Hubspot',
  [SNOWFLAKE_DATA_SOURCE_TYPE]: 'Snowflake',
  [PIPEDRIVE_DATA_SOURCE_TYPE]: 'Pipedrive',
  [MD_DATA_SOURCE_TYPE]: 'Microsoft Dynamics',
};
const chosenCrmFriendlyName = computed(() =>
  chosenCrm.value ? nameToFriendlyName[chosenCrm.value] : 'Choose a CRM',
);

function handleChooseCrm({ value }) {
  chosenCrm.value = value;
}

/* Connecting CRMs/Google */
const isSandbox = ref(false);
async function handleConnectCrm(type) {
  if (chosenCrm.value === SNOWFLAKE_DATA_SOURCE_TYPE) {
    // Snowflake requires an additional form
    await router.push({
      name: 'onboarding-data-sources-connect',
      params: { type: SNOWFLAKE_DATA_SOURCE_TYPE },
    });
    return;
  }

  const payload = {
    integration_type: type || chosenCrm.value,
    settings_usecase:
      type === GOOGLE_SHEETS_DATA_SOURCE_TYPE ? 'recommended' : 'custom',
  };

  if (isSandbox.value) payload.form_properties = { is_sandbox: true };
  const resp = await axios.post(urls.oauth.authorize, { params: payload });
  window.open(resp.data.authorization_url, '_self');
}

/* Adding a CSV */
const showAddDataModal = ref(false);
async function handleAddCsv() {
  showAddDataModal.value = false;
  await router.push({
    name: 'onboarding_file_uploads',
    query: { cancelDestination: 'home' },
  });
}

async function handleCloseModal() {
  await router.push({ name: 'home' });
}

/* Mobile rendering */
const pointbreak = usePointbreak();
const modalWidth = computed(() =>
  !['xl', 'xxl'].includes(pointbreak.value) ? null : 1200,
);
</script>

<style lang="pcss">
.c-onboarding-choose-data-modal {
  .o-bitts-modal__footer,
  .o-bitts-modal__header {
    @apply border-none;
  }
  .o-bitts-modal__content--background {
    @apply gap-24 flex flex-col;
    .bitts-container {
      @apply flex-1;
    }
  }
  &.xxl,
  &.xl {
    .o-bitts-modal__content--background {
      @apply flex flex-row px-72 py-24;
    }
  }
  .o-bitts-modal__header .o-bitts-modal__description {
    @apply text-center !important;
  }
  .bitts-checkbox-label {
    @apply text-neutral-text;
  }
  .c-bitts-dropdown > span {
    @apply w-full;
  }
}
</style>
<style lang="pcss" scoped>
.feature--not-included,
.feature--included {
  @apply flex items-center gap-8;
  .icon {
    @apply text-16;
  }
  p {
    @apply text-neutral-text-weak;
  }
}

.feature-card {
  @apply flex flex-col;
  h2 {
    @apply mt-32 mb-8;
  }

  .action-button {
    @apply w-full mt-56;
  }
}

.feature--included {
  .icon {
    @apply text-success-accent;
  }
}
.feature--not-included {
  .icon {
    @apply text-danger-accent;
  }
}
</style>
