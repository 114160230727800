import DebookingLastActiveCell from '@/components/billing/DebookingLastActiveCell.vue';
import DebookingRoleCell from '@/components/billing/DebookingRoleCell.vue';
import DebookingSalesEdgeRoleCell from '@/components/billing/DebookingSalesEdgeRoleCell.vue';
import DebookingUserCell from '@/components/billing/DebookingUserCell.vue';

import { ADMIN_PANEL, sortRows } from '@/constants/team';

/* Used as the columns when downgrading users or invites as
 * part account cancellation and seat removal flows */
export const debookingColumns = [
  {
    field: 'Name',
    cellRenderer: DebookingUserCell,
    suppressMovable: true,
    autoHeight: true,
    sortable: true,
    sort: 'asc',
    headerComponentParams: { displayName: 'Name', showArrow: true },
    comparator: (_, __, { data: a }, { data: b }) => {
      const firstValue = a.user ? a.user.first_name : a.email;
      const secondValue = b.user ? b.user.first_name : b.email;
      if (firstValue === secondValue) return 0;
      return firstValue?.localeCompare(secondValue);
    },
  },
  {
    field: 'Last Activity',
    cellRenderer: DebookingLastActiveCell,
    suppressMovable: true,
    autoHeight: true,
    sortable: true,
    headerComponentParams: { displayName: 'Last Activity', showArrow: true },
    comparator: (_, __, { data: a }, { data: b }) => {
      return new Date(a.last_active) > new Date(b.last_active) ? -1 : 1;
    },
    suppressSizeToFit: true,
  },
  {
    field: 'Core Role',
    cellRenderer: DebookingRoleCell,
    suppressMovable: true,
    autoHeight: true,
    sortable: true,
    headerComponentParams: { displayName: 'Full Access Role', showArrow: true },
    comparator: (_, __, { data: a }, { data: b }) => {
      return sortRows(a.roleName?.toLowerCase(), b.roleName?.toLowerCase());
    },
    suppressSizeToFit: true,
  },
];

export const salesEdgeRoleColumn = {
  field: 'Sales Role',
  cellRenderer: DebookingSalesEdgeRoleCell,
  suppressMovable: true,
  autoHeight: true,
  sortable: true,
  headerComponentParams: { displayName: 'Sales Role', showArrow: true },
  comparator: (_, __, { data: a }, { data: b }) => {
    return sortRows(
      a.sales_edge_role?.toLowerCase(),
      b.sales_edge_role?.toLowerCase(),
    );
  },
  suppressSizeToFit: true,
};

export function reduceToIds(list, userIds, invert = false) {
  return list
    .filter((authOrInvite) =>
      invert
        ? !userIds.has(authOrInvite.user?.id || authOrInvite.id)
        : userIds.has(authOrInvite.user?.id || authOrInvite.id),
    )
    .filter((authOrInvite) => authOrInvite.authorizer_type !== ADMIN_PANEL)
    .map((authOrInvite) => authOrInvite.user?.id || authOrInvite.id);
}
