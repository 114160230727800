<template>
  <ul class="c-cost-summary">
    <li
      v-if="reclaimedCoreSeats"
      data-testid="reclaimed-core-seat-info"
      class="section reclaimed-core-seats"
    >
      <div class="section-header">
        <span> {{ reclaimedCoreSeats }} x Reclaimed Full Access Seats </span>
        <span class="seat-info"
          >{{ centsToDollars(costPerReclaimedCoreSeat) }} per seat/{{
            period
          }}</span
        >
      </div>
      <div class="section-value"> $0 </div>
    </li>
    <li
      v-if="reclaimedSalesSeats"
      data-testid="reclaimed-sales-seat-info"
      class="section reclaimed-sales-seats"
    >
      <div class="section-header">
        <span> {{ reclaimedSalesSeats }} x Reclaimed Sales Seats </span>
        <span class="seat-info"
          >{{ centsToDollars(costPerReclaimedSalesSeat) }} per seat/{{
            period
          }}</span
        >
      </div>
      <div class="section-value"> $0 </div>
    </li>
    <li
      v-if="coreSeatCount"
      data-testid="core-seat-info"
      class="section core-seats"
    >
      <div class="section-header">
        <span> {{ coreSeatCount }} x Full Access Seats </span>
        <span class="seat-info"
          >{{ centsToDollars(costPerCoreSeat) }} per seat/{{ period }}</span
        >
      </div>
      <div class="section-value">
        {{ centsToDollars(coreSeatsTotalCost) }}
      </div>
    </li>
    <li
      v-if="salesSeatCount"
      class="section sales-seats"
      data-testid="sales-seat-info"
    >
      <div class="section-header">
        <span> {{ salesSeatCount }} x Sales Seats </span>
        <span class="seat-info"
          >{{ centsToDollars(costPerSalesSeat) }} per seat/{{ period }}</span
        >
      </div>
      <div class="section-value">
        {{ centsToDollars(salesSeatsTotalCost) }}
      </div>
    </li>
    <li v-if="proratedFee" class="section proration">
      <div class="flex gap-6 items-center">
        <span>Prorated Fee</span>
        <BittsTooltip>
          <FontAwesomeIcon
            :icon="['fak', 'information-circle']"
            class="text-neutral-accent text-12 pb-1"
          />
          <template #title>
            We only charge you for the time remaining in your subscription
          </template>
        </BittsTooltip>
      </div>
      <div class="section-value"> -{{ centsToDollars(proratedFee) }} </div>
    </li>
    <li data-testid="summary-total" class="section summary-total">
      <div class="label">
        {{ summaryHeader }}
      </div>
      <slot name="total" :total="centsToDollars(summaryTotal)">
        <div class="summary-total__amount">
          {{ centsToDollars(summaryTotal) }}
        </div>
      </slot>
    </li>
  </ul>
</template>

<script setup>
import { BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import { FULL_ACCESS, SALES_ONLY, SEAT_COSTS } from '@/constants/billing';
import { centsToDollars } from '@/utils';

const props = defineProps({
  coreSeatCount: {
    type: Number,
    required: true,
  },
  costPerCoreSeat: {
    type: Number,
    required: true,
  },
  coreSeatsTotalCost: {
    type: Number,
    required: true,
  },
  reclaimedCoreSeats: {
    type: Number,
    default: 0,
  },
  salesSeatCount: {
    type: Number,
    default: null,
  },
  costPerSalesSeat: {
    type: Number,
    default: null,
  },
  reclaimedSalesSeats: {
    type: Number,
    default: 0,
  },
  salesSeatsTotalCost: {
    type: Number,
    default: null,
  },
  proratedFee: {
    type: Number,
    default: null,
  },
  summaryTotal: {
    type: Number,
    required: true,
  },
  summaryHeader: {
    type: String,
    required: true,
  },
  period: {
    type: String,
    required: true,
    validator: (v) => ['month', 'year'].includes(v),
  },
});
const costPerReclaimedCoreSeat = computed(
  () => SEAT_COSTS[FULL_ACCESS][props.period],
);
const costPerReclaimedSalesSeat = computed(
  () => SEAT_COSTS[SALES_ONLY][props.period],
);
</script>

<style lang="pcss" scoped>
.c-cost-summary {
  @apply flex flex-col gap-24;

  .section {
    @apply flex justify-between items-center;
  }

  .section:not(:last-of-type) {
    @apply border-b border-neutral-border pb-16;
  }

  .section-header {
    @apply flex flex-col;

    .seat-info {
      @apply text-neutral-text-weak;
    }
  }

  .section-value {
    @apply text-neutral-text-weak;
  }

  .summary-total .label {
    @apply font-bold;
  }

  .summary-total__amount {
    @apply text-success-text font-bold;
  }
}
</style>
