import type { GetApiResponseByUrl } from '@crossbeam/openapi';

import { crossbeamApi } from '@/api';
import { captureException } from '@/errors';
import {
  AttributionPageQuery,
  ClosedWonOpportunitiesParams,
} from '@/types/attribution';
import type { Partner } from '@/types/partners';

import { usePartnersStore } from '../PartnersStore';

import { useAttributionState } from './state';
import {
  extractFiltersFromQuery,
  fetchClosedWonOpportunities as fetchClosedWonOpportunitiesApi,
  fetchUnattributedOpportunities as fetchUnattributedOpportunitiesApi,
} from './utils';

const { isCached, opportunities, pagination, unattributedOpportunities } =
  useAttributionState();

export type OverlapsResponse =
  GetApiResponseByUrl<'/v1/overlaps/accounts/search'>;

async function fetchOpportunity({
  masterUUID,
  opportunityID,
}: {
  masterUUID: string;
  opportunityID: string;
}) {
  const { data } = await crossbeamApi.GET(
    '/v0.1/attribution/opportunities/{master_uuid}/{opportunity_id}',
    {
      params: {
        path: {
          master_uuid: masterUUID,
          opportunity_id: opportunityID,
        },
      },
    },
  );
  return data?.data[0];
}

async function fetchUnattributedOpportunities() {
  const response = await fetchUnattributedOpportunitiesApi();
  unattributedOpportunities.value = response?.data;
}

async function refreshOpportunities(
  query: AttributionPageQuery = {
    limit: 20,
    page: 1,
    order: 'desc',
    sort: 'close_date',
  },
) {
  const { page } = query;
  const filterParams = extractFiltersFromQuery(query);

  const params = {
    ...filterParams,
    limit: 20,
    page,
  };

  const { data: response } = await crossbeamApi.GET(
    '/v0.1/attribution/opportunities',
    {
      params: {
        query: params,
      },
    },
  );

  if (response) {
    opportunities.value = response?.data;
    pagination.value = response?.pagination;
  }
}

async function refreshClosedWonOppies(
  query: Partial<ClosedWonOpportunitiesParams> = {
    order: 'desc',
  },
) {
  try {
    const page = query.page ?? 1;
    const order = query.order ?? 'desc';
    const params: ClosedWonOpportunitiesParams = {
      ...query,
      limit: 20,
      order,
      page,
    };
    const response = await fetchClosedWonOpportunitiesApi(params);
    if (response) {
      opportunities.value = response?.data;
      pagination.value = response?.pagination;
    }
  } catch (err) {
    isCached.value = false;
    captureException(err);
  }
}

type PartnerData = {
  partner_id: string;
  partner_populations: OverlapsResponse['data'][number]['partner_populations'];
};

type PartnerOverlap = Partner & {
  populations: Set<string>;
};

type PartnerLookup = Record<string, PartnerOverlap>;

async function fetchOpportunityOverlaps({ recordId }: { recordId: string }) {
  const { data } = await crossbeamApi.GET('/v1/overlaps/accounts/search', {
    params: {
      query: {
        record_id: recordId,
      },
    },
  });

  const partnerStore = usePartnersStore();
  const response = data?.data || [];

  const partnerOverlapLookup: PartnerLookup = response.reduce(
    (acc: PartnerLookup, curr: PartnerData) => {
      const {
        partner_populations: partnerPopulations,
        partner_id: partnerUUID,
      } = curr;

      const uniquePopulations = new Set(
        partnerPopulations?.map((pop) => pop.name),
      );

      if (acc[partnerUUID]) {
        const populations = acc?.[partnerUUID]?.populations || [];
        acc[partnerUUID].populations = new Set([
          ...populations,
          ...uniquePopulations,
        ]);
      } else {
        const partner = partnerStore.getPartnerOrgByUuid(partnerUUID);

        if (partner) {
          acc[partnerUUID] = {
            ...partner,
            populations: new Set(uniquePopulations),
          };
        }
      }

      return acc;
    },
    {},
  );

  return Object.values(partnerOverlapLookup).map((curr: PartnerOverlap) => ({
    ...curr,
    populations: sortPops([...curr.populations]),
  }));
}

const sortPops = (pops: string[]) => {
  return pops.sort((a, b) => {
    if (a === 'Customers') return -1;
    else if (b === 'Customers') return 1;
    return a.localeCompare(b);
  });
};

function findOpportunityByID(oppId: string) {
  return opportunities.value
    .concat(unattributedOpportunities.value)
    .find((opp) => opp.id === oppId);
}

function getOpportunityInCurrentStateByID(oppId: string) {
  return opportunities.value.find((opp) => opp.id === oppId);
}

export default {
  fetchOpportunity,
  fetchUnattributedOpportunities,
  refreshOpportunities,
  refreshClosedWonOppies,
  fetchOpportunityOverlaps,
  findOpportunityByID,
  getOpportunityInCurrentStateByID,
  sortPops,
};
