<template>
  <p v-if="seatRequest" class="c-seat-request-table-message-cell">
    <i>{{ seatRequest.message }}</i>
  </p>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const seatRequest = computed(() => props.params.data);
</script>
<style lang="pcss">
.c-seat-request-table-message-cell {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @apply text-wrap max-h-[45px] overflow-ellipsis text-neutral-text text-sm;
}
</style>
