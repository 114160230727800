<template>
  <div
    :class="{
      last: isLastOfType,
      'amm-row-header-cell__bottom-cuttoff':
        (!rowIncrementDisabled || isEmpty) && isLastOfType,
    }"
    class="amm-row-header-cell"
  >
    <div
      :class="{ invisible: !population.standard_type }"
      class="amm-row-header-cell__standard-population-bar"
    />
    <div class="amm-row-header-cell__inner">
      <BittsTooltip v-if="!isEmpty" placement="right">
        {{ population.name }}
        <template #title>
          {{ population.name }}
        </template>
      </BittsTooltip>
      <div v-else class="amm-row-header-cell__empty" />
    </div>
  </div>
</template>

<script>
import { BittsTooltip } from '@crossbeam/bitts';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AMMRowHeaderCell',
  components: {
    BittsTooltip,
  },
  props: {
    population: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: null,
    },
    numRows: {
      type: Number,
      default: 3,
    },
    rowIncrementDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEmpty() {
      return !this.population.name;
    },
    isLastOfType() {
      return this.index === this.numRows - 1;
    },
  },
});
</script>

<style lang="pcss" scoped>
.amm-row-header-cell {
  @apply bg-neutral-background-weak border-b border-r border-solid border-neutral-border
  flex items-stretch w-[160px] h-[70px];
  &.last {
    @apply border-b-0;
  }
}
.amm-row-header-cell__empty {
  @apply w-3/4 h-8 rounded-bts-md bg-neutral-background-disabled;
}
.amm-row-header-cell__bottom-cuttoff {
  @apply h-[55px];
}
.amm-row-header-cell__inner {
  @apply flex flex-col justify-center h-full text-sm p-16
      text-neutral-text pl-10 pt-8 w-[160px];
}
.amm-row-header-cell__population-name {
  @apply mb-auto truncate max-w-[130px];
}
.amm-row-header-cell__standard-population-bar {
  @apply self-stretch w-4 rounded-bts-xl bg-info-accent m-4 mr-0;
  flex-shrink: 0;
}
</style>
