<template>
  <div class="c-integration-profile-settings-drawer">
    <BittsModalTwo
      v-if="confirmationModalType !== null"
      variant="confirm"
      :primary-button-text="isExitModal ? 'Exit' : 'Delete integration'"
      primary-button-type="danger"
      :title="isExitModal ? 'Exit without saving' : 'Delete integration'"
      :description="
        isExitModal
          ? 'You won’t be able to push your Crossbeam overlaps into your custom object until you finish the setup'
          : 'Are you sure you want to delete this integration?'
      "
      @action="isExitModal ? closeDrawerHandler() : deleteIntegration()"
      :action-loading="isDeleting"
      @closed="confirmationModalType = null"
    />
    <BittsDrawer
      visible
      v-if="enabledTray"
      destroy-on-close
      :title="enabledTray.name"
      :primary-button-text="saveButtonText"
      :is-saving="isSaving"
      :is-saving-disabled="
        isSaving || !isIntegrationAvailable || showEmptyState || isDeleting
      "
      @action:primary="saveChanges"
      @closed="onDrawerClosed"
    >
      <template #headerAvatar>
        <BittsAvatar
          :org="avatarLogo"
          :is-entity="true"
          :show-initials="true"
          size="medium"
          shape="square"
        />
      </template>

      <template #content>
        <div class="subtitle-section">
          <IntegrationCardHeader
            :statuses
            :loading
            :callout-text
            :status-type="statusType as IntegrationProfileStatus"
            :disabled
            :version
            @reauthorize="reauthorize"
          />
        </div>
        <template v-if="integrationProfileType">
          <div
            class="subtitle-section flex flex-col gap-4"
            :class="{ disabled }"
          >
            <div class="subtitle-section-content">
              <strong class="font-bold">Enable Data Push</strong>
            </div>
            <div class="flex items-center">
              <p class="text-neutral-text text-sm">
                {{ enableDescription }}
              </p>
              <BittsSwitch
                v-model="isPushEnabled"
                :disabled
                class="ml-auto mr-2"
                data-testid="tray-enabled-toggle"
              />
            </div>
            <div
              v-if="!!existingOpportunityPushSetting"
              class="flex items-center gap-8 mt-8 text-neutral-text-strong"
            >
              <BittsCheckbox
                :checked="isOpportunityPushEnabled"
                data-testid="opportunity-push-checkbox"
                @input="(val) => (isOpportunityPushEnabled = val)"
              />
              <p>Push opportunity data</p>
            </div>
          </div>
          <div v-if="hasPermissionToManageIntegrations" class="p-16">
            <template v-if="!showEmptyState">
              <div class="flex items-center gap-8 mb-4">
                <FontAwesomeIcon
                  :icon="['fak', 'mapping']"
                  class="text-secondary-text"
                />
                <p class="font-bold"> Customize Data in Push </p>
              </div>
              <p class="text-neutral-text text-sm">
                {{ integrationDescription }}
                <BittsLink
                  :url="enabledTray.learnMore"
                  text="Learn more about this"
                />
              </p>
              <IntegrationSettings
                v-if="integrationProfileSettings"
                :population-settings="{
                  population_settings:
                    integrationProfileSettings?.population_settings ?? [],
                  standard_population_settings:
                    integrationProfileSettings?.standard_population_settings ??
                    [],
                  exclude_partnerships_created_since:
                    integrationProfileSettings?.exclude_partnerships_created_since,
                }"
                :setting-type="integrationProfileType"
                @save:changes="onSettingsUpdated"
                @update:exclude-partnerships-created-since="
                  onUpdateExcludePartnershipsCreatedSince
                "
                :is-disabled="!isIntegrationAvailable || isSaving || loading"
              />
            </template>
            <BittsEmptyState
              v-else
              title="The Salesforce App isn't installed"
              svg-name="emptyStateFreeAccount"
            >
              <template #subtitle>
                <div
                  class="sf-overlap-settings-drawer__empty-state--description"
                >
                  To use this integration, follow these steps below:
                </div>
                <ol class="list-decimal list-inside">
                  <li>
                    <BittsLink
                      url="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FvKuqUAF"
                      text="Install Package"
                    />
                  </li>
                  <li>
                    <BittsLink
                      :url="sfCrossbeamAppLink"
                      text="Setup Salesforce Crossbeam App"
                      :open-in-new-tab="true"
                    />
                  </li>
                </ol>
              </template>
            </BittsEmptyState>
          </div>
        </template>
      </template>
      <template #secondaryButton>
        <BittsButton
          text="Delete integration"
          :left-icon="['fak', 'delete']"
          variant="ghost"
          class="w-1/2"
          type="danger"
          data-testid="delete-integration-button"
          @click="confirmationModalType = 'delete'"
          :disabled="isSaving || !isIntegrationAvailable"
        />
      </template>
    </BittsDrawer>
  </div>
</template>
<script setup lang="ts">
import {
  BittsAvatar,
  BittsButton,
  BittsCheckbox,
  BittsDrawer,
  BittsEmptyState,
  BittsLink,
  BittsModalTwo,
  BittsSwitch,
} from '@crossbeam/bitts';
import { GetApiPayloadByUrl } from '@crossbeam/openapi';

import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { ComputedRef, computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import IntegrationCardHeader from '@/components/integrations/IntegrationCardHeader.vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useTrayIntegrations from '@/composables/useTrayIntegrations';
import useTrayStatus from '@/composables/useTrayStatus';
import appConfig from '@/config';
import { MAP_TRAY_TO_INTEGRATION_PROFILE_TYPE } from '@/constants/integrations';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useFeedsStore,
  useFlashesStore,
  useIntegrationProfileStore,
  usePartnerCloudStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import {
  IntegrationProfile,
  IntegrationProfileStatus,
  MinimalIntegrationProfile,
} from '@/types/integration_profiles';
import { TrayTag } from '@/types/integrations';
import { STANDARD_POPULATIONS } from '@/types/populations';
import type { Organization } from '@/types/root';

import IntegrationSettings from './IntegrationSettings.vue';

const route = useRoute();
const router = useRouter();
const pathTrayTag = computed(() => route.params.tray_tag as TrayTag);

const defaultStandardPopSettings = (
  [...STANDARD_POPULATIONS, 'custom'] as const
).map((pop) => ({
  standard_type: pop,
  is_included: ['customers', 'open_opportunities'].includes(pop),
}));

const { currentOrg, hasPermission } = useAuth() as {
  currentOrg: ComputedRef<Organization>;
  hasPermission: (permission: string) => boolean;
};
const integrationProfileStore = useIntegrationProfileStore();
const { getFeedById } = useFeedsStore();
const { addSuccessFlash, addErrorFlash } = useFlashesStore();
const { getSourceById } = useSourcesStore();
const partnerCloudStore = usePartnerCloudStore();

const enabledTray = computed(() => {
  const foundTray = partnerCloudStore.enabledTray.find(
    (trayIntegration) => trayIntegration?.trayTag === pathTrayTag.value,
  );
  return foundTray;
});

const integrationProfileType = computed(() => {
  if (
    pathTrayTag.value &&
    pathTrayTag.value in MAP_TRAY_TO_INTEGRATION_PROFILE_TYPE
  ) {
    return MAP_TRAY_TO_INTEGRATION_PROFILE_TYPE[pathTrayTag.value];
  }

  return undefined;
});

const existingOpportunityPushSetting = computed(() =>
  enabledTray?.value?.configValues.find(
    (config) => config.externalId === 'external_opps-enabled',
  ),
);

const opportunityPushSetting = computed<boolean>(() =>
  JSON.parse(existingOpportunityPushSetting.value?.value ?? 'false'),
);

const isOpportunityPushEnabled = ref(opportunityPushSetting.value);
const { statusType, calloutText, statuses, hasSalesforceConnection, version } =
  useTrayStatus(enabledTray);

const excludePartnershipsCreatedSince = ref<string | undefined>(undefined);
const loading = ref(true);
const isSaving = ref(false);
const isDeleting = ref(false);
const confirmationModalType = ref<'exit' | 'delete' | null>(null);
const integrationProfileSettings = ref<IntegrationProfile | null>(null);
const existingIntegrationProfileSettings = ref<IntegrationProfile | null>(null);
const isPushEnabled = ref(enabledTray?.value?.enabled ?? false);
const originalEnabled = enabledTray?.value?.enabled; // used to track changes for triggering tray update

const { populations } = storeToRefs(usePopulationsStore());
const { isFreeTier, isEnterpriseTier } = storeToRefs(useBillingStore());

const disabled = computed(() => {
  const disableIfSalesforce =
    integrationProfileType.value === 'salesforce' &&
    !hasSalesforceConnection?.value;
  return (
    !hasPermissionToManageIntegrations.value ||
    !isIntegrationAvailable.value ||
    isSaving.value ||
    disableIfSalesforce
  );
});

const isExitModal = computed(() => confirmationModalType.value === 'exit');

const integrationDescription = computed(() => {
  return pathTrayTag.value === 'xb-clari'
    ? 'Configure the populations syncing to Clari. You can only sync Salesforce based populations and Partner Opps or Partner Customers.'
    : `Amplify and activate your ecosystem insights by pushing overlapping partner data into the Crossbeam overlaps custom object in ${friendlyName.value}.`;
});

const enableDescription = computed(() => {
  // todo confirm copy
  return `Push overlap data to ${friendlyName.value}. This will impact your record export limit`;
});

const hasPermissionToManageIntegrations = computed(() =>
  hasPermission('manage:integrations'),
);

const isIntegrationAvailable = computed(() =>
  integrationProfileType.value === 'hubspot'
    ? !isFreeTier.value
    : isEnterpriseTier.value,
);

const friendlyName = computed(() => {
  if (integrationProfileType.value === 'crossbeam_copilot') return null;
  const names = {
    clari: 'Clari',
    salesforce: 'Salesforce',
    hubspot: 'HubSpot',
    microsoft_dynamics: 'MS Dynamics',
    snowflake: 'Snowflake',
  };
  return integrationProfileType.value
    ? names[integrationProfileType.value]
    : enabledTray?.value?.name;
});

const avatarLogo = computed(() => {
  return enabledTray?.value?.avatar
    ? {
        logo_url: new URL(
          `../../assets/pngs/partner-cloud/${enabledTray?.value?.avatar}`,
          import.meta.url,
        ).href,
      }
    : null;
});

const saveButtonText = computed(() =>
  isSaving.value ? 'Saving Changes' : 'Save Changes',
);

const isIntegrationProfileConfigured = computed(() => {
  return enabledTray?.value?.configValues.some((config) => {
    const { externalId, value } = config;
    return (
      externalId === 'external_crossbeam-profile-id' &&
      value &&
      !!JSON.parse(value)
    );
  });
});

const showEmptyState = computed(() => {
  return (
    integrationProfileType.value === 'salesforce' &&
    !hasSalesforceConnection?.value
  );
});

const isTrayConfigUpdateRequired = computed(() => {
  const opportunityPushSettingChanged =
    !!existingOpportunityPushSetting.value &&
    isOpportunityPushEnabled.value !== opportunityPushSetting.value;
  const enabledSettingChanged = isPushEnabled.value !== originalEnabled;
  return (
    opportunityPushSettingChanged ||
    enabledSettingChanged ||
    !isIntegrationProfileConfigured.value
  );
});

const sfCrossbeamAppLink = computed(() => {
  return `https://login.salesforce.com/${appConfig.salesforceReauthUrl}`;
});

onMounted(async () => {
  if (integrationProfileType.value && !integrationProfileSettings.value) {
    await initProfileSettings();
  }
  loading.value = false;
});

const onDrawerClosed = () => {
  if (
    !enabledTray?.value?.enabled &&
    partnerCloudStore.inProgressInstallation
  ) {
    confirmationModalType.value = 'exit';
  } else {
    closeDrawerHandler();
  }
};

const closeDrawerHandler = () => {
  partnerCloudStore.$patch({ inProgressInstallation: null });
  confirmationModalType.value = null;
  integrationProfileSettings.value = null;
  existingIntegrationProfileSettings.value = null;
  router.push({ name: 'integrations' });
};

const onSettingsUpdated = (payload: MinimalIntegrationProfile) => {
  if (!integrationProfileSettings.value) return;
  integrationProfileSettings.value = {
    ...integrationProfileSettings.value,
    ...payload,
  };
};
function onUpdateExcludePartnershipsCreatedSince(isChecked: boolean) {
  if (!integrationProfileSettings.value) return;
  integrationProfileSettings.value = {
    ...integrationProfileSettings.value,
    exclude_partnerships_created_since: isChecked
      ? null
      : (excludePartnershipsCreatedSince.value ?? null),
  };
}

const saveChanges = () => {
  if (!integrationProfileType.value) {
    onDrawerClosed();
  } else {
    saveSettings();
  }
};

const createTrayConfigPayload = () => {
  const profileId = integrationProfileSettings.value?.profile_id;
  if (!profileId) {
    throw new Error('Integration profile settings not found');
  }

  if (!enabledTray?.value) {
    throw new Error('Tray integration info not found');
  }

  return {
    instance_name: enabledTray.value.name,
    instance_id: enabledTray.value.id,
    config: {
      integration_profile_id: profileId,
      start_timestamp: DateTime.now().plus({ minutes: 15 }).toISO(),
      enabled: isPushEnabled.value,
      opps_enabled: isOpportunityPushEnabled.value,
    },
  };
};

const saveSettings = async () => {
  if (!integrationProfileSettings.value || !enabledTray.value?.id) {
    return;
  }
  const outPayload = {
    exclude_partnerships_created_since:
      integrationProfileSettings.value.exclude_partnerships_created_since,
    population_settings:
      integrationProfileSettings.value.population_settings.map((p) => ({
        population_id: p.population_id,
        is_partner_population: p.is_partner_population,
        is_included: p.is_included,
      })),
    standard_population_settings:
      integrationProfileSettings.value.standard_population_settings.map(
        (p) => ({
          standard_type: p.standard_type,
          is_included: p.is_included,
        }),
      ),
  };

  isSaving.value = true;
  const isExcludePartnershipsCreatedSinceChanged =
    !!integrationProfileSettings.value?.exclude_partnerships_created_since !==
    !!existingIntegrationProfileSettings.value
      ?.exclude_partnerships_created_since;

  try {
    await integrationProfileStore.putIntegrationProfileSettings(
      integrationProfileSettings.value?.profile_id,
      outPayload,
    );
    await integrationProfileStore.refreshIntegrationProfileStore();
    if (isTrayConfigUpdateRequired.value) {
      const payload = createTrayConfigPayload();
      await updateTrayInstanceConfig(payload);
      await partnerCloudStore.refreshPartnerCloudStore();
    }
    addSuccessFlash({
      message: `${enabledTray.value.name} settings saved`,
      description: isTrayConfigUpdateRequired.value
        ? `Your integration configuration has been saved`
        : isExcludePartnershipsCreatedSinceChanged
          ? integrationProfileSettings.value?.exclude_partnerships_created_since
            ? 'Partners will no longer be added automatically'
            : 'New partners will be automatically added'
          : undefined,
    });
  } catch (err) {
    captureException(err);
    addErrorFlash({
      message: 'Your integration settings could not be saved',
    });
  } finally {
    closeDrawerHandler();
    isSaving.value = false;
  }
};

const setIntegrationProfileDefaults = (
  integrationProfile: IntegrationProfile,
) => {
  const standardPopSettingsRaw = [
    ...integrationProfile.standard_population_settings,
    ...defaultStandardPopSettings.filter(
      (pop) =>
        !integrationProfile?.standard_population_settings.some(
          (p) => p.standard_type === pop.standard_type,
        ),
    ),
  ];

  integrationProfileSettings.value = {
    ...integrationProfile,
    standard_population_settings: standardPopSettingsRaw,
    population_settings: integrationProfile.population_settings,
  };
  existingIntegrationProfileSettings.value = integrationProfileSettings.value;
};

type TrayUpdatedConfigPayload = {
  instance_name: string;
  instance_id: string;
  config: {
    integration_profile_id: string;
    start_timestamp: string;
    enabled: boolean;
    opps_enabled?: boolean;
  };
};
const updateTrayInstanceConfig = async (payload: TrayUpdatedConfigPayload) => {
  try {
    await crossbeamApi.PUT('/v0.1/tray-integrations', {
      body: payload,
    });
  } catch (err) {
    addErrorFlash({ message: 'Failed to set remote configuration' });
  }
};

const initProfileSettings = async () => {
  if (!integrationProfileType.value) return;

  await integrationProfileStore.readySync;
  const integrationProfile =
    integrationProfileStore.getIntegrationProfileByType(
      integrationProfileType.value,
    );
  // on initial fetch, we set the date to now
  excludePartnershipsCreatedSince.value = DateTime.now().toISO();

  if (integrationProfile) {
    setIntegrationProfileDefaults(integrationProfile);
    return;
  }

  // If there isn't an appropriate integration profile, make a new one and post it.
  const popSettings = populations.value
    .filter((pop) => {
      const { feed_id: feedId } = getSourceById(pop.source_id) as unknown as {
        feed_id: number;
      };
      const feed = getFeedById(feedId);
      return feed?.integration.type === integrationProfileType.value;
    })
    .map((pop) => {
      return {
        population_id: pop.id,
        is_partner_population: false,
        is_included: true,
      };
    });

  const payload: GetApiPayloadByUrl<'/v0.1/integration-profiles', 'post'> = {
    integration_type: integrationProfileType.value,
    population_settings: popSettings,
    standard_population_settings: defaultStandardPopSettings,
  };

  isSaving.value = true;

  try {
    const { data, error } =
      await integrationProfileStore.postIntegrationProfileSettings(payload);
    if (error) throw error;

    integrationProfileSettings.value = data;
    existingIntegrationProfileSettings.value = integrationProfileSettings.value;
    await integrationProfileStore.refreshIntegrationProfileStore();
  } catch (err) {
    if (err instanceof Error) {
      addErrorFlash({ message: err.message });
    }
  } finally {
    isSaving.value = false;
  }
};

const reauthorize = async () => {
  if (!enabledTray?.value) return;

  const { openConfigWindow } = useTrayIntegrations();
  try {
    loading.value = true;
    const body = {
      org_name: currentOrg.value.name,
      instance_name: enabledTray.value?.name,
      solution_id: enabledTray.value?.solution.id,
      instance_id: enabledTray.value?.id,
    };
    const { data } = await crossbeamApi.PATCH('/v0.1/tray-integrations', {
      body,
    });
    openConfigWindow(data);
  } catch (err) {
    addErrorFlash({
      message: 'Something went wrong',
      description: `If this error persists, please contact
      <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>.`,
    });
  } finally {
    loading.value = false;
  }
};

async function deleteIntegration() {
  if (!enabledTray.value?.id) return;
  try {
    isDeleting.value = true;
    await partnerCloudStore.deleteIntegration(enabledTray.value);
  } catch (error) {
    addErrorFlash({
      message: 'Failed to delete integration',
    });
  } finally {
    isDeleting.value = false;
    closeDrawerHandler();
  }
}

defineExpose({
  isTrayConfigUpdateRequired,
});
</script>

<style scoped lang="pcss">
.subtitle-section {
  @apply p-16 border-b border-neutral-background-disabled;
}
.subtitle-section-content {
  @apply flex items-center justify-between;
}

.c-integration-settings-alert :deep(div.self-start.pt-4) {
  @apply self-center pt-0 !important;
}
</style>
