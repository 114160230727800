<template>
  <button
    :data-testid="`preset-${props.preset.value}`"
    class="data-sharing-preset"
    :class="{
      'data-sharing-preset-active': isActive,
      'pointer-events-none': !canEditSharing,
    }"
    @click="onPresetClicked"
    type="button"
  >
    <div class="flex items-center justify-between w-full">
      <div class="flex flex-col">
        <div class="flex gap-4">
          {{ preset.name }}
          <component :is="tooltipOrDiv">
            <BittsTag size="x-small" variant="rounded">
              {{ `${fieldsForPreset.length} fields` }}
            </BittsTag>
            <template #title>
              <li
                v-for="table in sourceTables"
                :key="table"
                class="font-normal"
              >
                {{ table }}
                <ul
                  v-for="field in fieldsForTooltip[table].fields"
                  :key="`field__${field.id}`"
                  class="ml-4 font-normal"
                >
                  &bull;
                  {{
                    field.display_name
                  }}
                </ul>
              </li>
            </template>
          </component>
        </div>
        <div class="data-sharing-preset__description">
          <FontAwesomeIcon
            :icon="['fak', 'unlock']"
            class="h-8 w-8 text-neutral-accent mr-4"
          />
          {{ preset.description }}
        </div>
      </div>
      <div class="flex items-center">
        <div
          v-if="isCustom"
          class="text-sm font-normal mr-8"
          :class="isActive ? 'text-info-text' : 'text-neutral-text-weak'"
        >
          Customize
        </div>
        <FontAwesomeIcon
          :icon="iconInfo.icon"
          :class="iconInfo.class"
          class="text-16"
        />
      </div>
    </div>
    <div v-if="selectedPreset === CUSTOM && isCustom" class="w-full">
      <div class="data-sharing-preset__synced-fields">
        All Synced Fields
        <BittsTag
          v-if="allPresetFieldsChecked && !hideRecommended"
          data-testid="preset-fields-checked"
          color="success"
          size="x-small"
          variant="rounded"
          class="font-normal"
        >
          <FontAwesomeIcon
            :icon="['fak', 'check']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="text-success-accent mr-4"
          />
          All recommended fields added
        </BittsTag>
        <BittsButton
          v-else-if="!hideRecommended"
          left-icon-classes="text-neutral-accent w-12 h-12"
          :left-icon="['fak', 'add']"
          size="x-small"
          text="Add recommended fields"
          type="neutral"
          variant="outline"
          @click.stop="addRecommendedFieldsToCustom"
        />
      </div>
      <DataSharingFieldSelector
        :can-edit-sharing="canEditSharing"
        :selected-fields="customSelectedFields"
        @click.stop
        @fields-selected="(fields) => (customSelectedFields = fields)"
      />
    </div>
  </button>
</template>

<script setup>
import { BittsButton, BittsTag, BittsTooltip } from '@crossbeam/bitts';

import { computed, inject } from 'vue';

import DataSharingFieldSelector from '@/components/partners/DataSharingSettings/DataSharingFieldSelector.vue';

import { CUSTOM, DATA_SHARING_STATE_KEY } from '@/constants/data_shares';

const props = defineProps({
  canEditSharing: {
    type: Boolean,
    default: true,
  },
  hideRecommended: {
    type: Boolean,
    default: false,
  },
  preset: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const isActive = computed(() => props.preset.active);
const isCustom = computed(() => props.preset.value === CUSTOM);

const {
  addRecommendedFieldsToCustom,
  allPresetFieldsChecked,
  customSelectedFields,
  filterableSources,
  selectedPreset,
  trackPresetClicked,
} = inject(DATA_SHARING_STATE_KEY);

function onPresetClicked() {
  if (selectedPreset.value === props.preset.value) selectedPreset.value = null;
  else {
    selectedPreset.value = props.preset.value;
    trackPresetClicked.value(props.preset.value);
  }
}

const fieldsForTooltip = computed(() => {
  if (isCustom.value) return customSourcesAndFields.value;
  return props.preset.sourcesAndFields;
});
const sourceTables = computed(() => {
  return Object.keys(fieldsForTooltip.value);
});
const fieldsForPreset = computed(() => {
  if (isCustom.value) return customSelectedFields.value;
  return sourceTables.value.reduce(
    (fields, source) => [
      ...fields,
      ...props.preset.sourcesAndFields[source].fields,
    ],
    [],
  );
});
const customSourcesAndFields = computed(() => {
  if (!isCustom.value) return {};
  return filterableSources.value.reduce((sources, source) => {
    const fields = source.fields.filter((field) =>
      customSelectedFields.value.includes(field.id),
    );
    if (!fields.length) return sources;
    return { ...sources, [source.table]: { fields } };
  }, {});
});
const tooltipOrDiv = computed(() =>
  fieldsForPreset.value.length ? BittsTooltip : 'div',
);

const iconInfo = computed(() => {
  const info = {
    class: isActive.value ? 'text-info-accent' : 'text-neutral-accent',
  };
  if (isCustom.value) {
    if (isActive.value) info.icon = ['fak', 'chevron-down'];
    else info.icon = ['fak', 'chevron-right'];
  } else if (isActive.value) info.icon = ['fak', 'check'];
  else info.icon = ['fak', 'add'];
  return info;
});
</script>

<style lang="pcss" scoped>
.data-sharing-preset {
  @apply bg-neutral-background-weak py-8 px-16 rounded-bts-md
    flex flex-col gap-8 border-2 border-solid border-transparent;
  &:hover:not(.data-sharing-preset-active) {
    @apply border-info-accent;
  }
}
.data-sharing-preset-active {
  @apply bg-info-background-weak;
}
.data-sharing-preset__description {
  @apply flex items-center text-neutral-text-weak text-sm font-normal italic mt-4;
}
.data-sharing-preset__synced-fields {
  @apply flex items-center justify-between text-sm font-bold text-neutral-text mb-4 h-24;
}
</style>
