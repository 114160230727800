<template>
  <BittsModal
    title="Delete Column?"
    :content-text="content"
    confirm-type="danger"
    :width="400"
    save-text="Delete"
    :visible="true"
    @closed="onModalClosed"
    @saved="onConfirmDeleteButtonClicked"
  />
</template>
<script setup lang="ts">
import { BittsModal } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { crossbeamApi } from '@/api';
import useIteratively from '@/composables/useIteratively';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';

export type ColInfo = {
  colName: string;
  colId: string;
};

const { colInfo = null, listId = '' } = defineProps<{
  colInfo?: ColInfo | null;
  listId?: string;
}>();

const emit = defineEmits<(e: 'closed' | 'saved') => void>();

const { iteratively } = useIteratively();

const flashesStore = useFlashesStore();

const content = `Are you sure you want to delete this column?
  Your collaborators will no longer be able to see these columns, and this action can not be undone.`;

function onModalClosed() {
  emit('closed');
}

async function onConfirmDeleteButtonClicked() {
  try {
    if (!listId || !colInfo?.colId) return;
    const path = { list_id: listId, column_id: colInfo?.colId };
    await crossbeamApi.DELETE('/v0.1/lists/{list_id}/columns/{column_id}', {
      params: { path },
    });
    iteratively.userActsOnListColumns({
      column_action: 'Delete Column',
      column_name: colInfo?.colName,
      event_site: EVENT_SITES.SHARED_LIST_DELETE_COLS_MODAL,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
    flashesStore.addSuccessFlash({
      message: 'Your column was successfully deleted',
    });
    emit('saved');
  } catch (e) {
    emit('closed');
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
    captureException(e);
  }
}
</script>
