<template>
  <div
    :class="{
      'pb-16': feedIsErrored,
      disabled: feedIsDeleting,
    }"
    class="c-snowflake-feed c-feed-list__feed"
  >
    <div class="flex justify-between p-16 ml-24">
      <slot name="left-content" />
      <div v-if="feedStatus" class="flex items-center gap-10">
        <slot
          name="status"
          :icon-class="feedStatus.bgColor"
          :text="feedStatus.text"
        />
      </div>
      <Sync v-if="hasSnowflakeSyncNow" :feed="feed" />
      <slot name="feed-settings-popover" />
    </div>
    <slot v-if="feedIsErrored" name="feed-error" />
  </div>
</template>

<script>
import { computed } from 'vue';

import Sync from '@/components/data-sources/Sync.vue';

import useFeedDetails from '@/composables/useFeedDetails';
import useFeedSyncing from '@/composables/useFeedSyncing';
import { SNOWFLAKE_PULL_LIMIT } from '@/constants/feature_flags';
import { useFeatureFlagStore } from '@/stores';

export default {
  name: 'SnowflakeFeed',
  components: {
    Sync,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const featureFlagStore = useFeatureFlagStore();
    const hasSnowflakeSyncNow = computed(() =>
      featureFlagStore.hasFeatureFlag(SNOWFLAKE_PULL_LIMIT),
    );

    const { feedStatus, feedIsErrored, feedIsDeleting } = useFeedDetails({
      feedId: props.feed.id,
    });

    /* Polls the feed when it goes into a syncing state */
    useFeedSyncing({ feed: props.feed });

    return {
      feedIsDeleting,
      feedStatus,
      feedIsErrored,
      hasSnowflakeSyncNow,
    };
  },
};
</script>
