<template>
  <div class="flex flex-col gap-12">
    <BittsCard
      v-for="org in partnerOrgs"
      :key="org?.id"
      :no-padding="false"
      class="cursor-pointer"
      @click="emits('partnerSelected', org)"
    >
      <div class="flex justify-between items-center">
        <BittsAvatarItem :org :title="org?.name" :description="org?.domain" />
        <FontAwesomeIcon
          :icon="['fak', 'chevron-right']"
          :style="{ height: '16px', width: '16px' }"
          class="text-neutral-accent"
        />
      </div>
    </BittsCard>
  </div>
</template>
<script setup>
import { BittsAvatarItem, BittsCard } from '@crossbeam/bitts';
defineProps({
  partnerOrgs: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits(['partnerSelected']);
</script>
