<template>
  <a :href="href" class="c-support-link">support@crossbeam.com</a>
</template>

<script>
export default {
  name: 'Support',
  props: {
    subject: {
      type: String,
      default: '',
    },
  },
  computed: {
    queryString() {
      return this.subject ? `?Subject=${encodeURIComponent(this.subject)}` : '';
    },
    href() {
      return `mailto:support@crossbeam.com${this.queryString}`;
    },
  },
};
</script>
<style lang="pcss">
.c-support-link {
  @apply text-teal-700;
}
</style>
