const ATTRIBUTION = 'attribution';
const DEAL_DASHBOARD = 'deal_dashboard';
const ECOSYSTEM_INSIGHTS = 'ecosystem_insights';
const REPORT_FOLDER = 'folder';
const REPORTS = 'reports';

export const BASE_ROUTES = {
  ATTRIBUTION,
  DEAL_DASHBOARD,
  ECOSYSTEM_INSIGHTS,
  REPORT_FOLDER,
  REPORTS,
};

export const CHILD_ROUTES = {
  [ATTRIBUTION]: {
    ATTRIBUTE: 'attribute',
    ATTRIBUTION_SETTINGS: 'attribution_settings',
    ATTRIBUTION_TIMELINE: 'attribution_timeline',
  },
  [ECOSYSTEM_INSIGHTS]: {
    ECOSYSTEM_INSIGHTS_TIMELINE: 'ecosystem_insights_timeline',
    ECOSYSTEM_INSIGHTS_ATTRIBUTE: 'ecosystem_insights_attribute',
  },
  [REPORT_FOLDER]: {
    REPORTS_DELETE: 'folders.delete_report',
    REPORTS_DUPLICATE: 'folders.duplicate_report',
    REPORTS_NOTIFICATIONS: 'folders.report_notifications',
  },
  [REPORTS]: {
    REPORTS_DELETE: 'delete_report',
    REPORTS_DUPLICATE: 'duplicate_report',
    REPORTS_NOTIFICATIONS: 'report_notifications',
  },
};
