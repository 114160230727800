<template>
  <div class="timeline-skeleton mb-24">
    <div class="flex justify-between items-center mb-4">
      <div class="timeline-skeleton__avatar offset-avatar mr-8 loading" />
      <div class="grow">
        <div class="timeline-skeleton__title loading-dark max-w-1/2" />
      </div>
      <div class="timeline-skeleton__avatar loading" />
    </div>
    <div class="px-20">
      <div class="timeline-skeleton__subtitle loading max-w-1/4" />
      <div class="timeline-skeleton__content loading" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimelineEventSkeleton',
};
</script>

<style lang="pcss">
.timeline-skeleton {
  .loading {
    background: linear-gradient(
      to right,
      theme(colors.neutral.background-weak) 25%,
      theme(colors.neutral.background-disabled) 40%,
      theme(colors.neutral.background-weak) 65%
    );
    background-size: 400% 100%;
    animation: loading-timeline-skeleton 1.4s ease infinite;
  }
  .loading-dark {
    background: linear-gradient(
      to right,
      theme(colors.neutral.background-disabled) 25%,
      theme(colors.neutral.background-weak) 40%,
      theme(colors.neutral.background-disabled) 65%
    );
    background-size: 400% 100%;
    animation: loading-timeline-skeleton 1.4s ease infinite;
  }

  .offset-avatar {
    margin-left: -12px;
  }

  &__avatar {
    @apply bg-neutral-100 rounded h-24 w-24;
  }

  &__title {
    @apply bg-neutral-200 rounded h-16;
  }

  &__content {
    @apply bg-neutral-200 rounded-8 h-64;
  }

  &__subtitle {
    @apply bg-neutral-100 rounded h-8 mb-8;
  }

  @keyframes loading-timeline-skeleton {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
}
</style>
