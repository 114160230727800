<template>
  <div class="c-pwd-strength">
    <span
      v-for="level in passwordLevels"
      :key="`bar_${level}`"
      class="c-pwd-strength__bar"
      :class="getBarColor(level)"
    />
    <div class="c-pwd-strength__explanation">
      {{ pwdStrengthMsg }}<span v-if="pwdFeedback"> - {{ pwdFeedback }} </span>
    </div>
  </div>
</template>
<script>
import { debounce, zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { adjacencyGraphs, dictionary } from '@zxcvbn-ts/language-common';
import { translations } from '@zxcvbn-ts/language-en';

const options = {
  translations,
  graphs: adjacencyGraphs,
  dictionary: {
    ...dictionary,
    ...dictionary,
  },
};

/* This library scores a password from 0-4 */
zxcvbnOptions.setOptions(options);

export default {
  name: 'PasswordStrengthBar',
  props: {
    password: {
      type: String,
      required: true,
    },
    limitVisibleScore: {
      type: Number,
      default: 4,
    },
    limitFeedback: {
      type: String,
      default: '',
    },
  },
  emits: ['password-score'],
  data() {
    return {
      result: null,
      passwordLevels: [0, 1, 2, 3, 4],
    };
  },
  computed: {
    pwdStrength() {
      /* If the password is limited, return the lower of the two,
        otherwise just return the score */
      const resultScore = this.result?.score || 0;
      if (this.limitVisibleScore)
        return Math.min(resultScore, this.limitVisibleScore);
      return resultScore;
    },
    pwdStrengthMsg() {
      if (this.pwdStrength === 4) return 'Password is very strong!';
      if (this.pwdStrength === 3) return 'Password is strong';
      if (this.pwdStrength === 2) return 'Password is okay';
      if (this.pwdStrength === 1) return 'Password is weak';
      return 'Password is very weak';
    },
    pwdFeedback() {
      if (this.result?.feedback.warning) return this.result.feedback.warning;
      if (this.limitFeedback && this.limitVisibleScore) {
        if (
          this.pwdStrength <= this.limitVisibleScore &&
          this.result?.score >= 3
        ) {
          return this.limitFeedback;
        }
      }
      if (this.result?.score < 4) return 'Try adding more characters or words!';
      return '';
    },
  },
  watch: {
    password(newVal) {
      this.debouncePwdCheck(newVal);
    },
  },
  created() {
    this.result = zxcvbn(this.password);
    this.emitPasswordScore();
  },
  methods: {
    getBarColor(level) {
      if (
        (this.pwdStrength === 0 && level === 0) ||
        (this.pwdStrength === 1 && level <= 1)
      )
        return 'c-pwd-strength__bar--failure';
      if (this.pwdStrength === 2 && level <= 2)
        return 'c-pwd-strength__bar--warning';
      if (this.pwdStrength >= 3 && level <= this.pwdStrength)
        return 'c-pwd-strength__bar--success';
      return 'c-pwd-strength__bar--neutral';
    },
    debouncePwdCheck: debounce(function (pwd) {
      this.result = zxcvbn(pwd);
      this.emitPasswordScore();
    }, 200),
    emitPasswordScore() {
      this.$emit('password-score', this.result?.score);
    },
  },
};
</script>
<style lang="pcss">
.c-pwd-strength {
  @apply flex items-center flex-wrap gap-4;
}

.c-pwd-strength__bar {
  @apply h-8 flex-1 rounded-3;
}

.c-pwd-strength__bar--success {
  @apply bg-success-accent;
}

.c-pwd-strength__bar--warning {
  @apply bg-warning-accent;
}

.c-pwd-strength__bar--failure {
  @apply bg-danger-accent;
}

.c-pwd-strength__bar--neutral {
  @apply border border-neutral-300;
}
.c-pwd-strength__explanation {
  @apply w-full text-sm text-neutral-text-weak text-left leading-4;
}
</style>
