<template>
  <div class="c-seat-summary-card-counter">
    <div v-if="label" class="seat-label">
      {{ label }}
    </div>
    <div class="seat-count-info">
      <div>
        <span
          :class="{ 'text-neutral-text-strong': occupied }"
          data-testid="occupied-seat-count"
        >
          {{ occupied }}
        </span>
        <span>/</span>
        <span data-testid="total-seat-count">
          {{ computedTotal }}
        </span>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  occupied: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
  label: {
    type: String,
    default: '',
  },
});

const computedTotal = computed(() =>
  props.total === Infinity ? 'Custom' : props.total,
);
</script>

<style lang="pcss" scoped>
.c-seat-summary-card-counter {
  @apply text-xl font-bold text-neutral-text-placeholder flex-1 block bg-white;
}

.seat-label {
  @apply text-base text-neutral-text-weak font-normal flex gap-4 items-center;
}

.seat-count-info {
  @apply flex items-center justify-between;
}
</style>
