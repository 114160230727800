import { watch } from 'vue';

import useFeedDetails from '@/composables/useFeedDetails';
import usePolling from '@/composables/usePolling';
import { captureException } from '@/errors';
import { useFeedsStore, useFlashesStore } from '@/stores';

/* Polls the feed when it goes into a syncing state */
export default function ({ feed }) {
  const feedsStore = useFeedsStore();
  const flashesStore = useFlashesStore();

  const { feedIsSyncing } = useFeedDetails({ feedId: feed.id });

  const poll = usePolling({
    interval: 3000,
    async poller() {
      await feedsStore.refreshFeed(feed.id);
    },
    shouldComplete() {
      return !feedIsSyncing.value;
    },
    onComplete() {
      flashesStore.addSuccessFlash({
        message: `Your ${feed.integration.friendly_name} data source has finished syncing`,
      });
    },
    onError(err) {
      captureException(err);
      flashesStore.addUnhandledError(err);
    },
  });

  let timeout = null;
  function startSyncing() {
    timeout = poll();
  }

  if (feedIsSyncing.value) startSyncing();
  watch(feedIsSyncing, (isSyncing) => {
    if (isSyncing && !timeout) startSyncing();
  });
}
