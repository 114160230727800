<template>
  <div class="c-expandable-card">
    <div class="c-expandable-card__header">
      <slot name="header" />
    </div>
    <div class="c-expandable-card__content">
      <transition name="fade" mode="out-in">
        <slot v-if="!isExpanded" :toggle="toggle" name="collapsed" />
        <slot v-else :toggle="toggle" name="expanded" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpandableCard',
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-expandable-card {
  display: flex;
  flex-direction: column;
}

.c-expandable-card__content {
  margin: 12px 0px;
  background: var(--white);
  border: 1px solid var(--grey6);
  border-radius: 4px;
  min-height: 100px;
}
</style>
