<template>
  <div
    class="bitts-cta"
    :class="{
      [`bitts-cta-${size}`]: true,
      smaller: smaller,
    }"
  >
    <!-- This component either renders this extra small tag
    or the larger section which handles the other sizes -->
    <button
      v-if="size === 'x-small'"
      :class="{ 'cursor-not-allowed': !hasBillingPermission }"
      data-testid="bitts-cta-button"
      class="bitts-cta-button"
      @click="ctaButtonClicked"
      type="button"
    >
      <FontAwesomeIcon
        :icon="['fak', 'upgrade']"
        class="bitts-cta-icon bitts-cta-icon--small"
      />
      {{ extraSmallText }}
    </button>
    <div v-else class="bitts-cta-info">
      <div class="flex flex-col">
        <div class="main-content">
          <FontAwesomeIcon
            v-if="
              size === 'medium' || size === 'small' || size === 'large-vertical'
            "
            :icon="['fak', 'upgrade']"
            class="bitts-cta-icon bitts-cta-icon--main"
          />
          <div>
            <div class="flex items-center">
              <div data-testid="bitts-cta-message" class="bitts-cta-message">
                {{ message }}
              </div>
            </div>
            <div
              data-testid="bitts-cta-description"
              class="bitts-cta-description"
            >
              {{ description }}
            </div>
          </div>
        </div>
      </div>
      <div class="bitts-cta-clickable">
        <component
          :is="hasBillingPermission ? 'div' : BittsTooltip"
          placement="bottomLeft"
        >
          <div>
            <BittsButton
              :disabled="!hasBillingPermission"
              :text="buttonText ?? 'Upgrade'"
              :size="buttonSize"
              type="upsell"
              :left-icon="size === 'large' ? ['fak', 'upgrade'] : null"
              data-testid="bitts-cta-button"
              class="bitts-cta-button"
              @click="ctaButtonClicked"
            />
          </div>
          <template #title>
            You must be an admin to upgrade your account
            <a
              v-if="learnMoreExternalLink"
              :href="learnMoreExternalLink"
              target="_blank"
              class="learn-more-button"
            >
              Learn More
            </a>
          </template>
        </component>
        <div v-if="showLearnMoreLink" class="learn-more-link">
          <a
            :href="learnMoreExternalLink"
            target="_blank"
            class="learn-more-link__text"
          >
            Learn More
          </a>
          <FontAwesomeIcon
            :icon="['fak', 'open-link']"
            class="learn-more-link__icon"
          />
        </div>
      </div>
    </div>
    <div v-if="size === 'large'">
      <div class="bitts-cta-image hidden xl:block">
        <slot name="image" />
      </div>
      <div class="bitts-cta-image__background hidden xl:block" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsTooltip } from '@crossbeam/bitts';
import type { BittsButtonSize } from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import { BillingInteraction, BillingQuery } from '@/types/billing';

interface Props {
  buttonText?: string | null;
  message?: string;
  description?: string;
  learnMoreExternalLink?: string;
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'large-vertical';
  billingInteraction: BillingInteraction;
  billingQuery?: BillingQuery;
  routeTo?: { name: string } | null;
}

const props = withDefaults(defineProps<Props>(), {
  buttonText: null,
  message: '',
  description: '',
  learnMoreExternalLink: '',
  size: 'medium',
  billingQuery: null,
  routeTo: null,
});

const emit = defineEmits<(e: 'cta-button-clicked') => void>();

const SIZE_TO_BUTTON_SIZE: Record<
  NonNullable<Props['size']>,
  BittsButtonSize
> = {
  'x-small': 'small',
  small: 'small',
  medium: 'small',
  large: 'medium',
  'large-vertical': 'medium',
};

const { hasPermission } = useAuth();
const { handleBillingInteraction } = useBilling();

const buttonSize = computed<BittsButtonSize>(() => {
  if (smaller.value) return 'x-small';
  return SIZE_TO_BUTTON_SIZE[props.size];
});

const extraSmallText = computed(() => props.buttonText ?? 'Upgrade');
const hasBillingPermission = computed(
  () => hasPermission('manage:billing') || import.meta?.env?.STORYBOOK,
);
const showLearnMoreLink = computed(
  () =>
    !!props.learnMoreExternalLink &&
    ['large', 'large-vertical'].includes(props.size),
);
const smaller = computed(() => props.size === 'small' && !props.message);

async function ctaButtonClicked() {
  if (!hasBillingPermission.value) return;
  if (props.billingInteraction) {
    await handleBillingInteraction(
      props.billingInteraction,
      props.billingQuery ?? null,
      false,
      props.routeTo,
    );
  } else emit('cta-button-clicked');
}
</script>

<style lang="pcss" scoped>
.bitts-cta {
  /* Common styles */
  @apply text-neutral-text;
  background: var(
    --background-upsell-gradient-weak,
    linear-gradient(
      92deg,
      theme(colors.upsell.background-weak) 0%,
      theme(colors.upsell.background-weak / 0.2) 100%
    )
  );

  .bitts-cta-clickable {
    @apply flex items-center gap-8;
    .learn-more-button {
      @apply w-full py-4 px-16 bg-white bg-opacity-20 text-white block rounded-4 text-center my-4;
    }
  }

  .learn-more-link {
    @apply ml-8;
    &:hover {
      @apply opacity-90 cursor-pointer;
    }

    .learn-more-link__text {
      @apply text-neutral-text-button font-bold text-m;
    }

    .learn-more-link__icon {
      @apply text-16 text-blue-600;
    }
  }

  .bitts-cta-message {
    @apply font-bold text-upsell-text-strong;
  }

  .bitts-cta-description {
    @apply text-upsell-text;
  }

  .bitts-cta-icon {
    @apply text-upsell-accent overflow-hidden;
  }

  /* Size-specific styles */
  &.bitts-cta-x-small {
    @apply rounded-bts-lg px-8 inline-block;
    .bitts-cta-message {
      @apply text-base inline cursor-pointer;
    }
    .bitts-cta-message {
      @apply text-upsell-text;
    }
    .bitts-cta-button {
      @apply text-upsell-text-strong;
    }
    .bitts-cta-icon--small {
      /* This styling is preferable to flex because our font has a weird line height issue */
      @apply w-12 h-12 mr-2;
    }
  }

  &.bitts-cta-small,
  &.bitts-cta-medium {
    .main-content {
      @apply flex items-center;
    }
    .bitts-cta-info {
      @apply flex items-center justify-between;
    }
    .learn-more-link__text {
      @apply text-sm;
    }
    .bitts-cta-button {
      @apply ml-8;
    }
  }

  &.bitts-cta-small {
    @apply p-16 rounded-bts-lg;
    .main-content {
      @apply gap-8;
    }
    .bitts-cta-message {
      @apply text-sm mb-4;
    }
    .bitts-cta-icon--main {
      @apply min-w-[24px] min-h-[24px];
    }
    .bitts-cta-description {
      @apply text-sm;
    }
  }

  &.smaller {
    @apply py-8 px-12 rounded-md;
    .bitts-cta-icon--main {
      @apply min-w-[16px] min-h-[16px];
    }
  }

  &.bitts-cta-medium {
    @apply p-24 py-16 rounded-bts-lg;
    .main-content {
      @apply gap-16;
    }
    .bitts-cta-icon--main {
      @apply min-w-[24px] min-h-[24px];
    }
    .bitts-cta-message {
      @apply text-base;
    }
    .bitts-cta-description {
      @apply text-base;
    }
  }

  &.bitts-cta-large {
    @apply flex items-stretch justify-between rounded-bts-lg;
    .bitts-cta-message {
      @apply text-lg mb-8;
    }
    .bitts-cta-description {
      @apply mb-40 mr-24;
    }
    .bitts-cta-icon {
      @apply mb-8;
    }
    .bitts-cta-info {
      @apply p-40 max-w-full xl:max-w-[50%];
    }
    .learn-more-link__text {
      @apply text-m;
    }
  }

  &.bitts-cta-large-vertical {
    @apply rounded-bts-lg;

    .main-content {
      @apply flex flex-col gap-24 items-center;
    }
    .bitts-cta-icon--main {
      @apply min-w-[40px] min-h-[40px];
    }
    .bitts-cta-message {
      @apply text-lg w-full text-center mb-8;
    }
    .bitts-cta-description {
      @apply mb-40 mr-24 w-full text-center;
    }
    .bitts-cta-info {
      @apply py-40 px-40 w-full flex flex-col items-center;
    }
  }

  &.bitts-large,
  &.bitts-large-vertical {
    @apply gap-16;
    .bitts-cta-clickable {
      @apply flex items-center gap-8;
    }
  }
}
</style>

<style lang="pcss">
.bitts-cta {
  position: relative;
  overflow: hidden;
  .bitts-cta-image {
    max-height: 210px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    img {
      max-width: 400px;
      transform: translate(-40px, 20px);
    }
  }

  .bitts-cta-image__background {
    @apply rounded-r-16 absolute right-[57px] h-[211px] w-[500px];
    bottom: 0px;
    right: 0px;
    background-image: url('../../../assets/pngs/ctas/cta-pattern.png');
    background-size: cover;
  }
}
</style>
