<template>
  <div class="c-activity-timeline">
    <div class="c-activity-timeline__wrapper fullstory-hide dd-privacy-mask">
      <h1 v-if="!hasTempEcosystemActivities" class="c-activity-timeline__title">
        Activity Timeline
      </h1>
      <template v-if="!pageLoading">
        <BittsEmptyState
          v-if="showEmptyState"
          title="There's nothing to see here, yet"
          class="c-activity-timeline__empty-state lg:px-120"
          svg-name="timelineNoEventsYet"
        >
          <template #subtitle>
            <p class="text-center text-neutral-500 leading-6">
              {{ flaggedEmptyStateText }}

              <BittsLink
                v-if="!hasEvents"
                class="mt-4"
                text="View our help documentation"
                url="https://help.crossbeam.com/en/articles/3519493-create-reports"
              />
            </p>
            <router-link :to="{ name: 'account_mapping.create' }">
              <BittsButton
                v-if="hasScope('write:reports') && !kafkaEvents.length"
                text="Create report"
                class="mt-24"
              />
            </router-link>
          </template>
        </BittsEmptyState>
        <ActivityTimelineDeux
          v-else-if="hasTempEcosystemActivities"
          :events="filteredEvents"
          :account-name="accountName"
          class="mt-16"
        />
        <ActivityTimeline
          v-else
          :events="filteredEvents"
          :account-name="accountName"
          class="mt-16"
        />
      </template>
      <div v-else class="pl-8 mt-20">
        <component
          :is="
            hasTempEcosystemActivities
              ? TimelineEventSkeletonDeux
              : TimelineEventSkeleton
          "
          v-for="skeleton in 4"
          :key="skeleton"
        />
      </div>
    </div>
    <TimelineSidebar
      v-if="!pageLoading && !hasTempEcosystemActivities"
      :selected-filters="selectedFilters"
      :partner-orgs-in-record="partnerOrgsInRecord"
      :org-filters="orgFilters"
      :partner-stack-is-active="partnerStackIsActive"
      :gong-is-active="gongIsActive"
      :visible="hasVisiblePartnerEvents"
      @org-filter-checked="orgClickHandler"
      @filter-checked="onCheckedKeys"
    />
    <TimelineSidebarDeux
      v-if="!pageLoading && hasTempEcosystemActivities"
      :selected-filters="selectedFilters"
      :partner-orgs-in-record="partnerOrgsInRecord"
      :org-filters="orgFilters"
      :partner-stack-is-active="partnerStackIsActive"
      :gong-is-active="gongIsActive"
      :visible="hasVisiblePartnerEvents"
      @org-filter-checked="orgClickHandler"
      @filter-checked="onCheckedKeys"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsEmptyState, BittsLink } from '@crossbeam/bitts';

import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import ActivityTimeline from '@/components/ActivityTimeline.vue';
import ActivityTimelineDeux from '@/components/ActivityTimelineDeux.vue';
import TimelineEventSkeleton from '@/components/records/Timeline/TimelineEventSkeleton.vue';
import TimelineEventSkeletonDeux from '@/components/records/Timeline/TimelineEventSkeletonDeux.vue';
import TimelineSidebar from '@/components/records/Timeline/TimelineSidebar.vue';
import TimelineSidebarDeux from '@/components/records/Timeline/TimelineSidebarDeux.vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useIntegrations from '@/composables/useIntegrations';
import { TEMP_ECOSYSTEM_ACTIVITIES } from '@/constants/feature_flags';
import {
  ALL_EVENT_FILTERS,
  ATTRIBUTION_ACTIVITY,
  GONG,
  INTEGRATIONS,
  OVERLAP_MOVEMENT,
  PARTNERSTACK,
  POPULATION_MOVEMENT,
  SALES_EDGE_ACTIVITY,
} from '@/constants/timeline';
import { captureException } from '@/errors';
import { useFeatureFlagStore, usePartnersStore } from '@/stores';
import { Partner } from '@/types/partners';
import { TimelineEvent } from '@/types/timeline';

type EventType =
  | typeof GONG
  | typeof PARTNERSTACK
  | typeof ATTRIBUTION_ACTIVITY
  | typeof OVERLAP_MOVEMENT
  | typeof POPULATION_MOVEMENT
  | typeof SALES_EDGE_ACTIVITY
  | undefined;
type TimelineEventWithEventType = TimelineEvent & {
  eventType: EventType;
};

const {
  sourceId,
  sourcePrimaryKey,
  accountName = '',
} = defineProps<{
  sourceId: number;
  sourcePrimaryKey: string;
  accountName?: string;
}>();

const EVENT_TYPE_LOOKUP: Record<string, EventType> = {
  partner_mention: GONG,
  partnerstack_lead_created: PARTNERSTACK,
  attribution_created: ATTRIBUTION_ACTIVITY,
  attribution_updated: ATTRIBUTION_ACTIVITY,
  attribution_deleted: ATTRIBUTION_ACTIVITY,
  overlap_entry: OVERLAP_MOVEMENT,
  overlap_exit: OVERLAP_MOVEMENT,
  population_entry: POPULATION_MOVEMENT,
  population_exit: POPULATION_MOVEMENT,
  request_received: SALES_EDGE_ACTIVITY,
  request_sent: SALES_EDGE_ACTIVITY,
};

const EVENTS_WITHOUT_PARTNER = [
  'population_entry',
  'population_exit',
  'partnerstack_lead_created',
];

const NUM_EVENTS_OFFSET = 20;

const filteredEvents = ref<TimelineEventWithEventType[]>([]);
const kafkaEvents = ref<TimelineEventWithEventType[]>([]);
const loadUntil = ref(NUM_EVENTS_OFFSET);
const pageLoading = ref(true);
const pageError = ref<Error | null>(null);
const polling = ref<ReturnType<typeof setInterval> | null>(null);
const orgFilters = ref<Record<string, boolean>>({});
const selectedFilters = ref<string[]>([]);

const { allEnabledIntegrations, partnerStackIsActive } = useIntegrations();
const { hasScope } = useAuth();

const { hasFeatureFlag } = useFeatureFlagStore();
const { getPartnerOrgByUuid } = usePartnersStore();

const hasTempEcosystemActivities = computed(() => {
  return hasFeatureFlag(TEMP_ECOSYSTEM_ACTIVITIES);
});

const eventFilterTypes = [
  { type: INTEGRATIONS, key: ALL_EVENT_FILTERS[INTEGRATIONS] },
  { type: ATTRIBUTION_ACTIVITY, key: ALL_EVENT_FILTERS[ATTRIBUTION_ACTIVITY] },
  { type: POPULATION_MOVEMENT, key: ALL_EVENT_FILTERS[POPULATION_MOVEMENT] },
  { type: OVERLAP_MOVEMENT, key: ALL_EVENT_FILTERS[OVERLAP_MOVEMENT] },
  { type: SALES_EDGE_ACTIVITY, key: ALL_EVENT_FILTERS[SALES_EDGE_ACTIVITY] },
];

const hasEvents = computed(() => kafkaEvents.value.length > 0);

const flaggedEmptyStateText = computed(() => {
  if (!hasEvents.value) {
    return `Activities between you and your partners on this account will appear here.
Try creating a report with your partner to see your overlaps.`;
  }
  if (hasEvents.value && !filteredEvents.value.length) {
    return `There are currently no activities that match these filters.
         Try expanding your search by removing filters.`;
  }
  return '';
});

const showEmptyState = computed(() => {
  return !pageLoading.value && !filteredEvents.value.length;
});

const hasSomeOrgFiltersSelected = computed(() => {
  return Object.values(orgFilters.value).some((val) => val);
});

const hasSomeFiltersSelected = computed(() => {
  return selectedFilters.value.length > 0;
});

const filterFunctions = {
  [PARTNERSTACK]: filterIntegrations,
  [GONG]: filterIntegrations,
  [ATTRIBUTION_ACTIVITY]: filterCrossbeamActivities,
  [OVERLAP_MOVEMENT]: filterCrossbeamActivities,
  [POPULATION_MOVEMENT]: filterCrossbeamActivities,
  [SALES_EDGE_ACTIVITY]: filterCrossbeamActivities,
};

const partnerOrgsInRecord = computed(() => {
  const partnerUuids = kafkaEvents.value.map(
    (event) => event.partner_organization_uuid,
  );
  return [...new Set(partnerUuids)].reduce((finalPartners, currentId) => {
    if (!currentId) return finalPartners;
    const partnerInStore = getPartnerOrgByUuid(currentId, false);
    if (partnerInStore) finalPartners.push(partnerInStore);
    return finalPartners;
  }, [] as Partner[]);
});

const hasVisiblePartnerEvents = computed(() => {
  const partnerEvents = kafkaEvents.value.some(
    (event) => event?.partner_organization_uuid,
  );
  return !!partnerEvents;
});

const gongIsActive = computed(() => {
  return !!allEnabledIntegrations.value.find(
    (integration) => integration.type === 'tray_gong',
  );
});

watch(filteredEvents, () => {
  if (
    loadUntil.value > filteredEvents.value.length &&
    loadUntil.value > NUM_EVENTS_OFFSET &&
    hasSomeFiltersSelected.value &&
    hasSomeOrgFiltersSelected.value
  ) {
    loadUntil.value = filteredEvents.value.length;
  }
});

onMounted(async () => {
  await initialize();
  polling.value = setInterval(onPoll, 10000);

  selectedFilters.value = [
    ALL_EVENT_FILTERS[ATTRIBUTION_ACTIVITY],
    ALL_EVENT_FILTERS[POPULATION_MOVEMENT],
    ALL_EVENT_FILTERS[OVERLAP_MOVEMENT],
    ALL_EVENT_FILTERS[SALES_EDGE_ACTIVITY],
    ALL_EVENT_FILTERS[INTEGRATIONS],
  ];

  partnerOrgsInRecord.value.forEach((partnerOrg) => {
    orgFilters.value[partnerOrg.uuid] = true;
  });

  filterEvents();
});

onUnmounted(() => {
  if (polling.value) {
    clearInterval(polling.value);
  }
});

async function onPoll() {
  await updateTimeline();
}

function orgClickHandler({ id }: { id: string }) {
  orgFilters.value[id] = !orgFilters.value[id];
  filterEvents();
}

async function updateTimeline() {
  await fetchEvents();
}

async function initialize() {
  pageLoading.value = true;
  try {
    const partnersStore = usePartnersStore();
    const promises = [updateTimeline(), partnersStore.readySync];
    await Promise.all(promises);
  } catch (err) {
    pageError.value = err as Error;
    captureException(err);
  } finally {
    pageLoading.value = false;
  }
}

async function fetchEvents() {
  const allEvents: TimelineEvent[] = [];
  const limit = 1000;
  let page = 1;
  let nextHref: string | null | undefined;

  const response = await crossbeamApi.GET(
    '/v0.1/activity-timeline/{source_id}/{master_id}',
    {
      params: {
        query: {
          limit,
          page,
        },
        path: {
          source_id: sourceId,
          master_id: sourcePrimaryKey,
        },
      },
    },
  );

  if (response) allEvents.push(...(response.data?.items ?? []));
  nextHref = response?.data?.pagination.next_href;
  page = (response?.data?.pagination.page ?? 0) + 1;

  while (nextHref) {
    const { data } = await crossbeamApi.GET(
      '/v0.1/activity-timeline/{source_id}/{master_id}',
      {
        params: {
          query: {
            page,
            limit,
          },
          path: {
            source_id: sourceId,
            master_id: sourcePrimaryKey,
          },
        },
      },
    );
    allEvents.push(...(data?.items ?? []));
    nextHref = data?.pagination.next_href;
    page = (data?.pagination.page ?? 0) + 1;
  }

  kafkaEvents.value = allEvents
    .map(addEventType)
    .filter(
      (event) =>
        event.eventType &&
        Object.values(EVENT_TYPE_LOOKUP).includes(event.eventType),
    );
  filterEvents();
}

function addEventType(event: TimelineEvent): TimelineEventWithEventType {
  const eventType = event.type;
  return {
    ...event,
    eventType:
      eventType in EVENT_TYPE_LOOKUP
        ? EVENT_TYPE_LOOKUP[eventType as keyof typeof EVENT_TYPE_LOOKUP]
        : undefined,
  };
}

function onCheckedKeys({ keys }: { keys: string[] }) {
  selectedFilters.value = keys;
  filterEvents();
}

function filterEvents() {
  filteredEvents.value = kafkaEvents.value
    .filter((event) => {
      const defaultFilterFunction = () => false;
      const filterFunction =
        (event.eventType && filterFunctions[event.eventType]) ||
        defaultFilterFunction;
      return filterFunction(event);
    })
    .filter(filterByOrg);
}

function filterIntegrations(event: TimelineEventWithEventType) {
  if (selectedFilters.value?.includes(ALL_EVENT_FILTERS[INTEGRATIONS])) {
    return true;
  }
  return !!event.eventType && selectedFilters.value.includes(event.eventType);
}

function filterCrossbeamActivities(event: TimelineEventWithEventType) {
  const eventParentType = event.eventType;
  const allSelector = eventFilterTypes.find(
    (eventFilterType) => eventFilterType.type === eventParentType,
  )?.key;

  if (allSelector && selectedFilters.value?.includes(allSelector)) {
    return true;
  }
  return selectedFilters.value?.includes(event.type);
}

function filterByOrg(event: TimelineEventWithEventType) {
  // don't filter out events that never have a partner on them
  if (EVENTS_WITHOUT_PARTNER.includes(event.type)) return event;
  if (!event.partner_organization_uuid) return event;
  return orgFilters.value[event.partner_organization_uuid];
}
</script>

<style lang="pcss">
.c-activity-timeline {
  @apply grid grid-cols-10 gap-24;

  .c-activity-timeline__wrapper {
    @apply col-span-7;
  }

  .c-activity-timeline__title {
    @apply text-lg font-bold text-neutral-text-strong mb-8;
  }

  .c-activity-timeline__sidebar {
    @apply col-span-3 self-start sticky flex-1;
    --offset: 120px;
    top: 120px;
  }

  .c-activity-timeline__org-selector {
    @apply flex items-center justify-between py-12 px-16 border-b border-neutral-border;

    &:last-child {
      @apply border-0;
    }
  }
  .c-activity-timeline__empty-state {
    &.c-bitts-empty-state-large-border {
      @apply bg-white;
    }
    @apply px-24 py-72;
    img {
      @apply w-260;
    }
  }
}
</style>
