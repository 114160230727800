import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import appConfig from '@/config';
import { SALESFORCE_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

type ConnectionType = 'salesforce' | 'snowflake';
export interface Connection {
  id?: string;
  integration_type: ConnectionType;
  organization_id: number;
  connected_at: string;
  connected_by_user_id: number;
  connected_by_user_email: string;
  is_paused?: boolean;
  error_message?: string;
  date_last_pushed?: string;
  config: {
    app_version: string;
    feed_id: number;
    instance_url: string;
  };
}

export const useConnectionsStore = defineStore('Connections', () => {
  const connections = ref<Connection[]>([]);

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const response = await axios.get(urls.connections.all);
    connections.value = response.data.items as Connection[];
  });

  refresh({ useCache: true });

  const activeConnections = computed(() => {
    return connections.value.filter(
      (s) =>
        s.integration_type === SALESFORCE_DATA_SOURCE_TYPE ||
        (!s.is_paused && !s.error_message),
    );
  });

  function getConnectionByType(
    integrationType: ConnectionType,
  ): Connection | undefined {
    return connections.value.find(
      (s) => s.integration_type === integrationType,
    );
  }

  function getConnectionsByType(integrationType: ConnectionType): Connection[] {
    return connections.value.filter(
      (s) => s.integration_type === integrationType,
    );
  }

  function reauthSalesforce() {
    const salesforceConnection = getConnectionByType('salesforce');
    if (!salesforceConnection) {
      throw new Error('No Salesforce connection found');
    }
    window.open(
      `${salesforceConnection?.config?.instance_url}/${
        appConfig.salesforceReauthUrl
      }`,
      '_blank',
    );
  }

  return {
    error,
    ready,
    readySync,
    running,
    refreshConnectionsStore: refresh,
    connections,
    activeConnections,
    getConnectionByType,
    getConnectionsByType,
    reauthSalesforce,
  };
});
