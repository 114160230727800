import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import useFeedDetails from '@/composables/useFeedDetails';
import { patchFeed } from '@/constants/data_sources';
import { captureException } from '@/errors';
import {
  useDataSharesStore,
  useFeedsStore,
  useFlashesStore,
  useSourcesStore,
} from '@/stores';
import { Feed } from '@/types/feeds';

/* Used to provide state and handlers for the data source settings modal,
 * including patching sync frequency, loading + saving state, etc. */

const loading = ref(true);
const feed = ref<Feed | null>(null);
const isSyncing = ref(false);
const currentFrequency = ref<number | null>(null);

export default function () {
  const route = useRoute();
  const flashesStore = useFlashesStore();
  const dataSharesStore = useDataSharesStore();
  const feedsStore = useFeedsStore();
  const sourcesStore = useSourcesStore();

  const feedId = computed(() => Number(route.params.id));
  const saving = ref(false);

  /* On initial component mount, populate the initial values for feed, frequency.
  If the feedId value from the route changes (feedId changes) then 
  reset all of these values for the new feed */

  async function setValues() {
    if (feedId.value && feed.value?.id !== feedId.value) {
      loading.value = true;
      await feedsStore.readySync;
      feed.value = feedsStore.getFeedById(feedId.value) || null;
      isSyncing.value = !feed.value?.is_paused;
      currentFrequency.value = feed.value?.frequency || null;
    }

    loading.value = false;
  }

  onBeforeMount(setValues);
  watch([feedId], setValues);

  /* Update feed settings */
  async function handlePatchFeed() {
    saving.value = true;
    try {
      await patchFeed(feed.value?.id, {
        is_paused: !isSyncing.value,
        frequency: currentFrequency.value,
      });
      flashesStore.addSuccessFlash({ message: 'Settings saved' });
      await Promise.all([
        dataSharesStore.refreshDataSharesStore(),
        feedsStore.refreshFeedsStore(),
        sourcesStore.refreshSourcesStore(),
      ]);
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'Could not save settings',
        description: 'If the error persists contact support@crossbeam.com',
      });
    } finally {
      saving.value = false;
    }
  }

  /* Reauthorize feed */
  const { reauthorize } = useFeedDetails({ feedId });
  async function handleReauthorize() {
    try {
      await reauthorize(feed.value);
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'Could not reauthorize feed',
        description: 'If the error persists contact support@crossbeam.com',
      });
    }
  }

  return {
    handlePatchFeed,
    handleReauthorize,
    loading,
    saving,
    isSyncing,
    feed,
    currentFrequency,
  };
}
