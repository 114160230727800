<template>
  <div class="population-sharing-defaults">
    <div
      :data-testid="`default-visibility-${selectedOption.value}`"
      class="population-sharing-defaults__visibility"
      :class="gradientClass"
    >
      <div class="flex flex-row items-center">
        {{ selectedOption.name }}
        <BittsTooltip
          v-if="props.populationSetting.visibility === GREENFIELD_SHARING"
          placement="right"
          class="font-normal"
        >
          <BittsTag
            color="success"
            variant="rounded"
            class="population-sharing-defaults__greeenfield-tag"
          >
            <FontAwesomeIcon
              :icon="['fak', 'greenfield']"
              class="text-success-accent text-12"
            />
            <span class="text-success-text"> Greenfield </span>
          </BittsTag>
          <template #title>
            You are sharing Greenfield accounts in your Population sharing
            default settings
          </template>
        </BittsTooltip>
        <BittsTooltip
          v-if="canEditSharing"
          placement="topRight"
          class="font-normal ml-auto"
        >
          <BittsButton
            data-testid="change-default-sharing"
            size="x-small"
            variant="outline"
            :center-icon="['fak', 'edit']"
            @click="onEditDefaults"
          />
          <template #title>
            Edit default settings on the Populations page
          </template>
        </BittsTooltip>
      </div>
      <div class="font-normal text-neutral-text">
        {{ sharingText }}
      </div>
    </div>
    <SourceFieldList
      v-if="selectedOption.value === SHARING"
      :source-field-ids="props.populationSetting.source_field_ids"
    />
    <OverrideAlert v-if="showAlert" :partner-name="partner?.name" />
  </div>
</template>

<script setup>
import { BittsButton, BittsTag, BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import OverrideAlert from '@/components/partners/DataSharingSettings/OverrideAlert.vue';
import SourceFieldList from '@/components/partners/DataSharingSettings/SourceFieldList.vue';

import {
  GREENFIELD_SHARING,
  HIDDEN,
  OLAPS_ONLY,
  SELECTABLE_VISIBILITY_OPTIONS,
  SHARING,
} from '@/constants/data_shares';

const props = defineProps({
  canEditSharing: {
    type: Boolean,
    default: true,
  },
  partner: {
    type: Object,
    default: null,
  },
  populationSetting: {
    type: Object,
    default: () => {
      return { visibility: HIDDEN };
    },
  },
  showAlert: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['edit-pop-defaults']);
function onEditDefaults() {
  emit('edit-pop-defaults');
}

const selectedOption = computed(() => {
  let viz = props.populationSetting.visibility;
  if (props.populationSetting.visibility.includes(SHARING)) {
    viz = SHARING;
  }
  return SELECTABLE_VISIBILITY_OPTIONS.find((opt) => opt.value === viz);
});
const gradientClass = computed(() => {
  switch (selectedOption.value.value) {
    case HIDDEN:
      return 'from-danger-background-weak';
    case OLAPS_ONLY:
      return 'from-warning-background-weak';
    default:
      return 'from-success-background-weak';
  }
});

const sharingText = computed(() => {
  switch (selectedOption.value.value) {
    case SHARING:
      return 'By default, your partners can see the data shared with them for all overlaps and non-overlaps you choose to share.';
    case OLAPS_ONLY:
      return 'By default, your partners can only see a summary count of overlaps for this population. No specifics about the overlaps, including name or any other data, is shared.';
    default: // HIDDEN
      return 'By default, your partners will not see this population. You can make exceptions for specific partners.';
  }
});
</script>

<style lang="pcss" scoped>
.population-sharing-defaults {
  @apply flex flex-col gap-16;
}
.population-sharing-defaults__visibility {
  @apply w-full rounded-[12px] p-24 font-bold bg-gradient-to-r to-white flex flex-col gap-12;
  :deep(.ant-tag) {
    @apply ml-8;
    background: theme(colors.green.500 / 0.1) !important;
  }
}
</style>
