import axios from 'axios';
import { ref } from 'vue';

import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';

export default function useOfflinePartner() {
  const offlineCurrentUploadNames = ref([]);
  const offlineSources = ref([]);
  const offlineFilterableSources = ref([]);
  const offlinePopulations = ref([]);
  const offlinePopulationStats = ref([]);
  const offlineFeeds = ref([]);

  const flashesStore = useFlashesStore();
  async function refreshOfflineData(offlinePartnerUuid, rethrow = false) {
    try {
      const [
        { data: currentUploadNamesData },
        { data: sourcesData },
        { data: filterableSourcesData },
        { data: feedsData },
        { data: populationsData },
        { data: offlinePopulationStatsData },
      ] = await Promise.all([
        axios.get(urls.offlinePartners.dataSources.tables(offlinePartnerUuid)),
        axios.get(urls.offlinePartners.dataSources.sources(offlinePartnerUuid)),
        axios.get(
          urls.offlinePartners.dataSources.allFilterableSources(
            offlinePartnerUuid,
          ),
        ),
        axios.get(urls.offlinePartners.dataSources.feeds(offlinePartnerUuid)),
        axios.get(urls.offlinePartners.populations.list(offlinePartnerUuid)),
        axios.get(urls.offlinePartners.populations.stats(offlinePartnerUuid)),
      ]);

      offlineCurrentUploadNames.value = currentUploadNamesData.items;
      offlineSources.value = sourcesData.items;
      offlineFeeds.value = feedsData.items;
      offlinePopulations.value = populationsData.items;
      offlineFilterableSources.value = filterableSourcesData.items;
      offlinePopulationStats.value = offlinePopulationStatsData.items;
    } catch (err) {
      flashesStore.addErrorFlash({
        message: 'Could not refresh offline partner data',
      });
      captureException(err);
      if (rethrow) throw err;
    }
  }

  async function deleteOfflineSource(sourceId, offlinePartnerUuid) {
    try {
      const url = urls.offlinePartners.dataSources.delete(
        sourceId,
        offlinePartnerUuid,
      );
      await axios.delete(url);
      flashesStore.addSuccessFlash({ message: 'Data deleted' });
    } catch (err) {
      flashesStore.addErrorFlash({ message: 'Could not delete data' });
      captureException(err);
    } finally {
      await refreshOfflineData(offlinePartnerUuid);
    }
  }

  async function deleteOfflinePopulation(populationId, offlinePartnerUuid) {
    const url = urls.offlinePartners.populations.delete(
      populationId,
      offlinePartnerUuid,
    );
    await axios.delete(url);
    await refreshOfflineData(offlinePartnerUuid);
  }

  return {
    refreshOfflineData,
    offlinePopulations,
    offlineSources,
    offlineCurrentUploadNames,
    offlineFeeds,
    deleteOfflineSource,
    offlinePopulationStats,
    offlineFilterableSources,
    deleteOfflinePopulation,
  };
}
