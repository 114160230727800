<template>
  <div class="sharing-setting">
    <div
      v-if="dataShare?.visibility?.includes('full')"
      :class="visibilityText.cssClasses"
      class="sharing-setting__setting"
    >
      <BittsPopover
        class="inline text-brand-navy pl-2"
        :placement="placement"
        :mount-to-body="true"
        trigger="hover"
        @menu-visible="getSourcesWithFields"
      >
        <p v-if="showSharingText" class="inline"> Sharing </p>
        {{ sourceFields.length }} fields
        <template #content>
          <div v-if="hasSources" class="sharing-setting__popover">
            <div v-for="source in sources" :key="`source__${source.id}`">
              <span class="sharing-setting__popover-source">
                {{ source.table }}
              </span>
              <div class="sharing-setting__popover-source-fields">
                <div>
                  {{ stringifySourceNames(source.fields) }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="p-8 text-neutral-800 text-sm">
            {{
              sourcesLoading
                ? 'Loading...'
                : 'Share data with this partner to see fields'
            }}
          </div>
        </template>
      </BittsPopover>
    </div>
    <div
      v-else
      :class="visibilityText.cssClasses"
      class="sharing-setting__setting"
    >
      {{ visibilityText.title }}
    </div>
  </div>
</template>

<script setup>
import { BittsPopover } from '@crossbeam/bitts';

import { computed, ref } from 'vue';

import usePopulationShares from '@/composables/usePopulationShares';
import { HIDDEN, VISIBILITY_OPTIONS_TEXT } from '@/constants/data_shares';

const props = defineProps({
  dataShare: {
    type: Object,
    required: true,
  },
  isOwn: {
    type: Boolean,
    default: true,
  },
  placement: {
    type: String,
    default: 'bottom',
    validator: (val) => {
      return [
        'top',
        'right',
        'bottom',
        'left',
        'topLeft',
        'topRight',
        'bottomLeft',
        'bottomRight',
      ].includes(val);
    },
  },
  showSharingText: {
    type: Boolean,
    default: true,
  },
});

const visibility = computed(() => props.dataShare?.visibility || HIDDEN);
const visibilityText = computed(
  () => VISIBILITY_OPTIONS_TEXT[visibility.value],
);

const { sourcesWithFields } = usePopulationShares();
const sourceFields = computed(() => {
  const fields = props.isOwn
    ? props.dataShare?.source_field_ids
    : props.dataShare?.sourceFields;
  return fields || [];
});
const sourcesLoading = ref(true);
const sources = ref([]);
const hasSources = computed(() => sources.value.length);
function stringifySourceNames(sourceFields = []) {
  return sourceFields.map((field) => field.display_name).join(', ');
}
function getSourcesWithFields() {
  if (sources.value.length) return;
  sources.value = sourcesWithFields(sourceFields.value, !props.isOwn);
  sourcesLoading.value = false;
}
</script>

<style lang="pcss" scoped>
.sharing-setting__setting {
  @apply px-8 py-4 rounded-bts-sm text-brand-navy text-sm;
}

.sharing-setting__popover {
  @apply text-sm p-8 leading-loose;
  width: 320px;
}

.sharing-setting__popover-source {
  @apply text-neutral-500 uppercase tracking-wider;
}

.sharing-setting__popover-source-fields {
  @apply text-neutral-900 flex flex-wrap py-4;
}
</style>
