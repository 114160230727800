<template>
  <div class="flex flex-col gap-8 items-start">
    <div
      v-for="(item, index) in featureItems"
      :class="{
        'pb-16': index === featureItems.length - 1,
      }"
      :key="item.text"
    >
      <div class="flex items-center text-base">
        <FontAwesomeIcon
          :icon="comparisonModal ? ['far', 'check'] : ['fak', `${item.icon}`]"
          class="h-12 w-12 mr-8"
          :class="
            isCheckoutPage ? 'text-secondary-accent' : 'text-neutral-accent'
          "
        />
        <component :is="item.tooltip ? BittsTooltip : 'span'">
          <template #title>
            <p>
              {{ item.tooltip }}
            </p>
          </template>
          <p
            class="text-neutral-text"
            :class="{
              'text-secondary-accent font-bold':
                !isSupernodeTier && isHighlightedItem(item),
            }"
          >
            <span
              v-html="
                item.tooltip
                  ? injectStrings(item.text, {
                      values: [item.injection],
                      bold: false,
                      italic: false,
                      underline: true,
                    })
                  : item.text
              "
            />
          </p>
        </component>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import { BILLING_IMPROVEMENTS_COPY } from '@/constants/billing';
import { injectStrings } from '@/utils';

interface Props {
  tier: string;
  comparisonModal?: boolean;
  highlight?: string;
  isCheckoutPage?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  comparisonModal: false,
  highlight: '',
  isCheckoutPage: false,
});

const isSupernodeTier = computed(() => props.tier === 'supernode');
const isFreeTier = computed(() => props.tier === 'explorer');

interface FeatureItem {
  text: string;
  injection?: string;
  tooltip?: string;
  cta?: string[];
  icon?: string;
}

const featureItems = computed<FeatureItem[]>(() => {
  if (isSupernodeTier.value) {
    return props.comparisonModal
      ? BILLING_IMPROVEMENTS_COPY.supernode.features
      : BILLING_IMPROVEMENTS_COPY.supernode.featuresLongDescription;
  }
  if (isFreeTier.value) {
    return BILLING_IMPROVEMENTS_COPY.explorer.features;
  }
  return BILLING_IMPROVEMENTS_COPY.connector.features;
});

function isHighlightedItem(item: { cta?: string[] }) {
  return item.cta?.includes(props.highlight);
}
</script>
