<template>
  <BittsModal
    class="c-revoke-invite-modal"
    save-text="Revoke"
    cancel-text="Cancel"
    confirm-type="danger"
    :loading="loading || !ready"
    :width="480"
    :visible="true"
    @saved="confirmInviteDeletion"
    @closed="closeModal"
  >
    <template #subtitle>
      <h2 class="c-revoke-invite-modal__title"> Revoke Invitation? </h2>
    </template>
    <template #content>
      <p class="c-revoke-invite-modal__content">
        Are you sure you want to cancel the invitation to <b>{{ email }}</b
        >? They will no longer be able to join your organization.
      </p>
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useBillingStore, useFlashesStore, useTeamStore } from '@/stores';

const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
  email: {
    type: String,
    required: true,
  },
});

const router = useRouter();

const teamStore = useTeamStore();
const billingStore = useBillingStore();
const flashesStore = useFlashesStore();

const { ready } = storeToRefs(teamStore);

useHead({ title: 'Team - Remove User' });

const loading = ref(false);

async function closeModal() {
  loading.value = false;
  await router.push({ name: 'team' });
}

async function confirmInviteDeletion() {
  loading.value = true;
  try {
    await teamStore.revokeInvite({ inviteId: props.userId });
    flashesStore.addSuccessFlash({
      message: `Invitation to ${props.email} Revoked`,
      description: 'They will not be able to accept this invitation.',
    });
    await billingStore.refreshBillingStore();
  } catch (_err) {
    flashesStore.addErrorFlash({
      message: `The invitation to ${props.email} was not revoked`,
      description: 'If the error persists contact support@crossbeam.com',
    });
  } finally {
    await closeModal();
  }
}
</script>
<style lang="pcss">
.c-revoke-invite-modal__title {
  @apply w-full text-center mt-[-16px] text-neutral-text-strong text-xl font-bold;
}
</style>

<style lang="pcss">
.c-revoke-invite-modal {
  .c-bitts-modal__header {
    background: radial-gradient(
      196.96% 220.92% at 50.09% -12.42%,
      color-mix(in srgb, theme(colors.danger.background-medium) 20%, white) 0%,
      white,
      white
    );
    @apply rounded-16 mt-[-1px];
  }
  .c-revoke-invite-modal__content {
    @apply text-neutral-text text-m text-center;
  }
}

.c-revoke-invite-modal {
  .c-bitts-modal__content {
    @apply pt-16;
  }
  .c-bitts-modal__buttons--wrapper {
    @apply flex w-full items-center justify-between;
  }
}
</style>
