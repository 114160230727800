import { GetApiResponseByUrl } from '@crossbeam/openapi';

import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import { LIMIT_REACHED, OVER_75, OVER_90 } from '@/constants/billing';
import { RECORD_EXPORT_ALERTS } from '@/constants/record_exports';
import { useBillingStore } from '@/stores';

type RecordExportUsageResponse =
  GetApiResponseByUrl<'/v0.1/record-export-usage'>;

export type Destination =
  RecordExportUsageResponse['destinations_with_counts'][number]['destination'];

export type DestinationWithCounts = {
  unique_count: number;
  total_count: number;
  destination: Destination;
};

type RecentExportType = {
  date_exported: string;
  export_count: number;
  destination: Destination;
};
export type RecentExportsType = RecentExportType[];

const mapBucketToFriendlyName = {
  salesforce_custom_object: 'Salesforce Custom Object',
  hubspot_custom_object: 'Hubspot Custom Object',
  snowflake_custom_object: 'Snowflake Custom Object',
  microsoft_dynamics_custom_object: 'Microsoft Dynamics Custom Object',
  bigquery_custom_object: 'BigQuery Custom Object',
  leandata: 'LeanData',
  partnerportal: 'Partner Portal',
  clari_custom_object: 'Clari Custom Object',
  marketo: 'Marketo',
  superglue: 'Superglue',
  rest_api: 'Rest API',
  report_exports: 'Report Exports',
  clay: 'Clay',
} as const;

type FriendlyName =
  (typeof mapBucketToFriendlyName)[keyof typeof mapBucketToFriendlyName];

export const recordsExported = ref<number | null>(null);
export const endDate = ref<string | null>(null);
export const destinationsWithCounts = ref<
  {
    totalCount: number;
    uniqueCount: number;
    destination: Destination;
    title: FriendlyName;
  }[]
>([]);

export type FormattedExport = {
  dateExported: string;
  destinations: Partial<Record<FriendlyName, number>>;
  totalExportedCount: number;
};
export const recentExports = ref<FormattedExport[]>();

const processRecentExports = (recentRecordExports: RecentExportsType) => {
  if (!recentRecordExports?.length) return [];

  const aggregatedExports = recentRecordExports.reduce(
    (acc, { date_exported, export_count: exported_count, destination }) => {
      const dateKey = DateTime.fromISO(date_exported, { zone: 'utc' }).toFormat(
        'yyyy-MM-dd',
      );
      const friendlyDestination = mapBucketToFriendlyName[destination];

      // this is the date we started recording data
      // it is *really* confusing people at release because we
      // tell them they exported EVERY record they have exported so far on that day.
      // So, just skip it!
      if (dateKey === '2024-11-09') return acc;

      if (!acc[dateKey]) {
        acc[dateKey] = {
          dateExported: dateKey,
          destinations: {},
          totalExportedCount: 0,
        };
      }

      acc[dateKey].destinations[friendlyDestination] = exported_count;
      acc[dateKey].totalExportedCount += exported_count;

      return acc;
    },
    {} as Record<string, FormattedExport>,
  );

  return Object.values(aggregatedExports)
    .sort(
      (a, b) =>
        DateTime.fromISO(b.dateExported).toMillis() -
        DateTime.fromISO(a.dateExported).toMillis(),
    )
    .filter((exported) => exported.totalExportedCount > 0);
};

export default function useRecordExportLimits() {
  const NINETY_PERCENT = 0.9;
  const SEVENTY_FIVE_PERCENT = 0.75;

  const billingStore = useBillingStore();

  const { recordExportLimit } = storeToRefs(billingStore);

  const fetchRecordsExported = async function () {
    const { data } = await crossbeamApi.GET('/v0.1/record-export-usage');
    if (!data) return;
    endDate.value = data?.record_export_term_end_date;
    recordsExported.value = data.consumed_quota;
    recentExports.value = processRecentExports(data.recent_record_exports);
    destinationsWithCounts.value = data.destinations_with_counts
      ?.map(
        ({
          total_count: totalCount,
          unique_count: uniqueCount,
          destination,
        }) => ({
          totalCount,
          uniqueCount,
          destination,
          title: mapBucketToFriendlyName[destination],
        }),
      )
      .sort((a, b) => b.totalCount - a.totalCount);
  };

  if (recordExportLimit.value && !recordsExported.value) {
    fetchRecordsExported();
  }
  const limitUsedDecimal = computed(() => {
    if (!recordExportLimit.value || !recordsExported.value) return 0;
    return (
      Math.min(recordsExported.value, recordExportLimit.value) /
      recordExportLimit.value
    );
  });
  const limitUsedPercent = computed(() => {
    const percent = Math.round(limitUsedDecimal.value * 100);
    return `${percent}%`;
  });

  const exportLimitStatus = computed(() => {
    if (limitUsedDecimal.value >= 1) return LIMIT_REACHED;
    if (limitUsedDecimal.value >= NINETY_PERCENT) return OVER_90;
    if (limitUsedDecimal.value >= SEVENTY_FIVE_PERCENT) return OVER_75;
    return null;
  });

  const exportLimitReached = computed(
    () => exportLimitStatus.value === LIMIT_REACHED,
  );
  const progressBarMessage = computed(() => {
    let roundedPercent = Math.round(limitUsedDecimal.value * 100);
    // edge case rounding 99% to 100% but the exported count is still lower than the limit
    if (roundedPercent === 100 && exportLimitStatus.value !== LIMIT_REACHED)
      roundedPercent = 99;
    if (exportLimitStatus.value === OVER_90)
      return `Reached ${roundedPercent}% of limit`;
    if (exportLimitStatus.value === LIMIT_REACHED)
      return 'Exceeded 100% of limit';
    return 'Records exported';
  });

  return {
    destinationsWithCounts,
    recentExports,
    endDate,
    exportLimit: recordExportLimit,
    exportLimitReached,
    exportLimitStatus,
    limitUsedDecimal,
    limitUsedPercent,
    RECORD_EXPORT_ALERTS,
    recordsExported,
    progressBarMessage,
  };
}
