<template>
  <BittsModal
    :loading="loading || !ready"
    title="Delete User?"
    class="c-team__delete-authorization-modal"
    save-text="Delete"
    confirm-type="danger"
    :width="400"
    :visible="true"
    @saved="confirmAuthorizationDeletion"
    @closed="closeModal"
  >
    <template #content>
      <p class="text-neutral-500">
        {{ deleteAuthorizationText }}
      </p>
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { useBillingStore, useFlashesStore, useTeamStore } from '@/stores';

const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
});

const teamStore = useTeamStore();
const billingStore = useBillingStore();
const flashesStore = useFlashesStore();

const { ready } = storeToRefs(teamStore);

const router = useRouter();
const { currentOrg } = useAuth();

useHead({ title: 'Team - Remove User' });

const loading = ref(false);
const user = computed(
  () => teamStore.getAuthorizationByUserId(props.userId)?.user,
);
const deleteAuthorizationText = computed(
  () =>
    `Are you sure you want to remove ${user.value?.email} from ${currentOrg.value.name}?`,
);

async function confirmAuthorizationDeletion() {
  loading.value = true;
  try {
    await teamStore.removeAuthorization({ userId: props.userId });
    await billingStore.refreshBillingStore();
    flashesStore.addSuccessFlash({
      message: 'The user was successfully removed',
      description: 'This user no longer has access to your organization',
    });
  } catch (err) {
    flashesStore.addErrorFlash({
      message: 'This user was not removed.',
      description: 'If the error persists contact support@crossbeam.com',
    });
  } finally {
    await closeModal();
  }
}

async function closeModal() {
  loading.value = false;
  await router.push({ name: 'team' });
}
</script>
