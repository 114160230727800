<template>
  <div>
    <ReportNotificationsModal
      v-model="notifications"
      :report-name="report && report.name"
      :show-report-notifications-modal="showReportNotificationsModal"
      :partner-population-ids="report && report.partner_population_ids"
      @notifications-modal-hidden="hideNotificationsModal"
      @input="refreshReportsStore"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';

import ReportNotificationsModal from '@/components/reports/ReportNotificationsModal.vue';

import { BASE_ROUTES } from '@/constants/routes';
import { useReportsStore } from '@/stores';

export default {
  components: { ReportNotificationsModal },
  data() {
    return {
      showReportNotificationsModal: false,
    };
  },
  computed: {
    ...mapState(useReportsStore, ['reports']),
    folderId() {
      return this.$route.params.folder_id;
    },
    notifications() {
      if (this.report && this.report.notification_configs) {
        return this.report.notification_configs;
      }
      return [];
    },
    reportId() {
      return this.$route.query.id;
    },
    report() {
      return this.reports.find((report) => report.id === this.reportId);
    },
  },
  async created() {
    await this.refreshReportsStore();
  },
  mounted() {
    this.showReportNotificationsModal = true;
  },
  methods: {
    ...mapActions(useReportsStore, ['refreshReportsStore']),
    async hideNotificationsModal() {
      if (!this.folderId) {
        await this.$router.push({ name: BASE_ROUTES.REPORTS });
      } else {
        await this.$router.push({
          name: BASE_ROUTES.REPORT_FOLDER,
          params: { folder_id: this.folderId },
        });
      }
    },
  },
};
</script>
