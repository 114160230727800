<template>
  <div class="c-integration-profile-settings-drawer">
    <BittsDrawer
      visible
      v-if="integrationApp"
      destroy-on-close
      :title="integrationApp.name"
      :primary-button-text="saveButtonText"
      :is-saving="isSaving"
      :is-saving-disabled="isSaving || !isIntegrationAvailable"
      @action:primary="saveAndClose"
      @closed="closeDrawerHandler"
    >
      <template #headerAvatar>
        <BittsAvatar
          :org="avatarLogo"
          :is-entity="true"
          :show-initials="true"
          size="medium"
          shape="square"
        />
      </template>

      <template #content>
        <div class="subtitle-section">
          <IntegrationCardHeader
            v-if="snowflakeConnection"
            :statuses="[{ type: statusType, text: statusText }]"
            :loading
            :created="snowflakeConnection.connected_at"
            :version="snowflakeConnection.config?.app_version"
            :auth-by="snowflakeConnection.connected_by_user_email"
            :status-type
            :disabled
            :callout-text
            hide-advanced-settings
            hide-reauth
          />
        </div>
        <div class="subtitle-section flex flex-col gap-4" :class="{ disabled }">
          <div class="subtitle-section-content">
            <strong class="font-bold">Enable Data Push</strong>
          </div>
          <div class="flex items-center">
            <p class="text-neutral-text text-sm">
              {{ enableDescription }}
            </p>
            <BittsSwitch
              :model-value="isActive"
              class="ml-auto mr-2"
              data-testid="push-enabled-toggle"
              :disabled="hasConnectionError"
              @change="toggleSnowflakeConnection"
            />
          </div>
        </div>
        <div v-if="hasPermissionToManageIntegrations" class="p-16">
          <div class="flex items-center gap-8 mb-4">
            <FontAwesomeIcon
              :icon="['far', 'arrow-right-arrow-left']"
              class="text-secondary-text"
            />
            <p class="font-bold"> Customize Data in Push </p>
          </div>
          <p class="text-neutral-text text-sm">
            {{ integrationDescription }}
            <BittsLink
              :url="integrationApp.learnMore"
              text="Learn more about this"
            />
          </p>
          <IntegrationSettings
            v-if="integrationProfileSettings"
            :population-settings="{
              population_settings:
                integrationProfileSettings?.population_settings ?? [],
              standard_population_settings:
                integrationProfileSettings?.standard_population_settings ?? [],
              exclude_partnerships_created_since:
                integrationProfileSettings?.exclude_partnerships_created_since,
            }"
            :setting-type="integrationProfileType"
            @save:changes="onSettingsUpdated"
            @update:exclude-partnerships-created-since="
              onUpdateExcludePartnershipsCreatedSince
            "
            :is-disabled="!isIntegrationAvailable || isSaving || loading"
          />
        </div>
      </template>
    </BittsDrawer>
  </div>
</template>

<script setup lang="ts">
import {
  BittsAvatar,
  BittsDrawer,
  BittsLink,
  BittsSwitch,
} from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, inject, onMounted, ref } from 'vue';
import type { ComputedRef } from 'vue';

import IntegrationCardHeader from '@/components/integrations/IntegrationCardHeader.vue';

import useIntegrationProfile from '@/composables/useIntegrationProfile';
import { useRouterWrapper } from '@/composables/useRouterWrapper';
import useSnowflake from '@/composables/useSnowflake';
import { TEMP_SNOWFLAKE_INTEGRATION_PROFILE } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore } from '@/stores';
import {
  IntegrationProfileStatus,
  IntegrationProfileType,
} from '@/types/integration_profiles';

import IntegrationSettings from './IntegrationSettings.vue';

const { pushWithExistingQuery } = useRouterWrapper();

const {
  isActive,
  toggleSnowflakeConnection,
  snowflakeConnection,
  hasSnowflakeConnection,
  hasConnectionError,
  integrationApp,
  isPushDisabled,
  calloutText,
} = useSnowflake();

const integrationProfileType: IntegrationProfileType = 'snowflake';
const loading = ref(true);

onMounted(() => {
  loading.value = false;
});

const featureFlagsStore = useFeatureFlagStore();
const { billingTier } = storeToRefs(useBillingStore());

const disabled = computed(() => {
  return (
    !hasPermissionToManageIntegrations.value ||
    !isIntegrationAvailable.value ||
    isSaving.value
  );
});

const exportLimitReached = inject<ComputedRef<boolean>>('exportLimitReached');
const isPushAvailable = computed(
  () =>
    featureFlagsStore.hasFeatureFlag(TEMP_SNOWFLAKE_INTEGRATION_PROFILE) &&
    integrationApp.value &&
    !isPushDisabled.value &&
    billingTier.value === integrationApp.value.tier,
);

const statusType = computed<IntegrationProfileStatus>(() => {
  if (exportLimitReached?.value || hasConnectionError.value) return 'error';
  if (
    !isPushAvailable.value ||
    !isActive.value ||
    !integrationProfileSettings.value
  ) {
    return 'warning';
  }
  if (hasSnowflakeConnection.value && !hasConnectionError.value) {
    return 'success';
  }
  return 'error';
});

const statusText = computed(() => {
  if (exportLimitReached?.value) return 'Action needed';

  if (
    !isPushAvailable.value ||
    !isActive.value ||
    !integrationProfileSettings.value
  ) {
    return 'Paused';
  }
  if (hasSnowflakeConnection.value && !hasConnectionError.value) {
    return 'Active';
  }
  return 'Not Active';
});

const {
  clearProfilesState,
  enableDescription,
  hasPermissionToManageIntegrations,
  integrationDescription,
  integrationProfileSettings,
  isIntegrationAvailable,
  isSaving,
  onSettingsUpdated,
  onUpdateExcludePartnershipsCreatedSince,
  saveSettings,
} = useIntegrationProfile(integrationProfileType);

const avatarLogo = computed(() => {
  return {
    logo_url: new URL(
      `../../assets/pngs/partner-cloud/${integrationApp?.value?.avatar}`,
      import.meta.url,
    ).href,
  };
});

const saveButtonText = computed(() =>
  isSaving.value ? 'Saving Changes' : 'Save Changes',
);

const saveAndClose = async () => {
  await saveSettings();
  closeDrawerHandler();
};

const closeDrawerHandler = () => {
  clearProfilesState();
  pushWithExistingQuery({ name: 'integrations' });
};
</script>

<style scoped lang="pcss">
.subtitle-section {
  @apply p-16 border-b border-neutral-background-disabled;
}
.subtitle-section-content {
  @apply flex items-center justify-between;
}

.c-integration-settings-alert :deep(div.self-start.pt-4) {
  @apply self-center pt-0 !important;
}
</style>
