<template>
  <div
    class="c-debooking-table-se-role-cell"
    :class="{ disabled: isRowDisabled }"
  >
    <span class="text-neutral-text-strong" data-testid="role-name">
      <FontAwesomeIcon
        v-if="activeRole?.icon"
        :icon="activeRole.icon.type"
        :class="`${activeRole.icon.color} mr-2`"
      />
      {{ activeRole.name }}
    </span>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';

import useAuthOrInvite from '@/composables/useAuthOrInvite';
import { EXPLORER_SEATS } from '@/constants/billing';
import { SALES_EDGE_ROLES } from '@/constants/team';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const activeRole = ref(null);
const authOrInvite = ref(null);
onBeforeMount(() => {
  authOrInvite.value = props.params.data;
  activeRole.value =
    SALES_EDGE_ROLES.find(
      (role) => role.id === authOrInvite.value.sales_edge_role,
    ) ?? SALES_EDGE_ROLES.at(-1);
  context.value = props.params.context;
});

const { id } = useAuthOrInvite(authOrInvite);
const isRowDisabled = computed(
  () =>
    !context.value.checkAll &&
    !context.value.users?.has(id.value) &&
    context.value.users?.size >= EXPLORER_SEATS,
);

const context = ref(null);
</script>

<style lang="pcss">
.c-debooking-table-se-role-cell {
  @apply flex items-center h-64;
}
</style>
