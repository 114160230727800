<template>
  <div class="c-datasource-settings">
    <BittsModalTwo
      :width="1400"
      sidebar-title="Settings"
      :title="title"
      variant="sidebar"
      :loading="loading"
      @closed="close"
      @action="handleAction"
      :disabled="saving || loading"
      :action-loading="saving"
    >
      <template #sidebar-header-prefix>
        <BittsSvg
          v-if="feed?.integration"
          class="w-24 h-24 mr-16"
          :svg="feed.integration.type + 'Icon'"
        />
      </template>
      <template #sidebar-footer>
        <BittsButton
          :disabled="saving"
          type="danger"
          text="Remove data source"
          variant="ghost"
          :left-icon="['fak', 'delete']"
          data-testid="c-datasource-settings-delete"
          @click="showDeleteModal"
        />
      </template>
      <template #sidebar-content>
        <SidebarLink
          v-for="(item, i) in sidebarItems"
          :item="item"
          :key="item.tab"
          :class="{
            'mt-auto': i === sidebarItems.length - 1,
          }"
        />
      </template>
      <div class="c-datasource-settings__content py-12">
        <component :is="TabComponent" class="min-h-[600px]" />
      </div>
    </BittsModalTwo>
    <router-view-wrapper />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsModalTwo, BittsSvg } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DataImportTab from '@/components/data-sources/SettingsModal/DataImportTab.vue';
import GeneralTab from '@/components/data-sources/SettingsModal/GeneralTab.vue';
import SegmentsTab from '@/components/data-sources/SettingsModal/SegmentsTab.vue';
import SharingTab from '@/components/data-sources/SettingsModal/SharingTab.vue';
import SidebarLink, { SidebarItem } from '@/components/SidebarLink.vue';

import useDataSourceSettings from '@/composables/useDataSourceSettings';

export type DataSourceSettingsTabType =
  | 'general'
  | 'segments'
  | 'sharing'
  | 'data-import';

const router = useRouter();
const { handlePatchFeed, loading, saving, feed } = useDataSourceSettings();

const sidebarItems = computed<SidebarItem<DataSourceSettingsTabType>[]>(() => [
  {
    label: 'General',
    icon: ['far', 'gear'],
    tab: 'general',
  },
  {
    label: 'Segments',
    icon: ['far', 'database'],
    tab: 'segments',
  },
  {
    label: 'Sharing',
    icon: ['far', 'arrow-right-arrow-left'],
    tab: 'sharing',
  },
  {
    label: 'Data Import',
    icon: ['far', 'upload'],
    tab: 'data-import',
  },
]);

const route = useRoute();
const tab = computed(() => route.query.tab as DataSourceSettingsTabType);

const TabComponent = computed(() => {
  switch (tab.value) {
    case 'general':
      return GeneralTab;
    case 'segments':
      return SegmentsTab;
    case 'sharing':
      return SharingTab;
    case 'data-import':
      return DataImportTab;
    default:
      return GeneralTab;
  }
});

const title = computed(() => {
  switch (tab.value) {
    case 'general':
      return 'General Settings';
    case 'segments':
      return 'Segments Settings';
    case 'sharing':
      return 'Sharing Settings';
    case 'data-import':
      return 'Data Import Settings';
    default:
      return 'General Settings';
  }
});

// The action will be different depending on which tab is focused. The actions
// should be written in composables that share the state and handlers across each
// tab and this main component.
async function handleAction() {
  switch (tab.value) {
    case 'general':
      await handlePatchFeed();
      break;
    default:
      await handlePatchFeed();
  }
}

async function close() {
  await router.push({ name: 'data-sources' });
}

async function showDeleteModal() {
  await router.push({ name: 'delete-data-source' });
}
</script>

<style lang="pcss" scoped>
.c-datasource-settings__content {
  @apply flex flex-col;
}
</style>
