<template>
  <iframe
    v-if="hasAccess"
    ref="coSellIframe"
    :src="coSellLink"
    name="co-sell"
    frameborder="0"
    marginheight="0px"
    marginwidth="0px"
    height="100%"
    width="100%"
    allowfullscreen
    class="co-sell-page"
  />
  <AccessDenied
    v-else
    deny-message="You do not have access to Co-Selling. Please talk to your Crossbeam admin."
    :show-admins="false"
  />
</template>

<script setup>
import { useHead } from '@unhead/vue';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import AccessDenied from '@/components/AccessDenied.vue';

import useAuth from '@/composables/useAuth';
import appConfig from '@/config';
import { NO_ACCESS } from '@/constants/team';
import { useTeamStore } from '@/stores';

const prefix = '/co-sell';

useHead({ title: 'Co-Sell - Crossbeam' });

const teamStore = useTeamStore();
const router = useRouter();
const route = useRoute();
const { isAdminPanelUser, currentOrg, currentUser } = useAuth();

const salesEdgeRole = computed(
  () =>
    teamStore.allSeats.find((seat) => seat.user?.id === currentUser.value.id)
      ?.sales_edge_role || NO_ACCESS,
);
const hasAccess = computed(
  () => isAdminPanelUser.value || salesEdgeRole.value !== NO_ACCESS,
);

const initialLink = ref(route.fullPath.replace(prefix, '') ?? '/');
const loginOrgParam = ref(
  `${initialLink.value.includes('?') ? '&' : '?'}login-org=${currentOrg.value.uuid}`,
);
const coSellLink = computed(
  () =>
    `${appConfig.salesEdgeRedirectUrl}${initialLink.value}${loginOrgParam.value}`,
);

const coSellIframe = ref(null);
const ignoreEvent = ref(false);
/* Sync the iframe location with the router */
const onLocationChange = (event) => {
  ignoreEvent.value = false;
  if (
    event.origin !== appConfig.salesEdgeRedirectUrl ||
    event.data.type !== 'locationChange' ||
    !event.data.location
  )
    return;

  const currentLocation = route.fullPath || '';
  const fullPath = `${prefix}${event.data.location}`.replace(
    loginOrgParam.value,
    '',
  );
  if (currentLocation === fullPath) return;

  router.replace(fullPath);
  ignoreEvent.value = true;
};
onMounted(() => {
  window.addEventListener('message', onLocationChange, false);
});
onUnmounted(() => {
  window.removeEventListener('message', onLocationChange, false);
});

/* Sync the router with the iframe location */
watch(
  () => route.fullPath,
  (fullPath) => {
    if (ignoreEvent.value) {
      ignoreEvent.value = false;
      return;
    }

    if (!coSellIframe.value) return;
    coSellIframe.value.contentWindow.postMessage(
      { type: 'changeLocation', to: fullPath.replace(prefix, '') },
      appConfig.salesEdgeRedirectUrl,
    );
  },
);
</script>
