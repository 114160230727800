<template>
  <div class="c-individual-list-page__results-section">
    <div v-if="!!rowsSelected.length" class="flex justify-between pt-16">
      <span
        class="text-neutral-text-strong self-center"
        data-testid="c-individual-list-page__results"
        >{{ formatRecordsSelected }}</span
      >
      <div class="flex">
        <SharedListShareActionDropdown
          :rows-selected="rowsSelected"
          :list="currentList"
          data-testid="collaborate-share-action-dropdown"
          @option-selected="onOpenModal"
        />
        <BittsButton
          text="Delete Rows"
          variant="outline"
          size="small"
          type="danger"
          class="ml-8"
          :left-icon="['fak', 'delete']"
          @click="onOpenModal(DELETE_ROWS)"
        />
      </div>
    </div>
    <div v-else class="pt-16 flex">
      <span
        class="text-neutral-text-strong self-center"
        data-testid="c-individual-list-page__results"
        >{{ props.rowData.length }} Records</span
      >
      <div class="flex ml-auto">
        <SharedListShareActionDropdown
          data-testid="disabled-collaborate-share-action-dropdown"
          :disabled="true"
          class="mr-8"
        />
        <component :is="toolTipOrDiv" v-bind="toolTipProps">
          <div>
            <BittsButton
              size="small"
              type="neutral"
              text="Add more records"
              variant="outline"
              :disabled="maxRecordsLimitReached"
              :left-icon="['fak', 'add']"
              @click="emits('report-button-clicked')"
            />
          </div>
          <template #title>
            List has reached max of {{ maxListRowsLimit.value }} records
          </template>
        </component>
      </div>
    </div>
  </div>
</template>
<script setup>
import { BittsButton, BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import SharedListShareActionDropdown from '@/components/shared-list/SharedListShareActionDropdown.vue';

import useSharedList from '@/composables/useSharedList';

const props = defineProps({
  rowData: {
    type: Array,
    default: () => [],
  },
  rowsSelected: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits(['open-modal', 'report-button-clicked']);

const DELETE_ROWS = 'delete_rows';

const { currentList, maxListRowsLimit } = useSharedList();

const formatRecordsSelected = computed(() => {
  const singleOrMultiple =
    props.rowsSelected.length === 1 ? 'Record' : 'Records';
  return `${props.rowsSelected.length} ${singleOrMultiple} selected`;
});

const maxRecordsLimitReached = computed(
  () => props.rowData?.length >= maxListRowsLimit.value,
);

const toolTipOrDiv = computed(() =>
  maxRecordsLimitReached.value ? BittsTooltip : 'div',
);
const toolTipProps = computed(() => ({
  'mount-to-body': true,
  placement: 'bottom',
}));

function onOpenModal(option) {
  emits('open-modal', option);
}
</script>
<style lang="pcss">
.c-individual-list-page__results-section {
  @apply w-full px-24;
  height: 60px;
}
</style>
