<template>
  <div class="c-card-records">
    <div
      v-for="(visibleOrg, index) in visibleOrgs"
      :key="`org_${visibleOrg.org?.id}_${index}`"
      class="c-card-records__org"
    >
      <div
        :class="{
          'c-card-records__org__field': true,
          'c-card-records__org__field--with-icons': showFromIcons,
        }"
      >
        <div
          v-for="(field, fieldIndex) in visibleOrg.fields"
          :key="`${keySegment}_${fieldIndex}`"
          :class="{
            'c-card-records__org__field__label': field.type === 'name',
            'c-card-records__org__field__value': field.type === 'value',
            'c-card-records__org__field__icon': field.type === 'icon',
          }"
        >
          <span v-if="field.type !== 'icon'">
            {{ transformed(field) }}
          </span>
          <BittsAvatar
            v-else
            :org="visibleOrg.org"
            :show-initials="false"
            size="small"
            shape="square"
            class="mx-8"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BittsAvatar } from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { mapActions } from 'pinia';

import useAuth from '@/composables/useAuth';
import { useDataSharesStore, usePartnersStore } from '@/stores';

export default {
  name: 'CardRecords',
  components: {
    BittsAvatar,
  },
  props: {
    keySegment: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    showFromIcons: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'summary',
    },
    isOurDataSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return {
      currentOrg,
    };
  },
  computed: {
    hasOurData() {
      return this.item.fields.find((field) => field.is_ours);
    },
    orgsWithFields() {
      const partnerOrgIds = new Set(
        this.item.data_share_ids
          .map(this.getDataShareById)
          .map((share) => share?.partner_org_id),
      );
      let orgFields = [];
      if (this.item.is_ours && this.isOurDataSelected) {
        orgFields.push({
          org: this.currentOrg,
          fields: this.item.fields
            .filter((field) => field.is_ours)
            .reduce((fieldAccumulator, field) => {
              if (this.showFromIcons) {
                fieldAccumulator.push({
                  text: null,
                  type: 'icon',
                });
              }
              fieldAccumulator.push({ text: field.name, type: 'name' });
              fieldAccumulator.push({
                text: field.value,
                datatype: field.data_type,
                type: 'value',
              });
              return fieldAccumulator;
            }, []),
        });
      }

      orgFields = orgFields.concat(
        Array.from(partnerOrgIds).map((orgId) => {
          return {
            org: this.getPartnerOrgById(orgId),
            fields: this.item.fields
              .filter(
                (field) =>
                  field.data_share_id &&
                  this.getDataShareById(field.data_share_id)?.partner_org_id ===
                    orgId,
              )
              .reduce((fieldAccumulator, field) => {
                if (this.showFromIcons) {
                  fieldAccumulator.push({
                    text: null,
                    type: 'icon',
                  });
                }
                fieldAccumulator.push({ text: field.name, type: 'name' });
                fieldAccumulator.push({
                  text: field.value,
                  datatype: field.data_type,
                  type: 'value',
                });
                return fieldAccumulator;
              }, []),
          };
        }),
      );
      return orgFields;
    },
    visibleOrgs() {
      if (this.mode === 'summary' && this.item.is_ours) {
        return this.orgsWithFields.filter(
          (item) => item.org?.id === this.currentOrg.id,
        );
      }
      return this.orgsWithFields;
    },
    iconClass() {
      return `c-card-records__icon
      c-card-records__icon--grid`;
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(useDataSharesStore, ['getDataShareById']),
    transformed({ text, datatype }) {
      if (datatype === 'datetime' && !!text) {
        const parseDate = DateTime.fromISO(text, { setZone: true });
        return parseDate.toFormat('EEE, MMM d yyyy');
      }
      return text;
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-card-records {
  display: flex;
  flex-direction: column;
}

.c-card-records__org {
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
}

.c-card-records__icon {
  margin: 0px 6px;
  height: 16px;
  width: 16px;
  min-width: 16px;
}

.c-card-records__org__field {
  display: grid;
  grid-template-columns: [label] auto [value] 1fr;
  width: 100%;
}

.c-card-records__org__field__icon {
  display: flex;
  align-items: center;
}

.c-card-records__org__field--with-icons {
  grid-template-columns: [icon] 24px [label] auto [value] 1fr;
}

.c-card-records__org__field__label {
  margin: 6px 6px 6px 12px;
  font-size: 12px;
  color: var(--grey1);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.c-card-records__org__field__value {
  margin: 6px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
