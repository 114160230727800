<template>
  <div v-if="seatRequest" class="c-seat-request-table-user-cell">
    <BittsAvatar
      size="medium"
      :user="{
        first_name: seatRequest.first_name,
        last_name: seatRequest.last_name,
      }"
      :show-initials="true"
      class="items-center"
    />
    <div class="c-seat-request-table-cell-details">
      <div class="c-seat-request-table-cell__name">
        {{ seatRequest.first_name }} {{ seatRequest.last_name }}
      </div>
      <div class="c-seat-request-table-cell__email">
        {{ seatRequest.email }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { BittsAvatar } from '@crossbeam/bitts';

import { computed } from 'vue';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const seatRequest = computed(() => props.params.data);
</script>
<style lang="pcss">
.c-seat-request-table-user-cell {
  @apply flex gap-8 justify-between;
  .c-seat-request-table-cell__name {
    @apply text-neutral-text-strong font-bold text-m;
  }
  .c-seat-request-table-cell__email {
    @apply text-neutral-text text-base;
  }
  .c-seat-request-table-cell__message {
    @apply italic text-neutral-text;
  }
}
</style>
