<template>
  <router-link :to="link" class="flex w-full h-full items-center">
    {{ params.value.val }}
  </router-link>
</template>

<script>
export default {
  name: 'PopulationTableRecordCell',
  data() {
    return {
      recordId: null,
      sourceId: null,
    };
  },
  computed: {
    link() {
      return {
        name: 'individual_record',
        params: {
          source_id: this.sourceId,
          source_primary_key: this.recordId,
        },
      };
    },
  },
  beforeMount() {
    this.recordId = this.params.value.recordId;
    this.sourceId = this.params.value.sourceId;
  },
};
</script>
