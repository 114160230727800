export const CUSTOMERS_STANDARD_TYPE = 'customers';
export const OPEN_OPPS_STANDARD_TYPE = 'open_opportunities';
export const PROSPECTS_STANDARD_TYPE = 'prospects';

export const STANDARD_POPULATION_NAMES = [
  'Customers',
  'Open Opportunities',
  'Prospects',
];
export const STANDARD_POPULATION_TYPES = [
  CUSTOMERS_STANDARD_TYPE,
  OPEN_OPPS_STANDARD_TYPE,
  PROSPECTS_STANDARD_TYPE,
];

export const ALL_STANDARD_POPULATIONS = [
  {
    name: 'Customers',
    standard_type: CUSTOMERS_STANDARD_TYPE,
  },
  {
    name: 'Open Opportunities',
    standard_type: OPEN_OPPS_STANDARD_TYPE,
  },
  {
    name: 'Prospects',
    standard_type: PROSPECTS_STANDARD_TYPE,
  },
];

export const MAP_LINK_TYPE_TO_ROUTE_NAME = {
  edit: 'edit_population',
  sharing: 'populations.sharing',
  view: 'population_details',
};

export const MAP_POP_NAME_TO_STANDARD_TYPE = {
  Prospects: PROSPECTS_STANDARD_TYPE,
  'Open Opportunities': OPEN_OPPS_STANDARD_TYPE,
  Customers: CUSTOMERS_STANDARD_TYPE,
};

export const MAP_STANDARD_TYPE_TO_POP_NAME = {
  prospects: 'Prospects',
  open_opportunities: 'Open Opportunities',
  customers: 'Customers',
};

// get a population from a list of populations by standard type
export const getPopulationByStandardType = (type, populations) => {
  return populations.find((pop) => pop.standard_type === type);
};

export const emptyPopulation = (standardType) => {
  return {
    name: MAP_STANDARD_TYPE_TO_POP_NAME[standardType],
    standardType,
    isEmpty: true,
  };
};
