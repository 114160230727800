<template>
  <BittsLoading :is-loading="!ready">
    <BittsSelectTags
      ref="team-invite-emails"
      v-model="emails"
      :disabled="disabled"
      form-label="Email Addresses"
      :note="
        !approvingSeatRequest
          ? 'Note: You may paste in lists, or finalize your entries by hitting tab or enter'
          : null
      "
      :warning-message="emailWarningMessage"
      @search="onSearch"
      @change="onChangeEmails"
    />
  </BittsLoading>
</template>

<script setup>
import { BittsLoading, BittsSelectTags } from '@crossbeam/bitts';

import { email as emailVal } from '@vuelidate/validators';
import axios from 'axios';
import { uniq } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useTeamStore } from '@/stores';
import urls from '@/urls';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  inviteRequestId: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(['emails']);

const teamStore = useTeamStore();
const { ready, authorizations, redeemableInvites } = storeToRefs(teamStore);
const route = useRoute();

const approvingSeatRequest = computed(
  () => route.name === 'approve_seat_request',
);
onMounted(async () => {
  const queryEmails = (route.query?.emails?.split(',') || []).filter((v) => v);

  if (props.inviteRequestId) {
    const { data } = await axios.get(
      urls.inviteRequests.byId(props.inviteRequestId),
    );
    queryEmails.push(data.email);
  }

  onChangeEmails(uniq(queryEmails));
});

const emails = ref([]);
const showEmailError = ref(false);
const showTeamMemberError = ref(false);

function onChangeEmails(newEmails) {
  emails.value = newEmails.filter(isValidEmail);
  emit('emails', emails.value);
}

function isValidEmail(email) {
  const isValid = emailVal.$validator(email.toLowerCase());
  if (!isValid) showEmailError.value = true;
  const newEmail = !checkEmailExists(email);
  if (!newEmail) showTeamMemberError.value = true;
  return isValid && newEmail;
}

/* We allow you to send invites to someone with an SSO login, this is a known integrations issue */
function checkEmailExists(email) {
  return (
    authorizations.value.some(
      (auth) =>
        auth.authorizer_type !== 'sso_login' && auth.user.email === email,
    ) || redeemableInvites.value.some((invite) => invite.email === email)
  );
}

function onSearch() {
  showTeamMemberError.value = false;
  showEmailError.value = false;
}

const emailWarningMessage = computed(() => {
  if (!showEmailError.value && showTeamMemberError.value)
    return 'We removed any already existing users';
  if (showEmailError.value && showTeamMemberError.value)
    return 'We removed any invalid emails and already existing users';
  if (showEmailError.value) return 'We removed any invalid emails';
  return '';
});
</script>
