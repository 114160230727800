<template>
  <ExpandableCard>
    <template #header>
      <CardHeader :source="source" :title="title" />
    </template>
    <template #collapsed="{ toggle }">
      <div key="collapsed" class="c-card__content">
        <div class="c-card__content__grid-and-icons">
          <CardRecords
            :item="source"
            :show-from-icons="!isOurDataSelected"
            :mode="isOurDataSelected ? 'summary' : 'details'"
            :is-our-data-selected="isOurDataSelected"
            key-segment="account_info"
          />
          <div v-if="isOurDataSelected" class="c-card__content__icons">
            <CardOrgIcons
              :orgs="orgs"
              key-segment="account_information_details"
            />
          </div>
        </div>
        <CardFooter
          v-if="isOurDataSelected"
          :toggle="toggle"
          :item="source"
          mode="collapsed"
        />
      </div>
    </template>
    <template #expanded="{ toggle }">
      <div key="expanded" class="c-card__content">
        <div class="c-card__content__grid-and-icons">
          <CardRecords
            :item="source"
            :show-from-icons="true"
            :is-our-data-selected="isOurDataSelected"
            mode="details"
            key-segment="account_info"
          />
        </div>
        <CardFooter
          v-if="isOurDataSelected"
          :toggle="toggle"
          :item="source"
          mode="expanded"
        />
      </div>
    </template>
  </ExpandableCard>
</template>

<script>
import { mapActions } from 'pinia';

import useAuth from '@/composables/useAuth';
import { useDataSharesStore, usePartnersStore } from '@/stores';

import ExpandableCard from '../ExpandableCard.vue';

import CardFooter from './CardFooter.vue';
import CardHeader from './CardHeader.vue';
import CardOrgIcons from './CardOrgIcons.vue';
import CardRecords from './CardRecords.vue';

export default {
  name: 'ItemCard',
  components: {
    ExpandableCard,
    CardHeader,
    CardOrgIcons,
    CardRecords,
    CardFooter,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    isOurDataSelected: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return {
      currentOrg,
    };
  },
  computed: {
    orgs() {
      const appliedIncomingDataShares = this.source.data_share_ids.map(
        (dataShareId) => this.getDataShareById(dataShareId),
      );
      const partnerOrgIds = new Set(
        appliedIncomingDataShares.map((dataShare) => dataShare.partner_org_id),
      );
      const orgs = [...partnerOrgIds].map((orgId) => {
        const orgReceivingDataShares = appliedIncomingDataShares
          .filter((dataShare) => dataShare.partner_org_id === orgId)
          .map((dataShare) => ({
            ...this.getDataShareWithPopulationInfo(dataShare.id),
            type: 'receiving',
          }));

        return {
          ...this.getPartnerOrgById(orgId),
          dataShares: orgReceivingDataShares,
        };
      });
      if (this.source.is_ours) {
        orgs.unshift(this.currentOrg);
      }
      return orgs;
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(useDataSharesStore, [
      'getDataShareById',
      'getDataShareWithPopulationInfo',
    ]),
  },
};
</script>
<style lang="pcss" scoped>
.c-card__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.c-card__content__grid-and-icons {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
}

.c-card__content__icons {
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
}
</style>
