<template>
  <BittsTooltip :mount-to-body="true">
    {{ totalExported.toLocaleString() }}
    <template #title v-if="sortedDestinations.length > 1">
      <ul>
        <li
          v-for="[friendlyName, exportCount] in sortedDestinations"
          :key="friendlyName"
        >
          {{ friendlyName }}: {{ exportCount.toLocaleString() }}
        </li>
      </ul>
    </template>
  </BittsTooltip>
</template>

<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import type { FormattedExport } from '@/composables/useRecordExportLimits';
const { params } = defineProps<{
  params: {
    data: FormattedExport;
  };
}>();

const totalExported = computed(() =>
  Object.values(params?.data.destinations).reduce((acc, curr) => acc + curr, 0),
);

const sortedDestinations = computed(() =>
  Object.entries(params.data.destinations).sort((a, b) => b[1] - a[1]),
);
</script>
