import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { captureException } from '@/errors';
import {
  InboundShareRequest,
  OutboundShareRequest,
} from '@/interfaces/data_share_requests';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

export const useDataRequestsStore = defineStore('DataRequests', () => {
  const inboundShareRequests = ref<InboundShareRequest[]>([]);
  const outboundShareRequests = ref<OutboundShareRequest[]>([]);

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const endpoints = [
      axios.get(urls.shareRequests.inbound.default),
      axios.get(urls.shareRequests.outbound.default),
    ];
    const responses = await Promise.all(endpoints);
    const [inboundRequests, outboundRequests] = responses.map(
      (resp) => resp.data,
    );
    inboundShareRequests.value = inboundRequests.items;
    outboundShareRequests.value = outboundRequests.items;
  });

  refresh({ useCache: true });

  async function refreshInboundShareRequests(): Promise<void> {
    try {
      const { data } = await axios.get(urls.shareRequests.inbound.default);
      inboundShareRequests.value = data.items;
    } catch (error) {
      captureException(error);
    }
  }

  function getOutboundShareRequestById(
    id: string,
  ): OutboundShareRequest | undefined {
    return outboundShareRequests.value.find((request) => request.id === id);
  }

  function getOutboundShareRequestByPartnerAndPop(
    partnerOrgId: number,
    partnerPopId: number,
  ): OutboundShareRequest | undefined {
    return outboundShareRequests.value
      .filter(
        (request) =>
          request.partner_org_id === partnerOrgId &&
          request.partner_population_id === partnerPopId,
      )
      .at(-1);
  }

  function getInboundShareRequestById(
    id: string,
  ): InboundShareRequest | undefined {
    return inboundShareRequests.value.find((request) => request.id === id);
  }

  function getInboundShareRequestByPartnerAndPop(
    partnerOrgId: number,
    populationId: number,
  ): InboundShareRequest | undefined {
    return inboundShareRequests.value
      .filter(
        (request) =>
          request.partner_org_id === partnerOrgId &&
          request.population_id === populationId,
      )
      .at(-1);
  }

  async function ignoreShareRequest(requestId: string): Promise<void> {
    const response = await axios.patch(
      urls.shareRequests.inbound.byId(requestId),
      { status: 'ignored' },
    );
    upsertInboundShareRequest(response.data);
  }

  function removeDataRequestByPopulationId(populationId: number): void {
    inboundShareRequests.value = inboundShareRequests.value.filter(
      (request) => request.population_id !== populationId,
    );
  }

  async function revokeShareRequest(requestId: string): Promise<void> {
    const response = await axios.patch(
      urls.shareRequests.outbound.byId(requestId),
      { status: 'ignored' },
    );
    upsertOutboundShareRequest(response.data);
  }

  // helper functions
  function upsertInboundShareRequest(request: InboundShareRequest): void {
    const index = inboundShareRequests.value.findIndex(
      (o) => o.id === request.id,
    );
    if (index > -1) inboundShareRequests.value.splice(index, 1, request);
    else inboundShareRequests.value.push(request);
  }

  function upsertOutboundShareRequest(request: OutboundShareRequest): void {
    const index = outboundShareRequests.value.findIndex(
      (o) => o.id === request.id,
    );
    if (index > -1) outboundShareRequests.value.splice(index, 1, request);
    else outboundShareRequests.value.push(request);
  }

  async function sendShareRequestReminder(requestId: string): Promise<void> {
    const response = await axios.post(urls.shareRequests.remind(requestId));
    upsertOutboundShareRequest(response.data);
  }

  return {
    error,
    ready,
    readySync,
    running,
    getInboundShareRequestById,
    getInboundShareRequestByPartnerAndPop,
    getOutboundShareRequestById,
    getOutboundShareRequestByPartnerAndPop,
    ignoreShareRequest,
    inboundShareRequests,
    outboundShareRequests,
    refreshDataRequestsStore: refresh,
    removeDataRequestByPopulationId,
    revokeShareRequest,
    sendShareRequestReminder,
    refreshInboundShareRequests,
    /* exporting this to test it */
    upsertInboundShareRequest,
  };
});
