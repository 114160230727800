<template>
  <BittsTable
    :columns="columnDefinitions"
    :compress-columns="true"
    :rows="rows"
    :row-height="72"
    :pagination="false"
    :class="columnsReady ? 'fade-in' : 'invisible'"
    class="overlaps-table"
    @columns-compressed="onColumnsCompressed"
  />
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BittsTable } from '@crossbeam/bitts';

import { mapState } from 'pinia';

import OverlapsTableOverlapsCell from '@/components/billing/OverlapsTableOverlapsCell.vue';
import OverlapsTablePartnerCell from '@/components/billing/OverlapsTablePartnerCell.vue';

import { usePartnersStore } from '@/stores';

export default {
  name: 'OverlapsTable',
  components: {
    BittsTable,
    OverlapsTableOverlapsCell,
    OverlapsTablePartnerCell,
  },
  data() {
    return { columnsReady: false };
  },
  computed: {
    ...mapState(usePartnersStore, ['overlapCounts', 'partnerOrgs']),
    columnDefinitions() {
      return [
        {
          autoHeight: true,
          cellRenderer: 'OverlapsTablePartnerCell',
          field: 'name',
          headerName: 'Partner',
          sortable: true,
          sort: 'asc',
          suppressMovable: true,
          width: 200,
        },
        {
          autoHeight: true,
          cellRenderer: 'OverlapsTableOverlapsCell',
          field: 'overlaps',
          headerName: 'Overlaps',
          sortable: true,
          suppressMovable: true,
        },
      ];
    },
    partnerOverlapsLookup() {
      if (this.overlapCounts.overlap_usage) {
        return this.overlapCounts.overlap_usage.by_partner.reduce(
          (acc, partner) => {
            acc[partner.partner_organization_id] = partner.shared_overlap_count;
            return acc;
          },
          {},
        );
      }
      return {};
    },
    rows() {
      return this.partnerOrgs.reduce((partners, partnerOrg) => {
        const partnerData = {
          org: partnerOrg,
          name: partnerOrg.name,
          overlaps: this.partnerOverlapsLookup[partnerOrg.id],
        };
        partners.push(partnerData);
        return partners;
      }, []);
    },
  },
  methods: {
    onColumnsCompressed() {
      this.columnsReady = true;
    },
  },
};
</script>

<style lang="pcss">
.overlaps-table {
  .ag-cell-value {
    @apply w-full h-full;
  }
  .ag-header {
    @apply rounded-t-2xl;
  }
  .ag-row {
    @apply flex items-center;
  }
  .ag-row-last {
    @apply rounded-b-2xl;
  }
  .ag-root-wrapper {
    @apply rounded-2xl;
  }
  .ag-cell-wrapper {
    @apply w-full;
  }
}
</style>
