<template>
  <BittsCard :no-padding="false" class="c-needs-partnership-card">
    <div class="card-content">
      <div class="icons">
        <BittsAvatarStack
          :org-list="[{ ...currentOrg, showInitials: true }, {}]"
          :show-initials="true"
          size="large"
          class="mb-40"
        />
      </div>
      <h2 class="text-center"> You need a partner on Crossbeam to continue </h2>
      <p class="description">
        No partners have accepted your partnership invite but, not to worry, you
        have options:
      </p>
      <div class="partnership-invite-options">
        <div
          v-for="(option, i) in options"
          :key="`invite-option__${i + 1}`"
          class="option"
        >
          <div>
            <p class="heading"> {{ i + 1 }}. {{ option.title }} </p>
            <p class="body">
              {{ option.description }}
            </p>
          </div>
          <BittsInput
            v-if="option.type === INVITE_LINK"
            v-model="publicInviteCode"
            class="max-w-[340px]"
            name="public-code"
            :read-only="true"
            :allow-copy="true"
          />
          <BittsButton
            v-else-if="option.type === OFFLINE_PARTNER"
            size="small"
            text="Add Offline Partner"
            @click="addOfflinePartner"
          />
          <BittsButton
            v-else-if="option.type === INVITE_PARTNER"
            size="small"
            :text="step.primaryActionText"
            @click="step.primaryAction"
          />
          <BittsButton
            v-else-if="option.type === FIND_PARTNER"
            size="small"
            type="neutral"
            text="View Partnerbase"
            @click="step.secondaryAction"
          />
        </div>
      </div>
    </div>
  </BittsCard>
</template>
<script setup>
import {
  BittsAvatarStack,
  BittsButton,
  BittsCard,
  BittsInput,
} from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { TEMP_EXCLUDE_OFFLINE_PARTNERS } from '@/constants/feature_flags';
import { useFeatureFlagStore } from '@/stores';

defineProps({
  step: {
    type: Object,
    required: true,
  },
});

const { currentOrg } = useAuth();
const publicInviteCode = computed(
  () => `${window.location.origin}/i/${currentOrg.value.public_invite_code}`,
);
const featureFlagStore = useFeatureFlagStore();
const hasOfflinePartners = computed(
  () => !featureFlagStore.hasFeatureFlag(TEMP_EXCLUDE_OFFLINE_PARTNERS),
);

const INVITE_LINK = 'invite_link';
const INVITE_PARTNER = 'invite_partner';
const FIND_PARTNER = 'find_partner';
const OFFLINE_PARTNER = 'offline_partner';

const options = computed(() => {
  const base = [
    {
      type: INVITE_LINK,
      title: 'Share your invite URL',
      description:
        'This link allows partners to easily connect with you on Crossbeam',
    },
    {
      type: INVITE_PARTNER,
      title: 'Invite more partners',
      description: 'Send a few more partnership invites to your ecosystem',
    },
    {
      type: FIND_PARTNER,
      title: 'Find partners on Crossbeam',
      description:
        'Use Partnerbase to find your partners already using Crossbeam',
    },
  ];

  /* If the user has offline partnerships then add this as the second option */
  if (hasOfflinePartners.value) {
    base.splice(1, 0, {
      type: OFFLINE_PARTNER,
      title: 'Add an offline partner',
      description:
        'If you have data from a partner you can upload it on their behalf',
    });
  }

  return base;
});

const router = useRouter();
async function addOfflinePartner() {
  await router.push({
    name: 'create_proposal',
    query: { tab: 'offline_partner' },
  });
}
</script>
<style lang="pcss" scoped>
.c-needs-partnership-card {
  @apply flex flex-col items-center justify-center mt-24 pt-40;

  .partnership-invite-options {
    @apply flex flex-col gap-24 w-full;
  }

  .card-content {
    @apply flex flex-col items-center justify-center px-36 mb-40 w-full;

    h2 {
      @apply text-neutral-text-strong text-xl font-bold;
    }

    p.description {
      @apply text-center text-neutral-text mb-40 mt-4;
    }

    .option {
      @apply flex justify-between items-center bg-neutral-background-weak px-24 py-16 rounded-16;

      p {
        @apply text-sm;
        &.body {
          @apply text-neutral-text;
        }
        &.heading {
          @apply text-neutral-text-strong font-bold mb-4;
        }
      }
    }
  }
}
</style>
