<template>
  <BittsModal
    :modal-text="description"
    name="confirm-modal-rename-sf"
    title="Edit Connection Name"
    button-text="Save"
    :is-confirm="true"
    :visible="showRenameModal"
    :disabled="v$.newNickname.$errors.length > 0"
    @saved="updateNickname"
    @closed="hideRename"
  >
    <template #content>
      <BittsInput
        v-model.trim="newNickname"
        name="new-nickname-input"
        :status="v$.newNickname.$errors.length ? 'danger' : 'default'"
        :danger-text="v$.newNickname.$errors?.at(-1)?.$message || ''"
        :placeholder="nickname || externalUrl"
      />
    </template>
  </BittsModal>
</template>

<script>
import { BittsInput, BittsModal } from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import { helpers, maxLength } from '@vuelidate/validators';
import axios from 'axios';
import { mapActions, mapState } from 'pinia';
import { ref } from 'vue';

import { useFeedsStore, useFlashesStore } from '@/stores';
import urls from '@/urls';
import { returnTrimmedDomain } from '@/utils';

export default {
  name: 'DataSourceRenameModal',
  components: {
    BittsModal,
    BittsInput,
  },
  props: {
    feedId: {
      type: Number,
      required: true,
    },
    nickname: {
      type: String,
      required: true,
    },
  },
  setup() {
    const newNickname = ref('');

    const rules = {
      newNickname: {
        maxLength: helpers.withMessage(
          'That nickname is too long.',
          maxLength(100),
        ),
        unique: helpers.withMessage(
          'That nickname is already in use.',
          (value, _, vm) =>
            !(vm.otherFeedNames && vm.otherFeedNames.includes(value)),
        ),
      },
    };

    const v$ = useVuelidate(rules, { newNickname }, { $lazy: true });

    return { newNickname, v$ };
  },
  data() {
    return {
      feed: null,
      showRenameModal: false,
    };
  },
  computed: {
    ...mapState(useFeedsStore, ['feeds']),
    description() {
      return `This is how your feed is identified in 
        Crossbeam when using multiple connections.`;
    },
    otherFeedNames() {
      if (!this.feed) return;
      return this.feeds
        .filter((feed) => feed.nickname && feed.id !== this.feed.id)
        .map((feed) => feed.nickname);
    },
    externalUrl() {
      if (!this.feed) return '';
      return returnTrimmedDomain(this.feed.external_base_url);
    },
  },
  watch: {
    newNickname() {
      this.v$.$touch();
    },
  },
  async created() {
    this.feed = await this.fetchFeed();
  },
  mounted() {
    this.showRenameModal = true;
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useFeedsStore, ['refreshFeedsStore']),
    async hideRename() {
      await this.$router.push({ name: 'data-sources' });
    },
    async fetchFeed() {
      const url = urls.feeds.details(this.feedId);
      const response = await axios.get(url);
      return response.data;
    },
    async updateNickname() {
      try {
        if (this.v$.$invalid) return;

        if (this.newNickname !== '') {
          await axios.patch(urls.feeds.patch(this.feedId), {
            is_paused: this.feed.is_paused,
            frequency: this.feed.frequency,
            nickname: this.newNickname,
          });
          this.addSuccessFlash({ message: 'Data Source Renamed' });
        }
        await this.refreshFeedsStore();
        this.showRenameModal = false;
        this.$router.push({ name: 'data-sources' });
      } catch (_err) {
        this.addErrorFlash({ message: 'Connection could not be renamed.' });
      }
    },
  },
};
</script>
