<template>
  <div>
    <h4 class="mb-16 text-neutral-text-strong text-lg font-bold">
      Record Export Summary
    </h4>
    <BittsContainer no-padding class="mb-44">
      <RecordExportSummary class="p-24 pb-0" />
      <div
        class="border-t border-neutral-border py-12 px-24 flex items-center justify-between"
      >
        <span class="text-neutral-text-weak">
          Better understand record exports & see a full breakdown of usage
        </span>
        <BittsButton
          @click="isRecordExportModalVisible = !isRecordExportModalVisible"
          type="primary"
          variant="outline"
          size="x-small"
          text="Learn more"
        />
      </div>
    </BittsContainer>
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsContainer } from '@crossbeam/bitts';

import RecordExportSummary from '../integrations/RecordExportSummary.vue';

const isRecordExportModalVisible = defineModel<boolean>();
</script>
