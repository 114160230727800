<template>
  <div
    :class="{
      'empty-amm-cell__bottom-cutoff': isInLastRow,
      'empty-amm-cell__right-cutoff': isInLastColumn,
    }"
    class="empty-amm-cell"
  >
    <div class="empty-amm-cell__title-bar" />
    <div class="empty-amm-cell__description-bar" />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  cellIndex: {
    type: Number,
    default: 0,
  },
  rowIndex: {
    type: Number,
    default: 0,
  },
});

const MAX_COLUMN_INDEX = 3;
const MAX_ROW_INDEX = 4;

const isInLastRow = computed(() => props.rowIndex === MAX_ROW_INDEX);
const isInLastColumn = computed(() => props.cellIndex === MAX_COLUMN_INDEX);
</script>

<style lang="pcss" scoped>
.empty-amm-cell {
  @apply self-stretch w-full flex flex-col items-center justify-center
  border-l border-b border-solid border-neutral-border;
  &:nth-child(4n - 2) {
    @apply border-l-0;
  }
}
.empty-amm-cell__bottom-cutoff {
  @apply border-b-0;
  box-shadow: inset 0px -16px 24px -12px
    theme(colors.neutral.background-strong / 0.16);
  height: 55px;
}
.empty-amm-cell__description-bar {
  @apply w-1/2 h-8 rounded-bts-xxl bg-neutral-background-disabled;
}
.empty-amm-cell__right-cutoff {
  @apply w-[140px] min-w-[140px];
  box-shadow: inset -16px 0px 24px -12px
    theme(colors.neutral.background-strong / 0.16);
}

.empty-amm-cell__right-cutoff.empty-amm-cell__bottom-cutoff {
  box-shadow: inset -16px -16px 24px -12px theme(colors.neutral.background-strong /
        0.16);
  amm-cell-inner {
    box-shadow: inset -16px -12px 24px -12px theme(colors.neutral.background-strong /
          0.16);
    @apply h-[47px];
  }
}
.empty-amm-cell__title-bar {
  @apply w-1/4 h-16 rounded-bts-xxl mb-8 bg-neutral-background-disabled;
}
</style>
