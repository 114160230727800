<template>
  <component
    :is="rootComponent"
    class="reports-table-cell__wrapper"
    v-bind="rootProps"
  >
    <div
      :class="{ 'opacity-50 cursor-not-allowed': cannotEditNotifications }"
      class="flex items-center pr-40 justify-center"
    >
      <RouterLink
        :to="notificationsModalRoute"
        :class="{ 'pointer-events-none': cannotEditNotifications }"
        class="leading-none"
      >
        <BittsTooltip
          v-if="reportNotifications.length || isOwnGreenfieldReport"
          :mount-to-body="true"
          overlay-class="report-notification-cell__tooltip"
          placement="bottom"
          :class="{ 'cursor-not-allowed ml-12': isOwnGreenfieldReport }"
        >
          <div
            :class="
              isOwnGreenfieldReport
                ? 'report-notification-cell__greenfield'
                : 'report-notification-cell__icon bg-accent-background-weak mb-4'
            "
          >
            <FontAwesomeIcon
              :icon="[
                'fak',
                isOwnGreenfieldReport
                  ? 'notifications-off'
                  : 'notifications-on',
              ]"
              :style="{ height: '12px', width: '12px', color: 'currentColor' }"
              :class="
                isOwnGreenfieldReport
                  ? 'text-neutral-accent'
                  : 'mr-4 text-accent-accent'
              "
            />
            <span v-if="!isOwnGreenfieldReport"> On </span>
          </div>
          <template #title> {{ tooltipText }} </template>
        </BittsTooltip>
        <div
          v-else
          class="report-notification-cell__icon text-neutral-500 bg-neutral-50"
        >
          <FontAwesomeIcon
            :icon="['fak', 'notifications-off']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="text-neutral-accent mr-4"
          />
          <span>Off</span>
        </div>
      </RouterLink>
    </div>
    <template #title>
      {{ cannotEditText }}
    </template>
  </component>
</template>

<script>
import { BittsTooltip } from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { OWN_GREENFIELD } from '@/constants/reports';
import { CHILD_ROUTES } from '@/constants/routes';

const NOTIFS_TO_TOOLTIP_TEXT_MAP = {
  'slack-and-email': '🔔️ Email & Slack',
  slack: '🔔️ Slack',
  email: '🔔️ Email',
};

export default {
  name: 'ReportNotificationsCell',
  components: { BittsTooltip, FontAwesomeIcon },
  computed: {
    hasEmailNotif() {
      return !!this.reportNotifications.find((notif) => !!notif.emails);
    },
    hasSlackNotif() {
      return !!this.reportNotifications.find((notif) => !!notif.slack_channel);
    },
    isInErrorState() {
      return !!this.params.value.report.status?.errors.length;
    },
    hasWritePermissions() {
      return this.params.value.hasWritePermissions;
    },
    cannotEditNotifications() {
      return (
        this.isInErrorState ||
        !this.hasWritePermissions ||
        this.isOwnGreenfieldReport
      );
    },
    isOwnGreenfieldReport() {
      return (
        this.params.value.report.consolidated_report_type === OWN_GREENFIELD
      );
    },
    notificationsModalRoute() {
      if (this.params.value.isOnReportsPage) {
        return {
          name: CHILD_ROUTES.reports.REPORTS_NOTIFICATIONS,
          params: { id: this.params.value.report.id },
          query: { id: this.params.value.report.id },
        };
      }
      return {
        name: CHILD_ROUTES.folder.REPORTS_NOTIFICATIONS,
        params: { folder_id: this.params.value.report.folder_uuid },
        query: { id: this.params.value.report.id },
      };
    },
    reportNotifications() {
      return this.params.value.report.notification_configs;
    },
    rootComponent() {
      return this.cannotEditNotifications ? 'BittsTooltip' : 'RouterLink';
    },
    rootProps() {
      return this.cannotEditNotifications
        ? { placement: 'bottom', mountToBody: true }
        : { to: this.params.value.reportLink };
    },
    tooltipText() {
      if (this.hasEmailNotif && this.hasSlackNotif) {
        return NOTIFS_TO_TOOLTIP_TEXT_MAP['slack-and-email'];
      } else if (this.hasEmailNotif) {
        return NOTIFS_TO_TOOLTIP_TEXT_MAP.email;
      } else if (this.hasSlackNotif) {
        return NOTIFS_TO_TOOLTIP_TEXT_MAP.slack;
      }
      return 'Notifications are not available for this report type';
    },
    cannotEditText() {
      if (!this.hasWritePermissions) {
        return 'You have "view only" access based on your user role. Contact your admin to change reports notification';
      }
      if (this.isInErrorState) {
        return 'Notifications are paused until report errors have been addressed';
      }
      return 'Notifications are not available for this report type';
    },
  },
};
</script>

<style lang="pcss">
.report-notification-cell__greenfield {
  @apply flex justify-center p-4 text-neutral-500 rounded-bts-sm bg-neutral-50;
}

.report-notification-cell__icon {
  @apply text-sm inline-flex items-center rounded-bts-sm px-8 py-4;
  max-height: 20px !important;
}
</style>
