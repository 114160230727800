<template>
  <BittsModalTwo
    title="Record Exports"
    :hide-footer="true"
    :use-mask-to-close="true"
    variant="data"
    :no-padding="true"
    container-class="w-full lg:max-w-[1240px]"
    @closed="emit('closed:record-export-modal')"
  >
    <div class="block lg:flex">
      <section class="left-panel">
        <RecordExportSummaryPanel
          @clicked:destination="emit('closed:record-export-modal')"
        />
        <RecordExportPopulationsPanel />
      </section>
      <section>
        <RecordExportRecentsTable />
        <RecordExportLearnMore />
      </section>
    </div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsModalTwo } from '@crossbeam/bitts';

import RecordExportLearnMore from '@/components/integrations/RecordExportLearnMore.vue';
import RecordExportRecentsTable from '@/components/integrations/RecordExportRecentsTable.vue';

import RecordExportPopulationsPanel from './RecordExportPopulationsPanel.vue';
import RecordExportSummaryPanel from './RecordExportSummaryPanel.vue';
const emit = defineEmits<{
  'closed:record-export-modal': [];
}>();
</script>

<style scoped lang="pcss">
section {
  @apply w-full lg:w-1/2 p-24;
}

section.left-panel {
  @apply border-r border-neutral-border flex flex-col gap-40;
}

img {
  @apply mb-24 rounded-lg;
}
</style>
