import { EVENT_SITES } from '@crossbeam/itly';

import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, provide, reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';

import useIteratively from '@/composables/useIteratively';
import {
  CUSTOM,
  DATA_SHARING_STATE_KEY,
  HIDDEN,
  OLAPS_ONLY,
  PARTNER_POPULATION_RULE,
  POPULATION_RULE,
  PRESET_FIELDS_LOOKUP,
  RECOMMENDED,
  SHARING,
} from '@/constants/data_shares';
import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import {
  useDataSharesStore,
  useFeedsStore,
  usePartnersStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

export default function useDataSharing({ population, partnerId }) {
  const dataSharingState = reactive({
    addedPopToPopSettingsIds: [],
    addRecommendedFieldsToCustom() {
      dataSharingState.customSelectedFields = presetFieldIds.value.reduce(
        (selectedFields, presetId) => {
          if (!selectedFields.includes(presetId)) selectedFields.push(presetId);
          return selectedFields;
        },
        [...dataSharingState.customSelectedFields],
      );
    },
    allPresetFieldsChecked: computed(() =>
      presetFieldIds.value.every((presetFieldId) =>
        dataSharingState.customSelectedFields.includes(presetFieldId),
      ),
    ),
    allSelectableFields: computed(() => {
      return dataSharingState.filterableSources.reduce((fields, source) => {
        return [...fields, ...source.fields.map((field) => field.id)];
      }, []);
    }),
    customSelectedFields: [],
    deletedPopToPopSettingsIds: [],
    filterableSources: computed(
      () => popSourceIdToSourcesMap.value[population.source_id] || [],
    ),
    isGreenfieldChecked: false,
    popToPopSettings: {},
    selectedPreset: RECOMMENDED,
    trackPresetClicked(preset) {
      iteratively.userClickedDataSharingPreset({
        event_site: EVENT_SITES.SHARING_SETTINGS_MODAL,
        partner_org_id: partnerId?.toString() || '',
        preset_type: preset,
      });
    },
  });

  const dataSharesStore = useDataSharesStore();
  const feedsStore = useFeedsStore();
  const sourcesStore = useSourcesStore();
  const { popSourceIdToSourcesMap } = storeToRefs(sourcesStore);
  const partnersStore = usePartnersStore();
  const { partnerOrgs } = storeToRefs(partnersStore);

  const route = useRoute();

  const isNewPop = computed(() => route.query?.newPopulation);

  const isUpload = computed(() =>
    [FILE_UPLOAD_DATA_SOURCE_TYPE, GOOGLE_SHEETS_DATA_SOURCE_TYPE].some(
      (dataSourceType) => popSchema.value?.includes(dataSourceType),
    ),
  );

  // partner level
  const isPartnerType = computed(() => !!partnerId);
  const isPopulationLevelRule = computed(() => {
    const ruleType = population.dataShare.rule_type;
    return !ruleType || ruleType === POPULATION_RULE;
  });

  const partner = computed(() => {
    if (!partnerId) return null;
    return partnersStore.getPartnerOrgById(partnerId);
  });

  const partnersNotUsingDefaults = computed(() => {
    if (isPartnerType.value) return [];
    const partnerIdsUsingDefaults = population.partnersUsingDefaults.map(
      (org) => org.id,
    );
    return partnerOrgs.value.filter(
      (org) => !partnerIdsUsingDefaults.includes(org.id),
    );
  });

  // partner pop level
  function onOverrideAdded(id) {
    if (dataSharingState.deletedPopToPopSettingsIds.includes(id)) {
      dataSharingState.deletedPopToPopSettingsIds =
        dataSharingState.deletedPopToPopSettingsIds.filter(
          (deletedId) => deletedId !== id,
        );
    } else dataSharingState.addedPopToPopSettingsIds.push(id);
  }

  function onOverrideDeleted(id) {
    dataSharingState.deletedPopToPopSettingsIds.push(id);
  }

  async function deleteAllOverrides() {
    const promises = population.populationToPopulationSettings.reduce(
      (deletions, setting) => {
        deletions.push(
          dataSharesStore.removeAndDeleteDataShare(setting.dataShare.id),
        );
        return deletions;
      },
      [],
    );
    await Promise.all(promises);
  }
  function deleteOverrides() {
    return dataSharingState.deletedPopToPopSettingsIds.reduce(
      (promises, popId) => {
        const shareToDelete = population.populationToPopulationSettings.find(
          (setting) => setting.partnerPopulation.id === popId,
        );
        promises.push(
          dataSharesStore.removeAndDeleteDataShare(shareToDelete.dataShare.id),
        );
        return promises;
      },
      [],
    );
  }
  function trackPerPopOverrideUpdate(sourceFieldIds, popId) {
    const originalSetting = population.populationToPopulationSettings?.find(
      (setting) => setting.partnerPopulation.id.toString() === popId,
    );
    const prevFields = originalSetting?.source_field_ids || [];
    trackDataSharingChange({
      new_field_ids_shared: sourceFieldIds.map((id) => id.toString()),
      partner_id: [partnerId.toString()],
      partner_population_id: [popId],
      previous_field_ids_shared: prevFields.map((id) => id.toString()),
      sharing_level: SHARING,
      sharing_rule: PARTNER_POPULATION_RULE,
    });
  }
  function addAndUpdateOverrides() {
    const settingsToAddAndUpdate = Object.keys(
      dataSharingState.popToPopSettings,
    );
    return settingsToAddAndUpdate.reduce((promises, popId) => {
      const sourceFieldIds =
        dataSharingState.popToPopSettings[popId].selectedSourceFieldIds;
      if (!sourceFieldIds.length) return;
      let url = urls.dataShares.outgoingRules.default;
      let axiosFn = axios.post;
      const popIdInt = parseInt(popId);
      if (!dataSharingState.addedPopToPopSettingsIds.includes(popIdInt)) {
        const dataShareId = population.populationToPopulationSettings.find(
          (setting) => setting.partnerPopulation.id === popIdInt,
        ).dataShare.id;
        url = urls.dataShares.outgoingRules.byId(dataShareId);
        axiosFn = axios.patch;
      }
      trackPerPopOverrideUpdate(sourceFieldIds, popId);
      promises.push(
        axiosFn(url, {
          rule_type: PARTNER_POPULATION_RULE,
          partner_population_id: parseInt(popId),
          partner_org_id: partnerId,
          population_id: population.id,
          visibility: SHARING,
          source_field_ids: sourceFieldIds,
        }),
      );
      return promises;
    }, []);
  }
  async function saveOverrideSettings() {
    const promises = [...deleteOverrides(), ...addAndUpdateOverrides()];
    await Promise.all(promises);
  }

  // copy
  const modalText = computed(() => {
    if (isPartnerType.value) {
      return {
        title: `Sharing Settings for ${partner.value?.name || 'Partner'}`,
        text: `How do you want to share data with ${partner.value?.name || 'this partner'}
      for your ${population.name} population?`,
      };
    }
    return {
      title: `${population.name} - Sharing Default`,
      text: `${population.name} Sharing Default`,
    };
  });

  const sharingText = computed(() => {
    const partnerName = isPartnerType.value
      ? partner.value?.name
      : 'Your partners';
    return {
      [SHARING]: `${isPartnerType.value ? partner.value?.name : 'By default, your partners'} can see the data shared
      with them for all overlaps`,
      [OLAPS_ONLY]: `${partnerName} can see a summary count of overlaps for this population.
      No specifics about the overlaps, including name or any other data, is shared`,
      [HIDDEN]: `${partnerName} cannot see this population`,
    };
  });

  // analytics
  const { iteratively } = useIteratively();
  const baseIterativelyPayload = computed(() => {
    const preset = sharingPresets.value[PRESET_IDX].active
      ? RECOMMENDED
      : CUSTOM;
    return {
      event_site: EVENT_SITES.SHARING_SETTINGS_MODAL,
      partner_id: [],
      partner_population_id: [],
      population_id: population.id.toString(),
      preset_type: preset,
    };
  });

  function trackDataSharingChange(itlyPayload) {
    iteratively.userChangesSharingRules({
      ...baseIterativelyPayload.value,
      ...itlyPayload,
    });
  }

  // presets
  const popSource = computed(() =>
    sourcesStore.getSourceById(population.source_id),
  );
  const popSchema = computed(() => {
    const schema = feedsStore.getFeedById(popSource.value?.feed_id)?.integration
      .type;
    if (schema?.includes(HUBSPOT_DATA_SOURCE_TYPE)) return HS3_DATA_SOURCE_TYPE;
    return schema;
  });

  const presetFields = computed(() => {
    return dataSharingState.filterableSources.reduce((sources, source) => {
      if (!PRESET_FIELDS_LOOKUP[source.mdm_type]) return sources;
      const fields = source.fields.filter((field) =>
        PRESET_FIELDS_LOOKUP[source.mdm_type].includes(field.mdm_property),
      );
      if (!fields.length) return sources;
      return { ...sources, [source.table]: { ...source, fields } };
    }, []);
  });

  const presetFieldIds = computed(() => {
    return Object.keys(presetFields.value).reduce((fields, table) => {
      return [
        ...fields,
        ...presetFields.value[table].fields.map((field) => field.id),
      ];
    }, []);
  });

  function areOnlyPresetFieldsSelected(fields) {
    const presetIdsString = presetFieldIds.value.sort().join('');
    const selectedFieldsString = fields.sort().join('');
    return presetIdsString === selectedFieldsString;
  }

  const sharingPresets = computed(() => {
    const presets = [
      {
        description: 'Customize fields based on our recommendations',
        sourcesAndFields: dataSharingState.customSelectedFields,
        name: 'Customize Fields',
        value: CUSTOM,
        active: dataSharingState.selectedPreset === CUSTOM,
      },
    ];
    if (!isUpload.value) {
      presets.unshift({
        description: 'Required fields for account mapping and co-selling',
        sourcesAndFields: presetFields.value,
        name: 'Recommended',
        value: RECOMMENDED,
        active: dataSharingState.selectedPreset === RECOMMENDED,
      });
    }
    return presets;
  });

  const PRESET_IDX = 0;
  function selectRecommendedFields() {
    const isUploadOrSnowflake =
      isUpload.value || popSchema.value?.includes(SNOWFLAKE_DATA_SOURCE_TYPE);
    if (isUploadOrSnowflake) {
      dataSharingState.selectedPreset = CUSTOM;
      dataSharingState.customSelectedFields = [
        ...dataSharingState.allSelectableFields,
      ];
    } else {
      dataSharingState.selectedPreset = RECOMMENDED;
      dataSharingState.customSelectedFields = [...presetFieldIds.value];
    }
  }

  const dataSharingStateRefs = toRefs(dataSharingState);
  provide(DATA_SHARING_STATE_KEY, dataSharingStateRefs);
  return {
    areOnlyPresetFieldsSelected,
    ...dataSharingStateRefs,
    deleteAllOverrides,
    isNewPop,
    isPartnerType,
    isPopulationLevelRule,
    isUpload,
    modalText,
    onOverrideAdded,
    onOverrideDeleted,
    partner,
    partnersNotUsingDefaults,
    popSchema,
    presetFieldIds,
    saveOverrideSettings,
    selectRecommendedFields,
    sharingPresets,
    sharingText,
    trackDataSharingChange,
  };
}
