<template>
  <div class="c-notification">
    <div class="c-notification__content">
      <div class="flex justify-start self-start pr-16 pb-8">
        <div class="flex items-center h-full">
          <div class="mr-8" @click="onMarkRead">
            <div
              v-if="item.is_read"
              class="c-notification__read-indicator invisible"
            />
            <div v-else class="c-notification__read-indicator" />
          </div>
          <div class="w-40 mt-4 text-center">
            <slot name="logo" />
          </div>
        </div>
      </div>
      <div class="c-notifications-menu__message">
        <div class="c-notification__notification-text">
          <slot name="message" />
          <div
            class="flex items-center text-neutral-text-weak text-sm font-normal"
          >
            <span>{{ getDate() }} •</span>
            <slot name="icon" />
          </div>
        </div>
        <div
          class="text-neutral-text text-base"
          data-testid="notification-description"
        >
          <slot name="description" />
        </div>
        <div class="c-notification__footer">
          <slot name="action" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { DateTime } from 'luxon';
import { ref } from 'vue';

import { useNotificationsStore } from '@/stores';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const putPending = ref(false);

const { markRead } = useNotificationsStore();
async function onMarkRead() {
  if (!putPending.value) {
    putPending.value = true;
    await markRead({ id: props.item.id, isRead: true });
    putPending.value = false;
  }
}

function getDate() {
  return DateTime.fromISO(props.item.created_at).toRelative();
}
</script>

<style lang="pcss" scoped>
.c-notifications {
  @apply flex flex-col;
}

.c-notification__content {
  @apply flex items-center w-full py-8 px-12;
}

.c-notification__notification-text {
  @apply flex justify-between text-base items-center font-bold text-neutral-text-strong;
}

.c-notification__read-indicator {
  @apply border border-solid cursor-pointer bg-upsell-background border-upsell-background w-6 h-6;
  border-radius: 50%;
}

.c-notification__footer {
  @apply flex items-center justify-between pt-12 pb-4;
}
.c-notifications-menu__message {
  @apply w-full;
}
</style>
