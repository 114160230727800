<template>
  <div class="c-bitts-timeline">
    <template v-if="showActivities">
      <div
        v-for="[
          dateCopy,
          daysEvents,
        ] in activitiesGroupedByRelativeDateNoDupes.entries()"
        :key="dateCopy"
        class="timeline-date-grouping"
      >
        <div class="timeline-grouping-title">
          <FontAwesomeIcon
            :icon="['fak', 'calendar']"
            class="timeline-grouping-title__icon"
          />
          <h2
            class="text-neutral-text-strong font-bold"
            data-testid="date-group-title"
          >
            {{ dateCopy }}
          </h2>
        </div>
        <AttributionTimelineEvent
          v-for="event in daysEvents"
          :key="event.uuid"
          :activity="event"
          :account-name="accountName"
          class="w-full"
        >
          <template #footer>
            <GongMentions v-if="isGongEvent(event)" :activity="event" />
          </template>
        </AttributionTimelineEvent>
      </div>
    </template>
    <div ref="scrollTemplateRef" class="load-more">
      <template v-if="events.length > numInitialEvents">
        <FontAwesomeIcon
          :icon="['fak', 'party']"
          class="text-info-accent mr-8"
        />
        <p>You've reached the end of this timeline</p>
      </template>
    </div>
  </div>
</template>

<script setup>
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import AttributionTimelineEvent from '@/components/attribution/AttributionTimelineEvent.vue';
import GongMentions from '@/components/records/Timeline/GongMentions.vue';

import { ATTRIBUTION_EVENT_TYPES } from '@/constants/attribution';
import { todayYesterdayTomorrowOrCustom } from '@/date_time_utils';

const props = defineProps({
  events: {
    type: Array,
    default: () => [],
  },
  numInitialEvents: {
    type: Number,
    default: 15,
  },
  accountName: {
    type: String,
    default: null,
  },
});

const loadUntil = ref(props.numInitialEvents);
const showActivities = ref(false);
const scrollTemplateRef = ref(null);
const loadMoreValue = 10;

const isGongEvent = (event) => event.activity_source === 'gong';

const formatTimelineDate = (luxonDate) => {
  return luxonDate.toRelative({ unit: 'days' });
};

let intersectionObserver = null;

onMounted(() => {
  const options = {
    rootMargin: '30px',
    threshold: 0,
  };
  intersectionObserver = new IntersectionObserver(onIntersection, options);
  intersectionObserver.observe(scrollTemplateRef.value);
});

onBeforeUnmount(() => {
  intersectionObserver?.disconnect();
});

const onIntersection = (entries) => {
  const [loadMoreElement] = entries;
  if (loadMoreElement?.isIntersecting) {
    if (showActivities.value) loadUntil.value += loadMoreValue;
    showActivities.value = true;
  }
};

/* using a Map to guarantee insertion order */
const activitiesGroupedByRelativeDateNoDupes = computed(() => {
  return props.events?.slice(0, loadUntil.value).reduce((acc, currentEvent) => {
    const eventDate = DateTime.fromISO(currentEvent.triggered_at);
    const formattedDate = todayYesterdayTomorrowOrCustom(
      eventDate,
      false,
      formatTimelineDate,
    );
    if (acc.has(formattedDate)) {
      // filtering duplicates
      const currentEvents = acc.get(formattedDate);
      const previousEvent = currentEvents.at(-1);

      const sameEventType = previousEvent.type === currentEvent.type;
      const samePartner =
        previousEvent.partner_organization_uuid ===
        currentEvent.partner_organization_uuid;
      const sameData = isEqual(previousEvent.data, currentEvent.data);
      const isOverlapMovement =
        previousEvent.type === ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY ||
        previousEvent.type === ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT;
      const sameDataPopulationName =
        previousEvent.data.partner_population_name ===
        currentEvent.data.partner_population_name;

      // if the types, partner and data are the same, we definitely would display the exact same thing
      const generallyTheSameEvent = sameEventType && samePartner && sameData;

      // for overlap movements, we can be more restrictive
      // if the record is in multiple of our own population, we don't want to show that and they are rendered the same
      const overlapMovementTheSameEvent =
        isOverlapMovement &&
        sameEventType &&
        samePartner &&
        sameDataPopulationName;

      if (!generallyTheSameEvent && !overlapMovementTheSameEvent) {
        currentEvents.push(currentEvent);
      }
    } else {
      acc.set(formattedDate, [currentEvent]);
    }

    return acc;
  }, new Map());
});
</script>

<style scoped lang="pcss">
.c-bitts-timeline {
  @apply pb-24;
}
.load-more {
  @apply flex items-center justify-center mt-8 text-neutral-text;
}
.timeline-date-grouping {
  @apply ml-8;
}

.timeline-grouping-title {
  @apply flex items-center my-4;
}

.timeline-grouping-title__icon {
  @apply mr-8 text-neutral-accent text-16 ml-[-8px];
}
</style>
