<template>
  <div>{{ pops }}</div>
</template>
<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';

type Props = {
  params: {
    value: number;
  };
};

const props = withDefaults(defineProps<Props>(), {});

const pops = ref(0);
onBeforeMount(() => {
  pops.value = props.params.value;
});
</script>
