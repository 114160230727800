<template>
  <div class="c-debooking-table-user-cell" :class="{ disabled: isRowDisabled }">
    <span class="text-neutral-text-weak" data-testid="last-active">
      {{ dateOfActivity }}
    </span>
  </div>
</template>

<script setup>
import { DateTime } from 'luxon';
import { computed, onBeforeMount, ref } from 'vue';

import useAuthOrInvite from '@/composables/useAuthOrInvite';
import { EXPLORER_SEATS } from '@/constants/billing';
import { todayYesterdayTomorrowOrCustom } from '@/date_time_utils';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const context = ref(null);
const authOrInvite = ref(null);
onBeforeMount(() => {
  authOrInvite.value = props.params.data;
  context.value = props.params.context;
});
const { id } = useAuthOrInvite(authOrInvite);

const isRowDisabled = computed(
  () =>
    !context.value.checkAll &&
    !context.value.users?.has(id.value) &&
    context.value.users?.size >= EXPLORER_SEATS,
);

const dateOfActivity = computed(() => {
  const value = todayYesterdayTomorrowOrCustom(
    DateTime.fromISO(authOrInvite.value.last_active),
  );
  if (value === 'No date') return '--';
  return value;
});
</script>

<style lang="pcss">
.c-debooking-table-user-cell {
  @apply flex items-center h-64;
}
</style>
