<template>
  <div class="flex flex-wrap gap-4">
    <div
      v-for="tag in firstTags"
      :key="tag.id"
      class="c-sharing-dashboard-partner-tags__tag-container"
    >
      <p class="c-sharing-dashboard-partner-tags__label">
        {{ tag.label }}
      </p>
    </div>
    <BittsPopover
      v-if="restOfTags.length"
      trigger="hover"
      overlay-class="c-sharing-dashboard-partner-tags__popover"
    >
      <p class="c-sharing-dashboard-partner-tags__overflow">
        + {{ restOfTags.length }}
      </p>
      <template #content>
        <div class="flex flex-col p-8 gap-4 min-w-[184px]">
          <div
            v-for="tag in restOfTags"
            :key="tag.id"
            class="c-sharing-dashboard-partner-tags__tag-container"
          >
            <p class="c-sharing-dashboard-partner-tags__label">
              {{ tag.label }}
            </p>
          </div>
        </div>
      </template>
    </BittsPopover>
  </div>
</template>
<script setup>
import { BittsPopover } from '@crossbeam/bitts';

import { computed } from 'vue';

const props = defineProps({
  tags: {
    type: Array,
    default: () => [],
  },
});

const firstTags = computed(() => props.tags.slice(0, 3));
const restOfTags = computed(() => props.tags.slice(3));
</script>
<style lang="pcss">
.c-sharing-dashboard-partner-tags__tag-container {
  @apply px-8 py-4 rounded-4 flex flex-nowrap;
  max-width: fit-content;
  background: linear-gradient(
    92deg,
    theme(colors.neutral.background) 0%,
    theme(colors.neutral.background / 0.2) 100%
  );
}
.c-sharing-dashboard-partner-tags__popover {
  @apply flex items-center rounded-4;
}

.c-sharing-dashboard-partner-tags__label {
  @apply text-neutral-text-weak text-sm font-normal;
}
.c-sharing-dashboard-partner-tags__overflow {
  @apply text-neutral-text-weak font-normal text-sm px-8 py-4 rounded-4;
  max-width: fit-content;
  background: linear-gradient(
    92deg,
    theme(colors.neutral.background) 0%,
    theme(colors.neutral.background / 0.2) 100%
  );
}
</style>
