<template>
  <h6 class="text-neutral-text-strong text-base/6 font-bold mt-16">
    Partner-specific settings
  </h6>
  <div v-if="treeData.length === 0" class="flex">
    <p class="text-neutral-text-weak text-base/6 font-normal mr-8">
      You don’t have any partners
    </p>
    <RouterLink to="/partners/invite" class="text-secondary-accent text-base/6">
      <span>Add Partner</span>
      <FontAwesomeIcon class="text-[11px] ml-4" :icon="['fak', 'open-link']" />
    </RouterLink>
  </div>
  <BittsTreeWithSearch
    v-else
    :node-data="treeData"
    @update="onUpdate"
    :is-disabled
  >
    <template #node-suffix="{ node }: { node: TreeNodeType }">
      <BittsTag
        class="flex"
        v-if="isPartnerOrgNode(node) && isGhost(node.id)"
        color="neutral"
        size="x-small"
        variant="rounded"
      >
        <template #pre>
          <div class="flex items-center gap-4">
            <FontAwesomeIcon
              :icon="['fak', 'offline']"
              class="text-neutral-accent"
              :style="{
                height: '8px',
                width: '8px',
                color: 'currentColor',
              }"
            />
            Offline
          </div>
        </template>
      </BittsTag>
      <BittsTooltip
        placement="bottom"
        v-if="isPartnerOrgNode(node) && node.isDisabled"
      >
        <BittsTag
          class="flex"
          text="No data shared"
          color="warning"
          size="x-small"
          variant="rounded"
        />
        <template #title>This partner doesn’t have any populations </template>
      </BittsTooltip>
      <BittsTooltip
        button-text="Request data"
        :include-cta="true"
        @cta-clicked="redirectToPartnerFromPopId(Number(node.id))"
        placement="bottom"
        v-if="
          !isPartnerOrgNode(node) &&
          populationIdsSharingOnlyCount.includes(Number(node.id))
        "
      >
        <BittsTag
          class="flex"
          text="No data shared"
          color="warning"
          size="x-small"
          variant="rounded"
        />
        <template #title
          >This partner is not sharing data from this population with you
        </template>
      </BittsTooltip>
      <template v-if="ffStore.hasFeatureFlag(TEMP_SHOW_RESET_TO_DEFAULTS)">
        <BittsButton
          @click.stop
          @click="resetToDefault(String(node.id))"
          :left-icon="['fak', 'cycle']"
          left-icon-classes="text-neutral-accent"
          size="x-small"
          type="neutral"
          variant="ghost"
          text="Reset to default"
          v-if="
            node.children?.length !== 0 &&
            partnerIdsWithOverrides.includes(String(node.id))
          "
        />
      </template>
      <template v-else>
        <BittsTag
          text="Overridden Setting"
          class="cursor-default"
          size="x-small"
          color="info"
          v-if="
            node.children?.length !== 0 &&
            partnerIdsWithOverrides.includes(String(node.id))
          "
        />
      </template>
    </template>
  </BittsTreeWithSearch>
</template>

<script setup lang="ts">
import { BittsButton, BittsTag, BittsTreeWithSearch } from '@crossbeam/bitts';
import BittsTooltip from '@crossbeam/bitts/src/BittsTooltip/BittsTooltip.vue';
import { TreeNodeType } from '@crossbeam/bitts/src/BittsTree/BittsBaseTree.vue';

import { sortBy } from 'lodash';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { TEMP_SHOW_RESET_TO_DEFAULTS } from '@/constants/feature_flags';
import { STANDARD_POPULATION_TYPES } from '@/constants/standard_populations';
import {
  useDataSharesStore,
  useFeatureFlagStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';
import { MinimalIntegrationProfile } from '@/types/integration_profiles';
import { PartnerPopulation } from '@/types/populations';

const props = withDefaults(
  defineProps<{
    integrationProfileSettings?: MinimalIntegrationProfile;
    isDisabled?: boolean;
    settingType: string;
  }>(),
  { integrationProfileSettings: undefined, isDisabled: false },
);
const emit =
  defineEmits<
    (e: 'update-settings', payload: MinimalIntegrationProfile) => void
  >();
const ffStore = useFeatureFlagStore();
const router = useRouter();
const populationsStore = usePopulationsStore();
const partnersStore = usePartnersStore();
const dataShareStore = useDataSharesStore();
const checkedKeys = computed<number[]>(() => {
  return (
    props.integrationProfileSettings?.population_settings
      .filter((pop) => pop.is_partner_population && pop.is_included)
      .map((pop) => pop.population_id) ?? []
  );
});
const checkedStandardKeys = computed<string[]>(() => {
  return (
    props.integrationProfileSettings?.standard_population_settings
      .filter((pop) => pop.is_included)
      .map((pop) => pop.standard_type) ?? []
  );
});
const partnerIdsWithOverrides = computed<string[]>(() => {
  const populationSettingsSet = new Set(
    props.integrationProfileSettings?.population_settings.map(
      (p) => p.population_id,
    ) || [],
  );

  return partnersStore.partnerOrgs
    .filter((partner) =>
      populationsStore.partnerPopulations
        .filter((pop) => pop.organization_id === partner.id)
        .some((pop) => populationSettingsSet.has(pop.id)),
    )
    .map((partner) => partner.uuid);
});
const populationIdsSharingOnlyCount = computed<number[]>(() => {
  return populationsStore.partnerPopulations
    .filter((pop) => isSharingOnlyCount(pop.id))
    .map((pop) => pop.id);
});

const filterForCustomersAndOppsPopulations = (pop: PartnerPopulation) =>
  pop.standard_type === 'open_opportunities' ||
  pop.standard_type === 'customers';

const treeData = computed<TreeNodeType[]>(() => {
  return partnersStore.partnerOrgs.map((partner) => {
    const hasOverrides = partnerIdsWithOverrides.value.includes(partner.uuid);
    const children = sortBy(
      populationsStore.partnerPopulations.filter(
        (pop) =>
          pop.organization_id === partner.id &&
          (props.settingType === 'clari'
            ? filterForCustomersAndOppsPopulations(pop)
            : true),
      ),
      (pop) => {
        if (pop.standard_type)
          return STANDARD_POPULATION_TYPES.indexOf(pop.standard_type);
      },
    ).map((pop) => ({
      label: pop.name,
      id: pop.id,
      checked: hasOverrides
        ? checkedKeys.value.includes(pop.id)
        : pop.standard_type
          ? checkedStandardKeys.value.includes(pop.standard_type)
          : checkedStandardKeys.value.includes('custom'),
      children: [],
    }));
    return {
      isDisabled: children.length === 0,
      label: partner.name,
      id: partner.uuid,
      checked: children.length > 0 && children.every((child) => child.checked),
      children,
    };
  });
});

const isSharingOnlyCount = (partnerPopulationId: number) => {
  return (
    (dataShareStore.getIncomingSharingRules({
      partnerPopulationId,
      isOverride: false,
    })?.[0]?.shared_fields.length ?? 0) === 0
  );
};

const isPartnerOrgNode = (node: TreeNodeType) => {
  return typeof node.id === 'string';
};

const isGhost = (partnerId: number | string) => {
  return typeof partnerId === 'number'
    ? false
    : (partnersStore.getPartnerOrgByUuid(partnerId)?.offline_partner ?? false);
};

const onUpdate = (node: TreeNodeType[], updatedNodeId: number | string) => {
  const updatedPops: TreeNodeType[] = [];
  for (const partner of node) {
    if (partner.id === updatedNodeId) {
      updatedPops.push(...(partner.children ?? []));
      break;
    }
    const child = partner.children?.find((pop) => pop.id === updatedNodeId);
    if (!child) continue;
    const hasOverrides = partnerIdsWithOverrides.value.includes(
      partner.id as string,
    );
    hasOverrides
      ? updatedPops.push(child)
      : updatedPops.push(...(partner.children ?? []));
    break;
  }

  const updatedSettings: MinimalIntegrationProfile = {
    population_settings: [
      ...(props.integrationProfileSettings?.population_settings ?? []).filter(
        (pop) => !updatedPops.some((p) => p.id === pop.population_id),
      ),
      ...updatedPops.map((pop) => ({
        is_included: pop.checked ?? false,
        is_partner_population: true,
        population_id: pop.id as number,
        config_type: props.settingType,
      })),
    ],
    standard_population_settings:
      props.integrationProfileSettings?.standard_population_settings ?? [],
  };

  emit('update-settings', updatedSettings);
};

const redirectToPartnerFromPopId = (populationId: number) => {
  const partnerId = populationsStore.partnerPopulations.find(
    (pop) => pop.id === populationId,
  )?.organization_id;
  if (!partnerId) {
    return;
  }
  router.push({
    name: 'partner_details',
    params: { partner_org_id: partnerId },
    query: { tab: 'shared_with_you' },
  });
};

const resetToDefault = (partnerUuid: string) => {
  const partnerId = partnersStore.getPartnerOrgByUuid(partnerUuid)?.id;
  const populationsToRemove = populationsStore.partnerPopulations.filter(
    (pop) => pop.organization_id === partnerId,
  );
  const updatedSettings: MinimalIntegrationProfile = {
    population_settings: [
      ...(props.integrationProfileSettings?.population_settings ?? []).filter(
        (pop) => !populationsToRemove.some((p) => p.id === pop.population_id),
      ),
    ],
    standard_population_settings:
      props.integrationProfileSettings?.standard_population_settings ?? [],
  };

  emit('update-settings', updatedSettings);
};
</script>
