import { useIterativelyPackage } from '@crossbeam/itly';

import { DEVICE_UUID as FAKE_DEVICE_UUID } from '@test/constants';

import appConfig from '@/config';
import { captureException } from '@/errors';
import { ls } from '@/local_storage';

export default function useIteratively() {
  let uuid = ls.deviceUUID.get() || '';
  if (import.meta.env.VITEST === 'true') uuid = FAKE_DEVICE_UUID;

  const {
    iteratively,
    loadIteratively,
    setTrackingOptions,
    captureLoggedInUser,
  } = useIterativelyPackage({
    captureException,
    environment: appConfig.iteratively.environment,
    uuid,
  });

  return {
    iteratively,
    loadIteratively,
    setTrackingOptions,
    captureLoggedInUser,
  };
}
