<template>
  <BittsModalTwo
    class="c-invite-crm-admins-modal"
    title="Invite your CRM Admins"
    description="Inviting your Salesforce Administrators might be required to connect your Salesforce, which allows an optimal use of Crossbeam."
    secondary-button-text="Skip"
    @closed="handleCloseModal"
    @action="handleSendInvite"
    :disabled="isSendInviteDisabled"
    :width="560"
  >
    <template #image>
      <BittsAvatarStack
        :org-list="avatarStackOrgList"
        size="medium"
        :transparent="false"
      />
    </template>
    <div class="c-invite-crm-admins-modal__content">
      <BittsTooltip>
        <template #title v-if="!!crmAdminEmailAlreadyInvited">
          Already invited
        </template>
        <BittsInput
          v-model.trim="email1"
          name="email1"
          :placeholder="crmAdminEmailAlreadyInvited?.[0] ?? 'Work email'"
          :status="isValidEmail1 ? 'default' : 'danger'"
          danger-text="Incorrect email address"
          :disabled="!!crmAdminEmailAlreadyInvited"
        />
      </BittsTooltip>
      <BittsInput
        v-model.trim="email2"
        name="email2"
        placeholder="Work email"
        :status="isValidEmail2 ? 'default' : 'danger'"
        danger-text="Incorrect email address"
      />
      <div class="text-neutral-text-weak text-center mt-28">
        You can skip this step if you have permissions to connect your company’s
        CRM - or if you want to get started with another data source such as
        Google Sheets or CSV files
      </div>
    </div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsAvatarStack, BittsInput, BittsModalTwo } from '@crossbeam/bitts';
import BittsTooltip from '@crossbeam/bitts/src/BittsTooltip/BittsTooltip.vue';

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { isEmailValid, useConditionalDebouncedValidation } from '@/utils';

const { currentOrg } = useAuth();
const router = useRouter();

const email1 = ref('');
const email2 = ref('');

// TODO: Use BE data when ready.
const crmAdminEmailAlreadyInvited = ['jean@marc.com'];
// const crmAdminEmailAlreadyInvited = null;

const avatarStackOrgList = computed(() => [
  currentOrg.value,
  {
    customSvg: 'add',
  },
]);

const isValidEmail1 = useConditionalDebouncedValidation(email1, isValid, 700);
const isValidEmail2 = useConditionalDebouncedValidation(email2, isValid, 700);

const isSendInviteDisabled = computed(
  () =>
    (!email1.value.length && !email2.value.length) ||
    !isValid(email1.value) ||
    !isValid(email2.value),
);

async function handleCloseModal() {
  await router.push({ name: 'home' });
}

function handleSendInvite() {
  console.log('Save button clicked:', {
    email1: email1.value,
    email2: email2.value,
  }); // TODO: Post/patch to BE when it's ready
}

/* Helpers 🤝 */

function isValid(emailValue: string) {
  return !emailValue.length || (emailValue.length && isEmailValid(emailValue));
}
</script>

<style lang="pcss">
.c-invite-crm-admins-modal {
  .bitts-avatar-stack-medium {
    .bitts-avatar-stack__org-list {
      @apply justify-center p-0 mb-10;
      & > div {
        @apply w-[52px];
      }
      img {
        @apply bg-denim-200;
      }
    }
  }
  .c-invite-crm-admins-modal__content {
    @apply flex flex-col items-center gap-12 mt-24 mb-40;
    .c-bitts-input {
      @apply w-[400px];
    }
  }
}
</style>
