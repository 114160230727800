/* ID used for Chili Piper form */
export const BOOK_MEETING_FORM_ID = `book-meeting-form-container`;

export const displayOnboardingMeetingDialog = ({
  firstName,
  lastName,
  email,
  company,
  onSuccess,
  onClose,
}: {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  onSuccess: () => void;
  onClose: () => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).ChiliPiper.submit('reveal', 'CB_onboarding_router', {
    lead: {
      Campaign_content__c: 'Onboarding Step',
      Company: company,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      LeadSource: 'Chili Piper',
      Time_Zone__c: new Date().getTimezoneOffset(),
    },
    map: true,
    domElement: `#${BOOK_MEETING_FORM_ID}`,
    onSuccess,
    onClose,
    onError: onClose,
  });
};
