<template>
  <AutoComplete
    ref="autocomplete"
    :search-results="clearbitResults"
    :loading="clearbitLoading"
    :enable-empty-state="false"
    :placeholder="placeholder"
    :initial-value="initialValue"
    name="company_name"
    lodash-path="name"
    @change="onSearchChanged"
    @selected="setContactCompany"
  >
    <template #item="{ value: clearbitValue }">
      <div class="c-partner__autocomplete__dropdown-item">
        <BittsAvatar
          :org="orgForAvatar(clearbitValue)"
          size="small"
          :show-initials="true"
        />
        <span>{{ clearbitValue.name }}</span>
      </div>
    </template>
  </AutoComplete>
</template>

<script>
import { BittsAvatar } from '@crossbeam/bitts';

import axios from 'axios';
import { debounce } from 'lodash';

import AutoComplete from '@/components/AutoComplete.vue';

import urls from '@/urls';

export default {
  name: 'AutoCompleteClearbit',
  components: { BittsAvatar, AutoComplete },
  props: {
    initialValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Company Name',
    },
  },
  emits: ['change', 'selected'],
  data() {
    return {
      clearbitResults: [],
      clearbitLoading: false,
      searchDebounced: null,
    };
  },
  created() {
    this.searchDebounced = debounce(this.getClearbitCompanies, 300);
  },
  methods: {
    clear() {
      this.$refs.autocomplete.clear();
    },
    onSearchChanged(query) {
      this.$emit('change', query);
      this.clearbitLoading = true;
      if (query) {
        this.searchDebounced(query);
      } else {
        this.clearbitResults = [];
        this.clearbitLoading = false;
      }
    },
    orgForAvatar(clearbitValue) {
      const { name, domain } = clearbitValue;
      return { name, domain };
    },
    async getClearbitCompanies(query) {
      if (!query) {
        this.clearbitResults = [];
        this.clearbitLoading = false;
        return;
      }
      await axios
        .get(urls.clearbitAutoComplete(query))
        .then((response) => {
          return response.data;
        })
        .then((result) => {
          // on certain inputs (e.g. " ") clearbit will respond with a 412
          // confirming that the result is an array covers us for later iterating over the result
          this.clearbitResults = Array.isArray(result) ? result : [];
        })
        .catch(() => {
          this.clearbitResults = [];
        });
      this.clearbitLoading = false;
    },
    setContactCompany(company) {
      this.$emit('selected', {
        name: company.name,
        clearbit_domain: company.domain,
      });
    },
  },
};
</script>
<style lang="pcss">
.c-auto-complete-clearbit {
  .c-dropdown-menu-list__item--active {
    @apply bg-neutral-100;
  }
}

.c-partner__autocomplete__dropdown-item {
  color: var(--navy);
  height: 32px;
  font-size: 14px;
  white-space: nowrap;
  display: grid;
  grid-template-columns: [icon] 32px [text] auto;
  grid-column-gap: 16px;
  align-items: center;
  text-decoration: none;
  text-align: left;
}
</style>
