import axios from 'axios';
import { DateTime } from 'luxon';

import useAuth from '@/composables/useAuth';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';

const DATA_LIMIT = 1000000;
const DATA_LIMIT_STR = '1 million';
const DEFAULT_EXPORT_NAME = 'Crossbeam';

const getCleansedExportName = (exportName = null) => {
  const name = exportName || DEFAULT_EXPORT_NAME;
  return name.replace(/ /g, '_').replace(/[^a-zA-Z0-9_\\-]/gi, '');
};

const validOrUnknownSize = (size = null) => {
  return !size || size <= DATA_LIMIT;
};
export default function useExports() {
  const { currentUser } = useAuth();
  const flashesStore = useFlashesStore();
  const getFlashDesc = (size = null) => {
    return validOrUnknownSize(size)
      ? `You will receive an email at ${currentUser.value.email} once it completes.`
      : `Your export will include the first ${DATA_LIMIT_STR} results.
          If you need a larger export, please contact us at support@crossbeam.com.`;
  };

  async function performExport(exportPayload, exportUrl, exportSize = null) {
    const timestamp = DateTime.local().toFormat('y-MM-dd_hhmma');
    const cleansedExportName = getCleansedExportName(exportPayload.export_name);
    const payload = {
      ...exportPayload,
      requested_filename: `${cleansedExportName}_${timestamp}`,
    };
    try {
      await axios.post(exportUrl, payload);
      const flashObject = {
        message: 'We have started your export.',
        description: getFlashDesc(exportSize),
      };
      flashesStore.addSuccessFlash(flashObject);
    } catch (err) {
      flashesStore.addErrorFlash({
        message: 'Export failed. Try again',
        description: 'If the error persists contact support@crossbeam.com',
      });
      captureException(err);
    }
  }

  return { performExport };
}
