<template>
  <div class="c-expand-button" @click="toggle">
    <span class="c-expand-button__text">
      <FontAwesomeIcon
        v-if="mode === 'expanded'"
        :icon="['fak', 'chevron-down']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
      />
      <FontAwesomeIcon
        v-else
        :icon="['fak', 'chevron-right']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-400"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'ExpandButton',
  props: {
    toggle: {
      type: Function,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-expand-button {
  @apply text-brand-navy;
  border-radius: 4px;
  padding: 4px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
</style>
