import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { OLAPS_ONLY, VISIBILITY_OPTIONS_TEXT } from '@/constants/data_shares';
import {
  useDataRequestsStore,
  useDataSharesStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';

export default function usePartnerSharing(partnerOrgId) {
  const dataSharesStore = useDataSharesStore();
  const { outboundShareRequests } = storeToRefs(useDataRequestsStore());
  const { getPartnerOrgById } = usePartnersStore();
  const { getPartnerPopulationsByOrg, getPopulationById } =
    usePopulationsStore();
  const { populations } = storeToRefs(usePopulationsStore());

  const partnerOrg = computed(() => getPartnerOrgById(partnerOrgId));
  const partnerPopulations = computed(() =>
    getPartnerPopulationsByOrg(partnerOrgId),
  );

  function getSharingInformation(partnerPopulation) {
    const populationSetting = dataSharesStore.getIncomingSharingRules({
      partnerPopulationId: partnerPopulation.id,
      isOverride: false,
    })[0];
    if (!populationSetting) {
      return {
        visibility: OLAPS_ONLY,
        title: VISIBILITY_OPTIONS_TEXT.aggregates_only.selector,
        sharedFields: [],
      };
    }
    if (populationSetting.shared_fields.length > 0) {
      const title = `${populationSetting.shared_fields.length} Shared
        ${populationSetting.shared_fields.length !== 1 ? 'Fields' : 'Field'}`;
      return {
        visibility: 'full',
        title,
        sharedFields: populationSetting.shared_fields,
      };
    }
    return {
      visibility: OLAPS_ONLY,
      title: VISIBILITY_OPTIONS_TEXT.aggregates_only.selector,
      sharedFields: [],
    };
  }

  function getDataRequestInformation(partnerPopulation) {
    const dataRequestInformation = outboundShareRequests.value.find(
      (shareRequest) =>
        shareRequest.partner_org_id === partnerPopulation.organization_id &&
        shareRequest.partner_population_id === partnerPopulation.id &&
        shareRequest.status === 'pending',
    );
    if (!dataRequestInformation) {
      return;
    }
    return {
      ...dataRequestInformation,
      fromNow: DateTime.fromISO(dataRequestInformation.created_at).toRelative(),
    };
  }

  function transformPopulationToPopulationSetting(setting) {
    const population = getPopulationById(setting.population_id);
    const partnerOrganization = partnerOrg.value;
    const sourceFields = setting.shared_fields.slice();
    return {
      dataShare: { ...setting },
      population,
      partnerOrganization,
      sourceFields,
    };
  }

  const isOwnSharingWithPartner = computed(() =>
    populations.value.some((pop) =>
      dataSharesStore.isSharingPopulationWithPartner(pop.id, partnerOrgId),
    ),
  );

  const partnerPopulationsWithMetadata = computed(() => {
    return orderBy(
      partnerPopulations.value.map((partnerPopulation) => {
        const sharingInformation = getSharingInformation(partnerPopulation);
        const dataRequestInformation =
          getDataRequestInformation(partnerPopulation);
        const allIncomingDataShares = dataSharesStore.getAllIncomingDataShares({
          partnerOrgId: partnerPopulation.organization_id,
          partnerPopulationId: partnerPopulation.id,
        });
        const requestDataIsVisible = isOwnSharingWithPartner.value;

        return {
          ...partnerPopulation,
          requestDataIsVisible,
          dataRequestInformation,
          populationToPopulationSettings: allIncomingDataShares
            .filter((share) => share.is_override)
            .map(transformPopulationToPopulationSetting),
          dataShare: {
            visibility: sharingInformation.visibility,
          },
          visibilityText:
            VISIBILITY_OPTIONS_TEXT[sharingInformation.visibility],
          sourceFields: sharingInformation.sharedFields,
        };
      }),
      [(pop) => pop.name.toLowerCase()],
      ['asc'],
    );
  });

  return {
    partnerPopulationsWithMetadata,
    partnerPopulations,
    partnerOrg,
  };
}
