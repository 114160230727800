<template>
  <div class="c-standard-populations">
    <div v-if="showTitle">
      <h3 id="subtitle" class="subtitle"> Standard Populations </h3>
      <p class="subtitle-description">
        These are core segments we recommend for everyone
      </p>
    </div>
    <PopulationCard
      v-for="(population, i) in allPopulations"
      :key="`standard_pop__${i}`"
      :population="population"
      :record-number="getRecordNumber(population)"
      :at-or-above-cap="atOrAboveCap"
      :sharing-settings="!!partner"
      :standard-populations="standardPopulations"
      :index="i * -1"
      :is-standard="true"
      :partner
      class="population-card"
      @sharing-clicked="emit('edit-sharing', population.id)"
    />
  </div>
  <SharingSettingsModal
    v-if="popForSharing"
    :partner-id="partner.id"
    :population="popForSharing"
    :visible="!!popForSharing"
    @hide-sharing-modal="emit('sharing-hidden')"
  />
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import SharingSettingsModal from '@/components/partners/DataSharingSettings/SharingSettingsModal.vue';
import PopulationCard from '@/components/populations/PopulationCard.vue';

import usePopulations from '@/composables/usePopulations';

const props = defineProps({
  showTitle: {
    type: Boolean,
    default: true,
  },
  partner: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['edit-sharing', 'sharing-hidden']);

const { allPopulations, standardPopulations, getRecordNumber, atOrAboveCap } =
  usePopulations(props.partner?.id);

const route = useRoute();
const populationId = computed(() => {
  if (!route.query.population_id || route.name !== 'partner_details') return;
  return parseInt(route.query.population_id);
});
const popForSharing = computed(() => {
  if (!populationId.value) return;
  return standardPopulations.value.find(
    (population) => population.id === populationId.value,
  );
});
</script>

<style lang="pcss" scoped>
.c-standard-populations {
  .subtitle {
    @apply text-neutral-text-strong text-lg font-bold;
  }

  .subtitle-description {
    @apply text-neutral-text mb-24;
  }

  .population-card {
    @apply mb-16;
  }
}
</style>
