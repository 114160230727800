<template>
  <BittsLoading :is-loading="pageLoading">
    <div class="c-login my-48 sm:my-0">
      <div v-if="ssoConnection" class="c-login__sso">
        <BittsAvatar
          v-if="orgClearbitDomain"
          :org="orgForAvatar"
          shape="square"
          :show-initials="true"
          class="mb-8"
        />
        <h1 class="c-login__sso__title"> Log in to Crossbeam </h1>
        <BittsButton
          class="mt-12 w-full"
          type="primary"
          text="Log In with SAML SSO"
          :loading="loading"
          @click="ssoLogin"
        />
        <BittsLink
          url="login"
          text="Log in to Crossbeam another way"
          class="mt-8"
        />
      </div>
      <div v-else class="c-login__user-pass">
        <h1 class="c-login__user-pass__title"> Log in to Crossbeam </h1>
        <BittsButton
          class="mb-8 mt-24 w-full"
          type="neutral"
          variant="fill"
          text="Log in with Google"
          :loading="googleLoading"
          @click="loginGoogle"
        >
          <template #icon>
            <img
              v-if="!googleLoading"
              src="@/assets/google-icon.png"
              class="h-12 w-12 mr-12"
            />
          </template>
        </BittsButton>
        <BittsDivider text="or" class="text-base" />
        <form class="c-login__user-pass" @submit.prevent="login">
          <BittsInput
            v-model="email"
            form-label="Email Address"
            placeholder="Email Address"
            data-testid="email-input"
            name="user_email"
            class="mb-12"
            :status="v$.email.$errors.length ? 'danger' : 'default'"
            :danger-text="v$.email.$errors?.at(-1)?.$message || ''"
            @enter-pressed="login"
          />
          <BittsInput
            v-model="password"
            type="password"
            form-label="Password"
            placeholder="Password"
            data-testid="password-input"
            name="user_password"
            :status="v$.password.$errors.length ? 'danger' : 'default'"
            danger-text="Please enter your password"
            @enter-pressed="login"
          >
            <template #label>
              <div class="mb-4 flex justify-between w-full">
                <label class="text-neutral-text-strong font-bold text-sm">
                  Password
                </label>
                <BittsLink
                  size="x-small"
                  class="text-sm"
                  text="Forgot?"
                  @click="handleForgotPassword"
                />
              </div>
            </template>
          </BittsInput>
          <BittsAlert
            v-if="ssoErrorMessage"
            data-testid="missing-sso-email"
            :message="ssoErrorMessage"
            class="w-full my-12"
            color="error"
          >
            <template #body>
              <p>
                Email attribute missing, have your IT admin review your
                configuration and check out this
                <a
                  class="font-bold"
                  href="https://help.crossbeam.com/en/articles/4477745-setting-up-saml-sso-in-crossbeam"
                  >help article</a
                >.
              </p>
            </template>
          </BittsAlert>
          <BittsAlert
            v-else-if="errorMessage || emailAlreadyExists"
            data-testid="raw-error-message"
            :message="errorMessage || emailAlreadyExists"
            class="w-full my-12"
            color="error"
          />
          <BittsAlert
            v-else-if="message"
            :message="message"
            class="w-full my-12"
            color="success"
          />
          <BittsButton
            data-testid="login-button"
            text="Log In"
            class="w-full mt-24"
            :loading="loading"
            @click="login"
          />
          <div class="text-center">
            <span class="text-neutral-text-weak"
              >Don't have a Crossbeam account?
              <BittsLink class="mt-12" url="register-public" text="Sign up"
            /></span>
          </div>
          <div class="text-center">
            <span class="text-neutral-text-weak">
              Are you an existing Reveal user?
              <BittsLink
                url="https://app.reveal.co/login"
                text="Log in here"
                open-in-new-tab
            /></span>
          </div>
        </form>
      </div>
    </div>
  </BittsLoading>
</template>

<script>
import {
  BittsAlert,
  BittsAvatar,
  BittsButton,
  BittsDivider,
  BittsInput,
  BittsLink,
  BittsLoading,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { useVuelidate } from '@vuelidate/core';
import { email as emailVal, helpers, required } from '@vuelidate/validators';
import axios from 'axios';

import useAuth from '@/composables/useAuth';
import { ls } from '@/local_storage';
import urls from '@/urls';
import { getUrlParams } from '@/utils';

export default {
  name: 'Login',
  components: {
    BittsAvatar,
    BittsInput,
    BittsDivider,
    BittsButton,
    BittsAlert,
    BittsLink,
    BittsLoading,
  },
  emits: ['login-rejected'],

  setup() {
    useHead({
      title: 'Login - Crossbeam',
    });
    const { isLoggedIn } = useAuth();

    return { isLoggedIn };
  },

  data() {
    return {
      v$: useVuelidate(),
      loading: false,
      googleLoading: false,
      pageLoading: false,
      errorMessage: this.$route.query.errorDescription,
      ssoConnection: null,
      email: '',
      password: '',
      orgClearbitDomain: null,
      stashedSsoConn: null,
      stashedOrgClearbitDomain: null,
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('Please enter your email', required),
        email: helpers.withMessage('Please enter a valid email', emailVal),
      },
      password: {
        required: helpers.withMessage('Please choose a password', required),
      },
    };
  },
  computed: {
    missingSsoEmailAttribute() {
      return this.$route.query.errorDescription === 'Email not provided by IDP';
    },
    orgForAvatar() {
      return { domain: this.orgClearbitDomain };
    },
    ssoErrorMessage() {
      return this.missingSsoEmailAttribute
        ? 'SSO may not be set up properly'
        : null;
    },
    attemptedAuth() {
      return this.$route.query.attempted_auth;
    },
    emailAlreadyExists() {
      if (!this.$route.query.email_already_exists) {
        return null;
      }

      const signInOption =
        this.attemptedAuth === 'auth0' ? 'Google' : 'an Email and Password';
      return `Please try signing in using ${signInOption}.`;
    },
    ssoClearbitDomain() {
      return this.orgClearbitDomain;
    },
    message() {
      return this.$route.query.message;
    },
  },
  watch: {
    $route(to) {
      if (to.query.sso) {
        this.ssoConnection = this.stashedSsoConn;
        this.orgClearbitDomain = this.stashedOrgClearbitDomain;
        this.stashedSsoConnection = null;
        this.stashedOrgClearbitDomain = null;
      } else {
        this.stashedSsoConn = this.ssoConnection;
        this.stashedOrgClearbitDomain = this.orgClearbitDomain;
        this.ssoConnection = null;
        this.orgClearbitDomain = null;
      }
      this.$forceUpdate();
    },
  },
  async created() {
    this.pageLoading = true;
    const ssoConfigId = getUrlParams('sso')[0];
    if (ssoConfigId) {
      const response = await axios.get(urls.sso.auth_connection(ssoConfigId));
      this.ssoConnection = response.data.auth0_connection_name;
      this.orgClearbitDomain = response.data.clearbit_domain;
      if (getUrlParams('isLoginPopup')[0] === '1') {
        await this.ssoLogin();
        return;
      }
    }
    this.pageLoading = false;
  },
  methods: {
    async login() {
      try {
        if (this.email) this.email = this.email.trim();
        this.loading = true;
        this.errorMessage = null;
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.loading = false;
          return;
        }
        ls.nextUrl.set(this.$route.query.next);
        ls.newSession.set(true);
        await this.$auth.basicLogin(this.email, this.password);
      } catch (error) {
        this.errorMessage = error.description;
      } finally {
        this.loading = false;
      }
    },
    ssoLogin() {
      try {
        this.loading = true;
        ls.nextUrl.set(this.$route.query.next);
        ls.newSession.set(true);
        this.$auth.ssoLogin(this.ssoConnection);
      } catch (error) {
        this.errorMessage = error.description;
      } finally {
        this.loading = false;
      }
    },
    loginGoogle() {
      if (this.rejectLogin) {
        this.$emit('login-rejected');
        return;
      }
      this.googleLoading = true;
      ls.nextUrl.set(this.$route.query.next);
      ls.newSession.set(true);
      this.$auth.googleLogin();
    },
    handleForgotPassword() {
      this.$router.push({ name: 'forgot-password' });
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-login {
  @apply flex justify-center items-center px-16;

  .c-login__sso,
  .c-login__user-pass {
    @apply flex flex-col gap-4 items-center justify-center w-full lg:w-[400px];
  }

  .c-login__user-pass__title,
  .c-login__sso__title {
    font-weight: 600;
    @apply text-xl text-brand-navy text-center;
  }

  .email-validation,
  .password-validation {
    @apply w-full my-4;
  }
}
</style>

<style lang="pcss">
.c-login__forgot-password .c-bitts-btn--link a {
  @apply text-neutral-400;
}

.c-login {
  .c-bitts-divider {
    width: 100%;
  }
}
</style>
