<template>
  <router-link
    class="reports-table-cell__wrapper pl-0 pr-36"
    :to="params.value.reportLink"
  >
    <ReportOverflowMenu
      v-if="params.value.hasWritePermissions"
      :errors="params.value.report.status?.errors || []"
      :hide-export="params.value.hideExport"
      :is-on-reports-page="params.value.isOnReportsPage"
      :report="params.value.report"
      :report-type="params.value.reportType"
      :partner-org-ids="partnerOrgIds"
    />
  </router-link>
</template>

<script>
import ReportOverflowMenu from './ReportOverflowMenu.vue';

export default {
  name: 'ReportOverflowMenuCell',
  components: { ReportOverflowMenu },
  computed: {
    partnerOrgIds() {
      if (!this.params.value.partnersInReport) return [];
      return this.params.value.partnersInReport.map((partner) => partner.id);
    },
  },
};
</script>
