<template>
  <BittsLoading :is-loading="loading || !ready">
    <BittsLayout :is-page="true">
      <template #content>
        <div class="folders__header-bar">
          <div class="folders__header-bar__title">
            {{ folderName }}
          </div>
          <div class="flex items-center gap-8">
            <BittsInput
              v-model="reportSearch"
              placeholder="Search for a report"
              type="search"
              name="report-search"
              size="small"
            />
            <MoveReportsDropdown
              v-if="reportsSelected && hasWriteReportPermissions"
              :move-to-unfiled="true"
              :checked-reports="checkedReports"
              @reports-moved="reportsMoved"
            />
            <div v-else-if="hasWriteReportPermissions" class="flex gap-8">
              <BittsButton
                type="danger"
                variant="outline"
                text="Delete"
                size="small"
                @click="showDeleteModal = true"
              />
              <BittsButton
                type="neutral"
                variant="outline"
                text="Edit"
                size="small"
                @click="showEditModal = true"
              />
              <BittsButton
                text="Create Report"
                size="small"
                @click="showConfigureModal = true"
              />
            </div>
          </div>
        </div>
        <BittsEmptyState
          v-if="isFolderEmpty"
          title="There are no reports in this folder."
          svg-name="emptyStateReports"
          class="pt-95 mt-24"
        >
          <template #subtitle>
            <div class="folders__empty-state">
              Head to
              <router-link
                :to="{ name: BASE_ROUTES.REPORTS }"
                class="folders__empty-state__router-link"
              >
                Reports Home
              </router-link>
              to move existing reports here, or create a report directly in this
              folder. Need more help? View our
              <a
                href="https://help.crossbeam.com/en/articles/6009925-report-folders"
                class="text-brand-blue cursor-pointer"
                target="_blank"
              >
                help documentation.
              </a>
            </div>
            <BittsButton
              v-if="hasWriteReportPermissions"
              class="mt-16"
              text="Create Report"
              @click="showConfigureModal = true"
            />
          </template>
        </BittsEmptyState>
        <div v-else>
          <ReportsTable
            :is-on-reports-page="false"
            :hide-export="hideExport"
            :report-search="reportSearch"
            :reports="reportsInFolder"
            class="mt-8"
            @report-checked="onReportChecked"
          />
        </div>
      </template>
    </BittsLayout>
  </BittsLoading>
  <BittsModalTwo
    v-model="showDeleteModal"
    title="Delete Folder?"
    variant="confirm"
    primary-button-text="Delete"
    primary-button-type="danger"
    :action-loading="modalSaving.delete"
    @action="confirmDeleteFolder"
  />
  <BittsModalTwo
    v-model="showEditModal"
    title="Edit Folder"
    :action-loading="modalSaving.edit"
    @action="confirmEditFolder"
    @closed="cancelEditFolder"
  >
    <BittsInput
      v-model="newFolderName"
      name="folder-name"
      :status="isFolderNameInvalid ? 'danger' : 'default'"
      placeholder="New Folder Name"
      danger-text="Folder must have unique name."
    />
  </BittsModalTwo>
  <ConfigureReportModal
    v-if="showConfigureModal"
    :folder-id="folderUuid"
    :show-modal="showConfigureModal"
    @modal-closed="showConfigureModal = false"
  />

  <router-view-wrapper />
</template>

<script lang="ts" setup>
import {
  BittsButton,
  BittsEmptyState,
  BittsInput,
  BittsLayout,
  BittsLoading,
  BittsModalTwo,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import {
  computed,
  onMounted,
  provide,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ConfigureReportModal from '@/components/reports/ConfigureReportModal.vue';
import MoveReportsDropdown from '@/components/reports/MoveReportsDropdown.vue';
import ReportsTable from '@/components/reports/ReportsTable.vue';

import useAuth from '@/composables/useAuth';
import { REPORT_STATE_KEY } from '@/constants/reports';
import { BASE_ROUTES } from '@/constants/routes';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useFlashesStore,
  useReportsStore,
  useTeamStore,
} from '@/stores';
import { Report } from '@/types/reports';
import urls from '@/urls';

useHead({ titleTemplate: 'Crossbeam - Report Folders' });

const billingStore = useBillingStore();
const reportsStore = useReportsStore();
const flashesStore = useFlashesStore();
const teamStore = useTeamStore();

const { ready } = storeToRefs(teamStore);
const { isEnterpriseTier } = storeToRefs(billingStore);
const { folders, reports } = storeToRefs(reportsStore);

const route = useRoute();
const router = useRouter();

const { hasScope } = useAuth();
provide(REPORT_STATE_KEY, toRefs(reactive({ report: null })));

const showDeleteModal = ref(false);
const showEditModal = ref(false);
const showConfigureModal = ref(false);
const newFolderName = ref('');
const loading = ref(false);
const checkedReports = ref<Report['id'][]>([]);
const reportSearch = ref('');

const modalSaving = ref({
  delete: false,
  edit: false,
});

const isFolderNameInvalid = computed(() => {
  if (!newFolderName.value) return false;
  return !!folders.value.find((folder) => folder.name === newFolderName.value);
});

const hideExport = computed(() => !isEnterpriseTier.value);
const folder = computed(() =>
  folders.value.find((folder) => folder.id === route.params.folder_id),
);
const folderUuid = computed(() => folder.value?.id);
const hasWriteReportPermissions = computed(() => hasScope('write:reports'));
const reportsInFolder = computed(() =>
  reports.value.filter((report) => report.folder_uuid === folderUuid.value),
);
const folderName = computed(() => folder.value?.name);
const isFolderEmpty = computed(() => reportsInFolder.value.length === 0);
const reportsSelected = computed(() => checkedReports.value.length > 0);

watch(reports, () => {
  checkedReports.value = [];
});

onMounted(async () => {
  loading.value = true;
  await reportsStore.refreshReportsStore();
  loading.value = false;
});

function reportsMoved() {
  checkedReports.value = [];
}

async function confirmDeleteFolder() {
  try {
    modalSaving.value.delete = true;
    await axios.delete(urls.reports.folders.delete(folderUuid.value));
    flashesStore.addSuccessFlash({
      message: 'Report folder deleted',
    });
    await router.push({ name: BASE_ROUTES.REPORTS });
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Something went wrong',
      description: 'This folder could not be deleted',
    });
  }
}
async function confirmEditFolder() {
  try {
    modalSaving.value.edit = true;
    await axios.patch(urls.reports.folders.all, {
      id: folderUuid.value,
      name: newFolderName.value,
    });
    newFolderName.value = '';
    await reportsStore.refreshReportsStore();
  } catch (_err) {
    flashesStore.addErrorFlash({
      message: 'Something went wrong',
      description: 'The folder could not be renamed',
    });
  } finally {
    showEditModal.value = false;
    modalSaving.value.edit = false;
  }
}

function cancelEditFolder() {
  newFolderName.value = '';
}
function onReportChecked(reports: Report['id'][]) {
  checkedReports.value = reports;
}
</script>

<style lang="pcss" scoped>
.folders__empty-state {
  max-width: 480px;
  @apply text-neutral-500 text-center;
}

.folders__empty-state__router-link {
  @apply text-brand-blue;
}

.folders__header-bar {
  @apply flex justify-between mt-24 items-center;
}

.folders__header-bar__title {
  @apply text-lg text-neutral-900;
}
</style>
