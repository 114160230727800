<template>
  <div class="flex gap-8">
    <PartnerIcon size="small" :partner-uuid="partnerUuid" />
    <p class="text-neutral-text-strong font-bold">
      {{ partner?.name }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';

import PartnerIcon from '@/components/partners/PartnerIcon.vue';

import { usePartnersStore } from '@/stores';

const props = withDefaults(
  defineProps<{
    params: {
      value: string;
    };
  }>(),
  {},
);

const partnersStore = usePartnersStore();

const partnerUuid = ref('');
onBeforeMount(() => {
  partnerUuid.value = props.params.value;
});

const partner = computed(() =>
  partnersStore.getPartnerOrgByUuid(partnerUuid.value),
);
</script>
