import { ref } from 'vue';

export default function useTableFadeIn() {
  const columnsReady = ref(false);

  const onColumnsCompressed = () => {
    columnsReady.value = true;
  };

  return { columnsReady, onColumnsCompressed };
}
