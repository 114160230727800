<template>
  <BittsEmptyState
    :title
    :font-awesome-icon="pngName ? null : ['fak', 'upgrade']"
    :font-awesome-icon-class="pngName ? '' : 'text-upsell-accent'"
    :font-awesome-icon-style="{
      height: '40px',
      width: '40px',
      color: 'currentColor',
    }"
    :png-name="pngName"
    png-width="424px"
    class="upsell-overlay"
  >
    <template #subtitle>
      <div class="text-neutral-text">
        {{ text }}
      </div>
      <BittsButton
        :left-icon="['fak', 'upgrade']"
        type="upsell"
        size="small"
        text="Upgrade"
        class="mt-16"
        @click="
          handleBillingInteraction(billingInteraction, null, false, routeTo)
        "
      />
    </template>
  </BittsEmptyState>
</template>

<script setup lang="ts">
import { BittsButton, BittsEmptyState } from '@crossbeam/bitts';

import useBilling from '@/composables/useBilling';
import { BillingInteraction } from '@/types/billing';

interface Props {
  pngName?: string;
  text?: string;
  title?: string;
  routeTo?: { name: string } | null;
  billingInteraction: BillingInteraction;
}

withDefaults(defineProps<Props>(), {
  pngName: '',
  text: '',
  title: '',
  routeTo: null,
});

const { handleBillingInteraction } = useBilling();
</script>

<style scoped lang="pcss">
.upsell-overlay {
  @apply w-full h-full absolute z-30;
  background: linear-gradient(
    180deg,
    theme(colors.white / 0) 0%,
    theme(colors.neutral.background) 55.08%
  );
}
</style>
<style lang="pcss">
.upsell-overlay {
  .c-bitts-empty-state-large {
    @apply w-full;
  }
  &.c-bitts-empty-state-large-border {
    @apply border-none rounded-none;
  }
}
</style>
