<template>
  <BittsContainer :drop-shadow="true">
    <div class="partner-and-overlaps-card">
      <div class="partner-and-overlaps-card__info">
        <div
          v-for="item in infoTypes"
          :key="item.type"
          :class="{
            'partner-and-overlaps-card__partners': item.type === 'Partners',
          }"
          class="w-full flex items-center"
        >
          <BittsAvatar
            shape="square"
            size="medium"
            :avatar-classes="item.avatarBackgroundClass"
            :is-icon="true"
            class="mr-12"
          >
            <template #icon>
              <FontAwesomeIcon
                :icon="item.avatarIcon"
                :style="{
                  height: '18px',
                  width: '18px',
                  color: 'currentColor',
                }"
                :class="item.avatarColorClass"
              />
            </template>
          </BittsAvatar>
          <div class="partner-and-overlaps-card__number-and-type">
            <div class="font-bold text-m">
              {{ item.number || '--' }}
            </div>
            <div class="text-neutral-text-weak text-sm">
              {{ item.type }}
            </div>
          </div>
        </div>
      </div>
      <BittsAlert
        color="info"
        message="Overlaps only calculate for records where partners are sharing data"
      />
    </div>
  </BittsContainer>
</template>

<script>
import { BittsAlert, BittsAvatar, BittsContainer } from '@crossbeam/bitts';

export default {
  name: 'PartnerAndOverlapsCard',
  components: {
    BittsAlert,
    BittsAvatar,
    BittsContainer,
  },
  props: {
    overlapsNumber: {
      type: String,
      default: '--',
    },
    partnerNumber: {
      type: String,
      default: '--',
    },
  },
  computed: {
    infoTypes() {
      return [
        {
          avatarBackgroundClass: 'bg-beta-background-weak',
          avatarColorClass: 'text-beta-accent',
          avatarIcon: ['fak', 'team'],
          number: this.partnerNumber,
          type: 'Partners',
        },
        {
          avatarBackgroundClass: 'bg-info-background-weak',
          avatarColorClass: 'text-info-accent',
          avatarIcon: ['fak', 'mapping'],
          number: this.overlapsNumber,
          type: 'Overlaps',
        },
      ];
    },
  },
};
</script>

<style scoped lang="pcss">
.partner-and-overlaps-card {
  @apply flex justify-between;
}
.partner-and-overlaps-card__info {
  @apply w-1/3 flex items-center;
}
.partner-and-overlaps-card__number-and-type {
  @apply flex flex-col;
}
.partner-and-overlaps-card__partners {
  @apply border-r border-solid border-neutral-border mr-16;
}
</style>
