<template>
  <BittsModalTwo
    :loading="!ready || loading"
    title="Delete Data Source"
    description="Are you sure you want to delete your connection? Removing this data source will permanently delete all associated populations and sharing rules. This cannot be undone."
    primary-button-text="Yes, Continue"
    primary-button-type="danger"
    secondary-button-text="Discard"
    :width="400"
    save-text="Yes, Continue"
    cancel-text="No, Cancel"
    confirm-type="danger"
    @saved="deleteFeed"
    @closed="closeModal"
  />
</template>

<script setup>
import { BittsModalTwo } from '@crossbeam/bitts';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { captureException } from '@/errors';
import {
  allReady,
  useDataSharesStore,
  useFeedsStore,
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';

const props = defineProps({
  feedId: {
    type: Number,
    required: true,
  },
  cancelDestination: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const feedsStore = useFeedsStore();
const sourcesStore = useSourcesStore();

const loading = ref(false);
const ready = allReady(sourcesStore, feedsStore);

async function deleteFeed() {
  const flashesStore = useFlashesStore();
  const populationsStore = usePopulationsStore();
  const dataSharesStore = useDataSharesStore();

  loading.value = true;
  try {
    await feedsStore.removeFeed(props.feedId);
    await Promise.all([
      dataSharesStore.refreshDataSharesStore(),
      populationsStore.refreshPopulationsStore(),
      feedsStore.refreshFeedsStore(),
    ]);
    flashesStore.addSuccessFlash({ message: 'Data deletion initiated' });
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'There was an issue with your delete',
      description: 'If the error persists contact support@crossbeam.com',
    });
  } finally {
    closeModal();
  }
}

async function closeModal() {
  loading.value = false;
  await router.push({ path: props.cancelDestination });
}
</script>
