<template>
  <div class="downgrade-copy">
    <FontAwesomeIcon
      :icon="['fad', 'arrow-down-from-arc']"
      class="w-80 h-80 text-white mb-40"
    />
    <h2>Are you sure you want to downgrade?</h2>
    <p
      >You’ll lose access to some of our most popular features, have new limits
      on your account, and will only be able to keep 3 users. Also, we’d miss
      you a lot.</p
    >
  </div>
</template>
<style lang="pcss" scoped>
.downgrade-copy {
  @apply h-full px-40 py-32 lg:px-80 lg:pt-120;
  background: radial-gradient(
      41.02% 57.15% at 23.19% 83.74%,
      theme(colors.white / 0.3) 0%,
      theme(colors.white / 0) 100%
    ),
    radial-gradient(
      71.71% 50.59% at 87.36% -3.52%,
      theme(colors.white / 0.5) 0%,
      theme(colors.white / 0) 100%
    ),
    theme(colors.red.700);

  h2 {
    @apply text-xxl text-white font-bold;
  }

  p {
    @apply text-m text-white opacity-75 mt-8;
  }
}
</style>
