<template>
  <div class="c-leaf-info">
    <router-link
      v-if="sourceField.isPotInfAmountField"
      to="/organization/settings"
      class="text-neutral-text-weak"
    >
      <FontAwesomeIcon
        :icon="['fak', 'revenue']"
        :style="{ height: '12px', width: '12px' }"
        class="text-primary-accent"
      />
      Being used to calculate
      <span class="c-leaf-info__report__name">Potential Revenue</span>
      <FontAwesomeIcon :icon="['fak', 'open-link']" class="text-info-accent" />
    </router-link>
    <router-link
      v-for="pop in sourceField.blockedPops"
      :key="pop.name"
      :to="`/populations/${pop.id}/edit`"
      class="c-leaf-info__population"
    >
      <FontAwesomeIcon
        :icon="['fak', 'population']"
        :style="{ height: '12px', width: '12px' }"
        class="text-primary-accent"
      />
      Used in the Population
      <span class="c-leaf-info__population__name">{{ pop.name }}</span>
      <FontAwesomeIcon :icon="['fak', 'open-link']" class="text-info-accent" />
    </router-link>
    <router-link
      v-for="(filter, i) in sourceField.reportFilters"
      :key="`${filter.sourceId}_${i}`"
      :to="`/reports/${filter.report_id}`"
      class="c-leaf-info__report"
    >
      <FontAwesomeIcon
        :icon="['fak', 'report']"
        :style="{ height: '12px', width: '12px' }"
        class="text-primary-accent"
      />
      Used in the Report
      <span class="c-leaf-info__report__name">{{
        getReportName({ filter })
      }}</span>
      <FontAwesomeIcon :icon="['fak', 'open-link']" class="text-info-accent" />
    </router-link>
    <ul
      v-if="sourceField.isReferenceField || sourceField.isLookupField"
      :class="{ 'ml-24': sourceField.isLookupField }"
      class="c-leaf-info__links"
    >
      <li v-for="(linkedField, i) in linkedFields" :key="i">
        <FontAwesomeIcon
          :icon="['fak', 'link']"
          class="text-primary-accent"
          :style="{ height: '14px', width: '14px' }"
        />
        Linked to the
        <span class="font-bold">{{ linkedField.nickname }}</span> field in the
        {{ linkedField.object }} object
      </li>
    </ul>
  </div>
</template>
<script setup>
import { computed } from 'vue';

import { useReportsStore } from '@/stores';

const props = defineProps({
  sourceField: {
    type: Object,
    required: true,
  },
  sourceFieldMap: {
    type: Object,
    required: true,
  },
  lookupFieldIds: {
    type: Array,
    default: () => [],
  },
});

const reportsStore = useReportsStore();

function getReportName({ filter }) {
  return reportsStore.getByReportId(filter.report_id).name;
}

const lookupFields = computed(() => {
  if (!props.sourceField.isReferenceField) return null;
  return props.lookupFieldIds.reduce((acc, id) => {
    const lookupField = props.sourceFieldMap[id];
    if (lookupField.copies_source_field_id === props.sourceField.id) {
      acc.push(lookupField);
    }
    return acc;
  }, []);
});

const linkedFields = computed(() => {
  if (props.sourceField.isLookupField)
    return [props.sourceFieldMap[props.sourceField.copies_source_field_id]];
  return lookupFields.value;
});
</script>
<style lang="pcss">
.c-leaf-info {
  @apply flex flex-col text-sm;
}

.c-leaf-info__report__name,
.c-leaf-info__population__name {
  @apply font-bold text-secondary-text mr-4;

  &:hover {
    @apply underline;
  }
}

.c-leaf-info__links {
  @apply items-start text-neutral-text-weak flex flex-col gap-4;
}
</style>
