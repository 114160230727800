<template>
  <BittsModalTwo
    variant="info"
    primary-button-text="Continue to Crossbeam"
    :width="400"
    @closed="emit('close-modal')"
    @action="emit('close-modal')"
  >
    <template #image>
      <BittsSvg
        svg="upgrade"
        class="w-120 h-120 p-30 text-info-accent bg-white bg-top-gradient from-teal-400/20 to-teal-400/0 rounded-[360px]"
      />
    </template>
    <template #header>
      <div class="flex flex-col text-center gap-8">
        <p class="text-neutral-text-strong font-bold text-xl">
          We'll be in touch shortly
        </p>
        <p class="text-neutral-text">
          You're 1 step closer to a more powerful Crossbeam!
        </p>
      </div>
    </template>
  </BittsModalTwo>
</template>
<script setup lang="ts">
import { BittsModalTwo } from '@crossbeam/bitts';
import BittsSvg from '@crossbeam/bitts/src/BittsSvg/BittsSvg.vue';

const emit = defineEmits<(e: 'close-modal') => void>();
</script>
