import { computed } from 'vue';
import type { LocationQuery } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';

export function useRouterWrapper() {
  const router = useRouter();
  const route = useRoute();

  type RouteResult = {
    name?: string;
    query?: LocationQuery;
    params?: Record<string, string | number>;
  };
  const buildExistingRoute = ({
    query = {},
    name,
    params = {},
  }: RouteResult) => ({
    name: name || undefined,
    params: Object.keys(params).length ? params : undefined,
    query: { ...route.query, ...query },
  });

  const pushWithExistingQuery = async (
    { query = {}, name = '', params = {} }: RouteResult,
    ...queryParamsToRemove: (string | number)[]
  ) => {
    const result = buildExistingRoute({ name, query, params });

    const resultQuery = result.query ?? {};
    const filteredQuery = Object.keys(resultQuery).reduce((acc, key) => {
      if (!queryParamsToRemove.includes(key) && resultQuery[key]) {
        acc[key] = resultQuery[key];
      }
      return acc;
    }, {} as LocationQuery);

    result.query = filteredQuery || {};

    await router.push(result);
  };

  const baseRoute = computed(() => route.matched?.at(1)?.name);

  return {
    pushWithExistingQuery,
    baseRoute,
    route,
  };
}
