<template>
  <BittsLayout :is-page="true" class="overlaps-page">
    <PartnerAndOverlapsCard
      :overlaps-number="overlapNumber"
      :partner-number="partnerNumber"
    />
    <OverlapsTable v-if="partnerOrgs && partnerOrgs.length" class="mt-24" />
    <BittsEmptyState
      v-else
      svg-name="emptyStatePartnersBitts"
      title="You don’t have any partners yet."
      class="overlaps-page__empty-state"
    >
      <template #subtitle>
        <div class="flex flex-col">
          <div class="overlaps-page__empty-state-description">
            Once you partner with other companies on Crossbeam, you will begin
            to see overlaps here.
          </div>
          <button
            class="overlaps-page__empty-state-cta"
            @click="goToPartnersPage"
            type="button"
          >
            View Partners Page
            <FontAwesomeIcon
              :icon="['fak', 'open-link']"
              :style="{ height: '8px', width: '8px', color: 'currentColor' }"
              class="text-white bg-info-accent ml-4 rounded p-2"
            />
          </button>
        </div>
      </template>
    </BittsEmptyState>
  </BittsLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BittsEmptyState, BittsLayout } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import Humanize from 'humanize-plus';
import { mapState } from 'pinia';

import OverlapsTable from '@/components/billing/OverlapsTable.vue';
import PartnerAndOverlapsCard from '@/components/billing/PartnerAndOverlapsCard.vue';

import { usePartnersStore } from '@/stores';

export default {
  name: 'Overlaps',
  components: {
    BittsEmptyState,
    OverlapsTable,
    PartnerAndOverlapsCard,
    BittsLayout,
  },

  setup() {
    useHead({
      title: 'Overlaps - Crossbeam',
    });
  },

  data() {
    return { loading: true };
  },
  computed: {
    ...mapState(usePartnersStore, ['overlapCounts', 'partnerOrgs']),
    overlapNumber() {
      return this.overlapCounts.overlap_usage
        ? Humanize.intComma(this.overlapCounts.overlap_usage.total)
        : '0';
    },
    partnerNumber() {
      return this.partnerOrgs
        ? Humanize.intComma(this.partnerOrgs.length)
        : '0';
    },
    rows() {
      return this.partnerOrgs.reduce((partners, partnerOrg) => {
        const partnerData = { org: partnerOrg };
        partners.push(partnerData);
        return partners;
      }, []);
    },
  },
  methods: {
    async goToPartnersPage() {
      await this.$router.push({ name: 'partners' });
    },
  },
};
</script>

<style scoped lang="pcss">
.overlaps-page__empty-state {
  @apply py-160 mt-24 flex items-center justify-center bg-neutral-background
    border border-solid border-neutral-border rounded-bts-lg;
}
.overlaps-page__empty-state-cta {
  @apply text-neutral-text-weak;
}
.overlaps-page__empty-state-description {
  @apply text-neutral-text;
}
.overlaps-page__information-card {
  @apply flex justify-between;
}
</style>
