<template>
  <div class="c-partnerships-settings">
    <BittsModalTwo
      :width="1400"
      sidebar-title="Settings"
      :title="title"
      variant="sidebar"
      :loading="loading"
      @closed="close"
      @action="handleAction"
      :disabled="saving || loading"
      :action-loading="saving"
    >
      <template #sidebar-header-prefix>
        <PartnerIcon :partner-uuid="partner?.uuid" class="mr-12" />
      </template>
      <template #sidebar-footer>
        <BittsButton
          :disabled="saving"
          type="danger"
          text="Delete partnership"
          variant="ghost"
          :left-icon="['fak', 'delete']"
          data-testid="c-settings-delete"
          @click="showDeleteModal"
        />
      </template>
      <template #sidebar-content>
        <SidebarLink
          v-for="(item, i) in sidebarItems"
          :item="item"
          :key="item.tab"
          default-tab="team-access"
          :class="{
            'mt-auto': i === sidebarItems.length - 1,
          }"
        />
      </template>
      <div class="c-partnerships-settings__content py-12">
        <component :is="TabComponent" class="min-h-[600px]" />
      </div>
    </BittsModalTwo>
    <router-view-wrapper />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsModalTwo } from '@crossbeam/bitts';

import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import TeamAccessTab from '@/components/partners/DataSharingSettings/SettingsModal/TeamAccessTab.vue';
import PartnerIcon from '@/components/partners/PartnerIcon.vue';
import SidebarLink, { SidebarItem } from '@/components/SidebarLink.vue';

import { usePartnersStore } from '@/stores';

export type PartnershipSettingsTabType = 'team-access';

const router = useRouter();
const route = useRoute();
const partnersStore = usePartnersStore();
const tab = computed(() => route.query.tab as PartnershipSettingsTabType);
const partnerId = computed(() => Number(route.params.partner_org_id as string));
const partner = computed(
  () => partnersStore.getPartnerOrgById(partnerId.value, false) || null,
);

const loading = ref(false);
const saving = ref(false);

const sidebarItems = computed<SidebarItem<PartnershipSettingsTabType>[]>(() => [
  {
    label: 'Team Access',
    icon: ['fad', 'user-group'],
    tab: 'team-access',
  },
]);

const TabComponent = computed(() => {
  switch (tab.value) {
    case 'team-access':
      return TeamAccessTab;
    default:
      return TeamAccessTab;
  }
});

const title = computed(() => {
  switch (tab.value) {
    case 'team-access':
      return 'Manage Access';
    default:
      return 'Manage Access';
  }
});

// The action will be different depending on which tab is focused. The actions
// should be written in composables that share the state and handlers across each
// tab and this main component.
async function handleAction() {
  switch (tab.value) {
    case 'team-access':
      await console.log('handleAction');
      break;
    default:
      await console.log('handleAction');
  }
}

async function close() {
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: partnerId.value },
  });
}

async function showDeleteModal() {
  await router.push({ name: 'delete-data-source' });
}
</script>

<style lang="pcss" scoped>
.c-partnerships-settings {
  @apply py-24 px-0;
}

.c-partnerships-settings__content {
  @apply flex flex-col;
}
</style>
