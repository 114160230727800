import {
  CO_SELLER,
  NO_ACCESS,
  PARTNER_MANAGER,
  VIEWER,
} from '@/constants/team';

export const CORE = 'core';
export const SALES = 'sales';

export const CORE_SEAT_OPTION = {
  value: CORE,
  label: 'Full Access',
  description: 'Access to all products in the Crossbeam platform',
};

export const SALES_SEAT_OPTION = {
  value: SALES,
  label: 'Sales',
  description: 'Access to Crossbeam for Sales',
};

export const V4_SEAT_OPTIONS = [CORE_SEAT_OPTION, SALES_SEAT_OPTION];

/* We plan to migrate the old sales edge roles into the new roles after the release
 * of pricing and packaging v4. This map shows the conversion between the old roles and their
 * new values */
export const OLD_ROLES_TO_NEW_MAP = {
  [PARTNER_MANAGER]: {
    label: 'Manager',
    value: 'manager',
  },
  [CO_SELLER]: {
    label: 'Standard',
    value: 'standard',
  },
  [VIEWER]: {
    label: 'Limited',
    value: 'limited',
  },
  [NO_ACCESS]: {
    label: 'No Access',
    value: null,
  },
};
