import { ref } from 'vue';

export default function ({ limit }) {
  const searchValue = ref('');
  const delayedSearchValue = ref('');

  let timeout = null;
  function search(newInput) {
    searchValue.value = newInput;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      delayedSearchValue.value = searchValue.value;
    }, limit);
  }

  return {
    searchValue,
    delayedSearchValue,
    search,
  };
}
