import axios from 'axios';
import { computed } from 'vue';

import { BIG_CSV_FILE_LIMIT_500MB } from '@/constants/feature_flags';
import { useFeatureFlagStore } from '@/stores';
import urls from '@/urls';

export default function useCSV() {
  const featureFlagStore = useFeatureFlagStore();
  const maxFileUploadLimit = computed(() => {
    return featureFlagStore.hasFeatureFlag(BIG_CSV_FILE_LIMIT_500MB) ? 500 : 15;
  });

  async function processFileMetadata(file, offlinePartnerUuid) {
    // take first 100kb of the uploaded file
    const fileSample = file.slice(0, 102400);

    const data = new FormData();
    data.append('file', fileSample);
    const fileOptionsUrl = offlinePartnerUuid
      ? `${urls.fileUploads.fileOptions}?offline_partner_org_uuid=${offlinePartnerUuid}`
      : urls.fileUploads.fileOptions;
    const response = await axios.post(fileOptionsUrl, data);

    const fileOptions = response.data;
    const fileHeaders = response.data.headers.map((header) => ({
      value: header,
      label: header,
    }));

    const fileName = file.name
      .split('.')
      .slice(0, -1)
      .join('.')
      .replace(/ /g, '_')
      .replace(/-/g, '_')
      .replace(/[^a-z0-9]/gim, '')
      .replace(/\s+/g, '');

    if (!fileHeaders || fileHeaders.length === 0) {
      throw new Error(
        'You need a header row and at least 1 row of data to upload',
      );
    }

    return { fileOptions, fileHeaders, fileName };
  }

  return {
    maxFileUploadLimit,
    processFileMetadata,
  };
}
