<template>
  <BittsLoading class="w-full" :is-loading="!ready">
    <BittsLayout :is-page="true">
      <template #content>
        <div class="seat-request-page">
          <PageTitle
            title="Seat Requests"
            subtitle="Team members that have requested access to Crossbeam"
          />
          <div class="page-content">
            <EnterpriseSeatCallout
              v-if="isEnterpriseTier && seatRequests.length > 0"
            />
            <div class="flex gap-24 mb-40">
              <FullAccessRequestCard />
              <SalesAccessRequestCard />
            </div>
            <SeatRequestTable v-if="seatRequests.length > 0" />
            <BittsEmptyState
              v-else
              data-testid="seat-requests-empty-state"
              class="flex-1"
              title="You've responded to all access requests"
              description="When someone else asks for a seat you'll see it here, but for now enjoy your day :)"
              :font-awesome-icon="['fak', 'check-circle']"
              font-awesome-icon-class="seat-requests-empty-state-icon"
              :font-awesome-icon-style="{ height: '40px', width: '40px' }"
            />
          </div>
          <router-view-wrapper />
        </div>
      </template>
    </BittsLayout>
  </BittsLoading>
</template>

<script setup>
import { BittsEmptyState, BittsLayout, BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, onMounted } from 'vue';

import EnterpriseSeatCallout from '@/components/billing/EnterpriseSeatCallout.vue';
import PageTitle from '@/components/PageTitle.vue';
import FullAccessRequestCard from '@/components/team/seat-requests/FullAccessRequestsCard.vue';
import SalesAccessRequestCard from '@/components/team/seat-requests/SalesAccessRequestsCard.vue';
import SeatRequestTable from '@/components/team/seat-requests/SeatRequestsTable.vue';

import { useBillingStore, useSeatRequestsStore } from '@/stores';

useHead({ title: 'Seat Requests - Crossbeam' });

const seatRequestsStore = useSeatRequestsStore();
const billingStore = useBillingStore();
const { isEnterpriseTier } = storeToRefs(billingStore);

const { seatRequests, ready } = storeToRefs(seatRequestsStore);
onMounted(() => {
  seatRequestsStore.startPolling();
});
onBeforeUnmount(() => {
  seatRequestsStore.stopPolling();
});
</script>

<style lang="pcss" scoped>
.seat-request-page {
  @apply flex-1 flex flex-col;
  .page-content {
    @apply flex flex-col flex-1;
  }
}
</style>
<style lang="pcss">
.seat-requests-empty-state-icon {
  @apply text-neutral-text-weak p-24 rounded-full;
  background: radial-gradient(
    136.96% 69.92% at 50.09% -12.42%,
    color-mix(in srgb, theme(colors.neutral.accent) 20%, transparent) 0%,
    #ffffff00 100%
  );
}
</style>
