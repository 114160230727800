<template>
  <div class="export-summary-export-panel">
    <BittsCallout
      v-if="showCallout"
      :title="NEW_RECORD_EXPORT_CALLOUTS.buildTitle(limitUsedPercent)"
      :subtitle="calloutSubtitle"
      action-text="Contact us"
      size="x-small"
      :type="exportLimitStatus === 'limit-reached' ? 'danger' : 'warning'"
      :action-button-type="
        exportLimitStatus === 'limit-reached' ? 'danger' : 'neutral'
      "
      class="mb-16"
    />
    <RecordExportSummary />
    <SummaryPanelRow
      v-for="{
        destination,
        title,
        totalCount,
        uniqueCount,
        to,
      } in destinationsWithCountsAndRoutes"
      :key="destination"
      :title
      :to
      :denominator="totalCount"
      :numerator="uniqueCount"
      :color="color"
      @clicked:destination="emit('clicked:destination')"
    >
      <template #row-avatar>
        <BittsAvatar
          v-if="!['report_exports', 'rest_api'].includes(destination)"
          size="x-small"
          :is-entity="true"
          :org="buildAvatar(destination)"
          class="mr-8"
          data-testid="bucket-avatar"
        />
        <FontAwesomeIcon
          v-else
          :icon="
            destination === 'rest_api'
              ? ['fak', 'integration']
              : ['far', 'file-export']
          "
          class="mr-8 text-16 text-neutral-accent"
        ></FontAwesomeIcon>
      </template>
    </SummaryPanelRow>
    <p v-if="destinationsWithCounts.length > 0">
      Unique records are counted when the record leaves Crossbeam for the first
      time
    </p>
  </div>
</template>

<script setup lang="ts">
import { BittsAvatar, BittsCallout } from '@crossbeam/bitts';
import { StrictExclude } from '@crossbeam/types';

import { computed } from 'vue';

import useRecordExportLimits, {
  Destination,
} from '@/composables/useRecordExportLimits';
import { NEW_RECORD_EXPORT_CALLOUTS } from '@/constants/record_exports';
import { TrayTag } from '@/types/integrations';

import RecordExportSummary from './RecordExportSummary.vue';
import SummaryPanelRow from './SummaryPanelRow.vue';
const emit = defineEmits<{
  'clicked:destination': [];
}>();

const { exportLimitStatus, limitUsedPercent, destinationsWithCounts } =
  useRecordExportLimits();

const destinationsWithCountsAndRoutes = computed(() =>
  destinationsWithCounts.value.map((destination) => ({
    ...destination,
    to: buildRoute(destination.destination),
  })),
);

const alertInfo = computed(() => {
  if (!exportLimitStatus.value || exportLimitStatus.value === 'over-75')
    return null;
  return NEW_RECORD_EXPORT_CALLOUTS[exportLimitStatus.value];
});

const showCallout = computed(() => !!alertInfo.value);
const calloutSubtitle = computed(() => alertInfo.value?.message || '');

const color = computed(() => {
  switch (exportLimitStatus.value) {
    case 'limit-reached':
      return 'danger';
    case 'over-90':
      return 'warning';
    default:
      return 'secondary';
  }
});

const mapBucketToAvatar: Record<
  StrictExclude<Destination, 'rest_api' | 'report_exports'>,
  string
> = {
  salesforce_custom_object: 'Salesforce',
  hubspot_custom_object: 'Hubspot',
  snowflake_custom_object: 'Snowflake',
  microsoft_dynamics_custom_object: 'MSDynamics',
  bigquery_custom_object: 'BigQuery',
  leandata: 'LeanData',
  partnerportal: 'PartnerPortal',
  clari_custom_object: 'Clari',
  marketo: 'AdobeMarketo',
  superglue: 'Superglue',
  clay: 'Clay',
};

const buildTrayRoute = (trayTag: TrayTag) => `/integrations/${trayTag}/manage`;

function buildRoute(destination: Destination) {
  const trayTagMap: Partial<Record<Destination, TrayTag>> = {
    salesforce_custom_object: 'xb-salesforce-overlaps-push',
    hubspot_custom_object: 'xb-hubspot',
    microsoft_dynamics_custom_object: 'xb-microsoft-dynamics',
    clari_custom_object: 'xb-clari',
    marketo: 'xb-marketo',
  };

  return trayTagMap[destination] ? buildTrayRoute(trayTagMap[destination]) : '';
}

const buildAvatar = (destination: Destination) => {
  return destination in mapBucketToAvatar
    ? {
        logo_url: new URL(
          `../../assets/pngs/partner-cloud/${mapBucketToAvatar[destination as keyof typeof mapBucketToAvatar]}.png`,
          import.meta.url,
        ).href,
      }
    : undefined;
};
</script>

<style scoped lang="pcss">
h3 {
  @apply text-neutral-text-strong font-bold text-base;
}

p {
  @apply text-neutral-text-weak text-sm;
}
</style>
