<template>
  <BittsModal
    :visible="true"
    :width="isDeleteModal ? 400 : 600"
    :save-text="!isDeleteModal ? 'Save Changes' : 'Delete'"
    :title="!isDeleteModal ? 'Edit Shared List' : 'Delete List'"
    :confirm-type="!isDeleteModal ? 'primary' : 'danger'"
    :disabled="saveButtonDisabled"
    :loading="!ready"
    :saving="isSaving"
    :show-divider="!isDeleteModal"
    :destroy-on-close="true"
    :delete-button="!isDeleteModal ? { text: 'Delete List' } : {}"
    :use-cancel-button-emitter="isDeleteModal"
    @cancelled="cancelButtonClicked"
    @closed="onEditListModalClosed"
    @deleted="onDeleteListButtonClicked"
    @saved="confirmButtonClicked"
  >
    <template #content>
      <div v-if="!isDeleteModal">
        <BittsInput
          v-model="newListName"
          :status="errors.name.error ? 'danger' : 'default'"
          :danger-text="errors.name.message"
          placeholder=""
          name="name-input"
          class="mb-12"
          data-testid="name-input"
          form-label="List Name"
        />
        <div class="c-edit-list-modal__description-section">
          <BittsTextArea
            v-model="newListDescription"
            class="some-class"
            data-testid="list-description-textarea"
            :status="errors.description.error ? 'danger' : 'default'"
            :danger-text="errors.description.message"
            :placeholder="
              listItem?.description
                ? ''
                : 'Add a description to help your partner know what to expect when collaborating on this List together'
            "
            form-label="List Description"
          />
        </div>
      </div>
      <p v-else class="text-neutral-text">
        Are you sure you want to delete this list? You and your collaborators
        will no longer be able to see this list, and this action can not be
        undone.
      </p>
    </template>
  </BittsModal>
</template>

<script setup lang="ts">
import { BittsInput, BittsModal, BittsTextArea } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';
import { Nullable } from '@crossbeam/types';

import { storeToRefs } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import { captureException } from '@/errors';
import { useCollaborateStore, useFlashesStore } from '@/stores';
import { SharedList, SharedListPatchPayload } from '@/types/shared_lists';

const { listItem = null } = defineProps<{
  listItem?: Nullable<SharedList>;
}>();

const emit = defineEmits<(e: 'closed' | 'saved') => void>();

const errors = reactive({
  name: {
    error: false,
    message: '',
  },
  description: {
    error: false,
    message: '',
  },
});

const router = useRouter();
const collaborateStore = useCollaborateStore();
const { lists, ready } = storeToRefs(collaborateStore);
const { iteratively } = useIteratively();

const { currentUser } = useAuth();

const isSaving = ref(false);
const isDeleteModal = ref(false);
const newListName = ref(listItem?.name);
const newListDescription = ref(listItem?.description || '');
const saveButtonDisabled = computed(
  () =>
    (!hasListInfoChanged.value ||
      isSaving.value ||
      errors.description.error ||
      errors.name.error) &&
    !isDeleteModal.value,
);

const flashesStore = useFlashesStore();

const hasListInfoChanged = computed(
  () => hasListNameChanged.value || hasListDescChanged.value,
);
const hasListNameChanged = computed(
  () => newListName.value && newListName.value !== listItem?.name,
);
const hasListDescChanged = computed(
  () => newListDescription.value !== listItem?.description,
);

function onDeleteListButtonClicked() {
  isDeleteModal.value = true;
}

function cancelButtonClicked() {
  isDeleteModal.value ? onCancelConfirmClicked() : onEditListModalClosed();
}

function onCancelConfirmClicked() {
  isDeleteModal.value = false;
}

function onEditListModalClosed() {
  emit('closed');
  isDeleteModal.value = false;
}

async function confirmButtonClicked() {
  isDeleteModal.value
    ? await onDeleteConfirmClicked()
    : await onSaveButtonClicked();
}

async function onSaveButtonClicked() {
  try {
    if (!listItem) return;
    isSaving.value = true;
    const path = { list_id: listItem.id };
    const body: SharedListPatchPayload = {};
    if (hasListNameChanged.value) body.name = newListName.value;
    if (hasListDescChanged.value) body.description = newListDescription.value;
    await crossbeamApi.PATCH(`/v0.1/lists/{list_id}`, {
      body,
      params: { path },
    });
    if (hasListNameChanged.value) {
      iteratively.userEditedList({
        event_site: EVENT_SITES.EDIT_LIST_MODAL,
        list_id: listItem.id,
        previous_value: listItem.name,
        new_value: newListName.value,
        action: 'Edited List Name',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
    if (hasListDescChanged.value) {
      iteratively.userEditedList({
        event_site: EVENT_SITES.EDIT_LIST_MODAL,
        list_id: listItem.id,
        previous_value: listItem.description,
        new_value: newListDescription.value,
        action: 'Edited List Description',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
    flashesStore.addSuccessFlash({
      message: 'Your List has successfully been updated',
    });
    emit('saved');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message =
      error.response?.data?.errors?.join(',') ||
      'Something went wrong, reach out to our support team for help';
    captureException(error);
    flashesStore.addErrorFlash({ message });
  } finally {
    isSaving.value = false;
  }
}

async function onDeleteConfirmClicked() {
  try {
    if (!listItem) return;
    isSaving.value = true;
    await router.push({ name: 'collaborate' });
    const path = { list_id: listItem.id };
    await crossbeamApi.DELETE('/v0.1/lists/{list_id}', {
      params: { path },
    });
    await collaborateStore.refreshCollaborateStore();
    flashesStore.addSuccessFlash({
      message: 'Your List has successfully been deleted',
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message =
      error.response?.data?.errors?.join(',') ||
      'Something went wrong, reach out to our support team for help';
    captureException(error);
    flashesStore.addErrorFlash({ message });
  } finally {
    isSaving.value = false;
  }
}

// validating when creating a list that it does not have the same name as a list already created
watch(
  () => newListName.value,
  (name) => {
    errors.name.error = false;
    if (!hasListInfoChanged.value) return;
    const isListNameDuplicated = lists.value.some((list) => {
      const owner = collaborateStore.getOwnerByListId(list.id);
      return list.name === name && owner?.user_id === currentUser.value.id;
    });
    if (isListNameDuplicated) {
      errors.name.error = true;
      errors.name.message = 'List names must be unique';
    }
    if (name && name.length > 100) {
      errors.name.error = true;
      errors.name.message = 'List names cannot exceed 100 characters';
    }
  },
  { immediate: true },
);

// validating when creating a list the description is less than 200 characters
watch(
  () => newListDescription.value,
  (description) => {
    errors.description.error = false;
    if (description && description.length > 200) {
      errors.description.error = true;
      errors.description.message =
        'List description cannot exceed 200 characters';
    }
  },
  { immediate: true },
);
</script>
