<template>
  <Notification v-if="partnerOrg?.name && shareRequest" :item="item">
    <template #logo>
      <BittsAvatar :org="partnerOrg" :show-initials="true" size="medium" />
    </template>
    <template #message> Data Share Request </template>
    <template #description>
      {{ partnerOrg.name }} requested data from your {{ populationNames }}
    </template>
    <template #action>
      <div v-if="requestStatus === 'pending'" class="flex items-center gap-4">
        <BittsButton
          text="Configure Sharing"
          type="primary"
          size="x-small"
          @click="viewRequest"
        />
        <BittsButton
          v-if="!hasMultiplePops"
          text="Ignore"
          type="danger"
          variant="ghost"
          size="x-small"
          @click="ignoreRequest"
        />
      </div>
      <BittsButton
        v-else-if="requestStatus === 'fulfilled'"
        text="View Overlaps"
        type="neutral"
        size="x-small"
        @click="viewOverlaps"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'population']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import { SINGLE_OVERLAPS_TYPE } from '@/constants/reports';
import {
  useDataRequestsStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';

export default {
  name: 'ShareRequestNotification',
  components: {
    BittsAvatar,
    BittsButton,
    Notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  computed: {
    shareRequest() {
      return this.getInboundShareRequestById(this.item.object_id);
    },
    partnerOrg() {
      if (!this.shareRequest) return {};
      return this.getPartnerOrgById(this.shareRequest?.partner_org_id);
    },
    ourPop() {
      return this.getPopulationById(this.shareRequest?.population_id);
    },
    partnerPopulationIds() {
      if (!this.partnerOrg) return [];
      return this.partnerPopulations
        .filter((pop) => pop.organization_id === this.partnerOrg.id)
        .map((pop) => pop.id);
    },
    requestStatus() {
      return this.shareRequest?.status;
    },
    hasMultiplePops() {
      return this.item.data?.target_population_names?.length > 1;
    },
    populationNames() {
      return this.hasMultiplePops
        ? `${this.item.data?.target_population_names?.slice(0, -1).join(', ')}
      and ${this.item.data?.target_population_names?.at(-1)} Populations`
        : `${this.ourPop.name} Population`;
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(usePopulationsStore, [
      'getPopulationById',
      'partnerPopulations',
    ]),
    ...mapActions(useDataRequestsStore, [
      'ignoreShareRequest',
      'getInboundShareRequestById',
    ]),
    ...mapActions(useNotificationsStore, ['markRead']),
    async ignoreRequest() {
      this.$emit('action-clicked', 'Decline');
      await this.ignoreShareRequest(this.item.object_id);
      await this.markRead({ id: this.item.id, isRead: true });
    },
    async viewOverlaps() {
      this.$emit('action-clicked', 'View Overlaps');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({
        name: 'account_mapping.create',
        query: {
          type: SINGLE_OVERLAPS_TYPE,
          partner_id: this.shareRequest.partner_org_id,
        },
      });
    },
    async viewRequest() {
      this.$emit('action-clicked', 'Share');
      await this.markRead({ id: this.item.id, isRead: true });
      const query = { tab: 'sharing' };
      if (!this.hasMultiplePops)
        query.population_id = this.shareRequest.population_id;
      await this.$router.push({
        name: 'partner_details',
        params: { partner_org_id: this.shareRequest.partner_org_id },
        query,
      });
    },
  },
};
</script>
