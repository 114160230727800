<template>
  <BittsModal
    :loading="loading"
    class="c-remove-users-modal"
    save-text="Delete users"
    confirm-type="danger"
    :visible="true"
    :width="480"
    @saved="handleDeleteUsers"
    @closed="closeModal"
  >
    <template #content>
      <div class="content">
        <BittsUserStack size="medium" :users="users" />
        <h1 class="title"> Delete {{ users?.length }} users? </h1>
        <p data-testid="remove-users-description" class="description">
          They will lose access to {{ currentOrg.name }}'s Crossbeam account
          immediately. This action cannot be undone.
        </p>
      </div>
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsModal, BittsUserStack } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { useBillingStore, useFlashesStore, useTeamStore } from '@/stores';

const router = useRouter();
const teamStore = useTeamStore();
const billingStore = useBillingStore();
const flashesStore = useFlashesStore();

const { currentOrg } = useAuth();

useHead({ title: 'Team - Remove Users' });

const route = useRoute();
const idInts = computed(() => route.query.ids.map((id) => parseInt(id)));
const users = computed(() =>
  idInts.value.map((id) => teamStore.getAuthorizationByUserId(id)?.user),
);

/* Handle case where users cannot be found, close the modal */
onMounted(async () => {
  if (users.value.some((user) => !user)) {
    flashesStore.addErrorFlash({
      message: 'One of those users does not exist',
    });
    await closeModal();
  }
});

const loading = ref(false);
async function handleDeleteUsers() {
  loading.value = true;
  try {
    await Promise.all(
      idInts.value.map((id) => teamStore.removeAuthorization({ userId: id })),
    );
    billingStore.refreshBillingStore();
    flashesStore.addSuccessFlash({
      message: 'Teammates deleted',
      description: 'They no longer have access to Crossbeam',
    });
  } catch (_err) {
    flashesStore.addErrorFlash({
      message: 'Some users were not removed',
      description: 'If the error persists contact support@crossbeam.com',
    });
  } finally {
    await closeModal();
  }
}

async function closeModal() {
  loading.value = false;
  await router.push({ name: 'team' });
}
</script>
<style lang="pcss" scoped>
.c-remove-users-modal {
  .content {
    @apply flex flex-col items-center mt-[-36px];
  }
  .title {
    @apply mt-20 text-neutral-text-strong text-xl font-bold mb-12;
  }
  .description {
    @apply text-neutral-text text-center text-m;
  }
}
</style>
<style lang="pcss">
.c-remove-users-modal {
  .ant-modal-body {
    background: var(
      --gradients-danger,
      radial-gradient(
        136.96% 46.92% at 50.09% -12.42%,
        theme(colors.danger.accent / 0.2) 0%,
        theme(colors.danger.accent / 0) 100%
      ),
      theme(colors.white)
    );
    @apply rounded-16;
  }
  .c-bitts-modal__buttons--wrapper {
    @apply flex w-full items-center justify-between;
  }
}
</style>
