<template>
  <div class="crossbeam">
    <div v-if="initializationFailed" data-testid="application-failed">
      The application failed to load. Please try again or contact
      <a href="mailto:support@crossbeam.com">support@crossbeam.com</a>.
    </div>
    <router-view v-else v-slot="{ Component }">
      <transition name="fade">
        <div v-if="Component">
          <TalkToSalesConfirmModal
            v-if="talkToSalesModalVisible"
            @action="talkToSalesModalVisible = false"
            @close-modal="talkToSalesModalVisible = false"
          />
          <BillingPlanComparisonModal
            v-if="comparisonPlanModalVisible"
            :billing-interaction
            :billing-query
            @close-modal="comparisonPlanModalVisible = false"
          />
          <component :is="Component" />
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { useHead } from '@unhead/vue';
import { computed, onMounted, provide, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import TalkToSalesConfirmModal from '@/components/billing/TalkToSalesConfirmModal.vue';

import useAuth from '@/composables/useAuth';
import { captureException } from '@/errors';

import BillingPlanComparisonModal from './billing/BillingPlanComparisonModal.vue';

const route = useRoute();
const router = useRouter();
const { initializationFailed, inMaintenanceMode } = useAuth();

const openIntercom = computed(() => route.query.intercom === 'open');

const talkToSalesModalVisible = ref(false);
const billingInteraction = ref(null);
const billingQuery = ref(null);
provide('openTalkToSalesModal', () => {
  talkToSalesModalVisible.value = true;
});

const comparisonPlanModalVisible = ref(false);
provide('openComparisonPlanModal', (opts, route) => {
  billingInteraction.value = opts;
  billingQuery.value = route;
  comparisonPlanModalVisible.value = true;
});
/**
 * We are watching for maintenance mode state coming from statuspage. If we have a
 * valid event, we punt user to the maintenance mode page.
 *
 * We punt the user back home if the old maintenance event is in state and has been reset to null
 * because no in_progress events are coming through. This essentially means maintenance mode has
 * been marked as completed
 */
watch(inMaintenanceMode, (newVal, oldVal) => {
  if (newVal?.id) router.push({ name: 'maintenance_mode' });
  else if (oldVal?.id && newVal === null) router.push({ name: 'home' });
});

useHead({
  title: 'Crossbeam',
});

onMounted(() => {
  if (openIntercom.value) {
    this.$nextTick(() => {
      try {
        window.Intercom('show');
      } catch (err) {
        captureException(err);
      }
    });
  }
});
</script>

<style lang="pcss">
#app {
  display: flex;
  flex-direction: column;
}
.crossbeam {
  position: relative;
  min-height: 100vh;
  height: 100vh;
}
@media (max-width: 768px) {
  .intercom-lightweight-app {
    display: none;
  }
}
</style>
