<template>
  <div class="c-bitts-timeline">
    <template v-if="showActivities">
      <div
        v-for="[
          dateCopy,
          daysEvents,
        ] in activitiesGroupedByRelativeDateNoDupes.entries()"
        :key="dateCopy"
        class="c-bitts-timeline__date-grouping"
      >
        <div class="flex items-center mb-[-1px]">
          <h2
            class="text-neutral-text font-bold text-xs bg-neutral-background-weak px-8 py-4 rounded-12"
            data-testid="date-group-title"
          >
            {{ dateCopy }}
          </h2>
        </div>
        <AttributionTimelineEventDeux
          v-for="event in daysEvents"
          :key="event.uuid"
          :activity="event"
          :account-name="accountName"
          class="w-full ml-8"
        />
      </div>
    </template>
    <div ref="load-more" class="load-more">
      <template v-if="events.length > numInitialEvents">
        <FontAwesomeIcon
          :icon="['fad', 'party-horn']"
          class="text-info-accent mr-8"
        />
        <p>You've reached the end of this timeline</p>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { computed, onBeforeUnmount, onMounted, ref, useTemplateRef } from 'vue';

import AttributionTimelineEventDeux from '@/components/attribution/AttributionTimelineEventDeux.vue';

import { ATTRIBUTION_EVENT_TYPES } from '@/constants/attribution';
import { todayYesterdayTomorrowOrCustom } from '@/date_time_utils';
import { TimelineEvent } from '@/types/timeline';

const {
  events = [],
  numInitialEvents = 15,
  accountName = '',
} = defineProps<{
  events?: TimelineEvent[];
  numInitialEvents?: number;
  accountName?: string;
}>();

const loadUntil = ref(numInitialEvents);
const showActivities = ref(false);
const scrollTemplateRef = useTemplateRef<HTMLDivElement>('load-more');
const loadMoreValue = 10;

const formatTimelineDate = (luxonDate: DateTime) => {
  return luxonDate.toRelative({ unit: 'days' });
};
let intersectionObserver: IntersectionObserver | null = null;

onMounted(() => {
  const options = {
    rootMargin: '30px',
    threshold: 0,
  };
  intersectionObserver = new IntersectionObserver(onIntersection, options);
  if (scrollTemplateRef.value)
    intersectionObserver.observe(scrollTemplateRef.value);
});

onBeforeUnmount(() => {
  intersectionObserver?.disconnect();
});

const onIntersection = (entries: IntersectionObserverEntry[]) => {
  const [loadMoreElement] = entries;
  if (loadMoreElement?.isIntersecting) {
    if (showActivities.value) loadUntil.value += loadMoreValue;
    showActivities.value = true;
  }
};

/* using a Map to guarantee insertion order */
const activitiesGroupedByRelativeDateNoDupes = computed(() => {
  return events?.slice(0, loadUntil.value).reduce((acc, currentEvent) => {
    const eventDate = DateTime.fromISO(currentEvent.triggered_at);
    const formattedDate: string = todayYesterdayTomorrowOrCustom(
      eventDate,
      false,
      formatTimelineDate,
    );
    if (acc.has(formattedDate)) {
      // filtering duplicates
      const currentEvents = acc.get(formattedDate) ?? [];
      const previousEvent = currentEvents.at(-1);

      const sameEventType = previousEvent?.type === currentEvent.type;
      const samePartner =
        previousEvent?.partner_organization_uuid ===
        currentEvent.partner_organization_uuid;
      const sameData = isEqual(previousEvent?.data, currentEvent.data);
      const isOverlapMovement =
        previousEvent?.type === ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY ||
        previousEvent?.type === ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT;
      const sameDataPopulationName =
        previousEvent?.data &&
        'partner_population_name' in currentEvent.data &&
        'partner_population_name' in previousEvent.data &&
        previousEvent?.data.partner_population_name ===
          currentEvent.data.partner_population_name;

      // if the types, partner and data are the same, we definitely would display the exact same thing
      const generallyTheSameEvent = sameEventType && samePartner && sameData;

      // for overlap movements, we can be more restrictive
      // if the record is in multiple of our own population, we don't want to show that and they are rendered the same
      const overlapMovementTheSameEvent =
        isOverlapMovement &&
        sameEventType &&
        samePartner &&
        sameDataPopulationName;

      if (!generallyTheSameEvent && !overlapMovementTheSameEvent) {
        currentEvents.push(currentEvent);
      }
    } else {
      acc.set(formattedDate, [currentEvent]);
    }

    return acc;
  }, new Map<string, TimelineEvent[]>());
});
</script>

<style scoped lang="pcss">
.c-bitts-timeline {
  @apply pb-24;
}

.c-bitts-timeline__date-grouping {
  & :nth-child(1 of .timeline-event) {
    @apply pt-12;
  }

  & .timeline-event:not(:nth-last-of-type(1)) {
    @apply pb-16;
  }

  & .timeline-event:nth-last-of-type(1) {
    @apply pb-12;
  }
}
.load-more {
  @apply flex items-center justify-center mt-8 text-neutral-text;
}
</style>
