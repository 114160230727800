<template>
  <BittsLayout
    :class="{ 'collaborate-page__empty-upsell': showUpsellSkeleton }"
    class="collaborate-page"
    :is-page="true"
  >
    <template #content>
      <BittsLoading :is-loading="!ready">
        <div
          :class="{ 'overflow-y-hidden h-full': showUpsellSkeleton }"
          class="c-collaborate-list"
        >
          <PageTitle
            title="Collaborate"
            subtitle="Swap accounts with your partners to build new relationships and make better introductions"
          >
            <template #action>
              <component
                :is="billingStore.isFreeTier ? UpsellTooltip : 'div'"
                tooltip-text="Upgrade to create a Shared List"
                :billing-interaction="{
                  cta: SHARED_LIST_CTAS.SHARED_LISTS,
                  event_site: EVENT_SITES.SHARED_LIST_UPGRADE_TOOLTIP,
                }"
              >
                <BittsButton
                  class="c-create-a-list-button"
                  data-testid="create-list-button"
                  text="Create List"
                  :disabled="disableCreateList"
                  @click="showListModal = true"
                />
                <template #title> Upgrade to create a Shared List </template>
              </component>
            </template>
          </PageTitle>
          <div :class="{ 'h-full': showUpsellSkeleton }" class="content">
            <UpsellOverlay
              v-if="showUpsellSkeleton"
              png-name="list-preview.png"
              text="Swap accounts with partners on Shared Lists to create introductions and close more opportunities"
              title="Upgrade to collaborate with partners on accounts"
              class="grow"
              :billing-interaction="{
                cta: SHARED_LIST_CTAS.SHARED_LISTS,
                event_site: EVENT_SITES.SHARED_LIST_SKELETON,
              }"
            />
            <div v-if="lists.length" class="flex flex-col">
              <h3 v-if="showSharedListModule"> Recent </h3>
              <SharedListModule
                v-if="showSharedListModule"
                class="collaborate-page__shared-list-module"
                @create-list-clicked="() => (showListModal = true)"
              />
              <SharedListTabContainer
                :lists="lists"
                :class="{ 'mt-40': showSharedListModule }"
              />
            </div>
            <ListsSkeleton v-if="showUpsellSkeleton" />
            <BittsEmptyState
              v-else-if="showEmptyState"
              :title="emptyStateTitle"
              :svg-name="svgName"
              description="Creating a list allows you to hand pick and group highly targeted accounts to share with partners or track internally.
            You will have the ability to customize columns, leave notes, add/remove records, sort/filter, and prioritize accounts"
              :button="{
                text: !hasPartnerOrgs ? 'View Partners' : 'Select Accounts',
              }"
              :link="{
                text: 'Learn more about this feature',
                url: 'https://help.crossbeam.com/en/articles/8345701-shared-lists',
              }"
              @button-clicked="onEmptyStateButtonClicked"
            />
          </div>
          <ListModal
            analytics-location="Collaborate"
            text="Create List"
            :visible="showListModal"
            @closed="showListModal = false"
          />
        </div>
      </BittsLoading>
    </template>
  </BittsLayout>
</template>
<script setup lang="ts">
import {
  BittsButton,
  BittsEmptyState,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';
import { EVENT_SITES, SHARED_LIST_CTAS } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import UpsellOverlay from '@/components/billing/UpsellOverlay.vue';
import UpsellTooltip from '@/components/billing/UpsellTooltip.vue';
import PageTitle from '@/components/PageTitle.vue';
import ListModal from '@/components/shared-list/ListModal.vue';
import ListsSkeleton from '@/components/shared-list/ListsSkeleton.vue';
import SharedListModule from '@/components/shared-list/SharedListModule.vue';
import SharedListTabContainer from '@/components/shared-list/SharedListTabContainer.vue';

import { BASE_ROUTES } from '@/constants/routes';
import { NO_ACCESS } from '@/constants/shared_list';
import {
  useBillingStore,
  useCollaborateStore,
  usePartnersStore,
} from '@/stores';

useHead({
  title: 'Collaborate - Crossbeam',
});

const billingStore = useBillingStore();
const collaborateStore = useCollaborateStore();
const route = useRoute();
const router = useRouter();
const showListModal = ref(false);

onMounted(() => {
  if (route.query?.createList) showListModal.value = true;
});

const { ready } = storeToRefs(collaborateStore);

const { lists, listAccess } = storeToRefs(collaborateStore);
const partnersStore = usePartnersStore();
const hasPartnerOrgs = computed(() => !!partnersStore.partnerOrgs.length);
const emptyStateTitle = computed(() =>
  hasPartnerOrgs.value
    ? 'Start a list of accounts with your partner'
    : 'Add partners to start collaborating on accounts with them',
);

const showSharedListModule = computed(() => !billingStore.isFreeTier);

const svgName = computed(() =>
  hasPartnerOrgs.value ? 'emptyStateHome' : 'emptyStatePartnersBitts',
);

const showEmptyState = computed(() => ready.value && !lists.value.length);
const showUpsellSkeleton = computed(() => {
  if (!showEmptyState.value) return false;
  return listAccess.value === NO_ACCESS;
});
const disableCreateList = computed(() => {
  if (!hasPartnerOrgs.value) return true;
  return billingStore.isFreeTier;
});

async function onEmptyStateButtonClicked() {
  await router.push({
    name: hasPartnerOrgs.value ? BASE_ROUTES.REPORTS : 'partners',
  });
}
</script>

<style scoped lang="pcss">
.collaborate-page {
  h3 {
    @apply text-lg font-bold text-neutral-text-strong mb-16;
  }
}
.content {
  @apply relative;
  .content-list-title {
    @apply text-xl font-bold;
  }
  .all-lists {
    @apply grid gap-16 max-w-full mb-68 max-h-full;
  }
}
</style>
<style lang="pcss">
.collaborate-page__empty-upsell.bitts-layout {
  @apply px-0 pb-0;
  .c-page-title {
    @apply px-40;
  }
}
</style>
