export interface ErrorWithResponse extends Error {
  response?: {
    status: number;
  };
}

export function isErrorWithResponse(
  error: unknown,
): error is ErrorWithResponse {
  return (
    typeof error === 'object' &&
    error !== null &&
    'response' in error &&
    typeof (error as ErrorWithResponse).response?.status === 'number'
  );
}
