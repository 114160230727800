<template>
  <BittsDropdown
    placement="bottomLeft"
    parent-class="c-collaborate-share-action-dropdown__container"
    :options="shareOptions"
    @selection="handleSelection"
    @visibility-changed="onVizChange"
    @cta-clicked="onViewTimelineClicked"
  >
    <template #prompt>
      <component :is="tooltipOrDiv" v-bind="tooltipProps" placement="topLeft">
        <div>
          <BittsButton v-bind="buttonProps" :active="isButtonActive" />
        </div>
        <template #title>
          {{ tooltipText }}
        </template>
      </component>
    </template>
  </BittsDropdown>
</template>

<script setup>
import { BittsButton, BittsDropdown, BittsTooltip } from '@crossbeam/bitts';
import { EVENT_SITES, SHARED_LIST_CTAS } from '@crossbeam/itly';

import { intersection, isEqual } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import UpsellTooltip from '@/components/billing/UpsellTooltip.vue';

import useIntegrations from '@/composables/useIntegrations';
import useIteratively from '@/composables/useIteratively';
import useSharedList from '@/composables/useSharedList';
import { CROSSBEAM_FOR_SALES, PARTNERSTACK } from '@/constants/shared_list';
import { useBillingStore } from '@/stores';

const props = defineProps({
  singleSend: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  params: {
    type: Object,
    default: () => ({}),
  },
  rowsSelected: {
    type: Array,
    default: () => [],
  },
  list: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['option-selected']);

const { iteratively } = useIteratively();

const { partnerStackIsActive } = useIntegrations();

const isButtonActive = ref(false);

const billingStore = useBillingStore();
const { isFreeTier } = storeToRefs(billingStore);
const { sendToSalesError } = useSharedList();

const partnerstackDisabled = computed(
  () =>
    !!props.params?.data?.data.find((col) => col.column_id === PARTNERSTACK)
      ?.value?.submitted_on,
);

const shareOptions = computed(() => {
  const options = [
    {
      display: 'Send to Account Owner',
      value: CROSSBEAM_FOR_SALES,
      svg: 'crossbeamForSalesTag',
      tooltip: crossbeamSalesSingleSendShareOption.value?.text || null,
      disabled: !!crossbeamSalesSingleSendShareOption.value,
      buttonText: crossbeamSalesSingleSendShareOption.value?.ctaText,
      includeCta: !!crossbeamSalesSingleSendShareOption.value?.ctaText,
    },
  ];
  if (props.singleSend && partnerStackIsActive.value) {
    options.splice(2, 0, {
      display: 'Refer leads to PartnerStack',
      value: PARTNERSTACK,
      svg: 'partnerStackAltLogo',
      disabled: partnerstackDisabled.value,
      tooltip: partnerstackDisabled.value
        ? 'This record has already been referred to PartnerStack'
        : null,
      buttonText: null,
      includeCta: false,
    });
  }
  return options;
});

const crossbeamSalesRecordSubmitted = computed(
  () =>
    !!props.params?.data?.data.find(
      (col) => col.column_id === CROSSBEAM_FOR_SALES,
    )?.value?.submitted_on,
);
const recordsCannotBeSent = computed(() =>
  props.rowsSelected.some(
    (record) => !!record.recordId && !record.can_send_to_sales,
  ),
);

const crossbeamSalesSingleSendShareOption = computed(() => {
  if (props.singleSend) {
    if (crossbeamSalesRecordSubmitted.value) {
      return {
        text: 'This record has already been sent to an Account Owner',
        ctaText: null,
      };
    }

    if (recordsCannotBeSent.value) {
      return {
        text: 'The record does not exist in either your population or your partner’s population. View this record’s timeline for population and overlap movement.',
        ctaText: 'View Timeline',
      };
    }

    if (sendToSalesError.value) {
      return {
        text: sendToSalesError.value.shortDescription,
        ctaText: null,
      };
    }

    return null;
  }

  return null;
});

const partnerGreenfieldRecordsSelected = computed(() =>
  props.rowsSelected.some((record) => record.isGreenfield && !record.recordId),
);
const recordsSelectedSentToSales = computed(() =>
  props.rowsSelected.filter(
    (row) =>
      !!row.data?.find((col) => col.column_id === CROSSBEAM_FOR_SALES)?.value,
  ),
);
const someRecordsSentToSales = computed(
  () =>
    !!intersection(props.rowsSelected, recordsSelectedSentToSales.value).length,
);

const allRecordsSentToSales = computed(() =>
  isEqual(recordsSelectedSentToSales.value, props.rowsSelected),
);

const tooltipText = computed(() => {
  if (isFreeTier.value) return 'Upgrade to take action via integrations';
  if (!props.singleSend) {
    if (!props.rowsSelected.length) return 'Select records to send';
    if (recordsCannotBeSent.value && partnerGreenfieldRecordsSelected.value)
      return 'De-select records with error and Greenfield records in order to send records in bulk';
    if (sendToSalesError.value) return sendToSalesError.value.shortDescription;
    if (partnerGreenfieldRecordsSelected.value)
      return 'De-select Greenfield records in order to send records in bulk';
    if (recordsCannotBeSent.value)
      return 'De-select records with error in order to send records in bulk';
    if (someRecordsSentToSales.value && !allRecordsSentToSales.value)
      return 'Some records have already been sent to an account owner. De-select records in order to send records in bulk.';
    if (allRecordsSentToSales.value)
      return 'Records have already been sent to an account owner and integrations';
  }
  return 'Send records to team members or take action via integrations';
});

const disableBulkSend = computed(() => {
  return (
    isFreeTier.value ||
    partnerGreenfieldRecordsSelected.value ||
    recordsCannotBeSent.value ||
    someRecordsSentToSales.value ||
    allRecordsSentToSales.value ||
    sendToSalesError.value
  );
});

const tooltipOrDiv = computed(() => {
  return isFreeTier.value
    ? UpsellTooltip
    : props.singleSend && props.disabled
      ? 'div'
      : BittsTooltip;
});

const tooltipProps = computed(() => {
  if (tooltipOrDiv.value === BittsTooltip) {
    return {
      mountToBody: true,
    };
  }
  return {
    'hide-upsell': !isFreeTier.value,
    'billing-interaction': {
      cta: SHARED_LIST_CTAS.CROSSBEAM_FOR_SALES,
      event_site: EVENT_SITES.SHARED_LIST_ACTION_DROPDOWN,
    },
  };
});

const buttonProps = computed(() => {
  if (props.singleSend) {
    return {
      variant: 'outline',
      size: 'x-small',
      disabled: props.disabled || isFreeTier.value,
      'center-icon': ['fak', 'send'],
    };
  }
  return {
    text: 'Send',
    size: 'small',
    disabled: !!(props.disabled || disableBulkSend.value),
    'right-icon': ['fak', 'send'],
  };
});

function onVizChange(value) {
  isButtonActive.value = value;
}

const router = useRouter();
async function onViewTimelineClicked() {
  await router.push({
    name: 'activity_timeline',
    params: {
      source_id: props.params.data.source_id,
      source_primary_key: props.params.data.record_id,
    },
  });
}

function handleSelection({ value }) {
  submitItlyEvent(value);
  emit('option-selected', value);
}

function submitItlyEvent(value) {
  iteratively.userClicksSendRecords({
    action_name: value,
    clicked_from: props.singleSend ? 'List Row' : 'Above List',
    list_id: props.list.id,
    list_name: props.list.name,
    location: 'Collaborate',
    number_of_records: !props.singleSend
      ? props.rowsSelected.length.toString()
      : '1',
    event_site: EVENT_SITES.SHARED_LIST_ACTION_DROPDOWN,
  });
}
</script>
<style lang="pcss">
.c-collaborate-share-action-dropdown__container.ant-popover {
  @apply pt-0;
}
</style>
