<template>
  <BittsLayout variant="thirds" :is-page="true" class="downgrade-page">
    <template #left>
      <DowngradeCopy />
    </template>
    <template #right>
      <BittsLoading
        label="Processing cancellation. Please do not close the page..."
        :is-loading="loading"
      >
        <div class="right-content">
          <div class="right-content__inner">
            <h1>Downgrade to Crossbeam Explorer</h1>
            <BittsBreadcrumbs
              :icon="['fak', 'billing']"
              :crumbs="crumbs"
              class="mb-36 mt-4"
              :active-crumb-index="activeCrumbIndex"
              :last-crumb-index="crumbs.length - 1"
              @root-clicked="handleClosePage"
              @breadcrumb-clicked="handleBreadcrumbClicked"
            />
            <DowngradeInformationView v-if="activeCrumbName === 'info'" />
            <DowngradeSelectionView
              v-if="activeCrumbName === 'user-selection'"
              v-model:user-ids="retainedUserIds"
              v-model:invite-ids="retainedInviteIds"
            />
            <RemoveOfflinePartners
              v-if="activeCrumbName === 'offline-partners'"
              v-model="savedOfflinePartnerUuid"
            />
            <DowngradeDetailsView
              v-if="activeCrumbName === 'details'"
              v-model="checkedReasons"
            />
            <DowngradeReviewView
              v-if="activeCrumbName === 'review'"
              :user-ids="retainedUserIds"
              :invite-ids="retainedInviteIds"
              :offline-partner-uuid="savedOfflinePartnerUuid"
            />
          </div>
          <BittsDivider class="mt-auto mb-0" />
          <div class="footer">
            <BittsButton
              v-if="activeCrumbIndex < crumbs.length - 1"
              :disabled="continueDisabled"
              size="large"
              text="Continue"
              data-testid="continue-button"
              variant="outline"
              type="neutral"
              @click="handleNextPage"
            />
            <BittsButton
              v-else
              text="Downgrade plan"
              type="danger"
              data-testid="downgrade-button"
              size="large"
              @click="handleDowngrade"
            />
            <BittsButton
              size="large"
              text="Keep current plan"
              data-testid="keep-plan-button"
              @click="handleClosePage"
            />
          </div>
        </div>
      </BittsLoading>
    </template>
  </BittsLayout>
</template>
<script setup>
import {
  BittsBreadcrumbs,
  BittsButton,
  BittsDivider,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import DowngradeCopy from '@/components/billing/DowngradeCopy.vue';
import DowngradeDetailsView from '@/components/billing/DowngradeDetailsView.vue';
import DowngradeInformationView from '@/components/billing/DowngradeInformationView.vue';
import DowngradeReviewView from '@/components/billing/DowngradeReviewView.vue';
import DowngradeSelectionView from '@/components/billing/DowngradeSelectionView.vue';
import RemoveOfflinePartners from '@/components/billing/RemoveOfflinePartners.vue';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import { CORE_SEAT_COMPONENTS } from '@/constants/billing';
import { reduceToIds } from '@/constants/debooking';
import { useBillingStore, usePartnersStore, useTeamStore } from '@/stores';

useHead({ title: 'Downgrade Account - Crossbeam' });

const billingStore = useBillingStore();
const partnersStore = usePartnersStore();
const teamStore = useTeamStore();

const { isAdminPanelUser, currentUser } = useAuth();
const { offlinePartners } = storeToRefs(partnersStore);
const { subscriptionType } = storeToRefs(billingStore);
const { coreUsers, coreInvites } = storeToRefs(teamStore);

const router = useRouter();
async function handleClosePage() {
  await router.push({ name: 'billing' });
}

/* Page Flow Logic */
const crumbs = computed(() => {
  const steps = [
    { text: 'Information', name: 'info' },
    { text: 'User Selection', name: 'user-selection' },
  ];
  if (offlinePartners.value.length > 0)
    steps.push({ text: 'Offline Partners', name: 'offline-partners' });
  return [
    ...steps,
    { text: 'Details', name: 'details' },
    { text: 'Review', name: 'review' },
  ];
});

const activeCrumbName = computed(
  () => crumbs.value[activeCrumbIndex.value].name,
);
const activeCrumbIndex = ref(0);
const continueDisabled = computed(() => {
  if (activeCrumbName.value === 'details' && checkedReasons.value.length === 0)
    return true;
  if (
    activeCrumbName.value === 'user-selection' &&
    retainedUserIds.value.size === 0
  )
    return true;
  return false;
});

function handleBreadcrumbClicked(crumb) {
  const i = crumbs.value.findIndex((c) => c.name === crumb.name);
  if (i > activeCrumbIndex.value) return;
  if (i <= 2) checkedReasons.value = [];
  if (i <= 1) resetRetainedUsers();
  activeCrumbIndex.value = i;
}

function handleNextPage() {
  activeCrumbIndex.value += 1;
}

/* User selections */
const retainedUserIds = ref(new Set([]));
const retainedInviteIds = ref(new Set([]));
function resetRetainedUsers() {
  const set = new Set([]);
  if (!isAdminPanelUser.value) set.add(currentUser.value.id);
  retainedUserIds.value = set;
  retainedInviteIds.value = new Set([]);
}
resetRetainedUsers();

const savedOfflinePartnerUuid = ref('');
const checkedReasons = ref([]);

/* Handle downgrade */
const { cancelConnector } = useBilling();
const loading = ref(false);
async function handleDowngrade() {
  loading.value = true;
  const payload = makePayload();
  const success = await cancelConnector({
    period: subscriptionType.value,
    payload,
  });
  loading.value = false;
  if (success) await router.push({ name: 'downgrade-complete' });
}

/* Helpers 🤝 */
function makePayload() {
  const retainedCoreUsers = reduceToIds(coreUsers.value, retainedUserIds.value);
  const retainedCoreInvites = reduceToIds(
    coreInvites.value,
    retainedInviteIds.value,
  );
  const retainedOfflineIds = [];
  if (savedOfflinePartnerUuid.value)
    retainedOfflineIds.push(savedOfflinePartnerUuid.value);
  const payload = {
    reasons: checkedReasons.value,
    updates: [
      {
        component_handle: CORE_SEAT_COMPONENTS[subscriptionType.value],
        retained_users: retainedCoreUsers,
        retained_invites: retainedCoreInvites,
        retained_offline_partners: retainedOfflineIds,
      },
    ],
  };

  return payload;
}
</script>

<style lang="pcss" scoped>
.right-content {
  @apply h-full flex flex-col;
  .right-content__inner {
    @apply px-24 mt-36 lg:px-120 lg:pt-48;

    h1 {
      @apply text-neutral-text-strong text-lg font-bold;
    }
  }

  .footer {
    @apply flex items-center justify-between py-40 px-120;
  }
}
</style>
<style lang="pcss">
.downgrade-page.bitts-layout {
  @apply p-0 min-h-screen;
}
</style>
