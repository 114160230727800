<template>
  <div class="h-full flex items-center">
    {{ value }}
  </div>
</template>

<script>
import { DateTime } from 'luxon';

const DATE_TIME = 'timestamp with time zone';

export default {
  name: 'PopulationTableCell',
  computed: {
    isValueDate() {
      return this.params?.value?.dataType === DATE_TIME;
    },
    value() {
      const val = this.params?.value?.val;
      if (!val) return '--';
      if (this.isValueDate) {
        return val
          ? DateTime.fromISO(val, { setZone: true }).toFormat('DD')
          : '--';
      }
      return val;
    },
  },
};
</script>
