<template>
  <div class="c-integration-settings-partner-defaults-switch">
    <div>
      <h6> Add new partnerships automatically </h6>
      <p
        >New partners will be added to this integration using the default
        settings</p
      >
    </div>
    <BittsSwitch
      class="ml-16"
      :disabled="isDisabled"
      :model-value="isChecked"
      @change="toggleExcludePartnershipsCreatedSince"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsSwitch } from '@crossbeam/bitts';

withDefaults(
  defineProps<{
    isChecked: boolean;
    isDisabled?: boolean;
  }>(),
  { isDisabled: false },
);
const emit =
  defineEmits<
    (e: 'update:exclude-partnerships-created-since', isChecked: boolean) => void
  >();
const toggleExcludePartnershipsCreatedSince = (isChecked: boolean) => {
  emit('update:exclude-partnerships-created-since', isChecked);
};
</script>

<style lang="pcss" scoped>
.c-integration-settings-partner-defaults-switch {
  @apply flex items-center justify-between;
}

h6 {
  @apply text-neutral-text-strong text-base font-bold;
}

p {
  @apply text-neutral-text text-sm;
}
</style>
