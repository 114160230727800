<template>
  <div />
</template>

<script>
import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions } from 'pinia';

import useAuth from '@/composables/useAuth';
import { captureException } from '@/errors';
import { useFlashesStore, usePartnersStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'PublicInvite',
  components: {},

  setup() {
    useHead({
      title: 'Invite - Crossbeam',
    });
    const { currentOrg, isLoggedIn } = useAuth();

    return { currentOrg, isLoggedIn };
  },

  data() {
    return {
      fromOrganizationName: null,
      publicInviteOrg: null,
    };
  },
  computed: {
    publicInviteCode() {
      return this.$route.params.code;
    },
  },
  async created() {
    if (this.publicInviteCode) {
      try {
        const publicInviteUrl = urls.publicInviteCode.getOrgId(
          this.publicInviteCode,
        );
        const response = await axios.get(publicInviteUrl);
        if (response.data) {
          this.publicInviteOrg = response.data;
          this.setPublicInviteOrg(response.data);
          this.setPublicInviteCode(this.publicInviteCode);
          const { from } = this.$route.query;
          if (!this.isLoggedIn) {
            this.$router.push({
              path: '/register',
              query: { public_invite_code: this.publicInviteCode },
            });
          } else if (
            this.currentOrg.public_invite_code === this.publicInviteCode
          ) {
            if (from === 'home') {
              this.$router.push({ name: from });
              this.addErrorFlash({
                message: 'You tried to scan your own QR Code',
              });
            } else {
              this.$router.push({
                path: '/partners',
              });
            }
          } else {
            const nextRoute = {
              path: '/partners/public-invite',
            };
            if (from === 'home') {
              nextRoute.query = {
                from,
              };
            }
            this.$router.push(nextRoute);
          }
        }
      } catch (error) {
        if (!this.isLoggedIn) {
          this.$router.push({ path: '/register' });
        } else {
          this.$router.push({
            path: '/home',
            query: { public_invite_code: this.publicInviteCode },
          });
        }
        captureException(error);
        this.publicInviteOrg = null;
      }
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addErrorFlash']),
    ...mapActions(usePartnersStore, [
      'setPublicInviteCode',
      'setPublicInviteOrg',
    ]),
  },
};
</script>
