<template>
  <div class="flex flex-col h-full">
    <div class="flex justify-between mb-4">
      <div class="flex">
        <BittsTag
          :text="pop.standard_type ? 'Standard' : 'Custom'"
          :color="pop.standard_type ? 'info' : 'neutral'"
          size="x-small"
          variant="rounded"
          class="mr-8 pop-type-tag"
        />
        <BittsTooltip
          v-if="isSharingGreenfield"
          placement="topLeft"
          class="c-population-card__greenfield"
        >
          <FontAwesomeIcon
            :icon="['fak', 'greenfield']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="text-success-accent"
          />
          <template #title>
            You are sharing Greenfield accounts from this Population
          </template>
        </BittsTooltip>
      </div>
      <BittsButton
        v-if="!isEmptyStateCard"
        size="x-small"
        :center-icon="['fak', 'edit']"
        type="neutral"
        :disabled="!hasManagePops"
        @click="onUpdatePopClicked"
      />
    </div>
    <div class="flex items-center max-w-[300px] mb-4">
      <BittsSvg
        v-if="!isEmptyStateCard"
        :svg="sourceIcon"
        data-testid="source-icon"
        class="w-16 h-16 mr-8 min-w-[16px]"
      />
      <p
        class="text-neutral-text-strong text-m font-bold mr-8 truncate"
        data-testid="pop-name"
      >
        {{ pop.name }}
      </p>
    </div>
    <div
      class="text-neutral-text mb-24 grow max-w-[300px]"
      data-testid="pop-description"
    >
      <p class="line-clamp-3">
        {{ pop.description || 'No description added' }}
      </p>
    </div>
    <div v-if="!isEmptyStateCard" class="flex justify-between">
      <div class="flex flex-col mr-8">
        <p class="mb-4 text-neutral-text-weak"> Sharing </p>
        <SharingSetting
          class="flex items-center"
          :show-sharing-text="false"
          :data-share="pop?.dataShare"
        />
      </div>
      <div class="flex flex-col">
        <p class="text-neutral-text-weak mb-4"> Using this default for </p>
        <BittsPopover
          class="cursor-pointer"
          :mount-to-body="true"
          trigger="hover"
          overlay-class="c-sharing-dashboard-pop-card__popover"
        >
          <p data-testid="num-default-partners">
            {{ numDefaultPartners }} Partners
          </p>
          <template #content>
            <div
              v-for="partner in defaultPartners"
              :key="partner.uuid"
              class="flex items-center max-w-[200px] overflow-hidden"
            >
              <div class="flex p-4 w-full">
                <PartnerIcon size="x-small" :partner-uuid="partner.uuid" />
                <p class="ml-8 text-sm truncate">
                  {{ partner.name }}
                </p>
              </div>
            </div>
          </template>
        </BittsPopover>
      </div>
    </div>
    <div v-else>
      <BittsButton
        text="Create Population"
        class="w-full"
        size="x-small"
        :disabled="!hasManagePops"
        @click="onCreatePopClicked"
      />
    </div>
  </div>
</template>
<script setup>
import {
  BittsButton,
  BittsPopover,
  BittsSvg,
  BittsTag,
  BittsTooltip,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import PartnerIcon from '@/components/partners/PartnerIcon.vue';
import SharingSetting from '@/components/populations/SharingSetting.vue';

import useIteratively from '@/composables/useIteratively';
import { GREENFIELD_SHARING } from '@/constants/sharing_dashboard';
import { useFeedsStore } from '@/stores';

const props = defineProps({
  pop: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  defaultPartners: {
    type: Array,
    default: () => [],
  },
  hasManagePops: {
    type: Boolean,
    default: false,
  },
});
const { feeds } = storeToRefs(useFeedsStore());
const { iteratively } = useIteratively();

const isEmptyStateCard = computed(() => props.pop.isEmptyStateCard);

const sourceIcon = computed(() => {
  if (props.pop?.base_schema) {
    const feed = feeds.value.find(
      (feed) => feed.schema_name === props.pop.base_schema,
    );
    if (feed) {
      return `${feed.integration.type}Icon`;
    }
  }
  return '';
});

const numDefaultPartners = computed(() => props.defaultPartners.length);

const isSharingGreenfield = computed(
  () =>
    !isEmptyStateCard.value &&
    props.pop.dataShare?.visibility === GREENFIELD_SHARING,
);

const router = useRouter();

async function onUpdatePopClicked() {
  iteratively.userClickedEdit({
    event_site: EVENT_SITES.SHARING_DASHBOARD_EDIT_DEFAULT_SHARING,
    property_name: props.pop.name,
  });
  await router.push({
    name: 'populations.sharing',
    params: { population_id: props.pop.id },
  });
}

async function onCreatePopClicked() {
  iteratively.userClickedCreatePopulation({
    event_site: EVENT_SITES.SHARING_DASHBOARD_CREATE_POPULATION,
    population_type: props.pop.standard_type,
  });
  await router.push({
    name: 'create_population',
    query: { standardType: props.pop.standard_type },
  });
}
</script>
<style lang="pcss">
.pop-type-tag.bitts-tag.bitts-tag__rounded > .ant-tag {
  @apply py-0;
}
.c-sharing-dashboard-pop-card__popover .ant-popover-content .ant-popover-inner {
  @apply max-h-[300px] overflow-scroll;
}
</style>
