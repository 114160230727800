<template>
  <div
    v-if="sources.length > 0"
    class="c-feed-list__feed"
    :class="{ 'pb-16': feedIsErrored, disabled: feedIsDeleting }"
  >
    <!-- Only show this component if there are actual uploads -->
    <div :class="itemHeaderClass">
      <ExpandButton
        v-if="sources.length > 0"
        :toggle="toggleRowsCollapsed"
        :mode="rowsCollapsed ? 'collapsed' : 'expanded'"
      />
      <slot name="left-content" :multiple-sources="true" />
      <div v-if="feedStatus" class="flex items-center gap-10">
        <slot
          name="status"
          :icon-class="connectionStatusColor"
          :text="connectionStatusText"
        />
        <BittsButton
          v-if="hasPermission('manage:data-sources')"
          type="neutral"
          variant="outline"
          size="x-small"
          text="Add"
          @click="showCSVUploadModal"
        />
      </div>
    </div>
    <slot v-if="feedIsErrored" name="feed-error" />
    <FeedListItemRow
      v-for="(source, index) in sources"
      :key="`feed_table_${index}`"
      :class="{ hidden: rowsCollapsed }"
      :source="source"
      :feed="feed"
      :feed-status="feedStatus"
    />
  </div>
</template>

<script>
import { BittsButton } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import FeedListItemRow from '@/components/data-sources/FeedListItemRow.vue';
import ExpandButton from '@/components/ExpandButton.vue';

import useAuth from '@/composables/useAuth';
import useFeedDetails from '@/composables/useFeedDetails';
import useSourcesSyncing from '@/composables/useSourcesSyncing';
import { SOURCE_STATUS_MAP } from '@/constants/data_sources';
import { useFileUploadsStore, useSourcesStore } from '@/stores';
import { sortByKey } from '@/utils';

export default {
  name: 'CsvFeed',
  components: {
    FeedListItemRow,
    ExpandButton,
    BittsButton,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const fileUploadsStore = useFileUploadsStore();
    const sourcesStore = useSourcesStore();
    const { hasPermission } = useAuth();

    const { feedStatus, feedIsErrored, feedIsDeleting, feedDeletionFailed } =
      useFeedDetails({ feedId: props.feed?.id });

    const { currentUploadNames } = storeToRefs(fileUploadsStore);

    const sources = computed(() =>
      sourcesStore
        .getSourcesByFeedId(props.feed.id)
        .filter((source) => currentUploadNames.value.includes(source.table))
        .sort(sortByKey('created_at', 'desc', 'timestamp')),
    );

    useSourcesSyncing(sources, sourcesStore.refreshSourcesStore);

    /* Local component state */
    const rowsCollapsed = ref(true);

    return {
      feedStatus,
      sources,
      feedIsDeleting,
      feedDeletionFailed,
      feedIsErrored,
      rowsCollapsed,
      hasPermission,
    };
  },
  computed: {
    hasSourceError() {
      return this.sources.some((source) => {
        return SOURCE_STATUS_MAP.error.includes(source.status);
      });
    },
    hasSourceProcessing() {
      return this.sources.some((source) => {
        return SOURCE_STATUS_MAP.process.includes(source.status);
      });
    },
    worstSourceStatus() {
      if (this.hasSourceError || this.feedIsDeleting || this.feedDeletionFailed)
        return 'danger';
      if (this.hasSourceProcessing) return 'warning';
      return 'success';
    },
    sourceIsErrored() {
      return this.sources.some((source) => {
        return SOURCE_STATUS_MAP.error.includes(source.status);
      });
    },
    connectionStatusText() {
      if (this.feedIsDeleting || this.feedDeletionFailed)
        return this.feedStatus.text;
      const statusMap = {
        danger: 'Error',
        warning: 'Setting up...',
        success: 'Active',
      };
      /* Return error if any sources are errored */
      return statusMap[this.worstSourceStatus] || '';
    },
    itemHeaderClass() {
      const base = ['flex justify-between p-16'];
      if (!this.sources.length) base.push('ml-28');
      if (this.feedIsErrored) base.push('pb-0');
      return base;
    },
    connectionStatusColor() {
      return this.worstSourceStatus
        ? `bg-${this.worstSourceStatus}-accent`
        : '';
    },
  },
  methods: {
    showCSVUploadModal() {
      this.$router.push({ name: 'file-uploads' });
    },
    toggleRowsCollapsed() {
      this.rowsCollapsed = !this.rowsCollapsed;
    },
  },
};
</script>
