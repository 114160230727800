<template>
  <BittsCollapse
    :is-open="isCollapseOpen"
    class="c-billing-plan-comparison-tag"
    :show-underline="false"
    @change="onChange"
  >
    <template #header>
      <div class="flex flex-col">
        <p class="c-billing-plan-comparison-header-title">
          Don’t miss out on enterprise-level power with our Supernode plan
        </p>
        <p class="text-sm text-neutral-text font-normal">
          Scale your ecosystems with advanced security, admin controls, and
          unlimited integrations
        </p>
      </div>
    </template>
    <div v-if="isCollapseOpen" class="pb-24" />
    <div class="rounded-lg bg-white">
      <div class="p-12">
        <BillingPlanComparisonFeatureList
          tier="supernode"
          :is-checkout-page="true"
        />
        <BittsButton
          text="Review Plans"
          size="small"
          variant="outline"
          @click="routeToBilling"
        />
      </div>
    </div>
  </BittsCollapse>
</template>
<script setup lang="ts">
import { BittsButton, BittsCollapse } from '@crossbeam/bitts';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

import BillingPlanComparisonFeatureList from '@/components/billing/BillingPlanComparisonFeatureList.vue';

const router = useRouter();

async function routeToBilling() {
  await router.push({ name: 'billing' });
}

const isCollapseOpen = ref(false);
function onChange(isOpen: boolean) {
  isCollapseOpen.value = isOpen;
}
</script>
<style scoped lang="pcss">
.c-billing-plan-comparison-tag {
  @apply rounded-lg p-12;
  background: linear-gradient(
    90deg,
    theme(colors.denim.50 / 0.15) -10.62%,
    theme(colors.pink[600] / 0.08) 108.71%
  );
  .c-billing-plan-comparison-header-title {
    @apply text-base font-bold;
    background: linear-gradient(
      90deg,
      theme(colors.denim.400) 0.13%,
      theme(colors.pink.600/ 0.75) 24.57%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
<style lang="pcss">
.c-billing-plan-comparison-tag {
  .ant-collapse
    .ant-collapse-item
    .ant-collapse-header
    .ant-collapse-expand-icon
    .ant-collapse-arrow {
    svg {
      color: theme(colors.neutral.accent) !important;
    }
  }
}
</style>
