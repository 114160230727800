<template>
  <div
    :class="{
      'opacity-50': activeRole === NO_ACCESS_ROLE,
    }"
    class="team-table-full-role"
  >
    <FontAwesomeIcon
      v-if="activeRole?.icon"
      :icon="activeRole.icon.type"
      :class="`${activeRole.icon.color}`"
    />
    <span class="px-4" data-testid="active-role">
      {{ activeRole?.name }}
    </span>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';

import useAuthOrInvite from '@/composables/useAuthOrInvite';
import { OLD_ROLES_TO_NEW_MAP } from '@/constants/billing';
import { NO_ACCESS_ROLE, SALES_EDGE_ROLES } from '@/constants/team';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const authOrInvite = ref(null);
onBeforeMount(() => {
  authOrInvite.value = props.params.data;
  column.value = props.params.column.colId;
});

const { role } = useAuthOrInvite(authOrInvite);

const column = ref(null);
const isSalesEdge = computed(() => column.value === 'Sales Role');

const activeRole = computed(() => {
  if (!isSalesEdge.value) return role.value ?? NO_ACCESS_ROLE;
  const oldRole =
    SALES_EDGE_ROLES.find(
      (role) => role.id === authOrInvite.value.sales_edge_role,
    ) || null;
  return oldRole
    ? { ...oldRole, name: OLD_ROLES_TO_NEW_MAP[oldRole.id] }
    : NO_ACCESS_ROLE;
});
</script>

<style lang="pcss">
.team-table-role-selector-cell {
  @apply pt-0;

  .c-bitts-dropdown-selections .c-bitts-dropdown-selections__section-separator {
    @apply pb-0;
  }
}
</style>
