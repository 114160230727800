<template>
  <div class="seat-request-pending"> Seat Request Pending Page </div>
</template>
<script setup>
import { useHead } from '@unhead/vue';
useHead({ title: 'Seat Request - Crossbeam' });
</script>
<style lang="pcss" scoped>
.seat-request-pending {
  @apply flex flex-col items-center justify-center relative;
}
</style>
