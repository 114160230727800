<template>
  <BittsAlert
    message="Existing overrides will be deleted"
    color="warning"
    class="override-alert"
  >
    <template #body>
      <div
        v-for="override in popToPopOverrides"
        :key="`override__${override.dataShare.id}`"
        class="override-alert__override"
      >
        <div class="override-alert__sharing">
          {{ `Sharing ${override.dataShare.source_field_ids.length} fields` }}
        </div>
        {{
          `when overlapping with ${partnerName}'s ${override.partnerPopulation.name}`
        }}
      </div>
    </template>
  </BittsAlert>
</template>

<script setup>
import { BittsAlert } from '@crossbeam/bitts';

import { inject } from 'vue';

defineProps({
  partnerName: {
    type: String,
    default: '',
  },
});

const popToPopOverrides = inject('popToPopOverrides');
</script>

<style lang="pcss" scoped>
.override-alert {
  @apply font-bold;
}
.override-alert__override {
  @apply font-normal flex items-center text-sm text-neutral-text-weak;
  &:not(:first-child) {
    @apply mt-4;
  }
}
.override-alert__sharing {
  @apply text-neutral-text-strong px-8 py-4 mr-4 rounded-bts-sm bg-success-background-weak;
}
</style>
