<template>
  <div
    v-if="seatRequest && hasScope('write:members')"
    class="c-seat-request-table-actions-cell"
  >
    <BittsButton
      variant="ghost"
      type="danger"
      text="Reject"
      size="x-small"
      data-testid="reject-seat-request"
      @click="handleRejectRequest"
    />
    <component
      :is="needsSeat ? UpsellTooltip : 'div'"
      :mount-to-body="true"
      data-testid="full-access-upsell-tooltip"
      :should-emit="true"
      :hide-upsell="mustTalkToSales || !canManageBilling"
      :billing-interaction="{
        event_site: EVENT_SITES.SEAT_REQUEST_ACCEPT_TOOLTIP,
        cta: isFreeTier ? TEAM_CTAS.FREE_NEEDS_MORE_SEATS : TEAM_CTAS.BUY_SEATS,
        talkToSalesReason:
          mustTalkToSales &&
          'Add more seats, to accept pending invite requests',
      }"
      :billing-query="billingQuery"
      :button-text="upsellButtonText"
    >
      <template #title>
        <span data-testid="upsell-tooltip-text">
          {{ upsellTooltipText }}
        </span>
      </template>
      <BittsButton
        type="primary"
        data-testid="accept-seat-request"
        :disabled="needsSeat"
        text="Approve"
        size="x-small"
        @click="handleApproveRequest"
      />
    </component>
  </div>
</template>

<script setup>
import { BittsButton } from '@crossbeam/bitts';
import { EVENT_SITES, TEAM_CTAS } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import UpsellTooltip from '@/components/billing/UpsellTooltip.vue';

import useAuth from '@/composables/useAuth';
import useSeats from '@/composables/useSeats';
import { CORE } from '@/constants/team';
import { useBillingStore, useSeatRequestsStore } from '@/stores';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const router = useRouter();
const route = useRoute();

const billingStore = useBillingStore();
const seatRequestsStore = useSeatRequestsStore();

const { atCoreSeatLimit, atSalesSeatLimit, mustTalkToSales } = useSeats();
const { isFreeTier, hasSubscription } = storeToRefs(billingStore);

const { hasScope } = useAuth();
const canManageBilling = computed(() => hasScope('write:billing'));

const seatRequest = computed(() => props.params.data);

async function handleRejectRequest() {
  await seatRequestsStore.rejectSeatRequest(seatRequest.value.id);
}

async function handleApproveRequest() {
  await router.push({
    name: 'approve_seat_request',
    query: {
      cancelDestination: route.name,
      seat_type: seatRequest.value.type,
      emails: seatRequest.value.email,
    },
  });
}

/* CTAs/Upsell */
const readableSeatType = computed(() =>
  seatRequest.value?.type === CORE ? 'Full Access' : 'Sales',
);
const needsSeat = computed(() =>
  seatRequest.value?.type === CORE
    ? atCoreSeatLimit.value
    : atSalesSeatLimit.value,
);
const billingQuery = computed(() =>
  hasSubscription.value ? { [seatRequest.value?.type]: 1 } : null,
);
const upsellTooltipText = computed(() => {
  if (isFreeTier.value) {
    return seatRequest.value?.type === CORE
      ? 'Upgrade to Connector to unlock access to more Full Access seats'
      : 'Upgrade to Connector to unlock access to Sales seats';
  }
  return `You don't have an available ${readableSeatType.value} seat`;
});
const upsellButtonText = computed(() => {
  if (mustTalkToSales.value) return 'Talk to Sales';
  if (isFreeTier.value) return 'Upgrade';
  return 'Add seat';
});
</script>
<style lang="pcss">
.c-seat-request-table-actions-cell {
  @apply flex gap-4;
}
</style>
