<template>
  <BittsModal
    :visible="true"
    :width="600"
    :no-padding="true"
    :loading="loading"
    :show-buttons="false"
    class="remove-source-modal"
    @closed="closeModal"
  >
    <template #content>
      <div class="remove-source-modal__header">
        <div class="trash-background">
          <FontAwesomeIcon
            :icon="['fak', 'delete']"
            :style="{ height: '72px', width: '72px' }"
            class="text-danger-accent mx-auto w-full"
          />
        </div>
      </div>
      <h1 class="remove-source-modal__title">
        Delete {{ tableName }} {{ feedType }}?
      </h1>
      <div class="remove-source-modal__bottom">
        <h3 class="text-neutral-text-strong font-bold mb-8">
          Here's what will happen:
        </h3>
        <ul class="danger-list">
          <li
            v-for="(change, i) in changeList"
            :key="`feature__${i}`"
            class="danger-list__item"
          >
            <FontAwesomeIcon
              class="text-danger-accent text-18 mt-2"
              :icon="['fak', 'x']"
            />
            <span class="text-neutral-text text-base">
              {{ change }}
            </span>
          </li>
        </ul>
        <BittsInput
          v-model="deleteText"
          data-testid="delete-confirmation-input"
          name="delete-confirmation-input"
          form-label="Type DELETE to confirm"
          class="pt-24"
          placeholder="DELETE"
        />
        <div class="remove-source-modal__buttons">
          <BittsButton
            class="flex-1"
            text="Cancel"
            data-testid="cancel-button"
            type="neutral"
            size="large"
            @click="closeModal"
          />
          <BittsButton
            :disabled="!canDelete"
            :text="`Delete ${feedType}`"
            data-testid="delete-source-button"
            class="flex-1"
            size="large"
            type="danger"
            @click="deleteTable"
          />
        </div>
      </div>
    </template>
  </BittsModal>
</template>
<script setup>
import { BittsButton, BittsInput, BittsModal } from '@crossbeam/bitts';

import { computed, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { GOOGLE_SHEETS_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { captureException } from '@/errors';
import {
  useDataSharesStore,
  useFeedsStore,
  useFileUploadsStore,
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';

const props = defineProps({
  sourceId: {
    type: Number,
    required: true,
  },
});

const feedsStore = useFeedsStore();
const populationsStore = usePopulationsStore();
const dataSharesStore = useDataSharesStore();
const sourcesStore = useSourcesStore();
const fileUploadsStore = useFileUploadsStore();
const flashesStore = useFlashesStore();

const route = useRoute();
const router = useRouter();

const loading = ref(false);

const offlineSources = inject('offlineSources', null);
const deleteOfflineSource = inject('deleteOfflineSource', null);

const deleteText = ref('');
const canDelete = computed(() => deleteText.value.toLowerCase() === 'delete');

const offlinePartnerId = computed(() => route.query?.offlinePartnerId);
const offlinePartnerUuid = computed(() => route.query?.offlinePartnerUuid);

const source = computed(() =>
  offlinePartnerId.value
    ? offlineSources.value.find((s) => s.id === props.sourceId)
    : sourcesStore.getSourceById(props.sourceId),
);

const tableName = computed(() => source.value?.table);

const feedType = computed(() => {
  if (offlinePartnerId.value)
    return 'CSV'; /* TODO: Fix me when feeds API is ready */
  const feedId = sourcesStore.getSourceById(props.sourceId)?.feed_id;
  if (!feedId) return;
  const isGoogle =
    feedsStore.getFeedById(feedId)?.integration.type ===
    GOOGLE_SHEETS_DATA_SOURCE_TYPE;
  return isGoogle ? 'Google Sheet' : 'CSV';
});

const changeList = computed(() => {
  const base = [
    `This ${feedType.value} will immediately be deleted`,
    `Any Populations using this ${feedType.value} will be deleted`,
    'Those Populations will no longer be available in any reports',
    'This action will be permanent and irreversible',
  ];

  if (!offlinePartnerId.value)
    base.splice(1, 0, 'This data will no longer be shared with partners');
  return base;
});

async function closeModal() {
  const route = offlinePartnerId.value
    ? {
        name: 'partner_details',
        params: { partner_org_id: offlinePartnerId.value },
        query: { tab: 'data' },
      }
    : { name: 'data-sources' };

  await router.push(route);
}

async function deleteTable() {
  if (offlinePartnerUuid.value) {
    await deleteOfflineSource(source.value?.id, offlinePartnerUuid.value);
    await closeModal();
    return;
  }

  try {
    /* On delete, we need to refresh all the state for the feeds and data sources */
    loading.value = true;
    await sourcesStore.deleteSource(source.value?.id);
    const promises = [
      populationsStore.refreshPopulationsStore(),
      dataSharesStore.refreshDataSharesStore(),
      feedsStore.refreshFeedsStore(),
      sourcesStore.refreshSourcesStore(),
      fileUploadsStore.refreshFileUploadsStore(),
    ];
    await Promise.all(promises);
    flashesStore.addSuccessFlash({
      message: 'Your data source is scheduled to be deleted',
    });
  } catch (err) {
    flashesStore.addErrorFlash({ message: 'Could not delete table' });
    captureException(err);
  } finally {
    loading.value = false;
    await closeModal();
  }
}
</script>
<style lang="pcss">
.trash-background {
  @apply rounded-full w-120 h-120 p-24;
  background: radial-gradient(
    136.96% 69.92% at 50.09% -12.42%,
    theme(colors.danger.accent / 0.2) 0%,
    theme(colors.danger.accent / 0) 100%
  );
}

.remove-source-modal__header {
  @apply w-full flex flex-col items-center;
}

.remove-source-modal__title {
  @apply text-neutral-text-strong text-xl font-bold text-center mt-16 mb-24 mx-72;
}

.remove-source-modal__bottom {
  @apply bg-neutral-background-weak px-40 py-24 rounded-b-bts-lg;
}

.remove-source-modal__buttons {
  @apply w-full flex items-center gap-16 pt-24;
}
</style>

<style lang="pcss" scoped>
.danger-list__item {
  @apply flex items-start gap-6;
}

.danger-list {
  @apply flex flex-col gap-4;
}
</style>
