<template>
  <BittsButton
    :text="label"
    :loading="isRedirecting"
    size="small"
    type="neutral"
    variant="outline"
    @click="installSlack"
  >
    <template #icon>
      <BittsSvg svg="slackIcon" class="mr-6 w-12 h-12" />
    </template>
  </BittsButton>
</template>

<script>
import { BittsButton, BittsSvg } from '@crossbeam/bitts';

import axios from 'axios';

import { ls } from '@/local_storage';
import urls from '@/urls';

export default {
  name: 'ConnectSlackButton',
  components: {
    BittsButton,
    BittsSvg,
  },
  props: {
    nextUrl: {
      type: String,
      required: true,
    },
    isSlackEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRedirecting: false,
    };
  },
  computed: {
    label() {
      return this.isSlackEnabled
        ? 'Reauthorize Connection'
        : 'Connect to Slack';
    },
  },
  methods: {
    async installSlack() {
      this.isRedirecting = true;
      ls.nextUrl.set(this.nextUrl);
      const slackResponse = await axios.get(urls.slack.installUrl);
      window.location.href = slackResponse.data.slack_install_url;
    },
  },
};
</script>
